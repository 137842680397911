import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "../../Images/moment.svg";
import "./sideNav.css";
import PersonaMomentContext from "../../context/MomentsContext";
import Swal from "sweetalert2";
import {
  ChakraProvider,
  Divider,
  Heading,
  Img,
  Stack,
  Text,
} from "@chakra-ui/react";
import setting from "../../Images/settings.svg";
import customize from "../../Images/sliders.svg";
import billing from "../../Images/credit-card.svg";
import security from "../../Images/shield.svg";
import usage from "../../Images/usage.png";
import users from "../../Images/users.svg";
import shield from "../../Images/shield1.svg";
import { PassowordMenuIcon } from "../../customIcons/customIcons";
const AdminNav = () => {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activeUserData = JSON.parse(localStorage.getItem("user_info"));
  const [momentsList, setmomentsList] = useState([]);
  const { getMoments, moments } = useContext(PersonaMomentContext);
  // useEffect(() => {
  //   if (moment) {
  //     setmomentsList(moments);
  //   }
  // }, [moments]);

  // useEffect(() => {
  //   getMoments().then((res) => {});
  // }, []);
  const adminItems = [
    {
      icon: setting,
      title: "General",
      path: "/admin/general",
    },
    {
      icon: users,
      title: "Users",
      path: "/admin/users",
    },
    {
      icon: shield,
      title: "Password",
      path: "/admin/change-password",
    },
    // {
    //   icon: customize,
    //   title: "Customization",
    //   path: "/admin/customization",
    // },

    // {
    //   icon: security,
    //   title: "Security",
    //   path: "/admin/security",
    // },
    {
      icon: billing,
      title: "Billing",
      path: "/admin/billing",
    },
  ];
  const currentPath = window.location.pathname;
  return (
    <div id="sideNavDrawer">
      <div className="personaNav">
        {/* admin dashboard */}
        {/* {parsedToken?.data?.role === "SuperAdmin" ||
          (parsedToken?.data?.role === "Admin" && ( */}
        <ChakraProvider>
          {/* <Text marginBottom={"0"} fontWeight={"500"} fontSize={"1.2rem"}>
            Administration
          </Text>
          <Divider borderbottomColor={"#D4D7DA"} /> */}
          <Stack>
            {adminItems?.map(
              (item) =>
                (activeUserData?.role === "SuperAdmin" ||
                  activeUserData?.role === "Admin" ||
                  item?.title === "Password") && (
                  <Link className="" to={item.path}>
                    <Stack
                      className={
                        currentPath === item.path
                          ? "admin-item current"
                          : "admin-item"
                      }
                      direction="row"
                      spacing={2}
                    >
                      <Img src={item.icon} w={4} h={4} />
                      <Text fontSize={"1rem"} fontWeight={"500"}>
                        {item.title}
                      </Text>
                    </Stack>
                  </Link>
                )
            )}
          </Stack>
        </ChakraProvider>
        {/* ))} */}
      </div>
    </div>
  );
};

export default AdminNav;
