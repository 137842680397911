  import React, { useState, useEffect } from 'react';
  import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
  import { Editor } from 'react-draft-wysiwyg';
  import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

  const TextEditor = ({ text,oldStylingState,onTextChange,setisSaveDisabled }) => {

    const [editorState, setEditorState] = useState(() => {
      if (text) {
        const contentState = ContentState.createFromText(text);
        return EditorState.createWithContent(contentState);
      } else {
        return EditorState.createEmpty();
      }
    });
    const [editorText, setEditorText] = useState()
    const [editorStyling, seteditorStyling] = useState()
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    // Convert editorState to plain text and pass it to the parent component
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText();
    setEditorText(plainText)
    const raw = convertToRaw(currentContent);
     // Convert raw JS object to string (to save in backend)
  const serializedState = JSON.stringify(raw);
  seteditorStyling(serializedState)
  // console.log("serializedState",serializedState)
    setisSaveDisabled(false)
  };
  const handleBlur = () => {
    // console.log('editorText',editorText)
    onTextChange(editorText,editorStyling)
    // Add any additional logic you want to perform when the editor is blurred
  };
  

  useEffect(() => {
    if(oldStylingState){
     const  contentState = convertFromRaw(JSON.parse(oldStylingState))
     setEditorState(EditorState.createWithContent(contentState));
    }
    else if (text) {
      const contentState = ContentState.createFromText(text);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [text,oldStylingState]);

  const toolbarOptions = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily'], // Customize the toolbar options as needed
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    blockType: {
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
    },
  };

  
    return (
      <div >
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          toolbar={toolbarOptions}
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          onBlur={handleBlur}
        />
      </div>
    );
  };

  export default TextEditor;

