import React, { useState } from "react";
import "./Feedback.css";
import {
  Button,
  ChakraProvider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { submitFeedbackService } from "../../Service/service";
import Swal from "sweetalert2";
import CustomToaster from "../../utils/toaster/CustomToaster";
const Feedback = ({ position }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setloading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const submitFeedbackHandler = async () => {
    try {
      setloading(true);
      const res = await submitFeedbackService(inputValue);
      if (res.success) {
        setloading(false);
        // Swal.fire({
        //   icon: "success",
        //   title: res.message,
        // });
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(
          "Thank you for sharing your valuable feedback with us."
        );
        setInputValue("");
        onClose();
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: Feedback.js:36 ~ submitFeedbackHandler ~ error:",
        error
      );
    }
  };
  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <button
        onClick={onOpen}
        className={
          position == "not-fixed" ? "not-fixed-button" : "bottom-right-button"
        }
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18" cy="18" r="17.5" fill="white" stroke="#CED3D9" />
          <path
            d="M25.1,21.4c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h-9.4l-3.1,3.1V13.5c0-0.4,0.2-0.8,0.5-1.1
	s0.7-0.5,1.1-0.5h11c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1V21.4z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <ChakraProvider>
        <Modal
          scrollBehavior="inside"
          size={"2xl"}
          isCentered
          isOpen={isOpen}
          onClose={() => {
            setInputValue("");
            setloading(false);
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Your feedback is important to us.</ModalHeader>
            <ModalCloseButton />
            <ModalBody py={4}>
              <Text>
                Please give us a detailed description of the issue you are
                facing.
              </Text>
              <Textarea
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                rows={5}
              ></Textarea>

              <Stack mt={4} w="100%" alignItems={"end"}>
                <Button
                  isDisabled={
                    !inputValue || (inputValue && inputValue.trim().length == 0)
                  }
                  size="md"
                  fontWeight={"500"}
                  fontSize={"13px"}
                  type="submit"
                  borderRadius={"5px"}
                  bg="#21DC82"
                  w="fit-content"
                  alignSelf={"end"}
                  color="white"
                  isLoading={loading}
                  onClick={submitFeedbackHandler}
                >
                  SUBMIT
                </Button>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};

export default Feedback;
