import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import "./chat.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import CloseIcon from "@mui/icons-material/Close";
import emissaryLogo from "../../Images/chatLogo.png";
import addresource from "../../Images/addresource.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import alertIcon from "../../Images/alert.svg";
import {
  AddChatFileService,
  AddResourceService,
  CreatePersonaChatService,
  CreateProjectService,
  CreateResourceChatService,
  DeleteChatFileService,
  DeleteChatUrlService,
  UpdateChatService,
  UpdateProjectService,
  UpdateResourceChatService,
  chatModalService,
  deleteEntityService,
  deleteProjectService,
  deleteResourceService,
  elasticSearchService,
  getAllResourcesChatService,
  getAllResourcesService,
  getEvaluationService,
  getProjectsService,
  getPromptTextServices,
  getResourceGraphDataService,
  getResourceKeywordsService,
  getResourceSummaryService,
  getSingleChatService,
  getSingleResourceChatService,
  personaStyleService,
  resourceChatModalService,
  updatePromptTemplateService,
} from "../../Service/service";
import {
  Button,
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  Image,
  Img,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  background,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PersonaChatContext from "../../context/ChatContext";
import PersonaMomentContext from "../../context/MomentsContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BsFillInfoCircleFill } from "react-icons/bs";
import UserProfileContext from "../../context/UserContext";
import { FaUserCircle } from "react-icons/fa";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../feedback/Feedback";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Modal as BootstrapModal } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
  Chip,
  Avatar,
  Button as MuiButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  useMediaQuery,
} from "@mui/material";
import {
  BiAnalyseIcon,
  ChartIcon,
  CreateProjectIcon,
  DeleteIcon,
  DeleteRedIcon,
  EditIcon,
  FileGreyIcon,
  FileIconFilled,
  MassiveAllianceIcon,
  MenuIcon,
  MindIcon,
  OutlinedPlusIcon,
  ProjectIconFilled,
  SaveIcon,
  SendChatIcon,
  SideBarPersonaIcon,
  SideBarTeamsIcon,
  ThreedotActionButton,
  ThreedotMenuIcon,
  UploadIcon,
} from "../../customIcons/customIcons";
import CancelIcon from "@mui/icons-material/Cancel";
import ReusableButton from "../reusableComponents/ReusableButton";
import CustomToaster from "../../utils/toaster/CustomToaster";
import { MdInfo } from "react-icons/md";
import CustomFloatingEdgeGraph from "../knowledgegraph/CustomFloatingEdgeGraph";
import { processData } from "../knowledgegraph/utils";
import { ImEnlarge } from "react-icons/im";
import moment from "moment";
import { AiOutlineEdit } from "react-icons/ai";
const Chat = () => {
  const tabletDevices = useMediaQuery("(max-width:767px)");

  const {
    getProjects,
    projects,
    getChatSession,
    deleteSession,
    deleteAllResourceSession,
    sessions,
    updateChatTitle,
    updateChatArray,
    clearChatSessionArray,
    getResourceChatSessions,
    resourceChats,
    updateAllResourceChatTitle,
  } = useContext(PersonaChatContext);
  const [momentsList, setmomentsList] = useState([]);
  const { activeUserData } = useContext(UserProfileContext);
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
  });
  const [selectedSearchMethod, setSelectedSearchMethod] = useState({
    label: "Search exact phrase",
  });
  const [searchedMethod, setSearchedMethod] = useState();
  const [isClickedSearch, setisClickedSearch] = useState(false);

  const [searchMethodError, setSearchMethodError] = useState(false);
  const {
    isOpen: isOpenGraphModal,
    onOpen: onOpenGraphModal,
    onClose: onCloseGraphModal,
  } = useDisclosure();
  const { getMoments, moments, isDarkMode } = useContext(PersonaMomentContext);
  const [activeButton, setActiveButton] = useState("allData"); // default active button
  const [chatInputText, setChatInputText] = useState("");
  const [chatTitle, setChatTitle] = useState("");
  const [projectTitle, setprojectTitle] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [sessionId, setSessionId] = useState();
  const [memoryId, setMemoryId] = useState();
  const [selectedProject, setselectedProject] = useState();
  // console.log(selectedProject)
  const [selectedProjectId, setselectedProjectId] = useState();
  const [editedProject, setEditedProject] = useState();
  const [deleteProjectId, setDeleteProjectId] = useState();

  const [momentType, setMomentType] = useState("single");
  const [previousMomentType, setPreviousMomentType] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [targetChatId, setTargetChatId] = useState("");
  const [messages, setMessages] = useState([]);
  // console.log("messages",messages)
  const [selectedFileResource, setSelectedFileResource] = useState("");
  const [url, setURL] = useState("");
  const [projectName, setprojectName] = useState("");
  const [projectDescription, setprojectDescription] = useState("");
  const [allSessions, setAllSessions] = useState([]);
  const [evaluationMatric, setevaluationMatric] = useState([]);
  const [momentDropdown, setMomentDropdown] = useState(false);
  const [isShowMessages, setisShowMessages] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [chatLoader, setchatLoader] = useState(false);
  const [isDisabledChatBox, setisDisabledChatBox] = useState(true);
  const [createProjectButton, setCreateProjectButton] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [isTypeWriterEffect, setisTypeWriterEffect] = useState(true);
  const [editableItem, setEditableItem] = useState(null);
  const [deletedChatItem, setdeletedChatItem] = useState(null);
  const [sourceText, setSourceText] = useState();
  const [promptText, setPromptText] = useState();
  const [elasticSearchText, setElasticSearchText] = useState();
  const [searchedText, setSearchedText] = useState();
  const [synonymsRegex, setSynonymsRegex] = useState();
  const [synonymsArray, setSynonymsArray] = useState([]);
  const [cleanedWordsArray, setcleanedWordsArray] = useState([]);
  const [myPersonaData, setMyPersonaData] = useState([]);

  const [documentList, setDocumentList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  // console.log(selectedItems);
  const [selectedUrls, setSelectedUrls] = useState([]);
  // console.log(selectedUrls)

  const [blobUrlsArray, setBlobUrlsArray] = useState([]);
  const [isKeywordsEmpty, setisKeywordsEmpty] = useState(false);
  const [uploadedResourceFiles, setUploadedResourceFiles] = useState([]);
  const [errorToast, setErrorToast] = useState(false);
  const [isSearchResultEmpty, setisSearchResultEmpty] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [graphData, setGraphData] = useState(null);
  const [keywordsArray, setKeywordsArray] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState();
  const [refresh, setrefresh] = useState(1);
  const [loadGraphData, setLoadGraphData] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchedKeyword, setSearchedKeyword] = useState();
  const [personaResources, setPersonaResources] = useState([]);
  const [showResourceContentMore, setShowResourceContentMore] = useState(false);
  const [targetResource, setTargetResource] = useState();
  const [promptTextCopy, setPromptTextCopy] =
    useState(`In this conversation, you are {persona_name}. Respond in the first person as if you are {persona_name}.
    You are {persona_name}, Do not announce yourself as an AI model.
  
    Converse with the user using only the language and opinions of {persona_name},
    The following is information about your personal identity
    Make sure that your responses agree completely with your provided context:{personal_info}.
  
    Make sure your responses agree with the provided context, never try to use
    generativeness to create a response, Do not describe experiences, preferences, or ideas of {persona_name},
    that are not mentioned by {persona_name} in the context.
    
    Do not mention quotations or media, such as books or movies, that are not mentioned by {persona_name} in the context:
    
    Do not say anything about {persona_name}'s affinities that is not supported by the context: And/Or
    Do not say anything about any specific entities or situations that are not claimed by {persona_name} in context
    
    Affinities are mentioned in a variable below (list_of_words)
    Provide answers as stated in this document (Context) below:
  
    
  ---------------------
  
    "Context:
  "
    "{{context_str}}"
    
  ---------------------
  
  
    Do not try to generate answers that are not present in the Context or things that are not mentioned.
    Do not disclose that you are an AI. Share only what {persona_name} might naturally say based on the information here:{personal_info}.
  
    If you lack information for a question, answer based on any relevant piece of information. If you still don't find it, say, 'I don't have that information.'
  
    Use {persona_name}'s vocabulary and the following list of words to reflect {persona_name}'s tone in the chat:
    {list_of_words}
  
    Avoid mentioning specific situations or named entities unless the user asks. Respond as if you are {persona_name}, addressing the user's prompts or questions.

Your response MUST be concise and to the point.`);
  const [paragraphsArray, setparagraphsArray] = useState([
    {
      text: "Lorem Ipsum is simply dummy text of the typesetting industry. This is Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ]);
  const [summary, setSummary] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [isShowCount, setIsShowCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const truncatedText = showMore ? summary : summary?.slice(0, 200);
  const truncatedContentText = showResourceContentMore
    ? targetResource?.processedFileContent
    : targetResource?.processedFileContent?.slice(0, 1250);

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };
  const toggleResourceContentShowMore = () => {
    setShowResourceContentMore((prevShowMore) => !prevShowMore);
  };
  const [isShowDelete, setisShowDelete] = useState(false);
  const [disableSaveChangeButton, setdisableSaveChangeButton] = useState(true);
  const toast = useToast();
  const textareaRef = useRef(null);
  const contentRef = useRef(null);
  const bottomRef = useRef(null);
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [confirmDeleteFileModal, setconfirmDeleteFileModal] = useState(false);
  const [confirmDeleteProject, setconfirmDeleteProject] = useState(false);
  const [confirmDeleteChat, setconfirmDeleteChat] = useState(false);
  const [resourceType, setResourceType] = useState({
    label: "Document",
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loadEmbeddings, setLoadEmbeddings] = useState(true);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isContextChecked, setIsContextChecked] = useState(false);
  const [showMoments, setShowMoments] = useState(true);
  const [targetUrl, settTargetUrl] = useState();
  // console.log(targetUrl)
  const [targetFile, settTargetFile] = useState();
  // console.log(targetFile)
  const [resourceData, setResourceData] = useState();
  // console.log("🚀 ~ Chat ~ resourceData:", resourceData);
  const [allResources, setallResources] = useState();
  // console.log("🚀 ~ Chat ~ allResources:", allResources);
  // console.log("🚀 ~ Chat ~ allResources:", typeof allResources);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const openChatMenu = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickChatMenu = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseChatMenu = () => {
    setAnchorEl2(null);
  };
  const inputRef = useRef(null);
  const handleCheckboxChange = (event) => {
    setIsContextChecked(event.target.checked);
    // Additional logic if needed
  };

  const {
    isOpen: isOpenMind,
    onOpen: onOpenMind,
    onClose: onCloseMind,
  } = useDisclosure();
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const {
    isOpen: isOpenEvaluation,
    onOpen: onOpenEvaluation,
    onClose: onCloseEvaluation,
  } = useDisclosure();

  const isMatchingObject = (searchObject) => {
    // alert('invoked')
    // console.log(searchObject);
    const fileNameToMatch = searchObject.documentName ?? searchObject.fileName;
    // console.log(fileNameToMatch);
    const matchingObject = personaResources?.find(
      (resource) =>
        (resource.fileName === fileNameToMatch && resource.isFile === true) ||
        (resource.originalFileName === fileNameToMatch &&
          resource.isFile === true) ||
        (resource.processedFileName === fileNameToMatch &&
          resource.isFile === true)
    );
    // console.log("matching", matchingObject);

    if (matchingObject) {
      setTargetResource(matchingObject);
      onOpenDrawer();
      getResourceKeywords(matchingObject);
    }
    return Boolean(matchingObject);
  };
  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
      // console.log("invoked");
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    const callPersonaStyle = async () => {
      const activePersona = JSON.parse(
        localStorage.getItem("active_persona_data")
      );
      const active_user = JSON.parse(localStorage.getItem("active_user"));
      const res = await personaStyleService(
        activePersona?.epClientEmail,
        active_user?.email || active_user?.epClientEmail
      );
    };
    callPersonaStyle();

    const getPromptHandler = async () => {
      const res2 = await getPromptTextServices();
      if (res2.success) {
        setPromptText(res2.Data);
      }
    };
    getPromptHandler();
  }, []);

  useEffect(() => {
    const getProjectsHandler = async () => {
      const res = await getProjects();
    };
    getProjectsHandler();
  }, []);
  const btnRef = React.useRef();
  const handleProjectClicked = (project) => {
    // setisDisabledChatBox(true);
    setElasticSearchText("");
    setisShowMessages(true);
    setTargetChatId();
    setDocumentList([]);
    setSelectedItems([]);
    setisSearchResultEmpty(false);
    setShowUploadButton(true);
    setselectedProject(project);
    setselectedProjectId(project?._id);
    setMessages([]);
    setprojectName(project?.projectName);
    setprojectDescription(project?.projectDescription);
    nav("/chat");
    const transformedData = project?.documents?.map((item) => {
      return {
        docUrl: item?.docUrl,
        fileName: item?.documentName,
        _id: item?._id,
      };
    });
    setSelectedItems(transformedData);
    setSelectedUrls(project.urls);
    setBlobUrlsArray(project.urls);
    // GetProjectChats(project._id);
    GetSingleProjectChat(project._id);
  };

  const handleEdit = (itemId) => {
    setEditableItem(itemId);
    setisShowDelete(false);
  };
  const handleDelete = (itemId) => {
    setdeletedChatItem(itemId);
    setisShowDelete(true);
  };
  const handleCancelDelete = (event) => {
    event.stopPropagation();
    setdeletedChatItem(null);
    setisShowDelete(false);
  };
  const handleCancelEdit = (event) => {
    event.stopPropagation();
    setEditableItem(null);
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setChatInputText(value);
  };

  const nav = useNavigate();
  const location = useLocation();
  // console.log("🚀 ~ Chat ~ location:", location);
  useEffect(() => {
    setIsLoading(true);
    resetTextareaSize();
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    setCurrentEmail(activePersona?.epClientEmail);
    setMessages([]);
    setSessionId();
    setMemoryId();
    setMomentType("all");
    setSelectedValue();
    setElasticSearchText("");
    setDocumentList([]);
    setselectedProject();
    setselectedProjectId();
    setSelectedFileResource(null);
    setSelectedItems([]);
    setSelectedUrls([]);
    setUploadedResourceFiles([]);
    setisDisabledChatBox(true);
    setBlobUrlsArray([]);
    clearChatSessionArray();
    setprojectName("");
    setprojectDescription("");
    // nav("/chat");
    const getProjectss = async () => {
      const res = await getProjects();
      const res2 = await getResourceChatSessions();
      setIsLoading(false);
    };
    getProjectss();
  }, [updateChatArray]);

  const handleCopyClick = () => {
    // toast({
    //   description: "Copied to clipboard",
    //   duration: 1500,
    //   isClosable: true,
    //   position: "top",
    // });
    setSnackbarSeverity("success");
    setErrorToast(true);
    setSnackbarMessage("Copied to clipboard");
  };

  const handleAllDataClick = async () => {
    setActiveButton("allData");
    setMomentDropdown(false);
    setSelectedValue("");
    setMomentType("all");
  };

  const handleSingleMomentClick = () => {
    setActiveButton("singleMoment");
    setMomentType("single");
    setMomentDropdown(true);
    const select = document.getElementById("select-moment");
    const defaultOptionIndex = select.selectedIndex;
    select.selectedIndex = defaultOptionIndex;
  };

  const GetSingleProjectChat = async (id) => {
    setIsLoading(true);
    const res = await getSingleChatService(id);

    if (res.success) {
      setMessages(res.data.messages);
      setSessionId(res.data._id);
      setMemoryId(res?.data?.memoryId);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    if (textareaRef.current) {
      setTimeout(() => {
        textareaRef.current.focus();
      }, 0);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getResourceChatSessions().then((res) => {
      setAllSessions(res.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    // const personas = JSON.parse(localStorage.getItem("persona_list"));
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    // const persona = personas.find((item) => item._id === activePersona?._id);
    setCurrentEmail(activePersona?.epClientEmail);
  }, []);
  useEffect(() => {
    const getAllResources = async () => {
      setIsLoading(true);
      const res = await getAllResourcesService();
      if (res.success) {
        setPersonaResources(res.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    getAllResources();
  }, []);

  useEffect(() => {
    if (location) {
      if (location?.state?.from == "newchat") {
        setMessages([]);
        setChatInputText("");
        setSessionId();
        setMemoryId();
        setMomentType("all");
        setActiveButton("allData");
        setMomentDropdown(false);
        setSelectedValue("");
      }
      if (location?.state?.from == "chatsession") {
        getAllResourcesChatService(location?.state?.id).then((res) => {
          if (res.success) {
            setMessages(res.data.messages);
            setSessionId(res.data._id);
            setMemoryId(res.data?.memoryId);
            setChatInputText("");
            setSelectedItems(res.data.filesData);
            setTargetChatId(res.data._id);
          }
        });
      }
      if (location?.state?.from == "resources") {
        console.log(location?.state?.resourceData);
        if (location?.state?.resourceData?.length > 1) {
          // setResourceData(location?.state?.resourceData);

          const tempFileArray = location?.state?.resourceData
            ?.map((item) => {
              if (item.isFile === true) {
                return {
                  docUrl: item.fileUrl,
                  documentName: item.fileName,
                  _id: item?._id,
                };
              }
            })
            .filter(Boolean);
          const tempUrlsArray = location?.state?.resourceData
            ?.map((item) => {
              if (item.isFile === false) {
                return item.url;
              }
            })
            .filter(Boolean);

          setSelectedItems(tempFileArray);
          setSelectedUrls(tempUrlsArray);
          setResourceData(
            location?.state?.resourceData?.filter(
              (item) => item.isFile !== false
            )
          );
          setallResources(location?.state?.resourceData);
        } else {
          setResourceData(location?.state?.resourceData);
          setallResources(location?.state?.resourceData);

          getResourceChatHandler(location?.state?.resourceData?._id);
          if (location?.state?.resourceData?.isFile === true) {
            const tempFileArray = [
              {
                docUrl: location?.state?.resourceData?.fileUrl,
                documentName: location?.state?.resourceData?.fileName,
                _id: location?.state?.resourceData?._id,
              },
            ];
            setSelectedItems(tempFileArray);
          } else {
            setSelectedUrls([location?.state?.resourceData?.url]);
          }
        }
      }
      if (location?.state?.from == "projects") {
        setDocumentList([]);
        setselectedProject(location.state?.projectData);
        setMessages([]);
        setprojectName(location.state?.projectData?.projectName);
        setprojectDescription(location.state?.projectData?.projectDescription);
        nav("/chat");
        const transformedData = location.state?.projectData?.documents?.map(
          (item) => {
            return {
              docUrl: item?.docUrl,
              fileName: item?.documentName,
              _id: item?._id,
            };
          }
        );
        setSelectedItems(transformedData);
        setSelectedUrls(location.state?.projectData.urls);
        setBlobUrlsArray(location.state?.projectData.urls);
        // GetProjectChats(project._id);
        GetSingleProjectChat(location.state?.projectData._id);
      }
    }
  }, [location]);
  const getResourceChatHandler = async (id) => {
    const res = await getSingleResourceChatService(id);
    if (res.success) {
      setMessages(res.data.messages);
      setSessionId(res.data._id);
      setMemoryId(res?.data?.memoryId);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (messages && messages.length > 0) {
        updateChat();
      } else {
        createNewChat();
      }
    }
  };
  const resetTextareaSize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "3.5rem";
    textarea.style.overflow = "hidden";
    setChatInputText("");
  };
  const TextAreaAutoResize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "3.5rem";
    const scrollHeight = textarea.scrollHeight;

    if (scrollHeight > 220) {
      textarea.style.height = "22em"; // Set a max height of 212px
      textarea.style.overflow = "auto"; // Add scrollbar
    } else {
      textarea.style.height = `${scrollHeight}px`; // Adjust height based on content
      textarea.style.overflow = "hidden"; // Hide scrollbar if not needed
    }
  };

  // create new chat handler
  const createNewChat = async () => {
    if (allResources && !selectedProject) {
      if (allResources?.length > 1) {
        // alert("multiple resources");
        const tempFileArray = resourceData?.map((item) => {
          return item?.processedFileName;
        });
        resetTextareaSize();
        setMessages([
          ...messages,
          { text: chatInputText, reply: null, source: null },
        ]);
        try {
          const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));
          setchatLoader(true);
          const response = await resourceChatModalService(
            latestFive,
            activePersona?.creatorEmail,
            chatInputText,
            currentEmail,
            loadEmbeddings,
            true,
            resourceData?.correctedResourceName,
            resourceData?.isFile,
            "multiple",
            tempFileArray ? tempFileArray : [],
            selectedUrls ? selectedUrls : [],
            null
          );
          setLoadEmbeddings(false);
          setMemoryId(response?.memoryId);

          // save chat api
          try {
            const tempFileDataArray = resourceData?.map((item) => {
              return {
                processedFileName: item?.processedFileName,
                fileName: item?.fileName,
                fileUrl: item?.processedFileUrl,
              };
            });
            const saveRes = await CreateResourceChatService(
              chatInputText,
              response?.response == null
                ? `${response?.title} is not mentioned in the context information.`
                : response?.response,
              response?.title,
              response.source,
              resourceData?._id,
              tempFileDataArray ? tempFileDataArray : [],
              selectedUrls ? selectedUrls : [],
              response?.memoryId
            );
            if (saveRes?.success) {
              setTargetChatId(saveRes?.data?._id);
              resetTextareaSize();
              nav(`/chat/${saveRes?.data?._id}`, {
                state: { from: "chatsession", id: saveRes?.data?._id },
              });
              setselectedProject();
              setselectedProjectId();
              setEditedProject();
              setDeleteProjectId();
              setSelectedItems(saveRes?.data?.filesData);
              setSelectedUrls(saveRes?.data?.urls);
              setDocumentList([]);
              setisSearchResultEmpty(false);
              setElasticSearchText("");
              setisDisabledChatBox(false);
              setchatLoader(false);
              setMessages([...messages, saveRes?.data.messages[0]]);
              setSessionId(saveRes?.data._id);
              const res = await getResourceChatSessions();
              // scrollToBottom();
            } else {
              setchatLoader(false);
              // setisSendReqMsg(true);
            }
          } catch (error) {
            setchatLoader(false);
          }
        } catch (error) {
          console.log("error", error);
          setchatLoader(false);
        }
      } else {
        // alert("one resource");
        resetTextareaSize();
        setMessages([
          ...messages,
          { text: chatInputText, reply: null, source: null },
        ]);
        const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));
        try {
          setchatLoader(true);
          const response = await resourceChatModalService(
            latestFive,
            activePersona?.creatorEmail,
            chatInputText,
            currentEmail,
            loadEmbeddings,
            true,
            typeof resourceData === "object"
              ? resourceData?.correctedResourceName
              : resourceData[0]?.correctedResourceName,
            typeof resourceData === "object"
              ? resourceData?.isFile
              : resourceData[0]?.isFile,

            "single",
            [],
            [],
            null
          );
          setLoadEmbeddings(false);
          // save chat api
          try {
            const saveRes = await CreateResourceChatService(
              chatInputText,
              response?.response == null
                ? `${response?.title} is not mentioned in the context information.`
                : response?.response,
              response?.title,
              response.source,
              resourceData?._id,
              [],
              [],
              response?.memoryId
            );
            if (saveRes?.success) {
              setchatLoader(false);
              setMessages([...messages, saveRes?.data.messages[0]]);
              setSessionId(saveRes?.data._id);
              setMemoryId(response?.memoryId);
              // const res = await getResourceChatSessions();
              // scrollToBottom();
            } else {
              setchatLoader(false);
              // setisSendReqMsg(true);
            }
          } catch (error) {
            setchatLoader(false);
          }
        } catch (error) {
          console.log("error", error);
          setchatLoader(false);
        }
      }
    } else if (selectedProject) {
      // alert('invoked')
      const tempFileArray = selectedProject?.documents?.map((item) => {
        return item?.documentName;
      });
      // console.log(tempFileArray)
      resetTextareaSize();
      const active_user = JSON.parse(localStorage.getItem("active_user"));
      setisTypeWriterEffect(true);
      setMessages([
        ...messages,
        { text: chatInputText, reply: null, source: null },
      ]);
      try {
        setchatLoader(true);
        const minValue = selectedValue === "" ? null : selectedValue;
        const response = await chatModalService(
          [],
          selectedProject?.projectName,
          active_user?.email || active_user?.epClientEmail,
          "single",
          chatInputText,
          currentEmail,
          loadEmbeddings,
          true,
          null,
          tempFileArray,
          selectedProject?.urls
        );
        setLoadEmbeddings(false);
        setMemoryId(response?.memoryId);
        // save chat api
        setPreviousMomentType(momentType);
        try {
          const saveRes = await CreatePersonaChatService(
            chatInputText,
            response?.response == null
              ? `${response?.title} is not mentioned in the context information.`
              : response?.response,
            response?.title,
            response.source,
            selectedProject?._id,
            response?.memoryId
          );
          if (saveRes?.success) {
            setchatLoader(false);
            setMessages([...messages, saveRes?.data.messages[0]]);
            setSessionId(saveRes?.data._id);
            const res = await getChatSession(selectedProject?._id);
            // scrollToBottom();
          } else {
            setchatLoader(false);
            // setisSendReqMsg(true);
          }
        } catch (error) {
          setchatLoader(false);
        }
        // }
      } catch (error) {
        console.log("error", error);
        setchatLoader(false);
      }
    } else {
      const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));
      resetTextareaSize();
      setMessages([
        ...messages,
        { text: chatInputText, reply: null, source: null },
      ]);
      try {
        const tempFileArray = selectedItems?.map((item) => {
          return item.processedFileName ?? item.fileName;
        });
        setchatLoader(true);
        const response = await resourceChatModalService(
          latestFive,
          activePersona?.creatorEmail,
          chatInputText,
          currentEmail,
          loadEmbeddings,
          true,
          "no name",
          true,
          selectedItems?.length + selectedUrls?.length === 0
            ? "all"
            : "multiple",
          selectedItems?.length === 0 ? [] : tempFileArray,
          selectedUrls?.length === 0 ? [] : selectedUrls,
          null
        );
        setLoadEmbeddings(false);
        // console.log("🚀 ~ createNewChat ~ response:", response);
        // save chat api
        try {
          const tempFileArray2 = selectedItems?.map((item) => ({
            fileName: item.processedFileName ?? item.fileName,
            fileUrl: item?.url,
          }));
          const saveRes = await CreateResourceChatService(
            chatInputText,
            response?.response == null
              ? `${response?.title} is not mentioned in the context information.`
              : response?.response,
            response?.title,
            response.source,
            null,
            selectedItems?.length === 0 ? [] : tempFileArray2,
            selectedUrls?.length === 0 ? [] : selectedUrls,
            response?.memoryId
          );
          if (saveRes?.success) {
            setTargetChatId(saveRes?.data?._id);
            resetTextareaSize();
            nav(`/chat/${saveRes?.data?._id}`, {
              state: { from: "chatsession", id: saveRes?.data?._id },
            });
            setselectedProject();
            setselectedProjectId();
            setEditedProject();
            setDeleteProjectId();
            setSelectedItems(saveRes?.data?.filesData);
            setSelectedUrls(saveRes?.data?.urls);
            setDocumentList([]);
            setisSearchResultEmpty(false);
            setElasticSearchText("");
            setisDisabledChatBox(false);
            setchatLoader(false);
            setMessages([...messages, saveRes?.data.messages[0]]);
            setSessionId(saveRes?.data._id);
            setchatLoader(false);
            setMessages([...messages, saveRes?.data.messages[0]]);
            setSessionId(saveRes?.data._id);
            setMemoryId(response?.memoryId);
            const res = await getResourceChatSessions();
            if (res.success) {
              setAllSessions(res.data);
            }
            // scrollToBottom();
          } else {
            setchatLoader(false);
            // setisSendReqMsg(true);
          }
        } catch (error) {
          setchatLoader(false);
        }
      } catch (error) {
        console.log("error", error);
        setchatLoader(false);
      }
    }
  };
  // update chat handler
  const updateChat = async () => {
    resetTextareaSize();
    const active_user = JSON.parse(localStorage.getItem("active_user"));
    // setisTypeWriterEffect(true);
    setMessages([
      ...messages,
      { text: chatInputText, reply: null, source: null },
    ]);
    if (location?.state?.from === "resources") {
      if (allResources?.length > 1 && !selectedProject) {
        const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));
        const tempFileArray = resourceData?.map((item) => {
          return item?.processedFileName || item.fileName;
        });
        try {
          setchatLoader(true);
          const response = await resourceChatModalService(
            latestFive,
            activePersona?.creatorEmail,
            chatInputText,
            currentEmail,
            loadEmbeddings,
            false,
            resourceData?.correctedResourceName,
            resourceData?.isFile,
            "multiple",
            tempFileArray ? tempFileArray : [],
            selectedUrls ? selectedUrls : [],
            memoryId ? memoryId : null
          );
          setLoadEmbeddings(false);
          setMemoryId(response?.memoryId);
          // save chat api
          try {
            const saveRes = await UpdateResourceChatService(
              sessionId,
              chatInputText,
              response?.response == null
                ? `It is not mentioned in the context information.`
                : response?.response,
              response?.memoryId
            );
            if (saveRes?.success) {
              setchatLoader(false);
              setSessionId(saveRes?.data._id);
              setMemoryId(response?.memoryId);
              setMessages(saveRes?.data.messages);
              // scrollToBottom();
            } else {
              setchatLoader(false);
            }
          } catch (error) {
            setchatLoader(false);
          }
          // }
        } catch (error) {
          console.log("error", error);
          setchatLoader(false);
        }
      } else {
        const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));
        try {
          setchatLoader(true);
          const response = await resourceChatModalService(
            latestFive,
            activePersona?.creatorEmail,
            chatInputText,
            currentEmail,
            loadEmbeddings,
            false,
            resourceData?.correctedResourceName,
            resourceData?.isFile,
            "single",
            [],
            [],
            memoryId ? memoryId : null
          );
          setLoadEmbeddings(false);
          setMemoryId(response?.memoryId);
          // save chat api
          try {
            const saveRes = await UpdateResourceChatService(
              sessionId,
              chatInputText,
              response?.response == null
                ? `It is not mentioned in the context information.`
                : response?.response,
              response?.memoryId
            );
            if (saveRes?.success) {
              setchatLoader(false);
              setSessionId(saveRes?.data._id);
              setMemoryId(response?.memoryId);
              setMessages(saveRes?.data.messages);
              // scrollToBottom();
            } else {
              setchatLoader(false);
            }
          } catch (error) {
            setchatLoader(false);
          }
          // }
        } catch (error) {
          console.log("error", error);
          setchatLoader(false);
        }
      }
    } else if (selectedProject) {
      const tempFileArray = selectedProject?.documents?.map((item) => {
        return item?.documentName;
      });
      const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));

      // console.log(tempFileArray)
      try {
        setchatLoader(true);
        const minValue = selectedValue === "" ? null : selectedValue;
        const response = await chatModalService(
          latestFive,
          selectedProject?.projectName,
          active_user?.email || active_user?.epClientEmail,
          "single",
          chatInputText,
          currentEmail,
          loadEmbeddings,
          false,
          memoryId ? memoryId : null,
          tempFileArray,
          selectedProject?.urls
        );
        setLoadEmbeddings(false);
        setPreviousMomentType(momentType);
        // save chat api
        try {
          const saveRes = await UpdateChatService(
            sessionId,
            chatInputText,
            response?.response == null
              ? `It is not mentioned in the context information.`
              : response?.response,
            response.source,
            response?.memoryId
          );
          if (saveRes?.success) {
            setchatLoader(false);
            setSessionId(saveRes?.data._id);
            setMemoryId(response?.memoryId);
            setMessages(saveRes?.data.messages);
            // scrollToBottom();
          } else {
            setchatLoader(false);
          }
        } catch (error) {
          setchatLoader(false);
        }
        // }
      } catch (error) {
        console.log("error", error);
        setchatLoader(false);
      }
    } else {
      try {
        const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));
        const tempFileArray = selectedItems?.map((item) => {
          return item?.processedFileName;
        });
        setchatLoader(true);
        const response = await resourceChatModalService(
          latestFive,
          activePersona?.creatorEmail,
          chatInputText,
          currentEmail,
          loadEmbeddings,
          false,
          "no name",
          true,
          selectedItems?.length + selectedUrls?.length === 0
            ? "all"
            : "multiple",
          selectedItems?.length === 0 ? [] : tempFileArray,
          selectedUrls?.length === 0 ? [] : selectedUrls,
          memoryId ? memoryId : null
        );
        setLoadEmbeddings(false);
        setMemoryId(response?.memoryId);
        // save chat api
        try {
          const saveRes = await UpdateResourceChatService(
            sessionId,
            chatInputText,
            response?.response == null
              ? `It is not mentioned in the context information.`
              : response?.response,
            response?.memoryId
          );
          if (saveRes?.success) {
            setchatLoader(false);
            setSessionId(saveRes?.data._id);
            setMemoryId(response?.memoryId);
            setMessages(saveRes?.data.messages);
            // scrollToBottom();
          } else {
            setchatLoader(false);
          }
        } catch (error) {
          setchatLoader(false);
        }
        // }
      } catch (error) {
        console.log("error", error);
        setchatLoader(false);
      }
    }
  };

  const renderTextWithLineBreaks = (text) => {
    const lines = text.split("\n");
    return lines.map((line, index) => (
      <Text marginBottom={0} key={index}>
        {line}
        <br />
      </Text>
    ));
  };
  const UpdateChatNameHandler = async (chatId, title) => {
    const chattitle = chatTitle == "" ? title : chatTitle;
    const res = await updateAllResourceChatTitle(chatId, chattitle);
    if (res.success) {
      setEditableItem(null);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
    }
  };
  const UpdateProjectNameHandler = async (projectt, title) => {
    const projectTitles = title == "" ? title : projectTitle;
    // console.log(projectTitle);
    const formData = new FormData();
    formData.append("projectId", projectt?._id);
    formData.append("projectName", projectTitles);
    formData.append("description", projectt?.projectDescription);
    const res = await UpdateProjectService(formData);
    if (res.success) {
      setEditedProject();
      const res2 = await getProjects();
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
    }
  };
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activeUser = JSON.parse(localStorage.getItem("user_info"));

  const updatePromptTemplateHandler = async () => {
    try {
      setIsLoading(true);
      const res = await updatePromptTemplateService(promptText);
      if (res.success) {
        setIsLoading(false);
        onClose();
        setdisableSaveChangeButton(true);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
      } else {
        setIsLoading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(
        "🚀 ~ file: Chat.js:418 ~ updatePromptTemplateHandler ~ error:",
        error
      );
    }
  };

  const getChatEvaluationHandler = async (query, response, context) => {
    try {
      setIsLoading(true);
      const res = await getEvaluationService(query, response, context);
      if (res.success) {
        setevaluationMatric(res.data);
        setIsLoading(false);
        onOpenEvaluation();
      } else {
        setIsLoading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(
        "🚀 ~ file: Chat.js:418 ~ updatePromptTemplateHandler ~ error:",
        error
      );
    }
  };

  const resourceTypes = [
    { label: "Document" },
    { label: "External Link" },
    // { label: "RSS" },
  ];

  function filterSearchResults(documents, searchResults, threshold = 0.1) {
    // Iterate over search results array
    const filteredResults = searchResults.filter((result) => {
      // Check if fileName is an exact match with any documentName
      const exactMatch = documents.some(
        (document) =>
          result.fileName.toLowerCase() === document.documentName.toLowerCase()
      );

      // If an exact match is found, exclude the result from the filtered array
      if (exactMatch) {
        return false;
      } else {
        return true;
      }
    });
    // console.log("🚀 ~ filteredResults ~ filteredResults:", filteredResults);
    if (filteredResults?.length > 0) {
      setisSearchResultEmpty(false);
    } else {
      setisSearchResultEmpty(true);
    }
    // console.log("🚀 ~ filteredResults ~ filteredResults:", filteredResults);
    setDocumentList(filteredResults);
    return filteredResults;
  }

  const elasticSearchHandler = async () => {
    // setMessages([]);
    setisShowMessages(false);
    setisClickedSearch(true);

    // Check if there is text in the search document field and a search method is selected
    if (
      !elasticSearchText ||
      (elasticSearchText && elasticSearchText.trim().length === 0)
    ) {
      // Set an error state for the search document field
      setErrorToast(true);
      setSnackbarMessage("Search requires input text");
      setSnackbarSeverity("error");
      setisClickedSearch(false);
      return;
    }

    try {
      const words = elasticSearchText?.match(
        /(?:[^\s"']+|"([^"]*)"|'([^']*)')/g
      );

      // Filter out null values, flatten the array, and update the search array
      const filteredWords = words
        ? words.flat().filter((word) => word !== null)
        : [];

      // Remove quotes and other characters attached to words
      const cleanedWords = filteredWords?.map((word) =>
        word.replace(/['"\\]+/g, "")
      );
      setcleanedWordsArray(cleanedWords);
      closeAllAccordions();
      setSearchedText(elasticSearchText);
      setSearchedMethod(selectedSearchMethod.label);
      setIsLoading(true);
      const startDateObject = new Date(filterData?.start_date);
      const startFormattedDate = startDateObject?.toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      const endDateObject = new Date(filterData?.end_date);
      const endFormattedDate = endDateObject?.toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      const res = await elasticSearchService(
        activePersona?.creatorEmail,
        activePersona?.epClientEmail,
        cleanedWords,
        filterData?.start_date !== "" ? startFormattedDate : "",
        filterData?.end_date !== "" ? endFormattedDate : ""
      );
      if (res.success) {
        // Add 'show' property to each object
        const updatedDocumentList = res.data.map((item) => ({
          ...item,
          show: true,
        }));
        if (selectedProject) {
          filterSearchResults(selectedProject?.documents, updatedDocumentList);
        } else {
          setDocumentList(updatedDocumentList);
        }

        if (res.synonyms !== null) {
          setSynonymsArray(res.synonyms);
          const highlightSynonyms = res.synonyms.join("|");
          const synnysmRegex = new RegExp(`\\b(${highlightSynonyms})\\b`, "gi");
          setSynonymsRegex(synnysmRegex);
          const lowercasedSynonymsArray = res.synonyms.map((synonym) =>
            synonym.toLowerCase()
          );
          setSynonymsArray(lowercasedSynonymsArray);
        }
        if (res.data.length > 0) {
          // setIsSearchResultEmpty(false);
          setCreateProjectButton(true);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error:", error);
    }
  };

  const handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      // Call your function here
      elasticSearchHandler();
    }
  };

  const closeAllAccordions = () => {
    const newExpandedState = {};
    documentList.forEach((_, index) => {
      newExpandedState[index] = false;
    });
    setExpanded(newExpandedState);
  };
  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: isExpanded,
    }));
  };

  const handleAddToSelectedItems = async (item) => {
    if (location?.state?.from === "chatsession") {
      setIsLoading(true);
      const res = await AddChatFileService(sessionId, {
        fileUrl: item?.url,
        fileName: item?.fileName,
        processedFileName: item?.fileName,
      });
      if (res.success) {
        setLoadEmbeddings(true);
        setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
        setDocumentList((prevDocumentList) =>
          prevDocumentList.map((doc) =>
            doc.url === item.url ? { ...doc, show: false } : doc
          )
        );
        if (targetChatId) {
          const res2 = await getResourceChatSessions();
          const fetchedChat = res2?.data?.filter(
            (itm) => itm?._id === sessionId
          );
          setSelectedItems(fetchedChat && fetchedChat[0]?.filesData);
          setSelectedUrls(fetchedChat && fetchedChat[0]?.urls);
        }
        setIsLoading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
      } else {
        setIsLoading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
      }
    } else {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
      // Set the 'show' property to false for the added item
      setDocumentList((prevDocumentList) =>
        prevDocumentList.map((doc) =>
          doc.url === item.url ? { ...doc, show: false } : doc
        )
      );
    }
  };

  const handleDeleteTag = (deletedItem) => {
    if (
      location?.state?.from === "resources" &&
      allResources?.length > 1 &&
      messages?.length === 0
    ) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.docUrl !== deletedItem.docUrl)
      );
      setResourceData((prevSelectedItems) => {
        const filteredItems = prevSelectedItems.filter(
          (item) => item.fileUrl !== deletedItem.docUrl
        );

        // Check if only one element is left after filtering
        if (filteredItems.length === 1) {
          // Convert the array to an object
          const singleObject = { ...filteredItems[0] };
          // Update state with the object
          return singleObject;
        } else {
          // If more than one element is left or none, update state with the filtered array
          return filteredItems;
        }
      });
      setallResources((prevSelectedItems) => {
        const filteredItems = prevSelectedItems.filter(
          (item) => item.fileUrl !== deletedItem.docUrl
        );

        // Check if only one element is left after filtering
        if (filteredItems.length === 1) {
          // Convert the array to an object
          const singleObject = { ...filteredItems[0] };
          // Update state with the object
          return singleObject;
        } else {
          // If more than one element is left or none, update state with the filtered array
          return filteredItems;
        }
      });
    } else if (
      "_id" in deletedItem ||
      (location?.state?.from === "resources" && messages?.length !== 0)
    ) {
      // It has an "_id" field, show an alert

      settTargetFile(deletedItem?._id);
      setconfirmDeleteFileModal(true);
    } else if ("show" in deletedItem) {
      // It's an accordion item, toggle the 'show' property
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.url !== deletedItem.url)
      );
      // Toggle the 'show' property for the deleted item
      setDocumentList((prevDocumentList) =>
        prevDocumentList.map((doc) =>
          doc.url === deletedItem.url ? { ...doc, show: true } : doc
        )
      );
    } else {
      // It's a directly uploaded file, remove it from selectedItems
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.name !== deletedItem.name)
      );
    }
  };

  const handleDeleteResource = async () => {
    if (
      (location?.state?.from === "chatsession" && !targetUrl) ||
      (location?.state?.from === "resources" &&
        allResources?.length > 1 &&
        !targetUrl)
    ) {
      setIsLoading(true);
      const res = await DeleteChatFileService(sessionId, targetFile);
      if (res.success) {
        setLoadEmbeddings(true);
        setSelectedItems((prevSelectedItems) =>
          prevSelectedItems.filter((item) => item._id !== targetFile)
        );
        const res5 = await getResourceChatSessions();
        const fetchedChat = res5.data.filter((itm) => itm?._id === sessionId);
        setSelectedItems(fetchedChat && fetchedChat[0]?.filesData);
        setSelectedUrls(fetchedChat && fetchedChat[0]?.urls);
        setconfirmDeleteFileModal(false);
        setIsLoading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
      }
    } else if (
      location?.state?.from === "resources" ||
      location?.state?.from === "chatsession"
    ) {
      if (targetUrl) {
        // alert('invoked')
        setIsLoading(true);

        const res = await DeleteChatUrlService(sessionId, targetUrl);
        if (res.success) {
          setLoadEmbeddings(true);
          setSelectedUrls((prevSelectedItems) =>
            prevSelectedItems.filter((url) => url !== targetUrl)
          );
          setBlobUrlsArray((prevSelectedItems) =>
            prevSelectedItems.filter((url) => url !== targetUrl)
          );
          setconfirmDeleteFileModal(false);
          setIsLoading(false);
          setErrorToast(true);
          setSnackbarMessage(res.message);
          setSnackbarSeverity("success");
        }
      } else {
        setIsLoading(true);

        const res = await deleteResourceService(
          resourceData?._id,
          targetUrl,
          targetFile
        );
        if (res.success) {
          setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.filter((item) => item._id !== targetFile)
          );
          setSelectedUrls((prevSelectedItems) =>
            prevSelectedItems.filter((url) => url !== targetUrl)
          );
          setBlobUrlsArray((prevSelectedItems) =>
            prevSelectedItems.filter((url) => url !== targetUrl)
          );
          setconfirmDeleteFileModal(false);
          nav("/chat");
          setMessages([]);
          setSessionId();
          setMemoryId();
          setIsLoading(false);
          setErrorToast(true);
          setSnackbarMessage(res.message);
          setSnackbarSeverity("success");
        }
      }
    } else {
      // alert('invoked')
      setIsLoading(true);
      const res = await deleteEntityService(
        selectedProject?._id,
        targetUrl,
        targetFile
      );
      if (res.success) {
        settTargetUrl();
        settTargetFile();
        if (selectedProject) {
          const res4 = await getProjects();
          const fetchSelectedProject = res4.data?.filter(
            (itm) => itm._id === selectedProject?._id
          );
          console.log(fetchSelectedProject);
          setselectedProject(fetchSelectedProject && fetchSelectedProject[0]);
          setselectedProjectId(
            fetchSelectedProject && fetchSelectedProject[0]?._id
          );
        }
        setLoadEmbeddings(true);
        setSelectedItems((prevSelectedItems) =>
          prevSelectedItems.filter((item) => item._id !== targetFile)
        );
        setSelectedUrls((prevSelectedItems) =>
          prevSelectedItems.filter((url) => url !== targetUrl)
        );
        setBlobUrlsArray((prevSelectedItems) =>
          prevSelectedItems.filter((url) => url !== targetUrl)
        );
        setconfirmDeleteFileModal(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
        setIsLoading(false);
      }
    }
  };

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    // Trigger click on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event) => {
    // Handle file upload logic here
    const selectedFile = event.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      // Check if the file size is greater than 6MB
      if (fileSizeInMB > 6) {
        setErrorToast(true);
        setSnackbarMessage("Please upload a file with a size less than 6MB.");
        setSnackbarSeverity("error");
        // Reset the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      } else {
        setSelectedFileResource(selectedFile);
        // Call your API here with the selected file
        // Example: callApi(selectedFile);
      }
    }
    // Perform your file upload logic with the selectedFile
  };

  const handleResourceTypeChange = (_, newValue) => {
    setResourceType(newValue);
  };
  const handleAddToUrlArray = () => {
    if (url?.trim().length === 0) {
      setErrorToast(true);
      setSnackbarMessage("Please provide a link");
      setSnackbarSeverity("error");
    } else {
      // Check if the URL already exists in the array
      if (blobUrlsArray?.includes(url)) {
        // Show error toaster if the URL already exists
        setErrorToast(true);
        setSnackbarMessage("This url already exists");
        setSnackbarSeverity("error");
      } else {
        if (selectedProject || targetChatId) {
          handleUploadLinkResource();
        } else {
          // Add the URL to the array if it doesn't exist
          setSelectedUrls((prevSelectedItems) => [...prevSelectedItems, url]);
          setURL();
          setShowAddResourceModal(false);
        }
      }
    }
  };

  const handleAddToFilesArray = async () => {
    if (!selectedFileResource) {
      setErrorToast(true);
      setSnackbarMessage("Please upload a document");
      setSnackbarSeverity("error");
    } else {
      // Check if the URL already exists in the array
      if (uploadedResourceFiles.includes(selectedFileResource)) {
        // Show error toaster if the URL already exists
        setErrorToast(true);
        setSnackbarMessage("This file already exists");
        setSnackbarSeverity("error");
      } else {
        if (selectedProject || targetChatId) {
          handleUploadFileResource();
        } else {
          // Add the URL to the array if it doesn't exist
          setUploadedResourceFiles((prevSelectedItems) => [
            ...prevSelectedItems,
            selectedFileResource,
          ]);
          setSelectedFileResource();
          setShowAddResourceModal(false);
        }
      }
    }
  };

  const handleCloseErrorToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorToast(false);
  };

  const handleRemoveFromUrlArray = async (item) => {
    if (
      (location?.state?.from === "resources" && messages?.length !== 0) ||
      blobUrlsArray.includes(item) ||
      location?.state?.from === "chatsession"
    ) {
      settTargetUrl(item);
      setconfirmDeleteFileModal(true);
    } else if (
      location?.state?.from === "resources" &&
      messages?.length === 0
    ) {
      setSelectedUrls((prevSelectedItems) =>
        prevSelectedItems.filter((url) => url !== item)
      );
      setallResources(
        allResources?.filter((resourceItem) => resourceItem?.docUrl !== item)
      );
    } else {
      // Remove the URL from the array
      setSelectedUrls((prevSelectedItems) =>
        prevSelectedItems.filter((url) => url !== item)
      );
    }
  };

  const handleRemoveFromFilesArray = (item) => {
    // Remove the URL from the array
    setUploadedResourceFiles((prevSelectedItems) =>
      prevSelectedItems.filter((file) => file?.name !== item?.name)
    );
  };

  const CreateProjectHandler = async () => {
    if (projectName?.trim().length === 0) {
      setSnackbarMessage("Project name is required");
      setSnackbarSeverity("error");
      setErrorToast(true);
      return;
    } else if (projectDescription?.trim().length === 0) {
      setSnackbarMessage("Project description is required");
      setSnackbarSeverity("error");
      setErrorToast(true);
      return;
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("makerId", parsedToken?.data?._id);
      formData.append("personaId", activePersona?._id);
      formData.append("projectName", projectName);
      formData.append("description", projectDescription);

      selectedUrls.forEach((string, index) => {
        formData.append(`urls[${index}]`, string);
      });
      // for (let i = 0; i < uploadedResourceFiles.length; i++) {
      //   formData.append("files", uploadedResourceFiles[i]);
      // }
      selectedItems.forEach((item, index) => {
        Object.entries(item).forEach(([key, value]) => {
          let processedKey = key;

          // Check and update key names
          if (key === "fileUrl" || key === "url") {
            processedKey = "url";
          } else if (key === "processedFileName") {
            processedKey = "fileName";
          } else if (key === "fileName") {
            processedKey = "fileName";
          } else {
            // Skip appending for keys other than 'fileUrl' and 'processedFileName'
            return;
          }

          formData.append(`selectedData[${index}][${processedKey}]`, value);
        });
      });

      if (targetChatId && location?.state?.from === "chatsession") {
        formData.append("chatId", targetChatId);
      }

      const res = await CreateProjectService(formData);
      if (res.success) {
        setCreateProjectModal(false);
        setDocumentList([]);
        setUploadedResourceFiles([]);
        setSelectedUrls([]);
        setSelectedItems([]);
        setMessages([]);
        setElasticSearchText("");
        setprojectName("");
        setprojectDescription("");
        const res2 = await getProjects();
        const res3 = await getResourceChatSessions();
        setselectedProject(res.data);
        setselectedProjectId(res.data._id);
        const transformedData = res.data?.documents?.map((item) => {
          return {
            docUrl: item?.docUrl,
            fileName: item?.documentName,
            _id: item?._id,
          };
        });
        setisShowMessages(true);
        setSelectedItems(transformedData);
        setSelectedUrls(res.data?.urls);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
        setErrorToast(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
        setErrorToast(true);
      }
    }
  };
  const UpdateProjectHandler = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("projectId", selectedProject?._id);
    formData.append("projectName", projectName);
    formData.append("description", projectDescription);
    selectedUrls.forEach((string, index) => {
      formData.append(`urls[${index}]`, string);
    });
    for (let i = 0; i < uploadedResourceFiles.length; i++) {
      formData.append("files", uploadedResourceFiles[i]);
    }

    selectedItems
      .filter((item) => !("_id" in item)) // Filter out items with '_id' key
      .forEach((item, index) => {
        Object.entries(item).forEach(([key, value]) => {
          // Exclude keys that are "content" or "score"
          if (key !== "content" && key !== "score" && key !== "show") {
            formData.append(`selectedData[${index}][${key}]`, value);
          }
        });
      });

    const res = await UpdateProjectService(formData);
    console.log(res);
    if (res.success) {
      setLoadEmbeddings(true);
      setCreateProjectModal(false);
      setDocumentList([]);
      setUploadedResourceFiles([]);
      setSelectedUrls(res.project.urls);
      setSelectedItems(res.project.documents);
      setElasticSearchText("");
      const res4 = await getProjects();
      const fetchSelectedProject = res4.data?.filter(
        (itm) => itm._id === selectedProjectId
      );
      // console.log(fetchSelectedProject);
      setselectedProject(fetchSelectedProject && fetchSelectedProject[0]);
      setselectedProjectId(
        fetchSelectedProject && fetchSelectedProject[0]?._id
      );

      setSnackbarMessage("Project updated successfully");
      setSnackbarSeverity("success");
      setErrorToast(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
      setErrorToast(true);
    }
  };
  const getTruncatedLabel = (item) => {
    if (item.name) {
      return item.name.length > 15
        ? `${item.name.substring(0, 15)}...`
        : item.name;
    } else if (item.fileName) {
      return item.fileName.length > 15
        ? `${item.fileName.substring(0, 15)}...`
        : item.fileName;
    } else if (item.documentName) {
      return item.documentName.length > 15
        ? `${item.documentName.substring(0, 15)}...`
        : item.documentName;
    }

    return ""; // Default label if none of the keys exist
  };

  const handleUploadLinkResource = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("personaId", activePersona?._id);
    formData.append("makerId", parsedToken?.data?._id);
    formData.append("url", url);
    if (selectedProject) {
      formData.append("projectId", selectedProjectId);
    }
    if (targetChatId) {
      formData.append("chatId", sessionId);
    }
    const res = await AddResourceService(formData);
    if (res.success) {
      setLoadEmbeddings(true);
      setURL();
      setShowAddResourceModal(false);
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
      if (selectedProject) {
        const res4 = await getProjects();
        const fetchSelectedProject = res4.data?.filter(
          (itm) => itm._id === selectedProjectId
        );
        // console.log(fetchSelectedProject);
        setselectedProject(fetchSelectedProject && fetchSelectedProject[0]);
        setselectedProjectId(
          fetchSelectedProject && fetchSelectedProject[0]?._id
        );
        setprojectName(
          fetchSelectedProject && fetchSelectedProject[0]?.projectName
        );
        setprojectDescription(
          fetchSelectedProject && fetchSelectedProject[0]?.projectDescription
        );
        const transformedData =
          fetchSelectedProject &&
          fetchSelectedProject[0]?.documents?.map((item) => {
            return {
              docUrl: item?.docUrl,
              fileName: item?.documentName,
              _id: item?._id,
            };
          });
        setSelectedItems(transformedData);
        setSelectedUrls(fetchSelectedProject[0]?.urls);
      }
      if (targetChatId) {
        // alert('invoked')
        const res5 = await getResourceChatSessions();
        // console.log("🚀 ~ handleUploadLinkResource ~ res5:", res5);
        const fetchSelectedChat = res5?.data?.filter(
          (itm) => itm._id === targetChatId
        );
        // console.log(
        //   "🚀 ~ handleUploadLinkResource ~ fetchSelectedChat:",
        //   fetchSelectedChat
        // );
        setSelectedItems(fetchSelectedChat[0]?.filesData);
        setSelectedUrls(fetchSelectedChat[0]?.urls);
      }
      // const res3 = await getResourceKeywordsService(res.data?._id, false);
    } else {
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
    }
  };

  const handleUploadFileResource = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("personaId", activePersona?._id);
    formData.append("makerId", parsedToken?.data?._id);
    if (selectedProject) {
      formData.append("projectId", selectedProjectId);
    }
    if (targetChatId) {
      formData.append("chatId", targetChatId);
    }

    formData.append("files", selectedFileResource);
    const res = await AddResourceService(formData);
    // console.log("🚀 ~ handleUploadFileResource ~ res:", res);
    if (res.success) {
      setElasticSearchText("");
      // setSelectedItems(res.data)
      if (!selectedProject && !targetChatId) {
        nav("/chat", { state: { from: "resources", resourceData: res.data } });
      }
      setLoadEmbeddings(true);
      setSelectedFileResource();
      setShowAddResourceModal(false);
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
      const res3 = await getResourceKeywordsService(res.data?._id, false);
      if (selectedProject) {
        const res4 = await getProjects();
        const fetchSelectedProject = res4.data?.filter(
          (itm) => itm._id === selectedProjectId
        );
        // console.log(fetchSelectedProject);
        setselectedProject(fetchSelectedProject && fetchSelectedProject[0]);
        setselectedProjectId(
          fetchSelectedProject && fetchSelectedProject[0]?._id
        );
        setprojectName(
          fetchSelectedProject && fetchSelectedProject[0]?.projectName
        );
        setprojectDescription(
          fetchSelectedProject && fetchSelectedProject[0]?.projectDescription
        );
        // console.log(fetchSelectedProject);
        const transformedData =
          fetchSelectedProject &&
          fetchSelectedProject[0]?.documents?.map((item) => {
            return {
              docUrl: item?.docUrl,
              fileName: item?.documentName,
              _id: item?._id,
            };
          });
        setSelectedItems(transformedData);
        setSelectedUrls(fetchSelectedProject[0]?.urls);
      }
      if (targetChatId) {
        // alert('invoked')
        const res5 = await getResourceChatSessions();
        // console.log("🚀 ~ handleUploadLinkResource ~ res5:", res5);
        const fetchSelectedChat = res5?.data?.filter(
          (itm) => itm._id === targetChatId
        );
        // console.log(
        //   "🚀 ~ handleUploadLinkResource ~ fetchSelectedChat:",
        //   fetchSelectedChat
        // );
        setSelectedItems(fetchSelectedChat[0]?.filesData);
        setSelectedUrls(fetchSelectedChat[0]?.urls);
      }
    } else {
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
      setIsLoading(false);
    }
  };

  const highlightWords = searchedText?.split(/\s+/).join("|");
  const highlightRegex = new RegExp(`\\b(${highlightWords})\\b`, "gi");

  const getResourceKeywords = async (item) => {
    console.log("matching resource", item);
    try {
      setIsLoading(true);
      const res2 = await getResourceKeywordsService(item?._id, false);

      if (res2.success && res2.data !== null) {
        const inputData2 = res2?.data;
        const newCategories = Object.keys(inputData2).map((categoryKey) => {
          return {
            title: categoryKey,
            people: inputData2[categoryKey].map((keyword) => ({
              keyword,
              isSelected: false,
            })),
          };
        });

        const nonEmptyCategories = newCategories
          .map((category) => ({
            ...category,
            people: category.people.filter(
              (person) => person.keyword.length >= 3
            ),
          }))
          .filter((category) => category.people.length > 0);

        setKeywordsArray(nonEmptyCategories);
        setIsShowCount(isShowCount + 1);
      } else {
        setisKeywordsEmpty(true);
      }

      const summaryResponse = await getResourceSummaryService(item?._id);
      if (summaryResponse.success) {
        // setisSummaryError("");
        setSummary(summaryResponse.data);
        setIsLoading(false);
      } else {
        // setisSummaryError(summaryResponse.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(
        "🚀 ~ file: AnalizeMoment.js:250 ~ getKeywords ~ error:",
        error
      );
    }
  };

  useEffect(() => {
    const updateKeywordCounts = async () => {
      const updatedKeywordsArray = keywordsArray
        ?.map((category) => {
          const keywordMap = new Map();

          category.people.forEach((keywordObj) => {
            const count = countKeywordOccurrences(keywordObj.keyword);
            if (count > 0) {
              if (keywordMap.has(keywordObj.keyword)) {
                keywordMap.get(keywordObj.keyword).count += count;
              } else {
                keywordMap.set(keywordObj.keyword, { ...keywordObj, count });
              }
            }
          });

          // Convert the Map to an array for sorting
          const people = Array.from(keywordMap.values());

          // Sort the people array by count in descending order
          people.sort((a, b) => b.count - a.count);

          return { ...category, people };
        })
        .filter((category) => category.people.length > 0);

      setKeywordsArray(updatedKeywordsArray);
    };
    if (isShowCount > 0) {
      updateKeywordCounts();
    }
  }, [isShowCount]);
  const handleKeywordClick = (categoryIndex, keywordIndex) => {
    const updatedKeywords = keywordsArray?.map((category, cIndex) => ({
      ...category,
      people: category.people.map((keywordObj, kIndex) => ({
        ...keywordObj,
        isSelected:
          cIndex === categoryIndex && kIndex === keywordIndex ? true : false,
      })),
    }));
    setKeywordsArray(updatedKeywords);
  };
  useEffect(() => {
    return () => {
      processData({
        edges: [],
        nodes: [],
      });
    };
  }, []);
  const countKeywordOccurrences = (keyword) => {
    const regex = new RegExp(
      `\\b${keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}\\b`,
      "gi"
    );

    const matches = targetResource?.processedFileContent?.match(regex);
    return matches ? matches.length : 0;
  };

  const getGraphDataHandler = async (keyword) => {
    setIsLoading(true);
    try {
      const res = await getResourceGraphDataService(
        targetResource?.correctedResourceName,
        null,
        keyword,
        loadGraphData
      );
      if (res.success) {
        setLoadGraphData(false);
        setrefresh(refresh + 1);
        const processedGraphData = processData(res.graph, keyword);
        setGraphData(processedGraphData);
        setIsLoading(false);
      } else {
        setErrorToast(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AnalizeMoment.js:308 ~ getGraphDataHandler ~ error:",
        error
      );
    }
  };

  const ReAnalyzeKeywordsHandler = async () => {
    const res2 = await getResourceKeywordsService(targetResource?._id, true);

    if (res2.success) {
      setisKeywordsEmpty(true);
      setKeywordsArray([]);
      const inputData2 = res2?.data;
      const newCategories = Object.keys(inputData2).map((categoryKey) => {
        return {
          title: categoryKey,
          people: inputData2[categoryKey].map((keyword) => ({
            keyword,
            isSelected: false,
          })),
        };
      });

      const nonEmptyCategories = newCategories
        .map((category) => ({
          ...category,
          people: category.people.filter(
            (person) => person.keyword.length >= 3
          ),
        }))
        .filter((category) => category.people.length > 0);

      setKeywordsArray(nonEmptyCategories);
      setIsShowCount(isShowCount + 1);
    } else {
      setisKeywordsEmpty(true);
    }
  };
  const handleDeleteProject = async () => {
    setIsLoading(true);
    const res = await deleteProjectService(deleteProjectId);
    if (res.success) {
      setDeleteProjectId();
      const res2 = await getProjects();
      setconfirmDeleteProject(false);
      setSelectedItems([]);
      setSelectedUrls([]);
      setMessages([]);
      nav("/chat");
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
    } else {
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
    }
  };
  const lowercasedCleanedWordsArray = cleanedWordsArray?.map((word) =>
    word.toLowerCase()
  );

  // Convert synonymsArray to lowercase
  const lowercasedSynonymsArray = synonymsArray?.map((word) =>
    word.toLowerCase()
  );
  useEffect(() => {
    const personaList = localStorage.getItem("my_persona_list");
    if (personaList) {
      const myPersonas = JSON.parse(personaList);
      setMyPersonaData(myPersonas);
    }
    // getMoments().then(() => {});
  }, []);
  const datePickerRef = useRef(null);
  const initializeFlatpickr = () => {
    const options = {
      mode: "range",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr) {
        const [startDate, endDate] = dateStr.split(" to ");
        if (endDate) {
          setFilterData((prev) => ({
            ...prev,
            start_date: startDate || "",
            end_date: endDate || startDate || "",
          }));
        }
      },
    };
    flatpickrInstanceRef.current = flatpickr(datePickerRef.current, options);
  };
  const clearDate = () => {
    flatpickrInstanceRef.current.clear();
    setFilterData({
      start_date: "",
      end_date: "",
    });
  };

  const flatpickrInstanceRef = useRef(null);

  useEffect(() => {
    const options = {
      mode: "range",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr) {
        const [startDate, endDate] = dateStr.split(" to ");
        // console.log("🚀  useEffect  endDate:", endDate);
        // console.log("🚀  useEffect  startDate:", startDate);

        if (endDate) {
          setFilterData((prev) => ({
            ...prev,
            start_date: startDate || "",
            end_date: endDate || startDate || "",
          }));
        }
      },
    };
    flatpickrInstanceRef.current = flatpickr(datePickerRef.current, options);
    flatpickr(datePickerRef.current, options);
  }, []);

  useEffect(() => {
    initializeFlatpickr();
    return () => {
      // Cleanup Flatpickr instance
      flatpickrInstanceRef.current.destroy();
    };
  }, []);
  const searchComponentRef = useRef(null);
  const closeSearchComponent = useCallback(() => {
    // Retrieve the Flatpickr calendar element
    const flatpickrCalendar = document.querySelector('.flatpickr-calendar');
  
    // Check if the Flatpickr calendar is open
    const isDatePickerOpen = flatpickrCalendar && flatpickrCalendar.classList.contains('open');
  
    const createModal = document.getElementById("chat-create-modal");
  
    // If the create modal is not present and the date picker is not open, close the search component
    if (!createModal && !isDatePickerOpen) {
      closeSearch();
    }
  }, []);

  useEffect(() => {
    const handleOverlayClick = (e) => {
      if (
        searchComponentRef.current &&
        !searchComponentRef.current.contains(e.target) 
      ) {
        closeSearchComponent();
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, []);

  const closeSearch = () => {
    setElasticSearchText("");
    setisShowMessages(true);
    clearDate();
    setDocumentList([]);
    setSelectedSearchMethod({
      label: "Search exact phrase",
    });
  };
  return (
    <>
      {/* Error toaster for duplicate URL */}
      <CustomToaster
        open={errorToast}
        onClose={handleCloseErrorToast}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback />

      <NavBar />
      <div
        ref={contentRef}
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <div className="chat-tab">
          <div className="mainContainer">
            {" "}
            <input type="checkbox" id="drawer-toggle" name="drawer-toggle" />
            <label for="drawer-toggle" id="drawer-toggle-label"></label>
            <div id="drawer" className="left-sidebar">
              <div style={{ paddingInline: "1rem", paddingTop: "1rem" }}>
                <Link
                  onClick={() => {
                    setMessages([]);
                    setSessionId();
                    setMemoryId();
                    setSelectedItems([]);
                    setselectedProject();
                    setselectedProjectId();
                    setSelectedUrls([]);
                    setResourceData();
                    setallResources();
                    setTargetChatId();
                    setisSearchResultEmpty(false);
                    setprojectName();
                    setprojectDescription();
                    setShowUploadButton(true);
                    if (selectedProject) {
                      setisDisabledChatBox(false);
                    }
                    resetTextareaSize();
                  }}
                  to={"/chat"}
                  state={{ from: "newchat" }}
                  className="newChatBtn"
                >
                  New Chat
                </Link>
              </div>
              <div className="chats-container">
                <Accordion
                  defaultExpanded
                  sx={{
                    boxShadow: "none",
                    padding: "0",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      padding: "0",
                      color: "#76828b",
                      fontWeight: "600",
                      fontSize: "0.75rem",
                      minHeight: "2.5rem !important",
                      maxHeight: "2.5rem",
                    }}
                  >
                    <Typography>PROJECTS</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0",
                    }}
                  >
                    {/* project names */}
                    <div className="project-container">
                      {projects?.map((item) => (
                        <div
                          onClick={() => handleProjectClicked(item)}
                          // onMouseEnter={() => setselectedProjectId(item?._id)}
                          className="project-item"
                          style={{
                            background:
                              selectedProject?._id === item?._id
                                ? "#f5f6f7"
                                : "transparent",
                            borderRadius:
                              selectedProject?._id === item?._id ? "5px" : "",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <ProjectIconFilled />
                            {editedProject === item?._id && !deleteProjectId ? (
                              <input
                                onChange={(e) => {
                                  setprojectTitle(e.target.value);
                                }}
                                defaultValue={item?.projectName}
                                onClick={(e) => e.stopPropagation()}
                                onBlur={(e) => {
                                  UpdateProjectNameHandler(
                                    item,
                                    e.target.value
                                  );
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    UpdateProjectNameHandler(
                                      item,
                                      e.target.value
                                    );
                                  }
                                }}
                                style={{
                                  maxWidth: "8rem",
                                  marginLeft: "0.5rem",
                                  paddingLeft: "0.3rem",
                                  borderRadius: "4px",
                                  background: "white",
                                  border: "1px solid #CED3D9",
                                }}
                              />
                            ) : (
                              <span
                                className="project-title"
                                style={{
                                  color: "#051D2C",
                                  fontWeight: "500",
                                  whiteSpace: "nowrap",
                                  maxWidth:
                                    selectedProject?._id === item?._id
                                      ? "7rem"
                                      : "10rem",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {item?.projectName}
                              </span>
                            )}
                          </div>
                          {selectedProjectId === item?._id && (
                            <>
                              <MuiButton
                                sx={{ minWidth: "fit-content" }}
                                id="basic-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClick(e);
                                }}
                              >
                                <ThreedotActionButton />
                              </MuiButton>
                              <MuiMenu
                                PaperProps={{
                                  style: {
                                    minWidth: "133px", // Set your desired width here
                                  },
                                }}
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={(e) => {
                                  e.stopPropagation();
                                  handleClose();
                                }}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MuiMenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEditedProject(item?._id);
                                    setDeleteProjectId();
                                    handleClose();
                                  }}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    <EditIcon />
                                    <span
                                      style={{
                                        color: "#051D2C",
                                        fontSize: "1rem",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Rename
                                    </span>
                                  </div>
                                </MuiMenuItem>
                                <MuiMenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteProjectId(item?._id);
                                    setconfirmDeleteProject(true);
                                    setEditedProject();

                                    handleClose();
                                  }}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    <DeleteRedIcon />
                                    <span
                                      style={{
                                        color: "#C51333",
                                        fontSize: "1rem",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Delete
                                    </span>
                                  </div>
                                </MuiMenuItem>
                              </MuiMenu>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  defaultExpanded
                  sx={{
                    boxShadow: "none",
                    padding: "0",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    sx={{
                      padding: "0",
                      color: "#76828b",
                      fontWeight: "600",
                      fontSize: "0.75rem",
                      minHeight: "2.5rem !important",
                      maxHeight: "2.5rem",
                    }}
                  >
                    <Typography>CHAT HISTORY</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0",
                    }}
                  >
                    {/* Chat sessions */}
                    <div className="chatHistory">
                      {resourceChats?.map((chat, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            resetTextareaSize();
                            setPreviousMomentType("");
                            nav(`/chat/${chat._id}`, {
                              state: { from: "chatsession", id: chat?._id },
                            });
                            setselectedProject();
                            setselectedProjectId();
                            setEditedProject();
                            setDeleteProjectId();
                            setSelectedItems(chat?.filesData);
                            setSelectedUrls(chat?.urls);
                            setDocumentList([]);
                            setisSearchResultEmpty(false);
                            setElasticSearchText("");
                            setisDisabledChatBox(false);
                            setTargetChatId(chat?._id);
                          }}
                          className="chatHistory_item"
                          style={{
                            background:
                              targetChatId === chat?._id ? "#f6f6f6" : "",
                          }}
                        >
                          <div className="chatItem-primary">
                            {editableItem === chat?._id ? (
                              <Input
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    UpdateChatNameHandler(
                                      chat?._id,
                                      chat.title === ""
                                        ? chat?.messages[0].text.slice(0, 13)
                                        : chat?.title
                                    );
                                  }
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                className="edit-title"
                                onChange={(e) => setChatTitle(e.target.value)}
                                defaultValue={
                                  chat.title === ""
                                    ? chat?.messages[0].text.slice(0, 13)
                                    : chat?.title
                                }
                                size="sm"
                              />
                            ) : (
                              <div className="chat-title">
                                {chat?.title === ""
                                  ? chat?.messages[0].text
                                  : chat?.title}
                              </div>
                            )}

                            <div
                              style={{
                                display:
                                  targetChatId === chat?._id ? "flex" : "none",
                              }}
                              className="chatHistory_buttons"
                            >
                              {targetChatId === chat?._id && (
                                <>
                                  <MuiButton
                                    sx={{ minWidth: "fit-content" }}
                                    id="basic-button-2"
                                    aria-controls={
                                      openChatMenu ? "basic-menu-2" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      openChatMenu ? "true" : undefined
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickChatMenu(e);
                                    }}
                                  >
                                    <ThreedotActionButton />
                                  </MuiButton>
                                  <MuiMenu
                                    PaperProps={{
                                      style: {
                                        minWidth: "133px", // Set your desired width here
                                      },
                                    }}
                                    id="basic-menu-2"
                                    anchorEl={anchorEl2}
                                    open={openChatMenu}
                                    onClose={(e) => {
                                      e.stopPropagation();
                                      handleCloseChatMenu();
                                    }}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button-2",
                                    }}
                                  >
                                    <MuiMenuItem
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(chat._id);
                                        setdeletedChatItem();
                                        handleCloseChatMenu();
                                      }}
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <EditIcon />
                                        <span
                                          style={{
                                            color: "#051D2C",
                                            fontSize: "1rem",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Rename
                                        </span>
                                      </div>
                                    </MuiMenuItem>
                                    <MuiMenuItem
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setconfirmDeleteChat(true);
                                        handleDelete(chat?._id);
                                        setEditableItem();
                                        handleCloseChatMenu();
                                      }}
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <DeleteRedIcon />
                                        <span
                                          style={{
                                            color: "#C51333",
                                            fontSize: "1rem",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Delete
                                        </span>
                                      </div>
                                    </MuiMenuItem>
                                  </MuiMenu>
                                </>
                              )}
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              {/* MENU DROPDOWN */}
              <div
                style={{ padding: "1rem" }}
                className="d-flex flex-column gap-2"
              >
                <Link className="chatNav-item custom-link" to={"/team"}>
                  <span className="d-flex align-items-center gap-3">
                    <SideBarTeamsIcon
                      style={{
                        height: "1.1rem",
                        width: "1.1rem",
                        fontWeight: "bold",
                      }}
                    />
                    Teams
                  </span>
                </Link>
                <Link className="chatNav-item custom-link" to={"/resources"}>
                  <span className="d-flex align-items-center gap-3">
                    <MindIcon
                      style={{
                        height: "1.1rem",
                        width: "1.1rem",
                        fontWeight: "bold",
                      }}
                    />
                    Resources
                  </span>
                </Link>

                {parsedToken?.data?.role !== "Collaborator" &&
                parsedToken?.data?.role !== "User" ? (
                  <Link
                    className="chatNav-item custom-link"
                    to={"/edit-persona"}
                  >
                    <span className="d-flex align-items-center gap-3">
                      <SideBarPersonaIcon
                        style={{
                          height: "1.1rem",
                          width: "1.1rem",
                          fontWeight: "bold",
                        }}
                      />
                      Persona
                    </span>
                  </Link>
                ) : myPersonaData?.some(
                    (persona) =>
                      JSON.stringify(persona) === JSON.stringify(activePersona)
                  ) ? (
                  <Link
                    className="chatNav-item custom-link"
                    to={"/edit-persona"}
                  >
                    <span className="d-flex align-items-center gap-3">
                      <SideBarPersonaIcon
                        style={{
                          height: "1.1rem",
                          width: "1.1rem",
                          fontWeight: "bold",
                        }}
                      />
                      Persona
                    </span>
                  </Link>
                ) : (
                  <ChakraProvider>
                    <Tooltip hasArrow label="Not Allowed" fontSize="sm">
                      <Link className="notallowed-link">
                      <span className="d-flex align-items-center gap-3">
                      <SideBarPersonaIcon
                        style={{
                          height: "1.1rem",
                          width: "1.1rem",
                          fontWeight: "bold",
                        }}
                      />
                      Persona
                    </span>
                      </Link>
                    </Tooltip>
                  </ChakraProvider>
                )}
                <Link className="chatNav-item custom-link" to={"/projects"}>
                  <span className="d-flex align-items-center gap-3">
                    <CreateProjectIcon
                      style={{
                        height: "1.1rem",
                        width: "1.1rem",
                        fontWeight: "bold",
                      }}
                    />
                    Projects
                  </span>
                </Link>
                <Link className="chatNav-item custom-link" to={"/brand-guide"}>
                  <span className="d-flex align-items-center gap-3">
                    <MassiveAllianceIcon
                      style={{
                        height: "1.1rem",
                        width: "1.1rem",
                        fontWeight: "bold",
                      }}
                    />
                    Massive Alliance
                  </span>
                </Link>
              </div>
              {/* <ChakraProvider>
                <Menu bg="#F9F9F9" isLazy>
                  <MenuButton
                    style={{
                      position: "fixed",
                      background: "white",
                      bottom: "0",
                      paddingBottom: "0.714rem",
                      width: "14rem",
                    }}
                  >
                    <div className="menuButton">
                      <MenuIcon />
                      <span className="mb-0">Menu</span>
                    </div>
                  </MenuButton>
                  <MenuList bg="#F9F9F9">
                    <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                      <Link className="chatNav-item custom-link" to={"/team"}>
                        <span className="d-flex align-items-center gap-3">
                          <SideBarTeamsIcon
                            style={{
                              height: "1.1rem",
                              width: "1.1rem",
                              fontWeight: "bold",
                            }}
                          />
                          Teams
                        </span>
                      </Link>
                    </MenuItem>
                    <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                      {" "}
                      <Link className="chatNav-item custom-link" to={"/resources"}>
                        <span className="d-flex align-items-center gap-3">
                          <MindIcon
                            style={{
                              height: "1.1rem",
                              width: "1.1rem",
                              fontWeight: "bold",
                            }}
                          />
                          Resources
                        </span>
                      </Link>
                    </MenuItem>
                    <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                      <Link className="chatNav-item custom-link" to={"/edit-persona"}>
                        <span className="d-flex align-items-center gap-3">
                          <SideBarPersonaIcon
                            style={{
                              height: "1.1rem",
                              width: "1.1rem",
                              fontWeight: "bold",
                            }}
                          />
                          Persona
                        </span>
                      </Link>
                    </MenuItem>
                    <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                      {" "}
                      <Link className="chatNav-item custom-link" to={"/projects"}>
                        <span className="d-flex align-items-center gap-3">
                          <CreateProjectIcon
                            style={{
                              height: "1.1rem",
                              width: "1.1rem",
                              fontWeight: "bold",
                            }}
                          />
                          Projects
                        </span>
                      </Link>
                    </MenuItem>
                    <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                      {" "}
                      <Link className="chatNav-item custom-link" to={"/brand-guide"}>
                        <span className="d-flex align-items-center gap-3">
                          <MassiveAllianceIcon
                            style={{
                              height: "1.1rem",
                              width: "1.1rem",
                              fontWeight: "bold",
                            }}
                          />
                          Massive Alliance
                        </span>
                      </Link>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </ChakraProvider> */}
            </div>
            <div className="mainContainer-right">
              {/* chat section */}
              <div className="emissaryChat">
                {/* chat box */}
                <div className="emissaryChat-chatbox">
                  <div ref={searchComponentRef} className="chatHeader">
                    <div className="chatHeader-inner">
                      {/* elastic search  */}
                      <div className="d-flex w-100 gap-2">
                        <Box sx={{ flexGrow: "1", position: "relative" }}>
                          <TextField
                            size="small"
                            value={elasticSearchText}
                            onChange={(e) =>
                              setElasticSearchText(e.target.value)
                            }
                            fullWidth
                            label="Search"
                            onKeyDown={handleKeyPressSearch}
                            sx={{ background: "white" }}
                          />
                          <ChakraProvider>
                            <Button
                              isDisabled={isShowMessages}
                              onClick={closeSearch}
                              className="clearSearch"
                              _hover={{}}
                            >
                              <CloseIcon />
                            </Button>{" "}
                          </ChakraProvider>
                          <ChakraProvider>
                            <Tooltip
                              placement="top"
                              hasArrow
                              label="Search for multiple words exactly by wrapping them in single or double quotations for precise results. For example: 'United States' America"
                              aria-label="A tooltip"
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  right: "7px",
                                  bottom: "8px",
                                }}
                                className="cursor-pointer"
                              >
                                <MdInfo fontSize={"1.5rem"} />
                              </span>
                            </Tooltip>
                          </ChakraProvider>
                        </Box>
                        <Box sx={{ width: tabletDevices ? "150px" : "250px" }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              gap: 8,
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <input
                                style={{
                                  fontSize: tabletDevices ? "0.8rem" : "1rem",
                                  height: "2.7rem",
                                  borderRadius: "4px",
                                  width: "100%",
                                  border: "1px solid #C7C7C7",
                                  cursor: "pointer",
                                }}
                                className="bg-whiteGrey filter-date rounded-lg outline-none p-3 W-100"
                                type="text"
                                placeholder="Filter by date"
                                ref={datePickerRef}
                                // disabled={!showpersonaProjects}
                              />
                            </div>

                            <div
                              style={{
                                display: showUploadButton ? "flex" : "none",
                              }}
                              onClick={() => {
                                setShowAddResourceModal(true);
                              }}
                            >
                              {/* Upload icon with onClick handler */}
                              <IconButton className="upload-button">
                                <UploadIcon />
                              </IconButton>
                            </div>

                            <div
                              style={{
                                display: createProjectButton ? "flex" : "none",
                              }}
                              onClick={() => setCreateProjectModal(true)}
                              className="save-button"
                            >
                              <SaveIcon
                                style={{ height: "1.5rem", width: "1.5rem" }}
                              />
                            </div>
                          </div>
                        </Box>
                      </div>
                    </div>
                    {/* selected files/chips */}
                    <div className="chips-container-outer">
                      <div className="chips-container">
                        {selectedItems?.map((item, index) => (
                          <Chip
                            onClick={() => {
                              isMatchingObject(item);
                            }}
                            key={index}
                            size="medium"
                            label={getTruncatedLabel(item)}
                            onDelete={() => handleDeleteTag(item)}
                            sx={{
                              backgroundColor: "#4775D5",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              "& .MuiChip-label": {
                                fontSize: "12px",
                              },
                              "&:hover": {
                                backgroundColor: "#4775D5", // Change to your desired hover color
                              },
                            }}
                            deleteIcon={
                              <CancelIcon style={{ color: "white" }} />
                            }
                            avatar={
                              <Avatar style={{ backgroundColor: "#35579E" }}>
                                <FileIconFilled
                                  style={{ height: "24px", width: "24px" }}
                                />
                              </Avatar>
                            }
                          />
                        ))}
                        {uploadedResourceFiles?.map((item, index) => (
                          <Chip
                            key={index}
                            size="medium"
                            label={
                              item?.name?.length > 15
                                ? `${item?.name.substring(0, 15)}...`
                                : item?.name
                            }
                            onDelete={() => handleRemoveFromFilesArray(item)}
                            sx={{
                              backgroundColor: "#4775D5",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              "& .MuiChip-label": {
                                fontSize: "12px",
                              },
                            }}
                            deleteIcon={
                              <CancelIcon style={{ color: "white" }} />
                            }
                            avatar={
                              <Avatar style={{ backgroundColor: "#35579E" }}>
                                <FileIconFilled
                                  style={{ height: "24px", width: "24px" }}
                                />
                              </Avatar>
                            }
                          />
                        ))}
                        {selectedUrls?.map((item, index) => (
                          <Chip
                            key={index}
                            size="medium"
                            label={
                              item?.length > 15
                                ? `${item?.substring(0, 15)}...`
                                : item
                            }
                            onDelete={() => handleRemoveFromUrlArray(item)}
                            sx={{
                              backgroundColor: "#4775D5",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              "& .MuiChip-label": {
                                fontSize: "12px",
                              },
                            }}
                            deleteIcon={
                              <CancelIcon style={{ color: "white" }} />
                            }
                            avatar={
                              <Avatar style={{ backgroundColor: "#35579E" }}>
                                <FileIconFilled
                                  style={{ height: "24px", width: "24px" }}
                                />
                              </Avatar>
                            }
                          />
                        ))}
                      </div>
                    </div>
                    {/* accordian */}
                    <div
                      style={{
                        paddingInline: "1.5rem",
                        marginRight: "1rem",
                        background: "#f5f6f7",
                      }}
                      className="accordion-container"
                    >
                      {documentList?.map(
                        (item, index) =>
                          item?.show && (
                            <Accordion
                              key={index}
                              sx={{
                                borderLeft: "3px solid #4775D5",
                                marginBottom: "1em",
                              }}
                              expanded={expanded[index] || false}
                              onChange={handleAccordionChange(index)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <div className="d-flex w-100 align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <OutlinedPlusIcon
                                      style={{
                                        height: "1.714rem",
                                        width: "1.714rem",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddToSelectedItems(item);
                                      }}
                                    />

                                    <Box
                                      style={{
                                        marginLeft: "1.143rem",
                                        marginRight: "0.571rem",
                                      }}
                                      sx={{
                                        position: "relative",
                                        display: "inline-flex",
                                      }}
                                    >
                                      <CircularProgress
                                        size={44}
                                        thickness={4}
                                        value={Math.floor(item?.score)}
                                        sx={{ color: "#1BB470" }}
                                        variant="determinate"
                                      />
                                      {/* CircularProgress for the empty portion */}
                                      <CircularProgress
                                        size={44}
                                        thickness={4}
                                        value={item?.score}
                                        sx={{
                                          color: "rgba(33, 45, 44, 0.1)",
                                          position: "absolute",
                                        }}
                                        variant="determinate"
                                      />
                                      <Box
                                        sx={{
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          position: "absolute",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography
                                          variant="caption"
                                          component="div"
                                          color="black"
                                          className="progress-value"
                                        >
                                          {Math.floor(item?.score)}%
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Typography
                                      sx={{ fontSize: 14 }}
                                      className="file-name"
                                    >
                                      {item?.fileName}
                                    </Typography>
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{
                                  borderTop: "1px solid #CED3D9",
                                  padding: "1.143rem",
                                }}
                              >
                                <div
                                  className="mt-3"
                                  style={{
                                    fontSize: "1rem",
                                    lineHeight: "1.7rem",
                                    fontWeight: "400",
                                  }}
                                >
                                  {item?.content
                                    ?.split(synonymsRegex)
                                    .filter(Boolean)
                                    .map((chunk, index) => {
                                      return (
                                        <span
                                          key={index}
                                          className={
                                            lowercasedCleanedWordsArray?.includes(
                                              chunk.toLowerCase()
                                            )
                                              ? "highlight-text"
                                              : lowercasedSynonymsArray?.includes(
                                                  chunk.toLowerCase()
                                                )
                                              ? "orange-text"
                                              : ""
                                          }
                                        >
                                          {chunk}
                                        </span>
                                      );
                                    })}
                                </div>
                                <div className="d-flex gap-2 align-items-center justify-content-between mt-3">
                                  <div
                                    style={{
                                      color: "#76828B",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    <span>
                                      Created on{" "}
                                      <span style={{ color: "#051D2C" }}>
                                        {moment(item?.resourceDate).format(
                                          "DD/MM/YYYY"
                                        )}{" "}
                                      </span>
                                    </span>
                                    by{" "}
                                    <span style={{ color: "#051D2C" }}>
                                      {item?.userName}
                                    </span>{" "}
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )
                      )}
                    </div>{" "}
                  </div>
                  {/* chat  messages section */}
                  {isSearchResultEmpty && !isShowMessages ? (
                    <Typography
                      sx={{ textAlign: "center", marginTop: "100px" }}
                    >
                      No results found
                    </Typography>
                  ) : null}
                  {isShowMessages && (
                    <div id="chat-rows" className="chat-rows">
                      <div ref={bottomRef} className="chat-scroll">
                        {messages?.map((item, index) => (
                          <div key={index}>
                            <div className="chat-row">
                              <div className="chatResponseContainer">
                                {activeUserData?.image === null ? (
                                  <FaUserCircle
                                    style={{
                                      height: "2rem",
                                      width: "2rem",
                                      marginRight: "10px",
                                    }}
                                    className="brook-img"
                                  />
                                ) : (
                                  <ChakraProvider>
                                    <Image
                                      borderRadius="full"
                                      boxSize="2rem"
                                      marginRight={"10px"}
                                      src={
                                        activeUserData.resizedImage
                                          ? `${
                                              process.env.REACT_APP_IMAGES_URL
                                            }/Users/${
                                              activeUserData?.resizedImage
                                            }?random=${Math.random()}`
                                          : `${
                                              process.env.REACT_APP_IMAGES_URL
                                            }/Users/${
                                              activeUserData?.image
                                            }?random=${Math.random()}`
                                      }
                                      alt="user-avatar"
                                    />
                                  </ChakraProvider>
                                )}
                                <div className="user-name">
                                  {activeUserData?.name}
                                </div>
                              </div>
                              <div className="chat-question-container">
                                <div className="chat-question mt-0">
                                  {renderTextWithLineBreaks(item.text)}
                                </div>
                              </div>
                            </div>
                            {item.reply === null ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: "80px",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <RotatingLines
                                  strokeColor="#051D2C"
                                  strokeWidth="4"
                                  animationDuration="0.85"
                                  width="35"
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <div className="chat-row">
                                <div className="chatResponseContainer">
                                  {activePersona?.image === null ? (
                                    <img
                                      className="avatar"
                                      style={{ cursor: "default" }}
                                      src={emissaryLogo}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="avatar"
                                      style={{ cursor: "default" }}
                                      src={
                                        activePersona?.resizedImage
                                          ? `${
                                              process.env.REACT_APP_IMAGES_URL
                                            }/Personas/${
                                              activePersona.creatorEmail
                                            }/${
                                              activePersona?.resizedImage
                                            }?random=${Math.random()}`
                                          : `${
                                              process.env.REACT_APP_IMAGES_URL
                                            }/Personas/${
                                              activePersona.creatorEmail
                                            }/${
                                              activePersona?.image
                                            }?random=${Math.random()}`
                                      }
                                      alt=""
                                    />
                                  )}
                                  <div className="user-name">
                                    {activePersona?.firstName +
                                      " " +
                                      activePersona?.lastName}
                                  </div>
                                </div>
                                <div className="chat-answer-container">
                                  {renderTextWithLineBreaks(item.reply)}
                                  <div className="lower-buttons">
                                    <div className="chat-row-action-buttons">
                                      <CopyToClipboard
                                        text={item.reply}
                                        onCopy={handleCopyClick}
                                      >
                                        <i className="icon-copy"></i>
                                      </CopyToClipboard>
                                      <i className="icon-thumbs-up disabled"></i>
                                      <i className="icon-thumbs-down disabled"></i>
                                    </div>
                                    <BiAnalyseIcon
                                      onClick={() =>
                                        getChatEvaluationHandler(
                                          item?.text,
                                          item?.reply,
                                          item?.source && item?.source?.Chunk
                                            ? item?.source?.Chunk
                                            : ""
                                        )
                                      }
                                      className="evaluation-button"
                                    />
                                  </div>
                                </div>{" "}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      {/* input section */}
                      <div className="chat-input">
                        {parsedToken?.data?.role === "SuperAdmin" && (
                          <div
                            className="UpdatePrompt"
                            onClick={onOpen}
                            cursor={"pointer"}
                          >
                            Update Prompt Template{" "}
                          </div>
                        )}
                        <div className="chatInput-inner">
                          <textarea
                            ref={textareaRef}
                            className={"custom-textarea"}
                            value={chatInputText}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            onInput={TextAreaAutoResize}
                            disabled={isLoading || chatLoader}
                            placeholder="Start typing…"
                            rows="1"
                          />
                          <span
                            className="send-btn"
                            onClick={() => {
                              !chatInputText
                                ? console.log("")
                                : messages && messages.length > 0
                                ? updateChat()
                                : createNewChat();
                            }}
                          >
                            <div
                              className={
                                isLoading || chatLoader || !chatInputText
                                  ? "disable-chat-button"
                                  : "chat-send"
                              }
                            >
                              <SendChatIcon />
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* prompt modal */}
      <ChakraProvider>
        <Modal
          size="3xl"
          isCentered
          isOpen={isOpen}
          onClose={() => {
            setdisableSaveChangeButton(true);
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update the Prompt Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={6} alignItems={"center"}>
                <Stack w="100%">
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w="100%"
                  >
                    <>
                      <BsFillInfoCircleFill
                        data-tooltip-id="my-tooltip-1"
                        cursor="pointer"
                        fontSize={20}
                      />

                      <ReactTooltip
                        style={{ width: "40vw", zIndex: "99999" }}
                        id="my-tooltip-1"
                        place="bottom"
                        type="dark"
                        effect="solid"
                        content={renderTextWithLineBreaks(
                          "The text in the curly braces '{}' are dynamic values which will be inserted for the logged in user and current persona and should be kept as they are.\nThe '{{context_str}}' in the prompt template refers to a string of context or information that you provide as part of the input to the model to help it generate relevant responses.\n'{ personal_info }' encompass the shared attributes of both the user and the persona, including their email addresses, names, and company names.\nThe term '{persona_name}' specifically refers to the name associated with a persona.\n'{list_of_words}' comprises words extracted from the transcript that are frequently used by the person, and these words exhibit strong associations 'affinities' with the persona."
                        )}
                      />
                    </>
                    <Button
                      onClick={() => {
                        setPromptText(promptTextCopy);
                        setdisableSaveChangeButton(false);
                      }}
                      size="md"
                      fontWeight={"500"}
                      fontSize={"1rem"}
                      type="submit"
                      borderRadius={"5px"}
                      bg="#21DC82"
                      w="fit-content"
                      color="white"
                      _hover={{ bg: "black" }}
                    >
                      REVERT TO MAIN
                    </Button>
                  </Stack>
                  <Textarea
                    resize={"none"}
                    rows={12}
                    borderColor={"black"}
                    _hover={{}}
                    value={promptText}
                    onChange={(e) => {
                      setdisableSaveChangeButton(false);
                      setPromptText(e.target.value);
                    }}
                  />
                </Stack>
                <Button
                  onClick={updatePromptTemplateHandler}
                  size="md"
                  fontWeight={"500"}
                  fontSize={"1rem"}
                  type="submit"
                  borderRadius={"5px"}
                  bg="#21DC82"
                  w="fit-content"
                  color="white"
                  _hover={{ bg: "black" }}
                  isDisabled={disableSaveChangeButton}
                >
                  SAVE CHANGES
                </Button>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
      {/* mind info modal */}
      <ChakraProvider>
        <Modal
          scrollBehavior="inside"
          size="xl"
          isCentered
          isOpen={isOpenMind}
          onClose={onCloseMind}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Context Source</ModalHeader>
            <ModalCloseButton />
            <ModalBody marginBottom={8}>
              <Stack spacing={6} alignItems={"center"}>
                <Text>{sourceText}</Text>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
      {/* evaluation modal */}
      <ChakraProvider>
        <Modal
          scrollBehavior="inside"
          size="3xl"
          isCentered
          isOpen={isOpenEvaluation}
          onClose={onCloseEvaluation}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Evaluation</ModalHeader>
            <ModalCloseButton />
            <ModalBody marginBottom={8}>
              <TableContainer borderRadius={"md"} border={"1px solid gray"}>
                <Table variant={"simple"}>
                  <Thead>
                    <Tr>
                      <Th>Feedback</Th>
                      <Th>Guideline</Th>
                      <Th>Pass</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {evaluationMatric?.map((item, index) => (
                      <Tr key={index}>
                        <Td
                          style={{
                            whiteSpace: "pre-line",
                            width: "300px",
                            lineHeight: "25px",
                            wordWrap: "break-word",
                            verticalAlign: "top",
                          }}
                        >
                          {item?.Feedback}
                        </Td>
                        <Td
                          style={{
                            whiteSpace: "pre-line",
                            width: "300px",
                            lineHeight: "25px",
                            wordWrap: "break-word",
                            verticalAlign: "top",
                          }}
                        >
                          {item?.Guideline}
                        </Td>
                        <Td style={{ verticalAlign: "top" }}>
                          {item.Pass ? "True" : "False"}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>

      {/* save project modal */}
      <BootstrapModal
        show={showAddResourceModal}
        onHide={() => {
          setShowAddResourceModal(false);
          setResourceType({ label: "Document" });
          setURL("");
          setSelectedFileResource();
        }}
        centered
      >
        <BootstrapModal.Header
          style={{ border: "none", backgroundColor: "#D4E2FF" }}
        >
          <div
            style={{
              backgroundColor: "#D4E2FF",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBlock: "1rem",
              position: "relative",
            }}
          >
            <CloseIcon
              onClick={() => {
                setShowAddResourceModal(false);
                setResourceType({ label: "Document" });
                setURL("");
                setSelectedFileResource();
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: -5,
                right: -5,
              }}
            />
            <img style={{ width: "250px" }} src={addresource} />
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div className="mb-3">
            <p
              style={{
                color: "#051D2C",
                fontWeight: "500",
                fontSize: "1.5rem",
              }}
            >
              Add a resource
            </p>
          </div>
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ background: "white", cursor: "pointer" }}
            options={resourceTypes}
            value={resourceType}
            onChange={handleResourceTypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type of resource"
                placeholder="Select resource"
              />
            )}
          />
          {resourceType?.label === "Document" ? (
            <div onClick={handleUploadClick} className="icon-button gap-2 mt-4">
              {/* Hidden file input */}
              <input
                onClick={(e) => {
                  e.stopPropagation();
                }}
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              {/* Upload icon with onClick handler */}
              <IconButton>
                <UploadIcon />
              </IconButton>
              <p
                style={{
                  color: "white",
                  fontWeight: "500",
                  fontSize: "1rem",
                  marginBottom: 0,
                }}
              >
                {selectedFileResource?.name || "UPLOAD FILE"}
              </p>
            </div>
          ) : (
            <div className="w-100 mt-4">
              <TextField
                value={url}
                onChange={(e) => setURL(e.target.value)}
                size="small"
                label="URL"
                placeholder="Enter url here"
                sx={{ width: "100%" }}
              />
            </div>
          )}

          <div className=" mt-4 d-flex gap-3 align-items-center justify-content-end">
            <MuiButton
              onClick={() => {
                setShowAddResourceModal(false);
                setResourceType({ label: "Document" });
                setURL("");
                setSelectedFileResource();
              }}
              sx={{ color: "#000000", fontWeight: "600" }}
              variant="text"
            >
              Cancel
            </MuiButton>
            <ReusableButton
              // isDisabled={!selectedFileResource && !url}
              onClick={() => {
                if (resourceType?.label === "Document") {
                  handleAddToFilesArray();
                } else if (resourceType?.label === "External Link") {
                  handleAddToUrlArray();
                } else {
                }
              }}
              buttonText={"SAVE RESOURCE"}
            />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
      {/* create project modal */}

      <BootstrapModal
      id="chat-create-modal"
        show={createProjectModal}
        onHide={() => {
          setprojectName();
          setprojectDescription();
          setCreateProjectModal(false);
        }}
        centered
      >
        <BootstrapModal.Header
          style={{ border: "none", backgroundColor: "#D4E2FF" }}
        >
          <div
            style={{
              backgroundColor: "#D4E2FF",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBlock: "1rem",
              position: "relative",
            }}
          >
            <CloseIcon
              onClick={() => setCreateProjectModal(false)}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: -5,
                right: -5,
              }}
            />
            <img style={{ width: "250px" }} src={addresource} />
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <p
            style={{ color: "#051D2C", fontWeight: "500", fontSize: "1.5rem" }}
          >
            {selectedProject ? " Save a project" : "Create a project"}
          </p>

          <div className="w-100 mt-4">
            <TextField
              disabled={selectedProject?.projectName ? true : false}
              value={projectName}
              onChange={(e) => setprojectName(e.target.value)}
              size="small"
              label="Project Name"
              placeholder="Enter project name"
              sx={{ width: "100%" }}
            />
          </div>
          <div className="w-100 mt-4">
            <TextField
              value={projectDescription}
              onChange={(e) => setprojectDescription(e.target.value)}
              size="small"
              label="Project Description"
              placeholder="Enter project description"
              sx={{ width: "100%" }}
            />
          </div>

          <div className=" mt-4 d-flex gap-3 align-items-center justify-content-end">
            <MuiButton
              onClick={() => setCreateProjectModal(false)}
              sx={{ color: "#000000", fontWeight: "600" }}
              variant="text"
            >
              Cancel
            </MuiButton>
            <ReusableButton
              // isDisabled={!projectName || !projectDescription}
              onClick={() => {
                selectedProject
                  ? UpdateProjectHandler()
                  : CreateProjectHandler();
              }}
              buttonText={selectedProject ? "SAVE PROJECT" : "CREATE PROJECT"}
            />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
      <div>
        <BootstrapModal
          show={confirmDeleteFileModal}
          onHide={() => {
            setconfirmDeleteFileModal(false);
            settTargetUrl();
            settTargetFile();
          }}
          centered
        >
          <BootstrapModal.Header
            style={{ border: "none", paddingBottom: 0 }}
            closeButton
          ></BootstrapModal.Header>
          <BootstrapModal.Body style={{ padding: 0 }}>
            <div className="d-flex flex-column gap-4 px-5  align-items-center">
              <img src={alertIcon} />
              <h6
                style={{
                  paddingLeft: "1.5rem",
                  fontSize: "1.3rem",
                  lineHeight: "29px",
                  textAlign: "center",
                }}
              >
                {location?.state?.from === "resources" &&
                !Array.isArray(allResources)
                  ? "Are you sure you want to permanently delete this resource?"
                  : selectedProjectId
                  ? "Are you sure you want to remove this resource from project?"
                  : "Are you sure you want to remove this resource from chat?"}
              </h6>
            </div>
            <div
              style={{ borderTop: "1px solid #CED3D9" }}
              className="mt-4 d-flex gap-1 align-items-center justify-content-between"
            >
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "1px solid #CED3D9",
                }}
              >
                <MuiButton
                  onClick={() => {
                    setconfirmDeleteFileModal(false);
                    settTargetUrl();
                    settTargetFile();
                  }}
                  sx={{ color: "#051D2C", fontWeight: "600" }}
                  variant="text"
                >
                  CANCEL
                </MuiButton>
              </div>
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MuiButton
                  onClick={handleDeleteResource}
                  sx={{ color: "#C51333", fontWeight: "600" }}
                  variant="text"
                >
                  {selectedProjectId || targetChatId
                    ? "YES, REMOVE"
                    : "YES, DELETE"}
                </MuiButton>
              </div>
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      </div>
      <div>
        <BootstrapModal
          show={confirmDeleteProject}
          onHide={() => {
            setDeleteProjectId();
            setconfirmDeleteProject(false);
          }}
          centered
        >
          <BootstrapModal.Header
            style={{ border: "none", paddingBottom: 0 }}
            closeButton
          ></BootstrapModal.Header>
          <BootstrapModal.Body style={{ padding: 0 }}>
            <div className="d-flex flex-column gap-4 px-5  align-items-center">
              <img src={alertIcon} />
              <h6
                style={{
                  paddingLeft: "1.5rem",
                  fontSize: "1.3rem",
                  lineHeight: "29px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to delete this project?
              </h6>
            </div>
            <div
              style={{ borderTop: "1px solid #CED3D9" }}
              className="mt-4 d-flex gap-1 align-items-center justify-content-between"
            >
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "1px solid #CED3D9",
                }}
              >
                <MuiButton
                  onClick={() => setconfirmDeleteProject(false)}
                  sx={{ color: "#051D2C", fontWeight: "600" }}
                  variant="text"
                >
                  CANCEL
                </MuiButton>
              </div>
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MuiButton
                  onClick={handleDeleteProject}
                  sx={{ color: "#C51333", fontWeight: "600" }}
                  variant="text"
                >
                  YES, DELETE
                </MuiButton>
              </div>
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      </div>
      <div>
        <BootstrapModal
          show={confirmDeleteChat}
          onHide={() => {
            setDeleteProjectId();
            setconfirmDeleteChat(false);
          }}
          centered
        >
          <BootstrapModal.Header
            style={{ border: "none", paddingBottom: 0 }}
            closeButton
          ></BootstrapModal.Header>
          <BootstrapModal.Body style={{ padding: 0 }}>
            <div className="d-flex flex-column gap-4 px-5  align-items-center">
              <img src={alertIcon} />
              <h6
                style={{
                  paddingLeft: "1.5rem",
                  fontSize: "1.3rem",
                  lineHeight: "29px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to delete this chat?
              </h6>
            </div>
            <div
              style={{ borderTop: "1px solid #CED3D9" }}
              className="mt-4 d-flex gap-1 align-items-center justify-content-between"
            >
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "1px solid #CED3D9",
                }}
              >
                <MuiButton
                  onClick={() => setconfirmDeleteChat(false)}
                  sx={{ color: "#051D2C", fontWeight: "600" }}
                  variant="text"
                >
                  CANCEL
                </MuiButton>
              </div>
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MuiButton
                  onClick={() => {
                    deleteAllResourceSession(targetChatId).then((res) => {
                      if (res.success) {
                        if (targetChatId === sessionId) {
                          nav("/chat");
                          if (
                            !selectedProject &&
                            (location?.state?.from !== "resources" ||
                              resourceData?.length > 1)
                          ) {
                            setMessages([]);
                            setSelectedItems([]);
                            setSelectedUrls([]);
                          }
                          setChatInputText("");
                          setSessionId();
                          setEditableItem(null);
                          setdeletedChatItem(null);
                          setconfirmDeleteChat(false);
                          setErrorToast(true);
                          setSnackbarMessage(res.message);
                          setSnackbarSeverity("success");
                        }
                      }
                    });
                  }}
                  sx={{ color: "#C51333", fontWeight: "600" }}
                  variant="text"
                >
                  YES, DELETE
                </MuiButton>
              </div>
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      </div>
      <ChakraProvider>
        <Drawer
          size={"lg"}
          isOpen={isOpenDrawer}
          placement="right"
          onClose={() => {
            setSearchedKeyword();
            setSelectedKeyword();
            setLoadGraphData(true);
            processData({
              edges: [],
              nodes: [],
            });
            onCloseDrawer();
          }}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <Tabs
                mt={8}
                orientation="horizontal"
                defaultIndex={0}
                className="tab-names"
              >
                <TabList borderBottom={"none"}>
                  <Tab
                    w="50%"
                    color="#76828B !important"
                    fontWeight={"500"}
                    fontSize={"14px"}
                    _selected={{
                      color: "#4775D5 !important",
                      borderBottomColor: "#4775D5 !important",
                    }}
                  >
                    DATA
                  </Tab>
                  <Tab
                    w="50%"
                    color="#76828B !important"
                    fontWeight={"500"}
                    fontSize={"14px"}
                    _selected={{
                      color: "#4775D5 !important",
                      borderBottomColor: "#4775D5 !important",
                    }}
                  >
                    ANALYSIS
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel className="px-0  mx-0">
                    <Stack px={6}>
                      <Text fontSize="lg" fontWeight={"semibold"} mb={0}>
                        Transcript
                      </Text>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-flex align-items-center gap-1 ">
                          <FileGreyIcon />
                          <div
                            style={{ color: "#051D2C", fontWeight: "400" }}
                            className="resource-type"
                          >
                            Document
                          </div>
                        </div>
                        <div style={{ color: "#76828B", fontSize: "0.9rem" }}>
                          <span>
                            Created on{" "}
                            <span style={{ color: "#051D2C" }}>
                              {moment(targetResource?.resourceDate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                            </span>
                          </span>
                          by{" "}
                          <span style={{ color: "#051D2C" }}>
                            {targetResource?.resourceCreator?.name}
                          </span>{" "}
                        </div>
                      </div>
                      <Box>
                        <Input
                          mt={2}
                          placeholder="Search"
                          value={selectedKeyword}
                          onChange={(e) => setSelectedKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              // Call your function here
                              setSearchedKeyword(e.target.value);
                            }
                          }}
                          // ... (Textarea props)
                        />
                        <Box
                          ref={containerRef}
                          justifyContent={"center"}
                          fontSize="md"
                          whiteSpace={"pre-line"}
                          pb={3}
                          pt={2}
                        >
                          <Text
                            fontWeight="400"
                            fontSize="1em"
                            lineHeight="23px"
                            letterSpacing="wide"
                            whiteSpace={"pre-line"}
                          >
                            {truncatedContentText
                              ?.split(
                                new RegExp(`(\\b${searchedKeyword}\\b)`, "gi")
                              )
                              .filter(Boolean)
                              .map((chunk, index) => (
                                <span
                                  key={index}
                                  className={
                                    chunk?.toLowerCase() ===
                                    searchedKeyword?.toLowerCase()
                                      ? "highlight-text"
                                      : ""
                                  }
                                >
                                  {chunk}
                                </span>
                              ))}
                            {!showResourceContentMore &&
                              targetResource?.processedFileContent?.length >
                                1250 &&
                              "..."}
                            {targetResource?.processedFileContent?.length >
                              1250 && (
                              <>
                                <span> </span>
                                <span
                                  style={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleResourceContentShowMore}
                                >
                                  {showResourceContentMore
                                    ? "Show Less"
                                    : "Show More"}
                                </span>
                              </>
                            )}
                          </Text>
                        </Box>
                      </Box>
                    </Stack>
                  </TabPanel>

                  <TabPanel className="px-0  mx-0">
                    <Stack px={6} maxWidth={"100%"}>
                      {/* SUMMARY */}
                      <Stack
                        bg="#F6F6F6"
                        w="100%"
                        padding={"10px 20px 10px 20px"}
                        borderRadius={"8px"}
                      >
                        <Text mb={0} fontWeight={"600"}>
                          Summary{" "}
                        </Text>
                        <Stack direction={"row"} alignItems="center">
                          <Text
                            fontWeight="400"
                            fontSize="sm"
                            lineHeight="23px"
                            letterSpacing="wide"
                          >
                            {truncatedText}
                            {!showMore && summary?.length > 200 && "..."}
                            {summary?.length > 200 && (
                              <>
                                <span> </span>
                                <span
                                  style={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleShowMore}
                                >
                                  {showMore ? "Show Less" : "Show More"}
                                </span>
                              </>
                            )}
                          </Text>
                        </Stack>
                      </Stack>
                      {/* RE-ANALYZE BUTTON */}
                      <div className="d-flex py-3 w-100 justify-content-end">
                        <Button
                          size="sm"
                          fontWeight={"500"}
                          bg="transparent"
                          w="fit-content"
                          color="#4775D5"
                          _hover={{}}
                          border="1.2px solid #4775D5"
                          textTransform={"uppercase"}
                          onClick={ReAnalyzeKeywordsHandler}
                        >
                          Re-Analyze
                        </Button>
                      </div>
                      {/* KEYWORDS */}
                      <Stack
                        maxW={"100%"}
                        pb={4}
                        minH={"fit-content"}
                        overflowX={"auto"}
                        spacing={3}
                      >
                        {keywordsArray && keywordsArray.length > 0 ? (
                          keywordsArray?.map((category, categoryIndex) => (
                            <Stack
                              key={category.title}
                              alignItems={"center"}
                              direction="row"
                              w="100%"
                              spacing={2}
                            >
                              <Text
                                fontWeight={"450"}
                                mb={0}
                                fontSize="14px"
                                minWidth={"23%"}
                                maxWidth={"140px"}
                              >
                                {category.title}
                              </Text>
                              <Stack w={"70%"} direction="row" spacing={3}>
                                {category?.people.map(
                                  (keywordObj, keywordIndex) =>
                                    keywordObj?.count > 0 && (
                                      <Stack
                                        key={keywordObj.keyword}
                                        bg={
                                          keywordObj.isSelected
                                            ? "#4775D5"
                                            : "transparent"
                                        }
                                        onClick={() => {
                                          handleKeywordClick(
                                            categoryIndex,
                                            keywordIndex
                                          );
                                          getGraphDataHandler(
                                            keywordObj.keyword
                                          );
                                        }}
                                        // Other props for styling
                                        border={"1.5px solid #4775D5"}
                                        // spacing={2}
                                        padding={"4px 4px 4px 10px"}
                                        // py={1}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent={"space-between"}
                                        rounded="full"
                                        // px={2}
                                        height={"27px"}
                                        minWidth={"fit-content"}
                                        cursor={"pointer"}
                                      >
                                        {/* Your keyword rendering code */}
                                        <Text
                                          margin={0}
                                          fontWeight={"400"}
                                          fontSize={"12px"}
                                          whiteSpace={"nowrap"}
                                          color={
                                            keywordObj.isSelected
                                              ? "white"
                                              : "#051D2C"
                                          }
                                        >
                                          {keywordObj.keyword}
                                        </Text>

                                        <Box
                                          marginLeft={"8px"}
                                          fontSize={"10px"}
                                          width="19px"
                                          height="19px"
                                          borderRadius="50%"
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          fontWeight={"500"}
                                          backgroundColor={
                                            keywordObj.isSelected
                                              ? "white"
                                              : "#4775D5"
                                          }
                                          color={
                                            keywordObj.isSelected
                                              ? "#4775D5"
                                              : "white"
                                          }
                                        >
                                          <div>{keywordObj?.count}</div>
                                        </Box>
                                      </Stack>
                                    )
                                )}
                              </Stack>
                            </Stack>
                          ))
                        ) : isLoading ? null : isKeywordsEmpty ? (
                          <Text>
                            Keywords are currently generating, you will receive
                            an email notification upon completion.
                          </Text>
                        ) : (
                          <SkeletonText
                            mt="4"
                            noOfLines={4}
                            spacing="4"
                            skeletonHeight="2"
                          />
                        )}
                      </Stack>
                      {/* graph */}
                      <Stack
                        height={"50vh"}
                        style={{ position: "relative" }}
                        // borderBlock={"2px solid black"}
                        border="1.4px solid #CED3D9"
                      >
                        <CustomFloatingEdgeGraph
                          graphData={graphData}
                          refresh={refresh}
                        />
                        <ImEnlarge
                          onClick={onOpenGraphModal}
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            right: 15,
                            top: 7,
                          }}
                        />
                      </Stack>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <>
          <Modal
            isCentered
            onClose={onCloseGraphModal}
            isOpen={isOpenGraphModal}
            size={"full"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <Stack height={"100vh"}>
                  <CustomFloatingEdgeGraph
                    graphData={graphData}
                    refresh={refresh}
                  />
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      </ChakraProvider>
    </>
  );
};

export default Chat;
