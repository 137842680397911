import {
  React,
  useState,
  useEffect,
  useRef,
  useContext,
  useImperativeHandle,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

import search from "../../Images/search.svg";
import "./AllPersonas.css";
import {
  ChakraProvider,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalHeader,
  Stack,
  Switch,
} from "@chakra-ui/react";
import Unknown from "../../Images/Unknown.jpg";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  UpdatePersonaProfilePicService,
  deleteUserService,
  getAllUsersService,
  getSingleUserService,
} from "../../Service/service";
import { forwardRef } from "react";
import { RotatingLines } from "react-loader-spinner";
import CustomToaster from "../../utils/toaster/CustomToaster";
import webpic from "../../Images/web.svg";
import {
  BlueChatIcon,
  BrowserIcon,
  FacebookIcon,
  FolderBlackIcon,
  GridViewIcon,
  InstagramIcon,
  LinkedInIcon,
  ListViewIcon,
  SearchGreyIcon,
  TiktokIcon,
  TwitterIcon,
  UserBlackIcon,
  YoutubeIcon,
} from "../../customIcons/customIcons";
import PersonaDataContext from "../../context/PersonaContext";
const AllPersonas = (
  { updatePersona, showUser, handleSwitchMyPersonas },
  ref
) => {
  const { updatePersonaImageRandomNumberForAll, personaImageRandomNumbersForAll,updatePersonaImageRandomNumberForSelf,personaImageRandomNumbersForSelf } =
    useContext(PersonaDataContext);
  const location = useLocation();
  const { id } = useParams(); // Fetch the "id" from the URL path
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const [isLoading, setIsLoading] = useState(false);
  const [showUsers, setShowUsers] = useState(true);
  const [isRerender, setIsRerender] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [searchPersonaText, setSearchPersonaText] = useState('');
  const [userId, setUserId] = useState(parsedToken?.data?._id);
  const [TargetUserId, setTargetUserId] = useState();
  const [TargetUserRole, setTargetUserRole] = useState();
  const [previewImage, setPreviewImage] = useState(null);
  const [activePersona, setActivePersona] = useState();
  const [isPersona, setIspersona] = useState(false);
  const [personaList, setPersonaList] = useState([]);
  const [tempPersonaList, settempPersonaList] = useState([]);
  const [gridView, setGridView] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [yourPersonasList, setYourPersonasList] = useState([]);
  const [personaId, setPersonaId] = useState();
  const [accessPersonas, setAccessPersonas] = useState("All");
  const [personaIndex, setPersonaIndex] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const fileInputRef = useRef(null);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };
  const showMoment = async (personaData, redirectUrl, projectItem) => {
    localStorage.setItem("active_persona", personaData._id);
    localStorage.setItem("selected_Project", null);
    const dataString = JSON.stringify(personaData);
    localStorage.setItem("active_persona_data", dataString);
    try {
      const res = await getSingleUserService(personaData?.userId);
      if (res.success) {
        const activeUserData = JSON.stringify(res.data);
        localStorage.setItem("active_user", activeUserData);
        // if (personaData?.moments !== 0) {
        if (projectItem) {
          navigate("/chat", {
            state: {
              from: "projects",
              projectData: projectItem,
            },
          });
        } else {
          navigate(redirectUrl);
        }
        // } else {
        //   navigate("/moments");
        // }
      }
    } catch (error) {
      console.log("🚀 ~ file: AllPersonas.js:107 ~ showMoment ~ error:", error);
    }
  };
  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };

  const handleCustomUploadClick = () => {
    fileInputRef.current.click();
  };

  const updateProfileImageHandler = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("personaId", activePersona._id);
      formData.append("image", profilePic);
      const res = await UpdatePersonaProfilePicService(formData);
      if (res.success) {
        setPreviewImage(null);
        setProfilePic(null);
        setIsLoading(false);
        setIsRerender(!isRerender);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(res.message);
      } else {
        setIsLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const fetchAdmins = async () => {
    try {
      setIsLoading(true);
      const response = await getAllUsersService("Admin");

      if (response.success) {
        // Process the received data
        setIspersona(true);
        setUsersList(response.data);
        localStorage.setItem("user_list", JSON.stringify(response.data));
        navigate("/admins");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    fetchAdmins,
  }));
  // getting all personas
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    getSingleUserService(parsedToken?.data?._id).then((res) => {
      const activeUserData = JSON.stringify(res.data);
      localStorage.setItem("active_user", activeUserData);
    });
    const storedSwitchValue = localStorage.getItem("switchValue");
    const initialSwitchValue =
      storedSwitchValue === null
        ? switchValue
        : storedSwitchValue == "All"
        ? false
        : true; // Convert to boolean

    const fetchData = async () => {
      const userID = id || userId;
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/personas/getAllPersonas`,
          {
            method: "POST",
            body: JSON.stringify({
              access:
                parsedToken?.data?.role === "User" ||
                parsedToken?.data?.role === "Collaborator"
                  ? "Self"
                  : initialSwitchValue == true
                  ? "Self"
                  : "All",
              userId: userID,
            }),
            headers: {
              auth_token: parsedToken?.auth_token,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Process the received data
          setYourPersonasList(data.data.yourPersonas);
          if (data.message == "My Personas") {
            const concatenatedData = [
              ...data.data.yourPersonas,
              ...data.data.allowedPersonas,
            ];
            setPersonaList(concatenatedData);
            settempPersonaList(concatenatedData);
            if(personaImageRandomNumbersForSelf?.length===0)
            {

              concatenatedData?.forEach((persona) => {
                const randomNumber = Math.random(); // Generate random number here
                updatePersonaImageRandomNumberForSelf(persona?._id, randomNumber);
              });
            }
           
           
            localStorage.setItem(
              "persona_list",
              JSON.stringify(data.data.yourPersonas)
            );
            localStorage.setItem(
              "my_persona_list",
              JSON.stringify(data.data.yourPersonas)
            );
            if (concatenatedData?.length > 0) {
              setIspersona(true);
            } else {
              setIspersona(false);
            }
          } else {
            setPersonaList(data.data);
            settempPersonaList(data.data);
            if(personaImageRandomNumbersForAll?.length===0)
            {

              data?.data?.forEach((persona) => {
                const randomNumber = Math.random(); // Generate random number here
                updatePersonaImageRandomNumberForAll(persona?._id, randomNumber);
              });
            }
            localStorage.setItem("persona_list", JSON.stringify(data.data));
            if (data.data.length > 0) {
              setIspersona(true);
            } else {
              setIspersona(false);
            }
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
          const errorData = await response.json();
          console.log(errorData.errors);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [updatePersona, isRerender, showUsers, location]);
  useEffect(() => {
    const storedSwitchValue = localStorage.getItem("switchValue");
    if (storedSwitchValue !== null) {
      setSwitchValue(storedSwitchValue == "Self" ? true : false);
    }
  }, [location]);

  // delete persona handler
  async function deletePersona() {
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    try {
      var requestOptions = {
        method: "DELETE",
        headers: parsedToken,
        redirect: "follow",
      };
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/personas/deletePersona/${personaId}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setIsLoading(false);
        closeModal();
        const updatedTempObjects = [...tempPersonaList];
        const updatedObjects = [...personaList];
        updatedObjects.splice(personaIndex, 1);
        updatedTempObjects.splice(personaIndex, 1);
        setPersonaList(updatedObjects);
        settempPersonaList(updatedTempObjects);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(result.message);
      } else {
        setIsLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  // search persona handler
  const searchPersonaHandler = (text) => {
    const splitText = text && text.split(" ");
    let username;
    const updatedArray = personaList.filter((persona) => {
      if (splitText.length >= 1) {
        username = `${persona.firstName} ${persona.lastName}`;
      } else {
        username = persona.firstName;
      }
      if (username.toLowerCase().includes(text.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });
    settempPersonaList(updatedArray);
  };
  useEffect(() => {

    const performSearch = () => {
    //  console.log(searchValue)
    if (searchPersonaText.length > 0) {
      searchPersonaHandler(searchPersonaText);
    } else {
      settempPersonaList(personaList);
    }
    };
    const timeoutId = setTimeout(performSearch, 300); 

    return () => clearTimeout(timeoutId);
  }, [searchPersonaText]); 
  const deleteUserHandler = async () => {
    try {
      setIsLoading(true);
      const res = await deleteUserService(TargetUserId);
      if (res.success) {
        setIsLoading(false);
        closeModal2();
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(res.message);
        setIsRerender(!isRerender);
      } else {
        setIsLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const deleteAdminHandler = async () => {
    try {
      setIsLoading(true);
      const res = await deleteUserService(TargetUserId);
      if (res.success) {
        fetchAdmins();
        setIsLoading(false);
        closeModal2();
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(res.message); // You can customize this message
        // setIsRerender(!isRerender);
      } else {
        setIsLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message); // You can customize this message
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const viewMyPersonas = () => {
    setUserId(parsedToken?.data?._id);
    setAccessPersonas("Self");
    setIsRerender(!isRerender);
  };
  const viewAllPersonas = () => {
    setUserId(parsedToken?.data?._id);
    setAccessPersonas("All");
    setIsRerender(!isRerender);
  };

  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="">
        <div className="pageHeader">
          <div className="d-flex align-items-center">
            {(parsedToken?.data?.role === "SuperAdmin" ||
              parsedToken?.data?.role === "Admin") && (
              <div className="d-flex align-items-center me-4">
                <ChakraProvider>
                  <Switch
                    isChecked={switchValue}
                    onChange={(e) => {
                      setSearchPersonaText("");
                      setSwitchValue(e.target.checked);
                      handleSwitchMyPersonas(e.target.checked);
                      localStorage.setItem(
                        "switchValue",
                        e.target.checked == true ? "Self" : "All"
                      );

                      if (e.target.checked == true) {
                        viewMyPersonas();
                      } else {
                        viewAllPersonas();
                      }
                    }}
                    className="me-2"
                    colorScheme="green"
                    size="md"
                  />
                </ChakraProvider>
                <h1
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "0",
                    color: "#111",
                  }}
                >
                  My Personas
                </h1>
              </div>
            )}

            <div className="search-input">
              <span>
                <SearchGreyIcon />
                {/* <img src={search} className="search-img" alt="" /> */}
              </span>
              <input
                type="text"
                className="persona-search w-100"
                placeholder="Search by name"
                aria-label="search"
                value={searchPersonaText}
                onChange={(e) => {
                  setSearchPersonaText(e.target.value);
                
                }}
              />
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <ListViewIcon onClick={() => setGridView(false)} role="button" />
            <GridViewIcon onClick={() => setGridView(true)} role="button" />
          </div>
        </div>
        {gridView ? (
          <div className="emissary-grid">
            {tempPersonaList.map((item, index) => {
              // Find the random number associated with the persona ID
              const randomNumberObj = !switchValue? personaImageRandomNumbersForAll?.find(
                (obj) => obj.personaId === item._id
              ):personaImageRandomNumbersForSelf?.find(
                (obj) => obj.personaId === item._id
              )
              // Use the found random number or fallback to Math.random() if not found
              const randomNumber = randomNumberObj
                ? randomNumberObj?.randomNumber
                : Math.random();

              return (
                <div
                  onClick={() => {
                    showMoment(item, "/chat");
                  }}
                  key={index}
                  className="emissary-card"
                >
                  <div className="upper-card">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div className="persona-info">
                        <div></div>
                        <img
                          src={
                            item.image === null
                              ? Unknown
                              : item.resizedImage
                              ? `${process.env.REACT_APP_IMAGES_URL}/Personas/${item.creatorEmail}/${item.resizedImage}?random=${randomNumber}`
                              : `${process.env.REACT_APP_IMAGES_URL}/Personas/${item.creatorEmail}/${item.image}?random=${randomNumber}`
                          }
                          className="personaAvatar"
                          alt="persona image"
                        />
                      </div>
                      <div className="persona-name">
                        <h3
                          style={{ padding: "0 20px" }}
                        >{`${item.firstName} ${item.lastName}`}</h3>
                        <p>
                          {item?.jobTitle ? `${item?.jobTitle} @ ` : null}{" "}
                          {item?.epClientCompany}
                        </p>
                      </div>
                      <div className="social-links">
                        {item?.socialLinks?.instagram && (
                          <a
                            href={item?.socialLinks?.instagram}
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <InstagramIcon />
                          </a>
                        ) }
                        {item?.socialLinks?.tikTok && (
                          <a
                            href={item?.socialLinks?.tikTok}
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <TiktokIcon />
                          </a>
                        )}
                        {item?.socialLinks?.twitter && (
                          <a
                            href={item?.socialLinks?.twitter}
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <TwitterIcon />
                          </a>
                        )}
                        {item?.socialLinks?.linkedin && (
                          <a
                            href={item?.socialLinks?.linkedin}
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <LinkedInIcon />
                          </a>
                        )}
                        {item?.socialLinks?.websiteUrl && (
                          <a
                            href={item?.socialLinks?.websiteUrl}
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <img src={webpic} style={{ width: "18px" }} />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="persona-action-buttons">
                      <div className="personaCardButton">
                        <BlueChatIcon
                          className="chaticon"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            showMoment(item, "/chat");
                          }}
                        />
                      </div>
                      <div className="personaCardButton">
                        <FolderBlackIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            showMoment(item, "/resources");
                          }}
                        />
                      </div>
                      <div className="personaCardButton">
                        <UserBlackIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            showMoment(item, "/edit-persona");
                          }}
                        />
                      </div>
                    </div>{" "}
                  </div>
                  <div className="lower-card">
                    {/* interests */}
                    <div className="persona-interest mb-4">
                      <h3>CURRENT INTERESTS</h3>
                      <p>
                        {item?.interests?.length > 0 ? (
                          <>
                            {item?.firstName}'s current interests are{" "}
                            {item?.interests?.join(", ")}
                          </>
                        ) : (
                          <>{item?.firstName} has no current interests.</>
                        )}
                      </p>
                    </div>
                    {/* projects */}
                    <div className="persona-projects">
                      <h3>RECENT PROJECTS</h3>
                      {/* projects chips */}
                      <div className="project-names-container">
                        {item?.projects?.length > 0 ? (
                          item?.projects?.map((project, index) => (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                showMoment(item, "/chat", project);
                              }}
                              key={index + "-item"}
                              className="projectname"
                            >
                              <span>{project?.projectName}</span>
                            </div>
                          ))
                        ) : (
                          <span style={{ fontSize: "14px" }} className="mb-0">
                            No projects created yet
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <ChakraProvider>
              {/* preview image modal */}
              <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader textAlign={"center"}></ModalHeader>
                  <ModalCloseButton />
                  <ModalBody py={6}>
                    <Stack
                      py={4}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={
                          activePersona && activePersona.image === null
                            ? Unknown
                            : `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                                activePersona?.creatorEmail
                              }/${activePersona?.image}?random=${Math.random()}`
                        }
                      />
                    </Stack>
                  </ModalBody>
                </ModalContent>
              </ChakraModal>
            </ChakraProvider>
          </div>
        ) : (
          <div className="emissary-list">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th scope="col">Persona Details</th>
                  <th scope="col">Projects</th>
                  <th scope="col">Interests</th>
                  <th scope="col">Created by</th>
                  <th scope="col">Created date</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {tempPersonaList?.map((item, index) => (
                  <tr
                    key={index + "item"}
                    onClick={() => {
                      showMoment(item, "/chat");
                    }}
                  >
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={
                            item.image === null
                              ? Unknown
                              : item?.resizedImage
                              ? `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                                  item.creatorEmail
                                }/${item?.resizedImage}?random=${Math.random()}`
                              : `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                                  item.creatorEmail
                                }/${item?.image}?random=${Math.random()}`
                          }
                          alt="persona image"
                        />
                        <div className="persona-name">
                          <h3>{`${item.firstName} ${item.lastName}`}</h3>
                          <p>
                            {item?.jobTitle ? `${item?.jobTitle} @ ` : null}{" "}
                            {item?.epClientCompany}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="grid-project-container">
                        {item?.projects?.length > 0 ? (
                          <>
                            {item?.projects?.slice(0, 2).map((project) => (
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  showMoment(item, "/chat", project);
                                }}
                              >
                                {project?.projectName}
                              </span>
                            ))}
                            {item?.projects?.length > 2 &&
                              `+${item?.projects?.length - 2}`}
                          </>
                        ) : (
                          <p className="mb-0">No projects created yet</p>
                        )}
                      </div>
                    </td>
                    <td>
                      <p className="mb-0">
                        {item?.interests?.length > 0 ? (
                          <>
                            {/* {item?.firstName}'s current interests are{" "} */}
                            {item?.interests
                              ?.slice(0, 3)
                              ?.map((item) => `${item}, `)}
                            {item?.interests?.length > 3 &&
                              `+${item?.interests?.length - 3}`}
                          </>
                        ) : (
                          <>{item?.firstName} has no current interests.</>
                        )}
                      </p>
                    </td>
                    <td>Brook Zimmatore</td>
                    <td>21/04/2023</td>
                    <td>
                      <div className="persona-action-buttons-table">
                        <BlueChatIcon
                          className="chaticon"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            showMoment(item, "/chat");
                          }}
                        />
                        <FolderBlackIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            showMoment(item, "/resources");
                          }}
                        />
                        <UserBlackIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            showMoment(item, "/edit-persona");
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {/* confirm delete popup for persona */}
      <div>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header style={{ border: "none" }} closeButton></Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "1.5rem", fontSize: "1.3rem" }}>
              Are you sure you want to delete?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal}>
              No
            </Button>
            <Button variant="danger" onClick={deletePersona}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* confirm delete popup for user */}
      <div>
        <Modal show={showModal2} onHide={closeModal2} centered>
          <Modal.Header style={{ border: "none" }} closeButton></Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "1.5rem", fontSize: "1.3rem" }}>
              Are you sure you want to delete?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal2}>
              No
            </Button>
            <Button
              variant="danger"
              onClick={
                TargetUserRole == "User"
                  ? deleteUserHandler
                  : deleteAdminHandler
              }
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default forwardRef(AllPersonas);
