import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import Swal from "sweetalert2";
import ReusableTextField from "../reusableComponents/ReuseableTextField";
import ReusableButton from "../reusableComponents/ReusableButton";
import { requestAccessService } from "../../Service/service";
import CustomToaster from "../../utils/toaster/CustomToaster";

const Register = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    // name validation
    if (formData.name.length < 3) {
      validationErrors.name = "Username must be at least 3 characters long.";
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !formData.email.match(emailPattern)) {
      validationErrors.email = "Please enter a valid email address.";
    }

    // Password validation
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;
    if (!formData.password || !formData.password.match(passwordPattern)) {
      validationErrors.password =
        "Password should be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, and 1 number. It should not include spaces.";
    }

    // Confirm password validation
    if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Handle successful form submission here
      try {
        setLoader(true);
        // Make the API call
        // Replace the following line with your actual API call for requestAccessService
        const res = await requestAccessService(formData);
        if (res.success) {
          // signup successful
          setFormData({
            // Reset the form fields
            email: "",
            password: "",
            confirmPassword: "",
            name: "",
          });
          setLoader(false);
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
          setSnackbarMessage(res.message);
        } else {
          setLoader(false);
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage(res.message);
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  };

  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <div className="signupContainer">
        <div className="signupContainer-inner">
          <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          <div className="login-title">Request Access</div>
          <div>
            <div className="signupContainer-form">
              <form
                className="loginForm"
                id="SignUpForm"
                onSubmit={handleSubmit}
              >
                <div>
                  {/* name */}
                  <div className="mb-3">
                    <ReusableTextField
                      label="Full Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter name"
                      error={errors.name}
                      helperText={errors.name}
                      required
                    />
                  </div>
                  {/* email */}
                  <div className="mb-3">
                    <ReusableTextField
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter email"
                      error={errors.email}
                      helperText={errors.email}
                      required
                    />
                  </div>
                  {/* password */}
                  <div className="mb-3">
                    <ReusableTextField
                      label="Password"
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Enter password"
                      error={errors.password}
                      helperText={errors.password}
                      showEyeIcon
                      required
                    />
                  </div>
                  {/* ... (other JSX) */}
                  <div className="mb-3">
                    <ReusableTextField
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      placeholder="Confirm password"
                      error={errors.confirmPassword}
                      helperText={errors.confirmPassword}
                      showEyeIcon
                      required
                    />
                  </div>
                  {/* login button */}
                  <div className="mb-3">
                    <ReusableButton
                      buttonText="REQUEST ACCESS"
                      onClick={handleSubmit}
                      loading={loader}
                      isDisabled={
                        !formData.name ||
                        !formData.email ||
                        !formData.password ||
                        !formData.confirmPassword ||
                        loader
                      }
                      height="53px"
                      width="100%"
                    />
                  </div>
                  <p className="forget-text">
                    Already have an account?{" "}
                    <span onClick={() => navigate("/")} className="signup-link login-blue-text">
                      Login
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
