import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import UserProfileContext from "../../../../../context/UserContext";
import { FaUserCircle } from "react-icons/fa";
import {
  ChakraProvider,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { RotatingLines } from "react-loader-spinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  BiAnalyseIcon,
  SendChatIcon,
} from "../../../../../customIcons/customIcons";
import {
  CreateBrandGuideChatService,
  UpdateBrandGuideChatService,
  chatModalService,
  getEvaluationService,
} from "../../../../../Service/service";
import PersonaChatContext from "../../../../../context/ChatContext";
import emissaryLogo from "../../../../../Images/chatLogo.png";
import { CloseButton } from "react-bootstrap";
function ChatPopup({
  closeChat,
  responseData,
  setUpdateLoading,
  chatResponse,
  messages,
  setMessages,
  setSnackbarSeverity,
  setSnackbarMessage,
  setErrorToast,
}) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    // setIsLoading(true);
    resetTextareaSize();
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    setCurrentEmail(activePersona?.epClientEmail);
    // setMessages([]);
    setSessionId();
    setMemoryId();
    setMomentType("all");
    setSelectedValue();
    setElasticSearchText("");
    setDocumentList([]);
    setselectedProject();
    setselectedProjectId();
    // setSelectedFileResource(null);
    setSelectedItems([]);
    setSelectedUrls([]);

    // setisDisabledChatBox(true);
    getBrandGuideChat();
  }, []);

  const { getChatSession } = useContext(PersonaChatContext);

  const { activeUserData } = useContext(UserProfileContext);

  const [chatInputText, setChatInputText] = useState("");

  const [currentEmail, setCurrentEmail] = useState("");
  const [sessionId, setSessionId] = useState();
  const [memoryId, setMemoryId] = useState();
  const [selectedProject, setselectedProject] = useState();
  // console.log(selectedProject)
  const [selectedProjectId, setselectedProjectId] = useState();

  const [momentType, setMomentType] = useState("single");
  const [previousMomentType, setPreviousMomentType] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  // const [messages, setMessages] = useState([]);

  const [evaluationMatric, setevaluationMatric] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [chatLoader, setchatLoader] = useState(false);

  const [isTypeWriterEffect, setisTypeWriterEffect] = useState(true);

  const [elasticSearchText, setElasticSearchText] = useState();

  const [documentList, setDocumentList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  // console.log(selectedItems)
  const [selectedUrls, setSelectedUrls] = useState([]);
  // console.log(selectedUrls)

  const textareaRef = useRef(null);

  const bottomRef = useRef(null);

  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [loadEmbeddings, setLoadEmbeddings] = useState(true);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
      // console.log("invoked");
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const {
    isOpen: isOpenEvaluation,
    onOpen: onOpenEvaluation,
    onClose: onCloseEvaluation,
  } = useDisclosure();

  const renderTextWithLineBreaks = (text) => {
    const lines = text.split("\n");
    return lines.map((line, index) => (
      <Text marginBottom={0} key={index}>
        {line}
        <br />
      </Text>
    ));
  };
  const resetTextareaSize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "3.5rem";
    textarea.style.overflow = "hidden";
    setChatInputText("");
  };
  const updateChat = async () => {
    resetTextareaSize();
    const active_user = JSON.parse(localStorage.getItem("active_user"));
    // setisTypeWriterEffect(true);
    setMessages([
      ...messages,
      { text: chatInputText, reply: null, source: null },
    ]);

    const tempFileArray = responseData?.documents?.map((item) => {
      return item?.documentName;
    });
    const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));
    try {
      setchatLoader(true);
      // const minValue = selectedValue === "" ? null : selectedValue;
      const response = await chatModalService(
        latestFive,
        responseData?.title,
        active_user?.email || active_user?.epClientEmail,
        "single",
        chatInputText,
        currentEmail,
        loadEmbeddings,
        false,
        memoryId ? memoryId : null,
        tempFileArray,
        responseData?.urls
      );
      setLoadEmbeddings(false);
      setPreviousMomentType(momentType);
      // save chat api
      try {
        const saveRes = await UpdateBrandGuideChatService(
          sessionId,
          chatInputText,
          response?.response == null
            ? `It is not mentioned in the context information.`
            : response?.response,
          response.source,
          response?.memoryId
        );
        if (saveRes?.success) {
          setchatLoader(false);
          setSessionId(saveRes?.data._id);
          setMemoryId(response?.memoryId);
          setMessages(saveRes?.data.messages);
          // scrollToBottom();
        } else {
          setchatLoader(false);
        }
      } catch (error) {
        setchatLoader(false);
      }
      // }
    } catch (error) {
      console.log("error", error);
      setchatLoader(false);
    }
  };
  const createNewChat = async () => {
    const tempFileArray = responseData?.documents?.map((item) => {
      return item?.documentName;
    });

    resetTextareaSize();
    const active_user = JSON.parse(localStorage.getItem("active_user"));
    setisTypeWriterEffect(true);
    setMessages([
      ...messages,
      { text: chatInputText, reply: null, source: null },
    ]);
    const latestFive = messages?.slice(-5)?.map(({ text, reply }) => ({ text, reply }));
    try {
      setchatLoader(true);
      // const minValue = selectedValue === "" ? null : selectedValue;
      const response = await chatModalService(
        latestFive,
        responseData?.title,
        active_user?.email || active_user?.epClientEmail,
        "single",
        chatInputText,
        currentEmail,
        loadEmbeddings,
        true,
        null,
        tempFileArray,
        responseData?.urls
      );
      setLoadEmbeddings(false);
      setMemoryId(response?.memoryId);
      // save chat api
      setPreviousMomentType(momentType);
      try {
        const saveRes = await CreateBrandGuideChatService(
          chatInputText,
          response?.response == null
            ? `${response?.title} is not mentioned in the context information.`
            : response?.response,
          response?.title,
          response.source,
          selectedProject?._id,
          response?.memoryId,
          responseData?._id
        );
        if (saveRes?.success) {
          setchatLoader(false);
          setMessages([...messages, saveRes?.data.messages[0]]);
          setSessionId(saveRes?.data._id);
          const res = await getChatSession(selectedProject?._id);
          // scrollToBottom();
        } else {
          setchatLoader(false);
          // setisSendReqMsg(true);
        }
      } catch (error) {
        setchatLoader(false);
      }
      // }
    } catch (error) {
      console.log("error", error);
      setchatLoader(false);
    }
  };

  const getBrandGuideChat = () => {
    setIsLoading(true);
    const res = chatResponse;

    if (res.success) {
      setMessages(res.data.messages);
      setSessionId(res.data._id);
      setMemoryId(res?.data?.memoryId);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    if (textareaRef.current) {
      setTimeout(() => {
        textareaRef.current.focus();
      }, 0);
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setChatInputText(value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (messages && messages.length > 0) {
        updateChat();
      } else {
        createNewChat();
      }
    }
  };
  const getChatEvaluationHandler = async (query, response, context) => {
    try {
      setIsLoading(true);
      setUpdateLoading(true);
      const res = await getEvaluationService(query, response, context);
      if (res.success) {
        setevaluationMatric(res.data);
        setIsLoading(false);
        setUpdateLoading(false);
        onOpenEvaluation();
      } else {
        setIsLoading(false);
        setUpdateLoading(false);
        // setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setIsLoading(false);
      setUpdateLoading(false);
      console.log(
        "🚀 ~ file: Chat.js:418 ~ updatePromptTemplateHandler ~ error:",
        error
      );
    }
  };
  const TextAreaAutoResize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "3.5rem";
    const scrollHeight = textarea.scrollHeight;

    if (scrollHeight > 220) {
      textarea.style.height = "22em"; // Set a max height of 212px
      textarea.style.overflow = "auto"; // Add scrollbar
    } else {
      textarea.style.height = `${scrollHeight}px`; // Adjust height based on content
      textarea.style.overflow = "hidden"; // Hide scrollbar if not needed
    }
  };
  const handleCopyClick = () => {
    setSnackbarSeverity("success");
    setErrorToast(true);
    setSnackbarMessage("Copied to clipboard");
  };
  const modalRef=useRef(null)
  const closeModal = useCallback(() => {
    closeChat()
}, [closeChat]);


  useEffect(() => {
    const handleOverlayClick = (e) => {
      const containerId = document.getElementById("chat-rows")
      if (containerId &&
      !containerId.contains(e.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, []);
  return (
    <>
      <div>
        <div id="chat-rows" className="chat-rows" ref={modalRef}>
          <div
            style={{ position: "absolute", right: 12, top: 6 }}
            onClick={closeChat}
          >
            <CloseButton style={{ fontSize: "0.8rem" }} />
          </div>
          <div ref={bottomRef} className="chat-scroll">
            {isLoading && (
              <div
                style={{
                  width: "100%",
                  height: "80px",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <RotatingLines
                  strokeColor="#051D2C"
                  strokeWidth="4"
                  animationDuration="0.85"
                  width="35"
                  visible={true}
                />
              </div>
            )}
            {messages?.map((item, index) => (
              <div key={index}>
                <div className="chat-row">
                  <div className="chatResponseContainer">
                    {activeUserData?.image === null ? (
                      <FaUserCircle
                        style={{
                          height: "2rem",
                          width: "2rem",
                          marginRight: "10px",
                        }}
                        className="brook-img"
                      />
                    ) : (
                      <ChakraProvider>
                        <Image
                          borderRadius="full"
                          boxSize="2rem"
                          marginRight={"10px"}
                          src={
                            activeUserData.resizedImage
                              ? `${process.env.REACT_APP_IMAGES_URL}/Users/${
                                  activeUserData?.resizedImage
                                }?random=${Math.random()}`
                              : `${process.env.REACT_APP_IMAGES_URL}/Users/${
                                  activeUserData?.image
                                }?random=${Math.random()}`
                          }
                          alt="user-avatar"
                        />
                      </ChakraProvider>
                    )}
                    <div className="user-name">{activeUserData?.name}</div>
                  </div>
                  <div className="chat-question-container">
                    <div className="chat-question mt-0">
                      {renderTextWithLineBreaks(item.text)}
                    </div>
                  </div>
                </div>
                {item.reply === null ? (
                  <div
                    style={{
                      width: "100%",
                      height: "80px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <RotatingLines
                      strokeColor="#051D2C"
                      strokeWidth="4"
                      animationDuration="0.85"
                      width="35"
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="chat-row">
                    <div className="chatResponseContainer">
                      {activePersona?.image === null ? (
                        <img
                          className="avatar"
                          style={{ cursor: "default" }}
                          src={emissaryLogo}
                          alt=""
                        />
                      ) : (
                        <img
                          className="avatar"
                          style={{ cursor: "default" }}
                          src={
                            activePersona?.resizedImage
                              ? `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                                  activePersona.creatorEmail
                                }/${
                                  activePersona?.resizedImage
                                }?random=${Math.random()}`
                              : `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                                  activePersona.creatorEmail
                                }/${
                                  activePersona?.image
                                }?random=${Math.random()}`
                          }
                          alt=""
                        />
                      )}
                      <div className="user-name">
                        {activePersona?.firstName +
                          " " +
                          activePersona?.lastName}
                      </div>
                    </div>
                    <div className="chat-answer-container">
                      {renderTextWithLineBreaks(item.reply)}
                      <div className="lower-buttons">
                        <div className="chat-row-action-buttons">
                          <CopyToClipboard
                            text={item.reply}
                            onCopy={handleCopyClick}
                          >
                            <i className="icon-copy"></i>
                          </CopyToClipboard>
                          <i className="icon-thumbs-up disabled"></i>
                          <i className="icon-thumbs-down disabled"></i>
                        </div>
                        <BiAnalyseIcon
                          onClick={() =>
                            getChatEvaluationHandler(
                              item.text,
                              item.reply,
                              item.source.Chunk ? item.source.Chunk : ""
                            )
                          }
                          className="evaluation-button"
                        />
                      </div>
                    </div>{" "}
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* input section */}
          <div className="chat-input">
            {/* {parsedToken?.data?.role === "SuperAdmin" && (
                        <div
                            className="UpdatePrompt"
                            onClick={onOpen}
                            cursor={"pointer"}
                        >
                            Update Prompt Template{" "}
                        </div>
                    )} */}
            <div className="chatInput-inner">
              <textarea
                ref={textareaRef}
                className={"custom-textarea"}
                value={chatInputText}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                onInput={TextAreaAutoResize}
                disabled={isLoading || chatLoader}
                placeholder="Start typing…"
                rows="1"
              />
              <span
                className="send-btn"
                onClick={() => {
                  !chatInputText
                    ? console.log("")
                    : messages && messages.length > 0
                    ? updateChat()
                    : createNewChat();
                }}
              >
                <div
                  className={
                    isLoading ||
                    chatLoader ||
                    // isDisabledChatBox ||
                    !chatInputText
                      ? "disable-chat-button"
                      : "chat-send"
                  }
                >
                  <SendChatIcon />
                </div>
              </span>
            </div>
          </div>
        </div>
        <ChakraProvider>
          <Modal
            scrollBehavior="inside"
            size="3xl"
            isCentered
            isOpen={isOpenEvaluation}
            onClose={onCloseEvaluation}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Evaluation</ModalHeader>
              <ModalCloseButton />
              <ModalBody marginBottom={8}>
                <TableContainer borderRadius={"md"} border={"1px solid gray"}>
                  <Table variant={"simple"}>
                    <Thead>
                      <Tr>
                        <Th>Feedback</Th>
                        <Th>Guideline</Th>
                        <Th>Pass</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {evaluationMatric?.map((item, index) => (
                        <Tr key={index}>
                          <Td
                            style={{
                              whiteSpace: "pre-line",
                              width: "300px",
                              lineHeight: "25px",
                              wordWrap: "break-word",
                              verticalAlign: "top",
                            }}
                          >
                            {item?.Feedback}
                          </Td>
                          <Td
                            style={{
                              whiteSpace: "pre-line",
                              width: "300px",
                              lineHeight: "25px",
                              wordWrap: "break-word",
                              verticalAlign: "top",
                            }}
                          >
                            {item?.Guideline}
                          </Td>
                          <Td style={{ verticalAlign: "top" }}>
                            {item.Pass ? "True" : "False"}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </ModalBody>
            </ModalContent>
          </Modal>
        </ChakraProvider>
      </div>
    </>
  );
}

export default ChatPopup;
