import React, { useState } from "react";
import { Stack, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import { forgotPasswordService } from "../../Service/service";
import Swal from "sweetalert2";
import ReusableTextField from "../reusableComponents/ReuseableTextField";
import ReusableButton from "../reusableComponents/ReusableButton";
import CustomToaster from "../../utils/toaster/CustomToaster";

const ForgotPassword = () => {
  const toast = useToast();
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const handleSubmit = async () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(email || email.trim() !== "")) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
      setLoading(true);
      const res = await forgotPasswordService(email);
      if (res.success) {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(res.message); 
        setEmail("");
      } else {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message); // You can customize this message
      }
    }
  };

  return (
    <>
      <div className="signupContainer">
        <div className="signupContainer-inner">
          <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          {/* <Stack spacing={1} pt={16} textAlign={"center"}>
            <Text fontWeight={"600"} fontSize={"xl"} color={"#0D1821"}>
              Forgot Password?
            </Text>
          </Stack> */}
          <div className="login-title"> Forgot Password?</div>
          <div>
            <div className="signupContainer-form">
              <form className="loginForm" id="SignInFrom">
                <div>
                  {/* email */}
                  <div className="mb-3">
                    <ReusableTextField
                      label="Email"
                      value={email}
                      onChange={(e) => handleEmailChange(e)}
                      error={!!emailError}
                      helperText={emailError}
                      isDisabled={loading}
                      required
                    />
                  </div>
                  {/* login button */}
                  <div className="mb-3">
                    <ReusableButton
                      buttonText="SUBMIT"
                      onClick={handleSubmit}
                      loading={loading}
                      isDisabled={!email || loading}
                      height="53px"
                      width="100%"
                    />
                  </div>
                  <p>
                    <span onClick={() => nav("/")} className="signup-link login-blue-text">
                      Back to login
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default ForgotPassword;
