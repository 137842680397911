// auth module API's
export function signUpUserService(formData) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/user/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// login service
export function loginService(formData) {
  console.log(process.env.REACT_APP_BASE_URL);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// forgot password
export function forgotPasswordService(email) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/user/forgotPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// reset password
export function resetPasswordService(password, confirmPassword, token) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/user/resetPassword", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        auth_token: token,
      },
      body: JSON.stringify({
        password,
        confirmPassword,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePasswordService(
  currentPassword,
  newPassword,
  confirmNewPassword
) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/user/changePassword", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        auth_token: parsedToken?.auth_token,
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        currentPassword,
        newPassword,
        confirmNewPassword,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          alert("untrcked");
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// request access
export function requestAccessService(formData) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/user/requestAccess", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// approve user
export function approveUserService(token, email, name) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/user/approve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        auth_token: token,
      },
      body: JSON.stringify({ email, name }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// chat model API
export function chatModalService(
  messages,
  momentName,
  userEmail,
  type,
  text,
  personaEmail,
  loadEmbeddings,
  chatTitle,
  memory_id,
  fileNamesArray,
  urlsArray
) {
  // console.log(momentName)
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const activeUser = JSON.parse(localStorage.getItem("user_info"));
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("auth-token", parsedToken?.auth_token);
  myHeaders.append(
    "Cookie",
    "session=eyJzZXNzaW9uX2lkIjoiN2JmM2U0NDktYzE1Yi00NThlLTk5NDktZTYyZDZhMDIxNzBmIn0.ZIxJ9g.I1fXAV4cZWRw8XmX4Y2P2NDEhm0"
  );

  var raw = JSON.stringify({
    messages,
    personaEmail,
    personaOwnerEmail: userEmail,
    firstName: activePersona?.firstName,
    lastName: activePersona?.lastName,
    companyName: activePersona?.epClientCompany,
    userEmail: activeUser?.email,
    userName: activeUser?.name,
    // type,
    text,
    projectName: momentName,
    loadEmbeddings,
    chatTitle,
    memoryId: memory_id,
    fileNames: fileNamesArray ?? [],
    urls: urlsArray ?? [],
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_PY_API}/query`, requestOptions)
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function personaStyleService(personaEmail, userEmail) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("auth-token", parsedToken?.auth_token);
  var raw = JSON.stringify({
    personaEmail,
    userEmail,
    personaName: `${activePersona?.firstName} ${activePersona?.lastName}`,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_PY_API}/personaStyle`, requestOptions)
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function CreatePersonaChatService(
  text,
  reply,
  title,
  sourceText,
  projectId,
  memory_id
) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/chats/createChat", {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        personaId: activePersona?._id,
        messages: [
          {
            text,
            reply,
            source: {
              Chunk: sourceText,
            },
          },
        ],
        projectId,
        title,
        memoryId: memory_id ? memory_id : null,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function CreateBrandGuideChatService(
  text,
  reply,
  title,
  sourceText,
  projectId,
  memory_id,
  brandGuideId
) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/brandGuideChats/createChat", {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        personaId: activePersona?._id,
        messages: [
          {
            text,
            reply,
            source: {
              Chunk: sourceText,
            },
          },
        ],
        projectId,
        title,
        memoryId: memory_id ? memory_id : null,
        brandGuideId,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Update chat API
export function UpdateChatService(
  chatId,
  message,
  reply,
  sourceText,
  memory_id
) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/chats/updateChat", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        message,
        reply,
        source: {
          Chunk: sourceText,
        },
        memoryId: memory_id ? memory_id : null,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function UpdateBrandGuideChatService(
  chatId,
  message,
  reply,
  sourceText,
  memory_id
) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/brandGuideChats/updateChat", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        message,
        reply,
        source: {
          Chunk: sourceText,
        },
        memoryId: memory_id ? memory_id : null,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function UpdateChatNameService(chatId, title) {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/chats/updateTitle", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        chatId,
        title,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get Single Chat API
export function getSingleChatService(projectId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/chats/getChat`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId,
        userId: parsedToken?.data?._id,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getBrandGuideChatService(brandGuideId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/brandGuideChats/getChat`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        brandGuideId,
        userId: parsedToken?.data?._id,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get single persona API
export function getSinglePersonaService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/personas/singlePersona/${activePersona?._id}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// get single user API
export function getSingleUserService(userId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/singleUser/${userId}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get all chat sessions API
export function getAllChatSessionsService(projectId) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/chats/getAllChats`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        personaId: activePersona?._id,
        projectId,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// delete single chat session API
export function deleteChatSessionsService(chatId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/chats/deleteChat/${chatId}`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdatePersonalDetailsService(text, socialLinks) {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL + "/api/personalDetails/updateDetails",
      {
        method: "PUT",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          personaId: activePersona?._id,
          text,
          socialLinks,
        }),
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPersonalDetailsService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/personalDetails/getDetails/${activePersona?._id}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCompanyDetailsService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/companyDetails/getDetails/${activePersona?._id}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateCompanyDetailsService(text, companyWebsite) {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL + "/api/companyDetails/updateDetails",
      {
        method: "PUT",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          personaId: activePersona?._id,
          text,
          companyWebsite,
        }),
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getClientPersonaService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/clientPersona/getDetails/${activePersona?._id}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateClientPersonaService(clientPersona) {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/clientPersona/updateDetails", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        clientPersona,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdatePersonaProfilePicService(formData) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/uploads/updatePersonaImage", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function searchPersonasService(userId, query, allPersona) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/personas/search?userId=${userId}&q=${query}`,
      {
        method: "POST",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          allPersona: allPersona,
        }),
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function searchMomentsService(personaId, query) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/moments/search?personaId=${personaId}&q=${query}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// get all chat sessions API
export function getAllUsersService(role) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activeUser = JSON.parse(localStorage.getItem("user_info"));
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/getAllUsers`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        role,
        userId: activeUser?._id,
      }),
    })
      .then((response) => {
        // console.log("🚀 ~ file: service.js:501 ~ .then ~ response:", response);
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function deleteUserService(payload) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/deleteUser`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function inviteUserService(email, role) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/inviteUser`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        role,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function addCollaboratorService(
  selectedEmail,
  selectedPersonas,
  newUser,
  role
) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/addCollaborator`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        selectedEmail,
        selectedPersonas: selectedPersonas ? selectedPersonas : [],
        newUser,
        role,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function joinEmissaryService(formData, token) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/joinEmissary`, {
      method: "POST",
      headers: {
        auth_token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function makeAdminService(userId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/addAdmin/${userId}`, {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// analyze momnet apis

export function getKeywordsService(id, analyze) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/moments/getKeywords", {
      method: "POST",
      body: JSON.stringify({
        momentId: id,
        userId: parsedToken?.data?._id,
        analyze: analyze,
      }),
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSummaryService(id) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/moments/getSummary/${id}`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getGraphDataService(momentName, keyword, loadData) {
  const storedDataString = localStorage.getItem("active_persona_data");

  // Convert the string back to an object using JSON.parse
  const personaData = JSON.parse(storedDataString);
  const storedDataString2 = localStorage.getItem("active_user");

  // Convert the string back to an object using JSON.parse
  const userData = JSON.parse(storedDataString2);
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_PY_API + `/knowledgeGraphData`, {
      method: "POST",
      headers: {
        // auth_token: parsedToken?.auth_token,
        "auth-token": parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loadData,
        momentName,
        keyword,
        personaEmail: personaData?.epClientEmail,
        userEmail: userData?.email,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDocumentsTextService(urlsArray) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_PY_API + `/getText`, {
      method: "POST",
      headers: {
        "auth-token": parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fileUrls: urlsArray,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// admin API's
export function getUsageStatsService() {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/getStats`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeUserRoleService(userId, role) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/changeRole`, {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        role,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function changeUserStatusService(userId, activate) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/changeStatus`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        activate,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// edit user profile
export function UpdateUserProfileService(formData) {
  // Log the content of the FormData
  // formData.forEach((value, key) => {
  //   console.log(key, value);
  // });
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/user/editProfile", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdatePersonaProfileService(formData) {
  // Log the content of the FormData
  // formData.forEach((value, key) => {
  //   console.log(key, value);
  // });
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/personas/edit", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUsageProfilePicService() {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/uploads/getUser/${parsedToken?.data?._id}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPersonaEmailsService(email) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/getEmails`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPersonalCollaboratorsService() {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/user/getCollaborators/${activePersona?._id}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function makePersonaOwnerService(newPersonaOwner, changeOwner) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/makePersonaOwner`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        newPersonaOwner,
        changeOwner,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deletePersonaCollaboratorService(userEmail) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/removeCollaborator`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        userEmail,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function submitFeedbackService(feedbackText) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/user/sendFeedback`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        feedbackText,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPromptTextServices() {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_PY_API + `/getPrompt`, {
      method: "GET",
      headers: {
        "auth-token": parsedToken?.auth_token,
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePromptTemplateService(promptText) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_PY_API + `/updatePrompt`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": parsedToken?.auth_token,
      },
      body: JSON.stringify({
        promptText,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get evaluation API

export function getEvaluationService(query, response, source) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_PY_API + `/getEvaluation`, {
      method: "POST",
      // redirect:'follow',
      headers: {
        "Content-Type": "application/json",
        "auth-token": parsedToken?.auth_token,
      },
      body: JSON.stringify({
        query,
        response,
        source: source ? source : "",
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// elastic search API

export function elasticSearchService(
  personaOwnerEmail,
  personaEmail,
  query,
  minDate,
  maxDate
) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    fetch(process.env.REACT_APP_PY_API + `/elasticSearch`, {
      method: "POST",
      // redirect:'follow',
      headers: {
        "Content-Type": "application/json",
        "auth-token": parsedToken?.auth_token,
      },
      body: JSON.stringify({
        query,
        personaEmail,
        personaOwnerEmail,
        minDate,
        maxDate,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/* ************************  /*
PROJECT APIS
/* ************************  */

export function CreateProjectService(formData) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/projects/createProject`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          // window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateProjectService(formData) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/projects/editProject`, {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          // window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProjectsService() {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      `https://massivealliancenodetest.azure-api.net/api/projects/getAllProjects/${activePersona?._id}`,
      // process.env.REACT_APP_PY_API +
      // `/api/projects/getAllProjects/${activePersona?._id}`
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteEntityService(projectId, url, documentId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/projects/deleteEntities`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId: projectId,
        documentIds: documentId ? [documentId] : [],
        urls: url ? [url] : [],
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function deleteProjectService(id) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL + `/api/projects/deleteProject/${id}`,
      {
        method: "DELETE",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// ANALYZE PROJECT API'S

export function getProjectSummaryService(projectId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL + `/api/projects/getSummary/${projectId}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProjectKeywordsService(projectId, analyze) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/projects/getKeywords", {
      method: "POST",
      body: JSON.stringify({
        projectId,
        userId: parsedToken?.data?._id,
        analyze: analyze,
      }),
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetUserPersonasService(userId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/personas/getAllPersonas`, {
      method: "POST",
      body: JSON.stringify({
        access: "Self",
        userId: userId,
      }),
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
/* ************************  /*
 RESOURCE API's
/* ************************  */
export function AddResourceService(formData) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/resources/createResource`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          // window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllResourcesService() {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      `https://massivealliancenodetest.azure-api.net/api/resources/getAllResources/${activePersona?._id}`,
      // process.env.REACT_APP_PY_API +
      // `/api/projects/getAllProjects/${activePersona?._id}`
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteResourceService(resourcesIdArray) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/resources/deleteResource`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        resourceIds: resourcesIdArray,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resourceChatModalService(
  messages,
  userEmail,
  text,
  personaEmail,
  loadEmbeddings,
  chatTitle,
  correctedResourceName,
  isFile,
  type,
  fileNamesArray,
  fileUrlsArray,
  memory_id,
) {
  // console.log("🚀 ~ isFile:", isFile)

  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const activeUser = JSON.parse(localStorage.getItem("user_info"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("auth-token", parsedToken?.auth_token);
  myHeaders.append(
    "Cookie",
    "session=eyJzZXNzaW9uX2lkIjoiN2JmM2U0NDktYzE1Yi00NThlLTk5NDktZTYyZDZhMDIxNzBmIn0.ZIxJ9g.I1fXAV4cZWRw8XmX4Y2P2NDEhm0"
  );

  var raw = JSON.stringify({
    messages,
    personaEmail,
    personaOwnerEmail: userEmail,
    firstName: activePersona?.firstName,
    lastName: activePersona?.lastName,
    companyName: activePersona?.epClientCompany,
    userEmail: activeUser?.email,
    userName: activeUser?.name,
    text,
    correctedResourceName: correctedResourceName
      ? correctedResourceName
      : "no name",
    type,
    loadEmbeddings,
    chatTitle,
    isFile: isFile === null || isFile === undefined ? true : isFile,
    fileNames: fileNamesArray,
    urls: fileUrlsArray,
    memoryId: memory_id,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_PY_API}/resourceQuery`, requestOptions)
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function CreateResourceChatService(
  text,
  reply,
  title,
  sourceText,
  resourceId,
  filesData,
  urlsData,
  memory_id
) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/resourceChats/createChat", {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        personaId: activePersona?._id,
        messages: [
          {
            text,
            reply,
            source: {
              Chunk: sourceText,
            },
          },
        ],
        resourceId,
        title,
        filesData: filesData ? filesData : [],
        urls: urlsData ? urlsData : [],
        memoryId: memory_id,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateResourceChatService(chatId, message, reply, memory_id) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/resourceChats/updateChat", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        message,
        reply,
        memoryId: memory_id ? memory_id : null,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getResourceChatSessionsService() {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/resourceChats/getAllChats`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        personaId: activePersona?._id,
        resourceId: null,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSingleResourceChatService(chatId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/resourceChats/getChat", {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        resourceId: chatId,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteAllResourceChatService(chatId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/resourceChats/deleteChat/${chatId}`,
      {
        method: "DELETE",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllResourcesChatService(chatId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/resourceChats/getAllEmbeddingsChat/${chatId}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateResourceChatNameService(chatId, title) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/resourceChats/updateTitle", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        chatId,
        title,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// ANALYZE RESOURCE API'S

export function getResourceSummaryService(resourceId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/resources/getSummary/${resourceId}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getResourceKeywordsService(resourceId, analyze) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/resources/getKeywords", {
      method: "POST",
      body: JSON.stringify({
        resourceId,
        userId: parsedToken?.data?._id,
        analyze: analyze,
      }),
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getResourceGraphDataService(
  correctedResourceName,
  projectName,
  keyword,
  loadData
) {
  const storedDataString = localStorage.getItem("active_persona_data");

  // Convert the string back to an object using JSON.parse
  const personaData = JSON.parse(storedDataString);
  const storedDataString2 = localStorage.getItem("active_user");

  // Convert the string back to an object using JSON.parse
  const userData = JSON.parse(storedDataString2);
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_PY_API + `/getGraphData`, {
      method: "POST",
      headers: {
        "auth-token": parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loadData,
        correctedResourceName,
        keyword,
        personaEmail: personaData?.epClientEmail,
        projectName,
        userEmail: userData?.email,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AddChatFileService(chatId, file) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/resourceChats/addFile", {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        filesData: [file],
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteChatFileService(chatId, fileDataId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/resourceChats/deleteFile`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        fileDataId,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteChatUrlService(chatId, url) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + `/api/resourceChats/deleteUrl`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        urls: [url],
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetBrandGuide(data) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_PY_API + "/getBrandGuide", {
      method: "POST",
      headers: {
        "auth-token": parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetBrandGuideInfo() {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/api/brandGuide/getBrandGuide/${activePersona?._id}`,
      {
        method: "GET",
        headers: {
          auth_token: parsedToken?.auth_token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function CreateBrandGuide(data) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/brandGuide/createBrandGuide", {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AddSectionInBrandGuide(sectionName, brandGuideId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/brandGuide/addSection", {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        makerId: parsedToken?.data?._id,
        section: sectionName,
        brandGuideId,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateBrandGuideQuries(data) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + "/api/brandGuide/updateBrandGuide", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        auth_token: parsedToken?.auth_token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status == 401) {
          localStorage.clear();
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
