import React from 'react';

import './customTextField.css'; 

function CustomTextField({ id, label,name, placeholder, value, onChange, onFocus, onBlur,error,errorMessage ,...otherProps  }) {


  return (
    <div className={`form__group custom-text-field`}>
      <input
        type="text"
        id={id}
        className="form__field"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        {...otherProps}
        style={{borderColor:`${error ? '#dc3545':''}`}}
      />
      <label htmlFor={id} className="form__label"  style={{color:`${error ? '#dc3545':''}`}}>
        {label}
      </label>
      {
        error &&
      <p className='error-message'>{errorMessage}</p>
      }
    </div>
  );
}



export default CustomTextField;
