import React, { createContext, useEffect, useState } from "react";

const PersonaMomentContext = createContext();
export function MomentsContext({ children }) {
  const [moments, setMoments] = useState();
  const [activeUser, setActiveUser] = useState();
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  // dark/light theme toggler
  const themeToggler = () => {
    setIsDarkMode(!isDarkMode);
  };
  // get all moments functions
  const getMoments = async () => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

    try {
      const requestOptions = {
        method: "GET",
        headers: parsedToken,
        redirect: "follow",
      };
      //   setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/moments/getAllMoments/${activePersona?._id}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        // setIsLoading(false);
        // console.log(result.data);
        setMoments(result.data);
        return result;
      }
    } catch (error) {
      console.log("error", error);
      //   setIsLoading(false);
    }
  };
// delete single moment form mind screen
  const deleteMoment = async (momentId) => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
    try {
      var requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth_token": parsedToken?.auth_token
        }
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/moments/deleteMoment/${momentId}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        const updatedItems = moments.filter(
          (moment) => moment._id !== momentId
        );
        setMoments(updatedItems);
        return result;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateActiveUserData = (userData) => {
    setActiveUser(userData)
  }
  return (
    <PersonaMomentContext.Provider
      value={{
        moments,
        getMoments,
        deleteMoment,
        themeToggler,
        isDarkMode,
        updateActiveUserData,
        activeUser
      }}
    >
      {children}
    </PersonaMomentContext.Provider>
  );
}

export default PersonaMomentContext;
