import React from "react";
import line from "../../../../../Images/line.svg";
import guidelines from "../../../../../Images/guidelines.png";
import { useMediaQuery } from "@material-ui/core";

const ContentGuideLines = ({ data }) => {
  const contentGuidelines = data?.Content_Guidelines;
  const smallLaptopScreen = useMediaQuery("(max-width:1200px)");
  const mobileScreen = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {contentGuidelines?.map((content, index) => (
        <div key={index} className="preview-header-lower-section mt-4">
          <div className="d-flex align-items-center">
            <div className="who-is-heading "></div>
            <div className="d-flex align-items-center">
              <p
                style={{
                  whiteSpace: "nowrap",
                  marginBottom: "0",
                  paddingLeft: mobileScreen ? "1.3rem" : "2.357rem",
                  color: "#051D2C",
                  fontSize: "1.25rem",
                  fontWeight: "350",
                }}
              >
                Content <span style={{ fontWeight: "500" }}>Guidelines</span>{" "}
              </p>
              <span style={{ paddingLeft: "1rem" }}>
                <img src={line} alt="line" />
              </span>
            </div>
          </div>
          <div
            className="d-flex gap-5 mt-4"
            style={{
              paddingLeft: mobileScreen ? "1.3rem" : "2.357rem",
              flexDirection: mobileScreen ? "column" : "row",
              alignItems: mobileScreen ? "center" : "start",
            }}
          >
            <img
              style={{
                width: mobileScreen
                  ? "8rem"
                  : smallLaptopScreen
                  ? "14rem"
                  : "18rem",
              }}
              src={guidelines}
              alt="guidelines"
            />
            <div>
              {content?.Response.split("\n").map((line, idx) => {
                const textAfterNumber = line?.replace(/^\d+\.\s*/, "").trim();
                if (textAfterNumber) {
                  return (
                    <div key={idx} className="mt-1">
                      <div className="d-flex align-items-start gap-3">
                        <p
                          style={{
                            letterSpacing: "1px",
                            fontSize: mobileScreen
                              ? "1rem"
                              : smallLaptopScreen
                              ? "1rem"
                              : "1.15rem",
                            color: "#212529",
                            fontFamily: "monospace",
                          }}
                        >
                          {`${(idx + 1).toString().padStart(2, "0")}`}
                        </p>
                        <p
                          style={{
                            letterSpacing: "1px",
                            fontSize: mobileScreen
                              ? "0.85rem"
                              : smallLaptopScreen
                              ? "1rem"
                              : "1.15rem",
                            color: "#212529",
                            paddingTop: 2,
                          }}
                        >
                          {line.replace(/^\d+\.\s/, "")}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContentGuideLines;
