import React, { useCallback, useEffect, useRef, useState } from "react";
import "../Persona/Persona.css";
import "../resources/style.css";
import "./PersonaProject.css";
import SideNav from "../sideNav/sideNav";
import NavBar from "../NavBar/NavBar";
import { RotatingLines } from "react-loader-spinner";
import addresource from "../../Images/addresource.svg";
import emptyResource from "../../Images/emptyResource.svg";
import alertIcon from "../../Images/alert.svg";
import { useContext } from "react";
import PersonaChatContext from "../../context/ChatContext";
import Feedback from "../feedback/Feedback";
import { MdDelete, MdInfo } from "react-icons/md";
import {
  BlueChatIcon,
  ChartIcon,
  ChartIconDsiabled,
  ChatIconOutlined,
  CreateProjectIcon,
  DeleteIcon,
  DisableBlueChatIcon,
  ExternalLinkIcon,
  FileGreyIcon,
  FileIconFilled,
  SaveIcon,
  UploadIcon,
} from "../../customIcons/customIcons";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  AddResourceService,
  CreateProjectService,
  deleteResourceService,
  elasticSearchService,
  getAllResourcesService,
  getResourceGraphDataService,
  getProjectSummaryService,
  getDocumentsTextService,
  getProjectKeywordsService,
  getResourceSummaryService,
  getResourceKeywordsService,
  deleteProjectService,
  deleteEntityService,
  UpdateProjectService,
} from "../../Service/service";
import { Modal as BootstrapModal } from "react-bootstrap";
import ReusableButton from "../reusableComponents/ReusableButton";
import {
  ChakraProvider,
  IconButton,
  Button as ChakraButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Input,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Stack,
  Text,
  SkeletonText,
  chakra,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Tooltip,
} from "@chakra-ui/react";
import CustomToaster from "../../utils/toaster/CustomToaster";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ImEnlarge } from "react-icons/im";
import CustomFloatingEdgeGraph from "../knowledgegraph/CustomFloatingEdgeGraph";
import { FaBackward } from "react-icons/fa";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { processData } from "../knowledgegraph/utils";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
const PersonaProjects = () => {
  const tabletDevices = useMediaQuery("(max-width:767px)");

  const {
    updateChatArray,
    getProjects,
    projects,
    filterProjectsByDateRange,
    tempProjects,
    resetProject,
  } = useContext(PersonaChatContext);
  // console.log("tempProjects",tempProjects)
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenGraphModal,
    onOpen: onOpenGraphModal,
    onClose: onCloseGraphModal,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSearchMethod, setSelectedSearchMethod] = useState({
    label: "Search exact phrase",
  });
  const [isClickedSearch, setisClickedSearch] = useState(false);
  const [searchMethodError, setSearchMethodError] = useState(false);
  const [elasticSearchText, setElasticSearchText] = useState();
  const [targetProject, settargetProject] = useState();
  const [targetResource, settargetResource] = useState();
  const [targetDocument, settargetDocument] = useState();
  const [targetProjectUrl, settargetProjectUrl] = useState();
  const [searchedText, setSearchedText] = useState();
  const [uploadedResourceFiles, setUploadedResourceFiles] = useState([]);
  const [synonymsArray, setSynonymsArray] = useState([]);
  const [cleanedWordsArray, setcleanedWordsArray] = useState([]);
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  // console.log("selected items", selectedItems);
  const [selectedProjectDocumentsArray, setSelectedProjectDocumentsArray] =
    useState([]);
  const [selectedProjectLinksArray, setSelectedProjectLinksArray] = useState(
    []
  );
  const [blobUrlsArray, setBlobUrlsArray] = useState([]);
  const [targetUrl, settTargetUrl] = useState();
  const [personaProjects, setpersonaProjects] = useState([]);
  const [personaResources, setPersonaResources] = useState([]);
  const [showpersonaProjects, setShowpersonaProjects] = useState(true);
  const [selectedpersonaProjects, setSelectedpersonaProjects] = useState([]);
  const [isSearchResultEmpty, setIsSearchResultEmpty] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [expandedResourceIndex, setexpandedResourceIndex] = useState({});
  const [expandedResource, setExpandedResource] = useState([]);
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [createProjectButton, setCreateProjectButton] = useState(false);
  const [confirmDeleteFileModal, setconfirmDeleteFileModal] = useState(false);
  const [resourceType, setResourceType] = useState({
    label: "Document",
  });
  const [synonymsRegex, setSynonymsRegex] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isContextChecked, setIsContextChecked] = useState(false);
  const [showMoments, setShowMoments] = useState(true);
  const [targetFile, settTargetFile] = useState();
  const [selectedResource, setSelectedResource] = useState();
  const [selectedFileResource, setSelectedFileResource] = useState("");
  const [url, setURL] = useState("");
  const [projectName, setprojectName] = useState("");
  const [projectDescription, setprojectDescription] = useState("");
  const [searchedMethod, setSearchedMethod] = useState();
  const [showMore, setShowMore] = useState(false);
  const [showResourceContentMore, setShowResourceContentMore] = useState(false);
  const [keywordsArray, setKeywordsArray] = useState([]);
  // const [tempPersonaProjects, setTempPersonaProjects] = useState([]);
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
  });
  const [selectedKeyword, setSelectedKeyword] = useState();
  const [searchedKeyword, setSearchedKeyword] = useState();
  const [isKeywordsEmpty, setisKeywordsEmpty] = useState(false);
  const [isSummaryError, setisSummaryError] = useState("");
  const [summary, setSummary] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [targetProjectId, setTargetProjectId] = useState();
  const [refresh, setrefresh] = useState(1);
  const [loadGraphData, setLoadGraphData] = useState(true);
  const [paragraphsArray, setparagraphsArray] = useState([]);
  const [isShowCount, setIsShowCount] = useState(0);
  const [projectDocumentsText, setProjectDocumentsText] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const searchComponentRef = useRef(null);
  const closeSearchComponent = useCallback(() => {
    // Retrieve the Flatpickr calendar element
    const flatpickrCalendar = document.querySelector('.flatpickr-calendar');
  
    // Check if the Flatpickr calendar is open
    const isDatePickerOpen = flatpickrCalendar && flatpickrCalendar.classList.contains('open');
  
    const createModal = document.getElementById("create-modal-2");
  
    // If the create modal is not present and the date picker is not open, close the search component
    if (!createModal && !isDatePickerOpen) {
      closeSearch();
    }
  }, []);

  useEffect(() => {
    const handleOverlayClick = (e) => {
      if (
        searchComponentRef.current &&
        !searchComponentRef.current.contains(e.target)
      ) {
            closeSearchComponent();
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, [closeSearchComponent]);
  const truncatedText = showMore ? summary : summary?.slice(0, 200);
  const truncatedContentText = targetResource
    ? showResourceContentMore
      ? targetResource.processedFileContent
      : targetResource?.processedFileContent?.slice(0, 1250)
    : showResourceContentMore
    ? paragraphsArray && paragraphsArray[currentIndex]?.text
    : paragraphsArray && paragraphsArray[currentIndex]?.text?.slice(0, 1250);

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };
  const toggleResourceContentShowMore = () => {
    setShowResourceContentMore((prevShowMore) => !prevShowMore);
  };
  const btnRef = React.useRef();

  const handleDocumentCheckboxChange = (item) => {
    const index = selectedProjectDocumentsArray?.findIndex(
      (selectedItem) => selectedItem._id === item._id
    );

    // If the item is not in the selectedItems array, add it; otherwise, remove it
    if (index === -1) {
      setSelectedProjectDocumentsArray([
        ...selectedProjectDocumentsArray,
        item,
      ]);
      setSelectedProjectDocumentsArray([
        ...selectedProjectDocumentsArray,
        item,
      ]);
    } else {
      const updatedSelectedItems = [...selectedProjectDocumentsArray];
      updatedSelectedItems.splice(index, 1);
      setSelectedProjectDocumentsArray(updatedSelectedItems);
    }
  };
  const handleLinkCheckboxChange = (item) => {
    const index = selectedProjectLinksArray?.findIndex(
      (selectedItem) => selectedItem._id === item._id
    );

    // If the item is not in the selectedItems array, add it; otherwise, remove it
    if (index === -1) {
      setSelectedProjectLinksArray([...selectedProjectLinksArray, item]);
    } else {
      const updatedSelectedItems = [...selectedProjectLinksArray];
      updatedSelectedItems.splice(index, 1);
      setSelectedProjectLinksArray(updatedSelectedItems);
    }
  };
  const top100Films = [];
  useEffect(() => {
    setShowpersonaProjects(true);
    setSelectedItems([]);
    setSelectedpersonaProjects([]);
    setDocumentList([]);
    setElasticSearchText("");
    const getProjectsHandler = async () => {
      setIsLoading(true);
      const res = await getAllResourcesService();
      if (res.success) {
        setPersonaResources(res.data);
      }
      const res2 = await getProjects();
      if (res2.success) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    getProjectsHandler();
  }, [updateChatArray]);
  useEffect(() => {
    setpersonaProjects(projects);
  }, [projects]);

  const resourceTypes = [
    { label: "Document" },
    { label: "External Link" },
    // { label: "RSS" },
  ];
  const stopPropagation = (event) => {
    // Prevent the event from reaching the parent (AccordionSummary)
    event.stopPropagation();
  };

  const elasticSearchHandler = async () => {
    setisClickedSearch(true);

    // Check if there is text in the search document field and a search method is selected
    if (
      !elasticSearchText ||
      (elasticSearchText && elasticSearchText.trim().length === 0)
    ) {
      // Set an error state for the search document field
      setErrorToast(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Search requires input text");
      setisClickedSearch(false);
      return;
    }

    try {
      const words = elasticSearchText?.match(
        /(?:[^\s"']+|"([^"]*)"|'([^']*)')/g
      );

      // Filter out null values, flatten the array, and update the search array
      const filteredWords = words
        ? words.flat().filter((word) => word !== null)
        : [];

      // Remove quotes and other characters attached to words
      const cleanedWords = filteredWords?.map((word) =>
        word.replace(/['"\\]+/g, "")
      );
      setcleanedWordsArray(cleanedWords);
      closeAllResourcesAccordions();
      setIsLoading(true);
      setSearchedText(elasticSearchText);
      setSearchedMethod(selectedSearchMethod.label);
      const startDateObject = new Date(filterData?.start_date);
      const startFormattedDate = startDateObject?.toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      const endDateObject = new Date(filterData?.end_date);
      const endFormattedDate = endDateObject?.toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      const res = await elasticSearchService(
        activePersona?.creatorEmail,
        activePersona?.epClientEmail,
        cleanedWords,
        filterData?.start_date !== "" ? startFormattedDate : "",
        filterData?.end_date !== "" ? endFormattedDate : ""
      );

      if (res.success) {
        setSelectedItems([]);
        setShowpersonaProjects(false);
        setSelectedpersonaProjects([]);
        if (res.data.length > 0) {
          setIsSearchResultEmpty(false);
          setCreateProjectButton(true);
        } else {
          setIsSearchResultEmpty(true);
        }
        setShowMoments(false);
        if (expandedResource?.length > 0) {
          filterSearchResults(expandedResource[0]?.documents, res.data);
        } else {
          setDocumentList(res.data);
        }
        if (res.synonyms !== null) {
          const highlightSynonyms = res.synonyms.join("|");
          const synnysmRegex = new RegExp(`\\b(${highlightSynonyms})\\b`, "gi");
          setSynonymsRegex(synnysmRegex);
          const lowercasedSynonymsArray = res.synonyms.map((synonym) =>
            synonym.toLowerCase()
          );
          setSynonymsArray(lowercasedSynonymsArray);
        }
      }

      setIsLoading(false);
      setisClickedSearch(false);
    } catch (error) {
      setIsLoading(false);
      setisClickedSearch(false);
      console.log("Error in elasticSearchHandler:", error);
    }
  };

  function filterSearchResults(documents, searchResults) {
    // Create a function to compare words ignoring case and spaces
    const compareWords = (word1, word2) =>
      word1.trim().toLowerCase() === word2.trim().toLowerCase();

    // Iterate over search results array
    const filteredResults = searchResults.filter((result) => {
      // Iterate over documents array
      const matchFound = documents.some((document) => {
        // Split and compare each word in fileName and documentName
        const fileNameWords = result.fileName.split("_");
        const documentNameWords = document.documentName.split(" ");

        return fileNameWords.every((fileNameWord) =>
          documentNameWords.some((documentNameWord) =>
            compareWords(fileNameWord, documentNameWord)
          )
        );
      });

      // If match found, exclude the result from the filtered array
      return !matchFound;
    });
    // console.log("🚀 ~ filteredResults ~ filteredResults:", filteredResults);

    return filteredResults;
  }
  const handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      // Call your function here
      elasticSearchHandler();
    }
  };

  const closeAllAccordions = () => {
    const newExpandedState = {};
    documentList.forEach((_, index) => {
      newExpandedState[index] = false;
    });
    setExpanded(newExpandedState);
  };
  const closeAllResourcesAccordions = () => {
    const newExpandedState = {};
    personaProjects?.forEach((_, index) => {
      newExpandedState[index] = false;
    });
    setexpandedResourceIndex(newExpandedState);
  };
  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: isExpanded,
    }));
    setexpandedResourceIndex({});
  };

  const handleResourcesAccordionChange =
    (index, item) => (event, isExpanded) => {
      setexpandedResourceIndex((prevExpanded) => ({
        ...prevExpanded,
        [index]: isExpanded,
      }));

      setExpandedResource((prevExpandedResources) => {
        if (isExpanded) {
          // If the accordion is being expanded, add its data to the array
          return [...prevExpandedResources, item];
        } else {
          // If the accordion is being collapsed, remove its corresponding object from the array
          return prevExpandedResources.filter(
            (expandedItem) => expandedItem !== item
          );
        }
      });
    };

  // const handleResourcesAccordionChange =
  //   (index, item) => (event, isExpanded) => {
  //     setexpandedResourceIndex((prevExpanded) => ({
  //       ...prevExpanded,
  //       [index]: isExpanded,
  //     }));
  //     if (!isExpanded) {
  //       setExpandedResource([]);
  //     } else {
  //       // If the accordion is being expanded, set the expandedResource state
  //       setExpandedResource(item);
  //     }
  //   };

  // const handleResourcesAccordionChange =
  //   (index, item) => (event, isExpanded) => {
  //     setexpandedResourceIndex((prevExpanded) => {

  //       const updatedExpanded = { [index]: isExpanded };

  //       if (isExpanded) {
  //         Object.keys(prevExpanded).forEach((key) => {
  //           if (key !== index.toString()) {
  //             updatedExpanded[key] = false;
  //           }
  //         });
  //       }

  //       if (!isExpanded) {
  //         setExpandedResource(null);
  //       } else {
  //         // If the accordion is being expanded, set the expandedResource state
  //         setExpandedResource(item);
  //       }
  //       return updatedExpanded;
  //     });
  //   };

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    // Trigger click on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event) => {
    // Handle file upload logic here
    const selectedFile = event.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      // Check if the file size is greater than 6MB
      if (fileSizeInMB > 6) {
        setErrorToast(true);
        setSnackbarMessage("Please upload a file with a size less than 6MB.");
        setSnackbarSeverity("error");
        // Reset the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      } else {
        setSelectedFileResource(selectedFile);
        // Call your API here with the selected file
        // Example: callApi(selectedFile);
      }
    }
    // Perform your file upload logic with the selectedFile
  };

  const handleResourceTypeChange = (_, newValue) => {
    setResourceType(newValue);
  };
  const handleUploadLinkResource = async () => {
    setIsLoading(true);
    clearDate();
    const formData = new FormData();
    formData.append("personaId", activePersona?._id);
    formData.append("makerId", parsedToken?.data?._id);
    formData.append("url", url);
    const res = await AddResourceService(formData);
    if (res.success) {
      setURL();
      setShowAddResourceModal(false);
      closeAllResourcesAccordions();
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
      // const res3 = await getResourceKeywordsService(res.data?._id, false);
    } else {
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
    }
  };

  const handleUploadFileResource = async () => {
    clearDate();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("personaId", activePersona?._id);
    formData.append("makerId", parsedToken?.data?._id);
    formData.append("files", selectedFileResource);
    const res = await AddResourceService(formData);
    if (res.success) {
      setShowpersonaProjects(true);
      setSelectedpersonaProjects([]);
      setElasticSearchText("");
      closeAllResourcesAccordions();
      setSelectedFileResource();
      setShowAddResourceModal(false);
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
      const res3 = await getResourceKeywordsService(res.data?._id, false);
    } else {
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
      setIsLoading(false);
    }
    // Check if the URL already exists in the array
    // if (uploadedResourceFiles.includes(selectedFileResource)) {
    //   // Show error toaster if the URL already exists
    //   setErrorToast(true);
    //   setSnackbarMessage("This file already exists");
    //   setSnackbarSeverity("error");
    // } else {
    //   // Add the URL to the array if it doesn't exist
    //   setUploadedResourceFiles((prevSelectedItems) => [
    //     ...prevSelectedItems,
    //     selectedFileResource,
    //   ]);
    //   setSelectedFileResource();
    //   setShowAddResourceModal(false);
    // }
  };

  //   setIsLoading(true);
  //   const formData = new FormData();
  //   formData.append("projectId", expandedResource?._id);
  //   formData.append("projectName", expandedResource?projectName);
  //   formData.append("description", expandedResource?projectDescription);
  //   selectedUrls.forEach((string, index) => {
  //     formData.append(`urls[${index}]`, string);
  //   });
  //   for (let i = 0; i < uploadedResourceFiles.length; i++) {
  //     formData.append("files", uploadedResourceFiles[i]);
  //   }

  //   selectedItems
  //     .filter((item) => !("_id" in item)) // Filter out items with '_id' key
  //     .forEach((item, index) => {
  //       Object.entries(item).forEach(([key, value]) => {
  //         // Exclude keys that are "content" or "score"
  //         if (key !== "content" && key !== "score" && key !== "show") {
  //           formData.append(`selectedData[${index}][${key}]`, value);
  //         }
  //       });
  //     });

  //   const res = await UpdateProjectService(formData);
  //   if (res.success) {
  //     setCreateProjectModal(false);
  //     setDocumentList([]);
  //     setUploadedResourceFiles([]);
  //     setSelectedUrls(res.project.urls);
  //     setSelectedItems(res.project.documents);
  //     setElasticSearchText("");
  //     const res2 = await getProjects();
  //     setSnackbarMessage("Project updated successfully");
  //     setSnackbarSeverity("success");
  //     setErrorToast(true);
  //     setIsLoading(false);
  //   } else {
  //     setIsLoading(false);
  //   }
  // };
  const handleAddToFilesArray = async () => {
    if (!selectedFileResource) {
      setErrorToast(true);
      setSnackbarMessage("Please upload a document");
      setSnackbarSeverity("error");
    } else {
      // Check if the URL already exists in the array
      if (uploadedResourceFiles.includes(selectedFileResource)) {
        // Show error toaster if the URL already exists
        setErrorToast(true);
        setSnackbarMessage("This file already exists");
        setSnackbarSeverity("error");
      } else {
        // Add the URL to the array if it doesn't exist
        if (expandedResource?.length > 0) {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("personaId", activePersona?._id);
          formData.append("makerId", parsedToken?.data?._id);
          formData.append("files", selectedFileResource);
          formData.append("projectId", expandedResource[0]?._id);
          const res = await AddResourceService(formData);
          if (res.success) {
            setCreateProjectModal(false);
            setDocumentList([]);
            setUploadedResourceFiles([]);
            setElasticSearchText("");
            const res2 = await getProjects();
            setSelectedFileResource();
            setShowAddResourceModal(false);
            setIsLoading(false);
            setSnackbarMessage("Project updated successfully");
            setSnackbarSeverity("success");
            setErrorToast(true);
            const res3 = getProjectKeywordsService(
              expandedResource[0]?._id,
              false
            );
          } else {
            setIsLoading(false);
            setSnackbarMessage(res.message);
            setSnackbarSeverity("error");
            setErrorToast(true);
          }
        } else if (documentList && !showpersonaProjects) {
          setUploadedResourceFiles((prevSelectedItems) => [
            ...prevSelectedItems,
            selectedFileResource,
          ]);
          setSelectedFileResource();
          setShowAddResourceModal(false);
        } else {
          handleUploadFileResource();
        }
      }
    }
  };
  const handleAddToUrlArray = async () => {
    if (url?.trim().length === 0) {
      setErrorToast(true);
      setSnackbarMessage("Please provide a link");
      setSnackbarSeverity("error");
    } else {
      // Check if the URL already exists in the array
      if (blobUrlsArray?.includes(url)) {
        // Show error toaster if the URL already exists
        setErrorToast(true);
        setSnackbarMessage("This url already exists");
        setSnackbarSeverity("error");
      } else {
        if (expandedResource?.length > 0) {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("personaId", activePersona?._id);
          formData.append("makerId", parsedToken?.data?._id);
          formData.append("projectId", expandedResource[0]?._id);
          formData.append("projectName", expandedResource[0]?.projectName);
          formData.append(
            "description",
            expandedResource[0]?.projectDescription
          );
          formData.append("url", url);
          const res = await AddResourceService(formData);

          if (res.success) {
            setCreateProjectModal(false);
            setDocumentList([]);
            setURL();
            setUploadedResourceFiles([]);
            setElasticSearchText("");
            const res2 = await getProjects();
            setSelectedFileResource();
            setShowAddResourceModal(false);
            setIsLoading(false);
            setSnackbarMessage("Project updated successfully");
            setSnackbarSeverity("success");
            setErrorToast(true);
            // const res3 = getProjectKeywordsService(expandedResource[0]?._id, false);
          } else {
            setIsLoading(false);
            setSnackbarMessage(res.message);
            setSnackbarSeverity("error");
            setErrorToast(true);
          }
        } else if (documentList && !showpersonaProjects) {
          // Add the URL to the array if it doesn't exist
          setSelectedUrls((prevSelectedItems) => [...prevSelectedItems, url]);
          setURL();
          setShowAddResourceModal(false);
        } else {
          handleUploadLinkResource();
        }
      }
    }
  };

  const handleCloseErrorToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorToast(false);
  };

  const handleRemoveFromFilesArray = (item) => {
    // Remove the URL from the array
    setUploadedResourceFiles((prevSelectedItems) =>
      prevSelectedItems.filter((file) => file?.name !== item?.name)
    );
  };
  const CreateProjectHandler = async () => {
    if (projectName?.trim().length === 0) {
      setSnackbarMessage("Project name is required");
      setSnackbarSeverity("error");
      setErrorToast(true);
      return;
    } else if (projectDescription?.trim().length === 0) {
      setSnackbarMessage("Project description is required");
      setSnackbarSeverity("error");
      setErrorToast(true);
      return;
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("makerId", parsedToken?.data?._id);
      formData.append("personaId", activePersona?._id);
      formData.append("projectName", projectName);
      formData.append("description", projectDescription);
      showpersonaProjects
        ? selectedProjectLinksArray?.forEach((string, index) => {
            formData.append(`urls[${index}]`, string);
          })
        : selectedUrls?.forEach((string, index) => {
            formData.append(`urls[${index}]`, string);
          });
      for (let i = 0; i < uploadedResourceFiles.length; i++) {
        formData.append("files", uploadedResourceFiles[i]);
      }
      showpersonaProjects
        ? selectedProjectDocumentsArray.forEach((item, index) => {
            Object.entries(item).forEach(([key, value]) => {
              // Check if the key is "docUrl" or "documentName"
              if (key === "docUrl" || key === "documentName") {
                let adjustedKey = "";
                // Adjust the key to "url" if it's "docUrl" or "documentName"
                if (key === "docUrl") {
                  adjustedKey = "url";
                } else {
                  adjustedKey = "fileName";
                }

                // Append the adjusted key and value to the FormData
                formData.append(
                  `selectedData[${index}][${adjustedKey}]`,
                  value
                );
              }
              // Ignore all other keys
            });
          })
        : selectedItems?.forEach((item, index) => {
            Object.entries(item).forEach(([key, value]) => {
              formData.append(`selectedData[${index}][${key}]`, value);
            });
          });

      const res = await CreateProjectService(formData);
      if (res.success) {
        closeAllResourcesAccordions();
        setCreateProjectModal(false);
        setDocumentList([]);
        setUploadedResourceFiles([]);
        setSelectedUrls([]);
        setSelectedProjectDocumentsArray([]);
        setSelectedProjectLinksArray([]);
        setSelectedItems([]);
        setSelectedpersonaProjects([]);
        setElasticSearchText("");
        setprojectName("");
        setprojectDescription("");
        setShowpersonaProjects(true);
        const res2 = await getProjects();
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
        setErrorToast(true);
        setIsLoading(false);
        const res3 = getProjectKeywordsService(res?.data?._id, false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const getTruncatedLabel = (item) => {
    if (item.name) {
      return item.name.length > 15
        ? `${item.name.substring(0, 15)}...`
        : item.name;
    } else if (item.fileName) {
      return item.fileName.length > 15
        ? `${item.fileName.substring(0, 15)}...`
        : item.fileName;
    } else if (item.documentName) {
      return item.documentName.length > 15
        ? `${item.documentName.substring(0, 15)}...`
        : item.documentName;
    }

    return ""; // Default label if none of the keys exist
  };
  const handleCheckboxChangeAccordion = (item) => {
    if (isSelected(item)) {
      // If the item is selected, remove it from the selectedItems array
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (selectedItem) => selectedItem.url !== item.url
        )
      );
    } else {
      // If the item is not selected, add it to the selectedItems array
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    }

    // Toggle the 'show' property for the added/removed item
    setDocumentList((prevDocumentList) =>
      prevDocumentList.map((doc) =>
        doc.url === item.url ? { ...doc, show: !doc.show } : doc
      )
    );
  };

  const handleResourceCheckboxChangeAccordion = (item) => {
    if (isSelectedResource(item)) {
      // If the item is selected, remove it from the selectedItems array
      setSelectedpersonaProjects((prevSelectedItems) =>
        prevSelectedItems.filter(
          (selectedItem) => selectedItem._id !== item._id
        )
      );
    } else {
      // If the item is not selected, add it to the selectedItems array
      setSelectedpersonaProjects((prevSelectedItems) => [
        ...prevSelectedItems,
        item,
      ]);
    }
  };

  // Function to check if an item is selected
  const isSelected = (item) => {
    return selectedItems.some((selectedItem) => selectedItem.url === item.url);
  };

  const isSelectedResource = (item) => {
    return selectedpersonaProjects.some(
      (selectedItem) => selectedItem._id === item._id
    );
  };

  const handleDeleteProject = async () => {
    setIsLoading(true);
    const res = await deleteProjectService(targetProjectId);
    if (res.success) {
      setTargetProjectId();
      const res2 = await getProjects();
      setSelectedResource();
      setSelectedpersonaProjects([]);
      setconfirmDeleteFileModal(false);
      closeAllResourcesAccordions();
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
    } else {
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
    }
  };

  const handleDeleteEntity = async () => {
    const tempId = targetProject?._id;
    const tempDocId = targetDocument;
    // console.log("🚀 ~ handleDeleteEntity ~ tempDocId:", tempDocId);
    const tempUrlId = targetProjectUrl;
    // console.log("🚀 ~ handleDeleteEntity ~ tempUrlId:", tempUrlId);
    setIsLoading(true);
    const res = await deleteEntityService(
      targetProject?._id,
      targetProjectUrl,
      targetDocument
    );
    if (res.success) {
      setSelectedProjectDocumentsArray(
        selectedProjectDocumentsArray?.filter((itm) => itm._id !== tempDocId)
      );
      setSelectedProjectLinksArray(
        selectedProjectLinksArray?.filter((itm) => itm !== tempUrlId)
      );
      settargetProject();
      setTargetProjectId();
      settargetDocument();
      settargetProjectUrl();
      const res2 = await getProjects();
      setSelectedResource();
      setSelectedpersonaProjects([]);
      setconfirmDeleteFileModal(false);
      closeAllResourcesAccordions();
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
      const res3 = getProjectKeywordsService(tempId, false);
    } else {
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
    }
  };

  const highlightWords = searchedText?.split(/\s+/).join("|");
  const highlightRegex = new RegExp(`\\b(${highlightWords})\\b`, "gi");

  const handleKeywordClick = (categoryIndex, keywordIndex) => {
    const updatedKeywords = keywordsArray?.map((category, cIndex) => ({
      ...category,
      people: category.people.map((keywordObj, kIndex) => ({
        ...keywordObj,
        isSelected:
          cIndex === categoryIndex && kIndex === keywordIndex ? true : false,
      })),
    }));

    // setSelectedKeyword(
    //   keywordsArray[categoryIndex].people[keywordIndex].keyword
    // );
    setKeywordsArray(updatedKeywords);
    if (targetProject) {
      // Find the paragraph index with the first occurrence of the selected keyword
      const targetIndex = paragraphsArray?.findIndex((paragraph) =>
        paragraph?.text?.toLowerCase().includes(selectedKeyword?.toLowerCase())
      );

      if (targetIndex !== -1) {
        setCurrentIndex(targetIndex);

        // Calculate the scroll position needed to bring the target paragraph to the top
        const targetParagraphRef = containerRef.current.children[targetIndex];
        const scrollPosition = targetParagraphRef.offsetTop;

        // Scroll the container to the calculated position
        try {
          // Scroll the container to the calculated position
          containerRef.current.scrollTop = scrollPosition;
        } catch (error) {
          console.log("Error while scrolling:", error);
        }
      }
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < paragraphsArray.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };
  const currentDocumentNumber = currentIndex + 1;
  const totalDocuments = paragraphsArray.length;

  const isMatchingObject = (searchObject, isFile) => {
    const fileNameToMatch = isFile ? searchObject.documentName : searchObject;

    const matchingObject = isFile
      ? personaResources?.find(
          (resource) =>
            resource.fileName === fileNameToMatch ||
            resource.originalFileName === fileNameToMatch ||
            resource.processedFileName === fileNameToMatch
        )
      : personaResources?.find((resource) => resource.url === fileNameToMatch);
    if (matchingObject) {
      nav("/chat", {
        state: {
          from: "resources",
          resourceData: matchingObject,
        },
      });
    }
    return Boolean(matchingObject);
  };

  const isMatchingResourceToAnalysis = (searchObject, isFile) => {
    const fileNameToMatch = isFile ? searchObject.documentName : searchObject;

    const matchingObject = isFile
      ? personaResources?.find(
          (resource) =>
            resource.fileName === fileNameToMatch ||
            resource.originalFileName === fileNameToMatch ||
            resource.processedFileName === fileNameToMatch
        )
      : personaResources?.find((resource) => resource.url === fileNameToMatch);
    if (matchingObject) {
      onOpen();
      settargetResource(matchingObject);
      getProjectKeywords(matchingObject, false);
    }
    return Boolean(matchingObject);
  };

  function areAllArraysEmpty(obj) {
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return false; // If any array is not empty, return false
      }
    }
    return true; // All arrays are empty
  }
  const getProjectKeywords = async (item, isProject) => {
    const fileUrls = item?.documents?.map((doc) => doc.docUrl);
    try {
      setIsLoading(true);
      if (isProject) {
        const res = await getDocumentsTextService(fileUrls);
        if (res.success) {
          setparagraphsArray(res.data);
          setProjectDocumentsText(res.data[0]?.text);
          // setIsLoa(false);
        } else {
          setIsLoading(false);
        }
      }
      // if (
      //   areAllArraysEmpty(item?.keywords) ||
      //   item?.updated?.keywords == true
      // ) {
      const res2 = isProject
        ? await getProjectKeywordsService(item?._id, false)
        : await getResourceKeywordsService(item?._id, false);

      if (res2.success && res2.data !== null) {
        const inputData2 = res2?.data;
        const newCategories = Object.keys(inputData2).map((categoryKey) => {
          return {
            title: categoryKey,
            people: inputData2[categoryKey].map((keyword) => ({
              keyword,
              isSelected: false,
            })),
          };
        });

        const nonEmptyCategories = newCategories
          .map((category) => ({
            ...category,
            people: category.people.filter(
              (person) => person.keyword.length >= 3
            ),
          }))
          .filter((category) => category.people.length > 0);

        setKeywordsArray(nonEmptyCategories);
        setIsShowCount(isShowCount + 1);
      } else {
        setisKeywordsEmpty(true);
      }
      // }
      //  else {
      //   const inputData2 = item?.keywords;
      //   const newCategories = Object.keys(inputData2).map((categoryKey) => {
      //     return {
      //       title: categoryKey,
      //       people: inputData2[categoryKey].map((keyword) => ({
      //         keyword,
      //         isSelected: false,
      //       })),
      //     };
      //   });

      //   const nonEmptyCategories = newCategories
      //     .map((category) => ({
      //       ...category,
      //       people: category.people.filter(
      //         (person) => person.keyword.length >= 3
      //       ),
      //     }))
      //     .filter((category) => category.people.length > 0);

      //   setKeywordsArray(nonEmptyCategories);
      //   setIsShowCount(isShowCount + 1);
      // }

      const summaryResponse = isProject
        ? await getProjectSummaryService(item?._id)
        : await getResourceSummaryService(item?._id);
      if (summaryResponse.success) {
        setisSummaryError("");
        setSummary(summaryResponse.data);
        setIsLoading(false);
      } else {
        setisSummaryError(summaryResponse.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(
        "🚀 ~ file: AnalizeMoment.js:250 ~ getKeywords ~ error:",
        error
      );
    }
  };

  useEffect(() => {
    const updateKeywordCounts = async () => {
      const updatedKeywordsArray = keywordsArray
        ?.map((category) => {
          const keywordMap = new Map();

          category.people.forEach((keywordObj) => {
            const count = countKeywordOccurrences(keywordObj.keyword);
            if (count > 0) {
              if (keywordMap.has(keywordObj.keyword)) {
                keywordMap.get(keywordObj.keyword).count += count;
              } else {
                keywordMap.set(keywordObj.keyword, { ...keywordObj, count });
              }
            }
          });

          // Convert the Map to an array for sorting
          const people = Array.from(keywordMap.values());

          // Sort the people array by count in descending order
          people.sort((a, b) => b.count - a.count);

          return { ...category, people };
        })
        .filter((category) => category.people.length > 0);

      setKeywordsArray(updatedKeywordsArray);
    };
    if (isShowCount > 0) {
      updateKeywordCounts();
    }
  }, [isShowCount]);

  useEffect(() => {
    return () => {
      processData({
        edges: [],
        nodes: [],
      });
    };
  }, []);

  const countKeywordOccurrences = (keyword) => {
    const regex = new RegExp(
      `\\b${keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}\\b`,
      "gi"
    );
    if (targetProject) {
      let totalCount = 0;

      paragraphsArray.forEach((paragraph) => {
        const matches = paragraph.text.match(regex);
        totalCount += matches ? matches.length : 0;
      });

      return totalCount;
    } else {
      const matches = targetResource
        ? targetResource?.processedFileContent.match(regex)
        : projectDocumentsText?.match(regex);
      return matches ? matches.length : 0;
    }
  };

  const getGraphDataHandler = async (keyword) => {
    setIsLoading(true);
    try {
      const res = await getResourceGraphDataService(
        targetResource ? targetResource?.correctedResourceName : null,
        targetResource ? null : targetProject?.projectName,
        keyword,
        loadGraphData
      );
      if (res.success) {
        setLoadGraphData(false);
        setrefresh(refresh + 1);
        const processedGraphData = processData(res.graph, keyword);
        setGraphData(processedGraphData);
        setIsLoading(false);
      } else {
        setErrorToast(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AnalizeMoment.js:308 ~ getGraphDataHandler ~ error:",
        error
      );
    }
  };

  const ReAnalyzeKeywordsHandler = async () => {
    const res2 = targetResource
      ? await getResourceKeywordsService(targetResource?._id, true)
      : await getProjectKeywordsService(targetProject?._id, true);

    if (res2.success) {
      setisKeywordsEmpty(true);
      setKeywordsArray([]);
      const inputData2 = res2?.data;
      const newCategories = Object.keys(inputData2).map((categoryKey) => {
        return {
          title: categoryKey,
          people: inputData2[categoryKey].map((keyword) => ({
            keyword,
            isSelected: false,
          })),
        };
      });

      const nonEmptyCategories = newCategories
        .map((category) => ({
          ...category,
          people: category.people.filter(
            (person) => person.keyword.length >= 3
          ),
        }))
        .filter((category) => category.people.length > 0);

      setKeywordsArray(nonEmptyCategories);
      setIsShowCount(isShowCount + 1);
    } else {
      setisKeywordsEmpty(true);
    }
  };
  useEffect(() => {
    const getProjectsHandler = async () => {
      const res = await getProjects();
    };
    getProjectsHandler();
  }, []);
  const handleDeleteTag = (deletedItem) => {
    if ("_id" in deletedItem) {
      settTargetFile(deletedItem?._id);
      setconfirmDeleteFileModal(true);
    } else if ("show" in deletedItem) {
      // It's an accordion item, toggle the 'show' property
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.url !== deletedItem.url)
      );
      // Toggle the 'show' property for the deleted item
      setDocumentList((prevDocumentList) =>
        prevDocumentList.map((doc) =>
          doc.url === deletedItem.url ? { ...doc, show: true } : doc
        )
      );
    } else {
      // It's a directly uploaded file, remove it from selectedItems
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.name !== deletedItem.name)
      );
    }
  };

  const handleRemoveFromUrlArray = async (item) => {
    if (blobUrlsArray.includes(item)) {
      settTargetUrl(item);
      setconfirmDeleteFileModal(true);
    } else {
      // Remove the URL from the array
      setSelectedUrls((prevSelectedItems) =>
        prevSelectedItems.filter((url) => url !== item)
      );
    }
  };
  function countTrueKeys(obj) {
    var trueCount = 0;

    for (var key in obj) {
      if (obj[key] === true) {
        trueCount++;
        if (trueCount > 1) {
          return true; // More than one true key found
        }
      }
    }

    return false; // Less than or equal to one true key found
  }
  const datePickerRef = useRef(null);
  const initializeFlatpickr = () => {
    const options = {
      mode: "range",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr) {
        const [startDate, endDate] = dateStr.split(" to ");
        if (endDate) {
          setFilterData((prev) => ({
            ...prev,
            start_date: startDate || "",
            end_date: endDate || startDate || "",
          }));
        }
      },
    };
    flatpickrInstanceRef.current = flatpickr(datePickerRef.current, options);
  };
  const clearDate = () => {
    flatpickrInstanceRef.current.clear();
    setFilterData({
      start_date: "",
      end_date: "",
    });
  };

  const flatpickrInstanceRef = useRef(null);

  useEffect(() => {
    const options = {
      mode: "range",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr) {
        const [startDate, endDate] = dateStr.split(" to ");
        // console.log("🚀  useEffect  endDate:", endDate);
        // console.log("🚀  useEffect  startDate:", startDate);

        if (endDate) {
          setFilterData((prev) => ({
            ...prev,
            start_date: startDate || "",
            end_date: endDate || startDate || "",
          }));
        }
      },
    };
    flatpickrInstanceRef.current = flatpickr(datePickerRef.current, options);
    flatpickr(datePickerRef.current, options);
  }, []);

  useEffect(() => {
    if (filterData.start_date || filterData.end_date) {
      const filteredResources = filterProjectsByDateRange(
        filterData.start_date,
        filterData.end_date || filterData.start_date
      );
      // setTempPersonaProjects(filteredResources);
    }
  }, [filterData, personaResources]);
  useEffect(() => {
    initializeFlatpickr();
    return () => {
      // Cleanup Flatpickr instance
      flatpickrInstanceRef.current.destroy();
    };
  }, []);
  const lowercasedCleanedWordsArray = cleanedWordsArray?.map((word) =>
    word.toLowerCase()
  );

  // Convert synonymsArray to lowercase
  const lowercasedSynonymsArray = synonymsArray?.map((word) =>
    word.toLowerCase()
  );
  const closeSearch = () => {
    // alert('invoked')
    setElasticSearchText("");
    setSelectedItems([]);
    // setCreateProjectModal(false);
    setDocumentList([]);
    setSelectedFileResource([]);
    setShowpersonaProjects(true);
    // resetProject();
    setIsSearchResultEmpty(false);
    clearDate();
  
  };
  return (
    <div>
      <CustomToaster
        open={errorToast}
        onClose={handleCloseErrorToast}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback />
      <NavBar />
      <div className="persona-container-light">
        <div className="mainContainer">
          {" "}
          <input
            type="checkbox"
            id="sideNavDrawer-toggle"
            name="sideNavDrawer-toggle"
          />
          <label
            for="sideNavDrawer-toggle"
            id="sideNavDrawer-toggle-label"
          ></label>
          <SideNav isShowMenu={false} />
          <div className="mainContainer-right">
            <div ref={searchComponentRef}>
              {/* elastic search  */}
              <div className="d-flex w-100 search-container gap-2">
                <Box sx={{ flexGrow: "1", position: "relative" }}>
                  <TextField
                    size="small"
                    value={elasticSearchText}
                    onChange={(e) => setElasticSearchText(e.target.value)}
                    fullWidth
                    label="Search"
                    onKeyDown={handleKeyPressSearch}
                    sx={{ background: "white" }}
                  />
                  <ChakraProvider>
                    <ChakraButton
                      isDisabled={showpersonaProjects}
                      onClick={closeSearch}
                      className="clearSearch"
                      _hover={{}}
                    >
                      <CloseIcon />
                    </ChakraButton>{" "}
                  </ChakraProvider>
                  <ChakraProvider>
                    <Tooltip
                      placement="top"
                      hasArrow
                      label="Search for multiple words exactly by wrapping them in single or double quotations for precise results. For example: 'United States' America"
                      aria-label="A tooltip"
                    >
                      <span
                        style={{
                          position: "absolute",
                          right: "7px",
                          bottom: "8px",
                        }}
                        className="cursor-pointer"
                      >
                        <MdInfo fontSize={"1.5rem"} />
                      </span>
                    </Tooltip>
                  </ChakraProvider>
                </Box>
                <Box sx={{ width: tabletDevices ? "150px" : "250px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: 7,
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <input
                        style={{
                          fontSize: tabletDevices ? "0.8rem" : "1rem",
                          height: "2.7rem",
                          borderRadius: "4px",
                          width: "100%",
                          border: "1px solid #C7C7C7",
                          cursor: !showpersonaProjects
                            ? "not-allowed"
                            : "pointer",
                        }}
                        className="bg-whiteGrey filter-date rounded-lg outline-none p-3 W-100"
                        type="text"
                        placeholder="Filter by date"
                        ref={datePickerRef}
                        // disabled={!showpersonaProjects}
                      />
                    </div>
                    <div>
                      {/* Upload icon with onClick handler */}
                      <IconButton
                        onClick={() => {
                          countTrueKeys(expandedResourceIndex)
                            ? console.log("")
                            : setShowAddResourceModal(true);
                        }}
                        style={{
                          opacity: countTrueKeys(expandedResourceIndex)
                            ? "0.4"
                            : "1",
                          cursor: countTrueKeys(expandedResourceIndex)
                            ? "not-allowed"
                            : "pointer",
                        }}
                        className="upload-button"
                      >
                        <UploadIcon />
                      </IconButton>
                    </div>

                    <div
                      style={{ display: createProjectButton ? "flex" : "none" }}
                      onClick={() => setCreateProjectModal(true)}
                      className="save-button"
                    >
                      <SaveIcon style={{ height: "1.5rem", width: "1.5rem" }} />
                    </div>
                  </div>
                </Box>
              </div>

              <div
                style={{ background: "#F5F6F7" }}
                class="mainContainer-Inner"
              >
                <h1 className="pageHeader">
                  {showpersonaProjects ? "Projects" : "Resources"}
                </h1>
                <div className="project-resources-container">
                  {/* uploaded resources */}
                  {showpersonaProjects && tempProjects?.length > 0 ? (
                    tempProjects?.map((item, index) => (
                      <Accordion
                        key={index}
                        sx={{
                          border: "1px solid #CED3D9",
                          boxShadow: "none",
                          marginBottom: "1rem",
                        }}
                        expanded={expandedResourceIndex[index] || false}
                        onChange={handleResourcesAccordionChange(index, item)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div className="d-flex w-100 align-items-center justify-content-between">
                            <div
                              style={{ width: "30%" }}
                              className="d-flex align-items-center gap-2"
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  color: "#051D2C",
                                  maxWidth: "80%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                className="file-name"
                              >
                                {item?.projectName}
                              </Typography>
                            </div>
                            <Typography sx={{ fontSize: 12, color: "#051D2C" }}>
                              {item?.documents?.length} documents,{" "}
                              {item?.urls?.length} external links
                            </Typography>
                            <div className="d-flex align-items-center gap-2 me-3">
                              {/* <MdDelete
                              onClick={(e) => {
                                e.stopPropagation();
                                settargetDocument();
                                settargetProjectUrl();
                                setTargetProjectId(item?._id);
                                setconfirmDeleteFileModal(true);
                              }}
                              style={{
                                height: "26px",
                                width: "26px",
                                color: "#4775D5",
                              }}
                            /> */}
                              {item?.documents?.length > 0 ? (
                                <div
                                  ref={btnRef}
                                  onClick={(e) => {
                                    stopPropagation(e);
                                    onOpen();
                                    settargetProject(item);
                                    getProjectKeywords(item, true);
                                  }}
                                >
                                  <ChartIcon
                                    style={{ height: "24px", width: "24px" }}
                                  />
                                </div>
                              ) : (
                                <ChartIconDsiabled
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    cursor: "default",
                                  }}
                                />
                              )}
                              {item?.documents?.length + item?.urls?.length >
                              0 ? (
                                <BlueChatIcon
                                  onClick={(e) => {
                                    stopPropagation(e);

                                    nav("/chat", {
                                      state: {
                                        from: "projects",
                                        projectData: item,
                                      },
                                    });
                                  }}
                                />
                              ) : (
                                <DisableBlueChatIcon
                                  style={{
                                    cursor: "default",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: "0",
                          }}
                        >
                          {item?.documents?.map((document, index) => (
                            <div
                              style={{
                                borderBottom:
                                  index < item?.documents?.length - 1
                                    ? "1px solid #CED3D9"
                                    : "",
                                borderTop:
                                  index === 0 ? "1px solid #CED3D9" : "",
                              }}
                              key={index}
                              className="d-flex ps-3 py-3 w-100 align-items-center justify-content-between"
                            >
                              <div
                                style={{ width: "50%" }}
                                className="d-flex align-items-center gap-2"
                              >
                                <Checkbox
                                  checked={selectedProjectDocumentsArray?.some(
                                    (selectedItem) =>
                                      selectedItem._id === document._id
                                  )}
                                  onClick={stopPropagation}
                                  onChange={() =>
                                    handleDocumentCheckboxChange(document)
                                  }
                                  style={{ height: "24px", width: "24px" }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    color: "#051D2C",
                                    maxWidth: "80%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    fontWeight: "400 !important",
                                  }}
                                  className="file-name"
                                >
                                  {document?.documentName}
                                </Typography>
                              </div>
                              <div
                                style={{ marginRight: "1.4rem" }}
                                className="d-flex align-items-center gap-2"
                              >
                                {/* <MdDelete
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setTargetProjectId();
                                  settargetDocument(document?._id);
                                  settargetProject(item);
                                  settargetProjectUrl();
                                  setconfirmDeleteFileModal(true);
                                }}
                                style={{
                                  height: "26px",
                                  width: "26px",
                                  color: "#4775D5",
                                  cursor: "pointer",
                                }}
                              /> */}
                                <div
                                  ref={btnRef}
                                  onClick={(e) => {
                                    stopPropagation(e);
                                    isMatchingResourceToAnalysis(
                                      document,
                                      true
                                    );
                                  }}
                                >
                                  <ChartIcon
                                    style={{
                                      height: "24px",
                                      width: "24px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <BlueChatIcon
                                  onClick={(e) => {
                                    stopPropagation(e);
                                    isMatchingObject(document, true);
                                  }}
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    cursor: "pointer",
                                  }}
                                />
                                <div
                                  style={{ height: "24px", width: "24px" }}
                                ></div>
                              </div>
                            </div>
                          ))}
                          {item?.urls?.map((urll, index) => (
                            <div
                              style={{
                                borderTop: "1px solid #CED3D9",
                              }}
                              key={index}
                              className="d-flex ps-3 py-3  w-100 align-items-center justify-content-between"
                            >
                              <div
                                style={{ width: "50%" }}
                                className="d-flex align-items-center gap-2"
                              >
                                <Checkbox
                                  checked={selectedProjectLinksArray?.some(
                                    (selectedItem) => selectedItem === urll
                                  )}
                                  onClick={stopPropagation}
                                  onChange={() =>
                                    handleLinkCheckboxChange(urll)
                                  }
                                  style={{ height: "24px", width: "24px" }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    color: "#051D2C",
                                    maxWidth: "80%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    fontWeight: "400 !important",
                                  }}
                                  className="file-name"
                                >
                                  {urll}
                                </Typography>
                              </div>
                              <div
                                style={{ marginRight: "1.4rem" }}
                                className="d-flex align-items-center gap-2"
                              >
                                {/* <MdDelete
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setTargetProjectId();
                                  settargetDocument();
                                  settargetProjectUrl(urll);
                                  settargetProject(item);
                                  setconfirmDeleteFileModal(true);
                                }}
                                style={{
                                  height: "26px",
                                  width: "26px",
                                  color: "#4775D5",
                                  cursor: "pointer",
                                }}
                              /> */}
                                <div
                                // ref={btnRef}
                                // onClick={(e) => {
                                //   stopPropagation(e);
                                //   isMatchingResourceToAnalysis(urll, false);
                                // }}
                                >
                                  <ChartIconDsiabled
                                    style={{
                                      height: "24px",
                                      width: "24px",
                                      cursor: "default",
                                    }}
                                  />
                                </div>
                                <BlueChatIcon
                                  onClick={(e) => {
                                    stopPropagation(e);
                                    isMatchingObject(urll, false);
                                  }}
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    cursor: "pointer",
                                  }}
                                />
                                <div
                                  style={{ height: "24px", width: "24px" }}
                                ></div>
                              </div>
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : showpersonaProjects &&
                    personaProjects?.length === 0 &&
                    isLoading === false ? (
                    <div className="empty-resource">
                      <img
                        style={{ width: "70px", height: "70px" }}
                        src={emptyResource}
                      />
                      <p className="my-3">
                        You have no projects added for this persona
                      </p>
                      <ReusableButton
                        size="small"
                        fontSize="0.8rem"
                        buttonText={"ADD ONE NOW"}
                        onClick={() => setShowAddResourceModal(true)}
                      />
                    </div>
                  ) : showpersonaProjects && personaProjects?.length > 0 ? (
                    <div className="empty-resource">
                      <img
                        style={{ width: "70px", height: "70px" }}
                        src={emptyResource}
                      />
                      <p className="my-3">
                        There are no projects added within the specified date
                        range.
                      </p>
                    </div>
                  ) : null}

                  {/* {/ search results /} */}
                  {!showpersonaProjects && documentList?.length > 0 ? (
                    documentList?.map((item, index) => (
                      // item?.show && (
                      <Accordion
                        key={index}
                        sx={{
                          borderLeft: "3px solid #4775D5",
                          marginBottom: "15px",
                        }}
                        expanded={expanded[index] || false}
                        onChange={handleAccordionChange(index)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          sx={{
                            borderTop: "1px solid #CED3D9",
                          }}
                        >
                          <div className="d-flex w-100 align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <Checkbox
                                checked={isSelected(item)}
                                onClick={stopPropagation}
                                onChange={() => {
                                  handleCheckboxChangeAccordion(item);
                                }}
                                style={{ height: "24px", width: "24px" }}
                              />

                              <Box
                                style={{ marginLeft: "20px" }}
                                sx={{
                                  position: "relative",
                                  display: "inline-flex",
                                }}
                              >
                                <CircularProgress
                                  size={44}
                                  thickness={4}
                                  value={Math.floor(item?.score)}
                                  sx={{ color: "#21DC82" }}
                                  variant="determinate"
                                />
                                <CircularProgress
                                  size={44}
                                  thickness={4}
                                  value={item?.score}
                                  sx={{
                                    color: "rgba(33, 45, 44, 0.1)",
                                    position: "absolute",
                                  }}
                                  variant="determinate"
                                />
                                <Box
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="black"
                                    className="progress-value"
                                  >
                                    {Math.floor(item?.score)}%
                                  </Typography>
                                </Box>
                              </Box>
                              <div className="d-flex flex-column gap-1 ms-3">
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  className="file-name"
                                >
                                  {item.fileName}
                                </Typography>

                                <div className="d-flex align-items-center gap-1 ">
                                  <FileGreyIcon />
                                  <div className="resource-type">Document</div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 me-3 ">
                              {/* <ChartIcon
                              style={{ height: "24px", width: "24px" }}
                              onClick={stopPropagation}
                            /> */}
                              <BlueChatIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  nav("/chat", {
                                    state: {
                                      from: "projects",
                                      projectData: item,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            borderTop: "1px solid #CED3D9",
                            padding: "16px",
                          }}
                        >
                          <div
                            className="mt-3"
                            style={{
                              fontSize: "1rem",
                              lineHeight: "1.7rem",
                              fontWeight: "400",
                            }}
                          >
                            {item?.content
                              ?.split(synonymsRegex)
                              .filter(Boolean)
                              .map((chunk, index) => {
                                return (
                                  <span
                                    key={index}
                                    className={
                                      lowercasedCleanedWordsArray?.includes(
                                        chunk.toLowerCase()
                                      )
                                        ? "highlight-text"
                                        : lowercasedSynonymsArray?.includes(
                                            chunk.toLowerCase()
                                          )
                                        ? "orange-text"
                                        : ""
                                    }
                                  >
                                    {chunk}
                                  </span>
                                );
                              })}
                          </div>
                          <div className="d-flex gap-2 align-items-center justify-content-between mt-3">
                            <div style={{ color: "#76828B", fontSize: "1rem" }}>
                              <span>
                                Created on{" "}
                                <span style={{ color: "#051D2C" }}>
                                  {moment(item?.resourceDate).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                </span>
                              </span>
                              by{" "}
                              <span style={{ color: "#051D2C" }}>
                                {item?.userName}
                              </span>{" "}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : !showpersonaProjects ? (
                    <div className="mt-5">
                      <Typography sx={{ textAlign: "center" }}>
                        No results found
                      </Typography>
                    </div>
                  ) : null}
                </div>
                {/* selected items */}
                {showpersonaProjects &&
                (selectedProjectDocumentsArray?.length > 0 ||
                  selectedProjectLinksArray?.length > 0) ? (
                  <div className="selected-resources-container">
                    <div className="selected-resources ">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="resources-count">
                          <h4>
                            {selectedProjectDocumentsArray?.length +
                              selectedProjectLinksArray?.length}
                          </h4>
                        </div>
                        <p className="resources-text">
                          {selectedProjectDocumentsArray?.length +
                            selectedProjectLinksArray?.length >
                          1
                            ? "Resources selected"
                            : "Resource Selected"}
                        </p>
                      </div>
                      <div className="icons-container d-flex gap-3 align-items-center">
                        <div
                          onClick={() => {
                            const tempFileArray =
                              selectedProjectDocumentsArray?.map((item) => {
                                return {
                                  fileUrl: item.docUrl,
                                  fileName: item.documentName,
                                  _id: item?._id,
                                  isFile: true,
                                };
                              });
                            const tempFileLinks =
                              selectedProjectLinksArray?.map((item) => {
                                return {
                                  url: item,
                                  isFile: false,
                                };
                              });
                            nav("/chat", {
                              state: {
                                from: "resources",
                                resourceData:
                                  tempFileArray?.length +
                                    tempFileLinks?.length >
                                  1
                                    ? [...tempFileArray, ...tempFileLinks]
                                    : tempFileArray?.length === 0
                                    ? tempFileLinks[0]
                                    : tempFileArray[0],
                              },
                            });
                          }}
                          className="action-item gap-1"
                        >
                          <ChatIconOutlined
                            style={{ height: "1.25rem", width: "1.25rem" }}
                          />
                          <span>Chat</span>
                        </div>
                        <div
                          onClick={() => setCreateProjectModal(true)}
                          className="action-item gap-1"
                        >
                          <CreateProjectIcon
                            style={{ height: "1.25rem", width: "1.25rem" }}
                          />
                          <span>Create Project</span>
                        </div>
                        {/* <div className="action-item gap-1">
                      <DeleteIcon style={{ height: "1.5rem", width: "1.5rem" }} />
                      <p>Delete</p>
                    </div> */}
                      </div>
                      <div
                        onClick={() => {
                          setSelectedpersonaProjects([]);
                          setSelectedProjectDocumentsArray([]);
                          setSelectedProjectLinksArray([]);
                        }}
                        className="mx-3 cursor-pointer"
                      >
                        <CloseIcon style={{ height: "30px", width: "30px" }} />
                      </div>
                    </div>
                  </div>
                ) : !showpersonaProjects && selectedItems?.length > 0 ? (
                  <div className="selected-resources-container">
                    <div className="selected-resources ">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="resources-count">
                          <h4>
                            {selectedItems?.length ||
                              selectedpersonaProjects?.length}
                          </h4>
                        </div>
                        <p className="resources-text">
                          {selectedItems?.length ||
                          selectedpersonaProjects?.length > 1
                            ? "Resources selected"
                            : "Resource Selected"}
                        </p>
                      </div>
                      <div className="icons-container d-flex gap-3 align-items-center">
                        {/* <div className="action-item gap-2">
                          <ChatIconOutlined
                            style={{ height: "20px", width: "20px" }}
                          />
                          <p>Chat</p>
                        </div> */}
                        <div
                          onClick={() => setCreateProjectModal(true)}
                          className="action-item gap-2"
                        >
                          <CreateProjectIcon
                            style={{ height: "20px", width: "20px" }}
                          />
                          <p>Create Project</p>
                        </div>
                        {/* <div className="action-item gap-2">
                          <DeleteIcon
                            style={{ height: "20px", width: "20px" }}
                          />
                          <p>Delete</p>
                        </div> */}
                      </div>
                      <div
                        onClick={() => {
                          setSelectedItems([]);
                          setSelectedpersonaProjects([]);
                        }}
                        className="mx-3 cursor-pointer"
                      >
                        <CloseIcon style={{ height: "30px", width: "30px" }} />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* save project modal */}
      <BootstrapModal
        show={showAddResourceModal}
        onHide={() => {
          setShowAddResourceModal(false);
          setResourceType({ label: "Document" });
          setURL("");
          setSelectedFileResource();
        }}
        centered
      >
        <BootstrapModal.Header
          style={{ border: "none", backgroundColor: "#D4E2FF" }}
        >
          <div
            style={{
              backgroundColor: "#D4E2FF",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBlock: "1rem",
              position: "relative",
            }}
          >
            <CloseIcon
              onClick={() => {
                setShowAddResourceModal(false);
                setResourceType({ label: "Document" });
                setURL("");
                setSelectedFileResource();
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: -5,
                right: -5,
              }}
            />
            <img style={{ width: "250px" }} src={addresource} />
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div className="mb-3">
            <p
              style={{
                color: "#051D2C",
                fontWeight: "500",
                fontSize: "1.5rem",
              }}
            >
              Add a resource
            </p>
          </div>
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ background: "white", cursor: "pointer" }}
            options={resourceTypes}
            value={resourceType}
            onChange={handleResourceTypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type of resource"
                placeholder="Select resource"
              />
            )}
          />
          {resourceType?.label === "Document" ? (
            <div onClick={handleUploadClick} className="icon-button gap-2 mt-4">
              {/* Hidden file input */}
              <input
                onClick={(e) => {
                  e.stopPropagation();
                }}
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              {/* Upload icon with onClick handler */}
              <IconButton>
                <UploadIcon />
              </IconButton>
              <p
                style={{
                  color: "white",
                  fontWeight: "500",
                  fontSize: "1rem",
                  marginBottom: 0,
                }}
              >
                {selectedFileResource?.name || "UPLOAD FILE"}
              </p>
            </div>
          ) : (
            <div className="w-100 mt-4">
              <TextField
                value={url}
                onChange={(e) => setURL(e.target.value)}
                size="small"
                label="URL"
                placeholder="Enter url here"
                sx={{ width: "100%" }}
              />
            </div>
          )}

          <div className=" mt-4 d-flex gap-3 align-items-center justify-content-end">
            <Button
              onClick={() => {
                setShowAddResourceModal(false);
                setResourceType({ label: "Document" });
                setURL("");
                setSelectedFileResource();
              }}
              sx={{ color: "#051D2C", fontWeight: "600" }}
              variant="text"
            >
              Cancel
            </Button>
            <ReusableButton
              // isDisabled={!selectedFileResource && !url}
              onClick={() => {
                if (resourceType?.label === "Document") {
                  handleAddToFilesArray();
                } else if (resourceType?.label === "External Link") {
                  handleAddToUrlArray();
                } else {
                }
              }}
              buttonText={"SAVE RESOURCE"}
            />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
      {/* create project modal */}

      <BootstrapModal
        id="create-modal-2"
        show={createProjectModal}
        onHide={() => setCreateProjectModal(false)}
        centered
      >
        <BootstrapModal.Header
          style={{ border: "none", backgroundColor: "#D4E2FF" }}
        >
          <div
            style={{
              backgroundColor: "#D4E2FF",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBlock: "1rem",
              position: "relative",
            }}
          >
            <CloseIcon
              onClick={() => setCreateProjectModal(false)}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: -5,
                right: -5,
              }}
            />
            <img style={{ width: "250px" }} src={addresource} />
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <p
            style={{ color: "#051D2C", fontWeight: "500", fontSize: "1.5rem" }}
          >
            Create a project
          </p>

          <div className="w-100 mt-4">
            <TextField
              // disabled={selectedProject?.projectName ? true : false}
              value={projectName}
              onChange={(e) => setprojectName(e.target.value)}
              size="small"
              label="Project Name"
              placeholder="Enter project name"
              sx={{ width: "100%" }}
            />
          </div>
          <div className="w-100 mt-4">
            <TextField
              value={projectDescription}
              onChange={(e) => setprojectDescription(e.target.value)}
              size="small"
              label="Project Description"
              placeholder="Enter project description"
              sx={{ width: "100%" }}
            />
          </div>

          <div className=" mt-4 d-flex gap-3 align-items-center justify-content-end">
            <Button
              onClick={() => setCreateProjectModal(false)}
              sx={{ color: "#051D2C", fontWeight: "600" }}
              variant="text"
            >
              Cancel
            </Button>
            <ReusableButton
              isDisabled={!projectName || !projectDescription}
              onClick={CreateProjectHandler}
              buttonText={"CREATE PROJECT"}
            />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
      <div>
        <BootstrapModal
          show={confirmDeleteFileModal}
          onHide={() => setconfirmDeleteFileModal(false)}
          centered
        >
          <BootstrapModal.Header
            style={{ border: "none", paddingBottom: 0 }}
            closeButton
          ></BootstrapModal.Header>
          <BootstrapModal.Body style={{ padding: 0 }}>
            <div className="d-flex flex-column gap-4 px-5  align-items-center">
              <img src={alertIcon} />
              <h6
                style={{
                  color: "#051D2C",
                  fontSize: "20px",
                  textAlign: "center",
                  lineHeight: "1.4",
                }}
              >
                Are you sure you want to permanently delete this{" "}
                {targetProjectId ? "project" : "resource"}?
              </h6>
            </div>
            <div
              style={{ borderTop: "1px solid #CED3D9" }}
              className="mt-4 d-flex gap-1 align-items-center justify-content-between"
            >
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "1px solid #CED3D9",
                }}
              >
                <Button
                  onClick={() => setconfirmDeleteFileModal(false)}
                  sx={{ color: "#051D2C", fontWeight: "600" }}
                  variant="text"
                >
                  CANCEL
                </Button>
              </div>
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={
                    targetProjectId ? handleDeleteProject : handleDeleteEntity
                  }
                  sx={{ color: "#C51333", fontWeight: "600" }}
                  variant="text"
                >
                  YES, DELETE
                </Button>
              </div>
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      </div>
      <ChakraProvider>
        <Drawer
          size={"lg"}
          isOpen={isOpen}
          placement="right"
          onClose={() => {
            setSearchedKeyword();
            setSelectedKeyword();
            setLoadGraphData(true);
            setKeywordsArray([]);
            setShowResourceContentMore(false);
            settargetProject();
            settargetResource();
            processData({
              edges: [],
              nodes: [],
            });
            onClose();
          }}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <Tabs
                mt={8}
                orientation="horizontal"
                defaultIndex={0}
                className="tab-names"
              >
                <TabList borderBottom={"none"}>
                  <Tab
                    w="50%"
                    color="#76828B !important"
                    fontWeight={"500"}
                    fontSize={"1rem"}
                    _selected={{
                      color: "#4775D5 !important",
                      borderBottomColor: "#4775D5 !important",
                    }}
                  >
                    DATA
                  </Tab>
                  <Tab
                    w="50%"
                    color="#76828B !important"
                    fontWeight={"500"}
                    fontSize={"1rem"}
                    _selected={{
                      color: "#4775D5 !important",
                      borderBottomColor: "#4775D5 !important",
                    }}
                  >
                    ANALYSIS
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel className="px-0  mx-0">
                    <Stack px={6} pt={4}>
                      <Text fontSize="lg" fontWeight={"semibold"} mb={0}>
                        Transcript
                      </Text>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-flex align-items-center gap-1 ">
                          <FileGreyIcon />
                          <div
                            style={{ color: "#051D2C", fontWeight: "400" }}
                            className="resource-type"
                          >
                            Document
                          </div>
                        </div>
                        <div style={{ color: "#76828B", fontSize: "0.9rem" }}>
                          <span>
                            Created on{" "}
                            <span style={{ color: "#051D2C" }}>
                              {moment(
                                targetProject
                                  ? targetProject?.projectDate
                                  : targetResource?.resourceDate
                              ).format("DD/MM/YYYY")}{" "}
                            </span>
                          </span>
                          by{" "}
                          <span style={{ color: "#051D2C" }}>
                            {targetProject
                              ? targetProject?.projectOwner?.name
                              : targetResource?.resourceCreator?.name}
                          </span>{" "}
                        </div>
                      </div>
                      <Box>
                        {targetProject &&
                          paragraphsArray &&
                          paragraphsArray.length > 0 && (
                            <Stack
                              alignItems={"center"}
                              direction="row"
                              spacing={3}
                            >
                              <IconButton
                                icon={<FaBackward fontSize={20} />}
                                onClick={handleBack}
                                disabled={currentIndex === 0}
                                variant="pagination-button"
                              />
                              <Text>
                                <chakra.span fontWeight={"600"}>
                                  {currentDocumentNumber}
                                </chakra.span>{" "}
                                of{" "}
                                <chakra.span fontWeight={"600"}>
                                  {totalDocuments}
                                </chakra.span>{" "}
                                Documents
                              </Text>
                              <IconButton
                                icon={<TbPlayerTrackNextFilled fontSize={24} />}
                                onClick={handleNext}
                                disabled={
                                  currentIndex === paragraphsArray &&
                                  paragraphsArray?.length - 1
                                }
                                variant="pagination-button"
                              />
                            </Stack>
                          )}
                        <Input
                          mt={4}
                          placeholder="Search"
                          value={selectedKeyword}
                          onChange={(e) => setSelectedKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              // Call your function here
                              setSearchedKeyword(e.target.value);
                            }
                          }}
                          // ... (Textarea props)
                        />
                        <Box
                          ref={containerRef}
                          justifyContent={"center"}
                          fontSize="md"
                          whiteSpace={"pre-line"}
                          py={3}
                        >
                          <Text
                            fontWeight="400"
                            fontSize="md"
                            lineHeight="23px"
                            letterSpacing="wide"
                            whiteSpace={"pre-line"}
                          >
                            {truncatedContentText
                              ?.split(
                                new RegExp(`(\\b${searchedKeyword}\\b)`, "gi")
                              )
                              .filter(Boolean)
                              .map((chunk, index) => (
                                <span
                                  key={index}
                                  className={
                                    chunk?.toLowerCase() ===
                                    searchedKeyword?.toLowerCase()
                                      ? "highlight-text"
                                      : ""
                                  }
                                >
                                  {chunk}
                                </span>
                              ))}
                            {targetResource &&
                              !showResourceContentMore &&
                              targetResource?.processedFileContent?.length >
                                1250 &&
                              "..."}
                            {targetResource &&
                              targetResource?.processedFileContent?.length >
                                1250 && (
                                <>
                                  <span> </span>
                                  <span
                                    style={{
                                      textDecorationLine: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={toggleResourceContentShowMore}
                                  >
                                    {showResourceContentMore
                                      ? "Show Less"
                                      : "Show More"}
                                  </span>
                                </>
                              )}
                            {targetProject &&
                              !showResourceContentMore &&
                              projectDocumentsText?.length > 1250 &&
                              "..."}
                            {targetProject &&
                              projectDocumentsText?.length > 1250 && (
                                <>
                                  <span> </span>
                                  <span
                                    style={{
                                      textDecorationLine: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={toggleResourceContentShowMore}
                                  >
                                    {showResourceContentMore
                                      ? "Show Less"
                                      : "Show More"}
                                  </span>
                                </>
                              )}
                          </Text>
                        </Box>
                      </Box>
                    </Stack>
                  </TabPanel>

                  <TabPanel className="px-0  mx-0">
                    <Stack maxWidth={"100%"}>
                      {/* SUMMARY */}
                      <Stack
                        bg="#F6F6F6"
                        w="100%"
                        padding={"10px 1.5rem 10px 1.5rem"}
                        borderRadius={"0.571rem"}
                      >
                        <Text mb={0} fontWeight={"600"}>
                          Summary{" "}
                        </Text>
                        <Stack direction={"row"} alignItems="center">
                          <Text
                            fontWeight="400"
                            fontSize="sm"
                            lineHeight="23px"
                            letterSpacing="wide"
                          >
                            {truncatedText}
                            {!showMore && summary?.length > 200 && "..."}
                            {summary?.length > 200 && (
                              <>
                                <span> </span>
                                <span
                                  style={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleShowMore}
                                >
                                  {showMore ? "Show Less" : "Show More"}
                                </span>
                              </>
                            )}
                          </Text>
                        </Stack>
                      </Stack>
                      {/* RE-ANALYZE BUTTON */}
                      <div className="d-flex py-3 w-100 justify-content-end">
                        <ChakraButton
                          size="sm"
                          fontWeight={"500"}
                          bg="transparent"
                          w="fit-content"
                          color="#4775D5"
                          _hover={{}}
                          border="1.2px solid #4775D5"
                          textTransform={"uppercase"}
                          onClick={ReAnalyzeKeywordsHandler}
                        >
                          Re-Analyze
                        </ChakraButton>
                      </div>
                      {/* KEYWORDS */}
                      <Stack
                        maxW={"100%"}
                        pb={4}
                        minH={"fit-content"}
                        overflowX={"auto"}
                        spacing={3}
                      >
                        {keywordsArray && keywordsArray.length > 0 ? (
                          keywordsArray?.map((category, categoryIndex) => (
                            <Stack
                              key={category.title}
                              alignItems={"center"}
                              direction="row"
                              w="100%"
                              spacing={3}
                            >
                              <Text
                                fontWeight={"450"}
                                mb={0}
                                fontSize="1rem"
                                minWidth={"23%"}
                                maxWidth={"140px"}
                              >
                                {category.title}
                              </Text>
                              <Stack w={"70%"} direction="row" spacing={3}>
                                {category?.people.map(
                                  (keywordObj, keywordIndex) =>
                                    keywordObj?.count > 0 && (
                                      <Stack
                                        key={keywordObj.keyword}
                                        bg={
                                          keywordObj.isSelected
                                            ? "#4775D5"
                                            : "transparent"
                                        }
                                        onClick={() => {
                                          handleKeywordClick(
                                            categoryIndex,
                                            keywordIndex
                                          );
                                          getGraphDataHandler(
                                            keywordObj.keyword
                                          );
                                        }}
                                        // Other props for styling
                                        border={"1.5px solid #4775D5"}
                                        spacing={2}
                                        padding={"4px 4px 4px 10px"}
                                        // py={1}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent={"space-between"}
                                        rounded="full"
                                        // px={2}
                                        height={"27px"}
                                        minWidth={"fit-content"}
                                        cursor={"pointer"}
                                      >
                                        {/* Your keyword rendering code */}
                                        <Text
                                          margin={0}
                                          fontWeight={"400"}
                                          fontSize={"0.857rem"}
                                          whiteSpace={"nowrap"}
                                          color={
                                            keywordObj.isSelected
                                              ? "white"
                                              : "#051D2C"
                                          }
                                        >
                                          {keywordObj.keyword}
                                        </Text>

                                        <Box
                                          fontSize={"10px"}
                                          width="19px"
                                          height="19px"
                                          borderRadius="50%"
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          fontWeight={"500"}
                                          backgroundColor={
                                            keywordObj.isSelected
                                              ? "white"
                                              : "#4775D5"
                                          }
                                          color={
                                            keywordObj.isSelected
                                              ? "#4775D5"
                                              : "white"
                                          }
                                        >
                                          <div>{keywordObj?.count}</div>
                                        </Box>
                                      </Stack>
                                    )
                                )}
                              </Stack>
                            </Stack>
                          ))
                        ) : isLoading ? null : isKeywordsEmpty ? (
                          <Text>
                            Keywords are currently generating, you will receive
                            an email notification upon completion.
                          </Text>
                        ) : (
                          <SkeletonText
                            mt="4"
                            noOfLines={4}
                            spacing="4"
                            skeletonHeight="2"
                          />
                        )}
                      </Stack>
                      {/* graph */}
                      <Stack
                        height={"50vh"}
                        style={{ position: "relative" }}
                        // borderBlock={"2px solid #051D2C"}
                        border="1.4px solid #CED3D9"
                      >
                        <CustomFloatingEdgeGraph
                          graphData={graphData}
                          refresh={refresh}
                        />
                        <ImEnlarge
                          onClick={onOpenGraphModal}
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            right: 15,
                            top: 7,
                          }}
                        />
                      </Stack>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <>
          <Modal
            isCentered
            onClose={onCloseGraphModal}
            isOpen={isOpenGraphModal}
            size={"full"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <Stack height={"100vh"}>
                  <CustomFloatingEdgeGraph
                    graphData={graphData}
                    refresh={refresh}
                  />
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      </ChakraProvider>
    </div>
  );
};

export default PersonaProjects;
