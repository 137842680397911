import {
  InputAdornment,
  TextField,
  // IconButton,
  useMediaQuery,
  Chip,
  Avatar,
  Accordion,
  AccordionSummary,
  CircularProgress,
  Typography,
  AccordionDetails,
  Autocomplete,
  Button,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  CalenderIcon,
  ChartIcon,
  FileIconFilled,
  OutlinedPlusIcon,
  SaveFileBlue,
  UploadIcon,
  UploadIconBlue,
} from "../../../../customIcons/customIcons";
import ConetentBlocks from "./LandingPageComponents/ConetentBlocks";
import GenratingBrand from "./LandingPageComponents/GenratingBrand";
import { IconButton } from "@chakra-ui/react";
import {
  AddResourceService,
  elasticSearchService,
} from "../../../../Service/service";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, borderRadius } from "@mui/system";
import PersonaChatContext from "../../../../context/ChatContext";
import CustomToaster from "../../../../utils/toaster/CustomToaster";
import { RotatingLines } from "react-loader-spinner";
import { Modal as BootstrapModal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import addresource from "../../../../Images/addresource.svg";
import ReusableButton from "../../../reusableComponents/ReusableButton";

const resourceTypes = [
  { label: "Document" },
  { label: "External Link" },
  // { label: "RSS" },
];
function LandingPage({
  setResponseData,
  textData,
  LoadingState,
  formData,
  isBrandGuide,
  setIsBrnadGuide,
  setTextData,
  responseData,
}) {
  const { getResourceChatSessions } = useContext(PersonaChatContext);
  const matches = useMediaQuery("(min-width:1400px)");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const location = useLocation();

  const [elasticSearchText, setElasticSearchText] = useState();
  const [documentList, setDocumentList] = useState([]);
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSearchMethod, setSelectedSearchMethod] = useState({
    label: "Search exact phrase",
  });
  const [selectedFileResource, setSelectedFileResource] = useState("");
  // const [sessionId, setSessionId] = useState();
  const [allResources, setallResources] = useState();
  const [messages, setMessages] = useState([]);
  const [targetFile, settTargetFile] = useState();
  const [resourceData, setResourceData] = useState();
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [uploadedResourceFiles, setUploadedResourceFiles] = useState([]);
  const [blobUrlsArray, setBlobUrlsArray] = useState([]);
  const [isClickedSearch, setisClickedSearch] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [createProjectButton, setCreateProjectButton] = useState(false);
  const [synonymsRegex, setSynonymsRegex] = useState();
  const [synonymsArray, setSynonymsArray] = useState([]);
  const [cleanedWordsArray, setcleanedWordsArray] = useState([]);
  const [searchedText, setSearchedText] = useState();
  const [searchedMethod, setSearchedMethod] = useState();
  const [expanded, setExpanded] = useState({});
  const [isSearchResultEmpty, setisSearchResultEmpty] = useState(false);
  const [resourceType, setResourceType] = useState({ label: "Document" });
  const [url, setURL] = useState("");

  const fileInputRef = useRef(null);
  const handleClear = () => {
    // setisShowMessages(true);

    setDocumentList([]);

    setSelectedSearchMethod({
      label: "Search exact phrase",
    });
    setElasticSearchText("");
  };

  const closeAllAccordions = () => {
    const newExpandedState = {};
    documentList.forEach((_, index) => {
      newExpandedState[index] = false;
    });
    setExpanded(newExpandedState);
  };
  const elasticSearchHandler = async () => {
    // setMessages([]);
    // setisShowMessages(false);
    setisClickedSearch(true);

    // Check if there is text in the search document field and a search method is selected
    if (
      !elasticSearchText ||
      (elasticSearchText && elasticSearchText.trim().length === 0)
    ) {
      // Set an error state for the search document field
      setErrorToast(true);
      setSnackbarMessage("Search requires input text");
      setSnackbarSeverity("error");
      setisClickedSearch(false);
      return;
    }

    try {
      const words = elasticSearchText?.match(
        /(?:[^\s"']+|"([^"]*)"|'([^']*)')/g
      );

      // Filter out null values, flatten the array, and update the search array
      const filteredWords = words
        ? words.flat().filter((word) => word !== null)
        : [];

      // Remove quotes and other characters attached to words
      const cleanedWords = filteredWords?.map((word) =>
        word.replace(/['"\\]+/g, "")
      );
      setcleanedWordsArray(cleanedWords);
      closeAllAccordions();
      setSearchedText(elasticSearchText);
      setSearchedMethod(selectedSearchMethod.label);
      setIsLoading(true);
      const res = await elasticSearchService(
        activePersona?.creatorEmail,
        activePersona?.epClientEmail,
        cleanedWords
      );
      if (res.success) {
        // Add 'show' property to each object
        const updatedDocumentList = res.data.map((item) => ({
          ...item,
          show: true,
        }));
        // if (selectedProject) {
        //   filterSearchResults(selectedProject?.documents, updatedDocumentList);
        // } else {
        setDocumentList(updatedDocumentList);
        // }

        if (res.synonyms !== null) {
          setSynonymsArray(res.synonyms);
          const highlightSynonyms = res.synonyms.join("|");
          const synnysmRegex = new RegExp(`\\b(${highlightSynonyms})\\b`, "gi");
          setSynonymsRegex(synnysmRegex);
          const lowercasedSynonymsArray = res.synonyms.map((synonym) =>
            synonym.toLowerCase()
          );
          setSynonymsArray(lowercasedSynonymsArray);
        }
        if (res.data.length > 0) {
          setCreateProjectButton(true);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error:", error);
    }
  };
  const handleKeyPressSearch = (event) => {
    if (event.key === "Enter" && !isBrandGuide) {
      elasticSearchHandler();
    }
  };

  const getTruncatedLabel = (item) => {
    if (item.name) {
      return item.name.length > 15
        ? `${item.name.substring(0, 15)}...`
        : item.name;
    } else if (item.fileName) {
      return item.fileName.length > 15
        ? `${item.fileName.substring(0, 15)}...`
        : item.fileName;
    } else if (item.documentName) {
      return item.documentName.length > 15
        ? `${item.documentName.substring(0, 15)}...`
        : item.documentName;
    }

    return ""; // Default label if none of the keys exist
  };

  const handleDeleteTag = (deletedItem) => {
    if (
      location?.state?.from === "resources" &&
      allResources?.length > 1 &&
      messages?.length === 0
    ) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.docUrl !== deletedItem.docUrl)
      );
      setResourceData((prevSelectedItems) => {
        const filteredItems = prevSelectedItems.filter(
          (item) => item.fileUrl !== deletedItem.docUrl
        );

        // Check if only one element is left after filtering
        if (filteredItems.length === 1) {
          // Convert the array to an object
          const singleObject = { ...filteredItems[0] };
          // Update state with the object
          return singleObject;
        } else {
          // If more than one element is left or none, update state with the filtered array
          return filteredItems;
        }
      });
      setallResources((prevSelectedItems) => {
        const filteredItems = prevSelectedItems.filter(
          (item) => item.fileUrl !== deletedItem.docUrl
        );

        // Check if only one element is left after filtering
        if (filteredItems.length === 1) {
          // Convert the array to an object
          const singleObject = { ...filteredItems[0] };
          // Update state with the object
          return singleObject;
        } else {
          // If more than one element is left or none, update state with the filtered array
          return filteredItems;
        }
      });
    } else if (
      "_id" in deletedItem ||
      (location?.state?.from === "resources" && messages?.length !== 0)
    ) {
      // It has an "_id" field, show an alert

      settTargetFile(deletedItem?._id);
      // setconfirmDeleteFileModal(true);
    } else if ("show" in deletedItem) {
      // It's an accordion item, toggle the 'show' property
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.url !== deletedItem.url)
      );
      // Toggle the 'show' property for the deleted item
      setDocumentList((prevDocumentList) =>
        prevDocumentList.map((doc) =>
          doc.url === deletedItem.url ? { ...doc, show: true } : doc
        )
      );
    } else {
      // It's a directly uploaded file, remove it from selectedItems
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.name !== deletedItem.name)
      );
    }
  };

  const handleRemoveFromFilesArray = (item) => {
    // Remove the URL from the array
    setUploadedResourceFiles((prevSelectedItems) =>
      prevSelectedItems.filter((file) => file?.name !== item?.name)
    );
  };

  const handleRemoveFromUrlArray = async (item) => {
    if (
      (location?.state?.from === "resources" && messages?.length !== 0) ||
      blobUrlsArray.includes(item) ||
      location?.state?.from === "chatsession"
    ) {
      // settTargetUrl(item);
      // setconfirmDeleteFileModal(true);
    } else if (
      location?.state?.from === "resources" &&
      messages?.length === 0
    ) {
      setSelectedUrls((prevSelectedItems) =>
        prevSelectedItems.filter((url) => url !== item)
      );
      setallResources(
        allResources?.filter((resourceItem) => resourceItem?.docUrl !== item)
      );
    } else {
      // Remove the URL from the array
      setSelectedUrls((prevSelectedItems) =>
        prevSelectedItems.filter((url) => url !== item)
      );
    }
  };
  function filterSearchResults(documents, searchResults, threshold = 0.1) {
    // Iterate over search results array
    const filteredResults = searchResults.filter((result) => {
      // Check if fileName is an exact match with any documentName
      const exactMatch = documents.some(
        (document) =>
          result.fileName.toLowerCase() === document.documentName.toLowerCase()
      );

      // If an exact match is found, exclude the result from the filtered array
      if (exactMatch) {
        return false;
      } else {
        return true;
      }
    });
    if (filteredResults?.length > 0) {
      setisSearchResultEmpty(false);
    } else {
      setisSearchResultEmpty(true);
    }
    setDocumentList(filteredResults);
    return filteredResults;
  }

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: isExpanded,
    }));
  };
  const handleAddToSelectedItems = async (item) => {
    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    // Set the 'show' property to false for the added item
    setDocumentList((prevDocumentList) =>
      prevDocumentList.map((doc) =>
        doc.url === item.url ? { ...doc, show: false } : doc
      )
    );
  };
  const stopPropagation = (event) => {
    // Prevent the event from reaching the parent (AccordionSummary)
    event.stopPropagation();
  };

  const lowercasedCleanedWordsArray = cleanedWordsArray?.map((word) =>
    word.toLowerCase()
  );
  const lowercasedSynonymsArray = synonymsArray?.map((word) =>
    word.toLowerCase()
  );
  const handleCloseErrorToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorToast(false);
  };
  // console.log(selectedItems)
  useEffect(() => {
    if (responseData?.documents?.length > 0) {
      const data = responseData?.documents?.map((obj) => ({
        fileName: obj.documentName,
        url: obj.docUrl,
      }));
      setSelectedItems(data);
    }
    if (responseData?.urls?.length > 0) {
      setSelectedUrls(responseData?.urls);
    }
  }, []);

  const handleResourceTypeChange = (_, newValue) => {
    setResourceType(newValue);
  };
  const handleUploadClick = () => {
    // Trigger click on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileUpload = (event) => {
    // Handle file upload logic here
    const selectedFile = event.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      // Check if the file size is greater than 6MB
      if (fileSizeInMB > 6) {
        setErrorToast(true);
        setSnackbarMessage("Please upload a file with a size less than 6MB.");
        setSnackbarSeverity("error");
        // Reset the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      } else {
        setSelectedFileResource(selectedFile);
        // Call your API here with the selected file
        // Example: callApi(selectedFile);
      }
    }
    // Perform your file upload logic with the selectedFile
  };
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const handleUploadFileResource = async () => {
    if (!selectedFileResource) {
      setErrorToast(true);
      setSnackbarMessage("Please upload a document");
      setSnackbarSeverity("error");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("personaId", activePersona?._id);
      formData.append("makerId", parsedToken?.data?._id);
      formData.append("files", selectedFileResource);
      const res = await AddResourceService(formData);
      if (res.success) {
        const data = {
          fileName: res?.data?.processedFileName,
          url: res?.data?.processedFileUrl,
        };
        setSelectedItems((prevSelectedItems) => [...prevSelectedItems, data]);
        setDocumentList([]);
        // setShowPersonaResources(true);
        // setSelectedPersonaResources([]);
        setElasticSearchText("");

        // closeAllResourcesAccordions();
        setSelectedFileResource();
        setIsLoading(false);
        setShowAddResourceModal(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
      } else {
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
        setIsLoading(false);
      }
    }
    // Check if the URL already exists in the array
  };
  // const closeAllResourcesAccordions = () => {
  //   const newExpandedState = {};
  //   personaResources?.forEach((_, index) => {
  //     newExpandedState[index] = false;
  //   });
  //   setResourcesExpanded(newExpandedState);
  // };
  const handleUploadLinkResource = async () => {
    // clearDate();
    if (url?.trim().length === 0) {
      setErrorToast(true);
      setSnackbarMessage("Please provide a link");
      setSnackbarSeverity("error");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("personaId", activePersona?._id);
      formData.append("makerId", parsedToken?.data?._id);
      formData.append("url", url);
      const res = await AddResourceService(formData);
      if (res.success) {
        setSelectedUrls((prevSelectedItems) => [
          ...prevSelectedItems,
          res?.data?.url,
        ]);
        setDocumentList([]);
        setElasticSearchText("");
        setURL();
        setShowAddResourceModal(false);
        // closeAllResourcesAccordions();
        setIsLoading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
        // const res3 = await getResourceKeywordsService(res.data?._id, false);
      } else {
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
        setIsLoading(false);
      }
    }
  };
  const handleInputText = (e) => {
    setElasticSearchText(e.target.value);
    if (e.target.value === "") {
      setDocumentList([]);
    }
  };

  // Rearrange the keys in the desired order
  const rearrangedTextData = {
    Who_is: textData && textData["Who_is"],
    Program_Goals: textData && textData["Program_Goals"],
    The_Audience: textData && textData["The_Audience"],
    Audience_Challenges: textData && textData["Audience_Challenges"],
    Content_Guidelines: textData && textData["Content_Guidelines"],
    Campaign: textData && textData["Campaign"],
    ...(textData?.Blank_Campaign ? { Blank_Campaign: textData["Blank_Campaign"] } : {})
  };
  
  const searchComponentRef = useRef(null);
  const closeSearchComponent = useCallback(() => {
    handleClear();
  }, []);

  useEffect(() => {
    const handleOverlayClick = (e) => {
      if (
        searchComponentRef.current &&
        !searchComponentRef.current.contains(e.target)
      ) {
        closeSearchComponent();
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, []);

  return (
    <>
      <CustomToaster
        open={errorToast}
        onClose={handleCloseErrorToast}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <div ref={searchComponentRef} className="search-chip-box" style={{ background: "#F6F6F6" }}>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <TextField
            size={matches ? "medium" : "small"}
            label="Search"
            value={elasticSearchText}
            onChange={handleInputText}
            onKeyDown={handleKeyPressSearch}
            sx={{ width: "100%", background: "white" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {elasticSearchText && (
                    <IconButton
                      aria-label="clear input"
                      onClick={handleClear}
                      edge="end"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M15 5L5 15"
                          stroke="#051D2C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 5L15 15"
                          stroke="#051D2C"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <div className="input-calender">
            <CalenderIcon />
          </div>
          <div className="d-flex icon-pair" style={{ marginLeft: "8px" }}>
            <div className="icon-child">
              <UploadIconBlue
                onClick={
                  isBrandGuide ? null : () => setShowAddResourceModal(true)
                }
              />
            </div>
            <div className="icon-child">
              <SaveFileBlue />
            </div>
          </div>
        </div>
        {
          // selectedUrls.length>0 || selectedItems.length>0 || uploadedResourceFiles.length>0 &&
          <div className="chips-container px-0 w-100 overflow-auto brnad-chips">
            {selectedItems?.map((item, index) => (
              <Chip
                // onClick={() => {
                //   isMatchingObject(item);

                // }}
                key={index}
                size="medium"
                label={getTruncatedLabel(item)}
                onDelete={isBrandGuide ? null : () => handleDeleteTag(item)}
                sx={{
                  backgroundColor: "#4775D5",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiChip-label": {
                    fontSize: "12px",
                  },
                  "&:hover": {
                    backgroundColor: "#4775D5", // Change to your desired hover color
                    // Add any additional styles on hover
                  },
                }}
                deleteIcon={<CancelIcon style={{ color: "white" }} />}
                avatar={
                  <Avatar style={{ backgroundColor: "#35579E" }}>
                    <FileIconFilled style={{ height: "24px", width: "24px" }} />
                  </Avatar>
                }
              />
            ))}
            {uploadedResourceFiles?.map((item, index) => (
              <Chip
                key={index}
                size="medium"
                label={
                  item?.name?.length > 15
                    ? `${item?.name.substring(0, 15)}...`
                    : item?.name
                }
                onDelete={
                  isBrandGuide ? null : () => handleRemoveFromFilesArray(item)
                }
                sx={{
                  backgroundColor: "#4775D5",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiChip-label": {
                    fontSize: "12px",
                  },
                }}
                deleteIcon={<CancelIcon style={{ color: "white" }} />}
                avatar={
                  <Avatar style={{ backgroundColor: "#35579E" }}>
                    <FileIconFilled style={{ height: "24px", width: "24px" }} />
                  </Avatar>
                }
              />
            ))}
            {selectedUrls?.map((item, index) => (
              <Chip
                key={index}
                size="medium"
                label={
                  item?.length > 15 ? `${item?.substring(0, 15)}...` : item
                }
                onDelete={
                  isBrandGuide ? null : () => handleRemoveFromUrlArray(item)
                }
                sx={{
                  backgroundColor: "#4775D5",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiChip-label": {
                    fontSize: "12px",
                  },
                }}
                deleteIcon={<CancelIcon style={{ color: "white" }} />}
                avatar={
                  <Avatar style={{ backgroundColor: "#35579E" }}>
                    <FileIconFilled style={{ height: "24px", width: "24px" }} />
                  </Avatar>
                }
              />
            ))}
          </div>
        }
        {documentList.length > 0 && (
          <div
            style={{
              background: "#f5f6f7",
            }}
            className="accordion-container"
          >
            {documentList?.map(
              (item, index) =>
                item?.show && (
                  <Accordion
                    key={index}
                    sx={{
                      borderLeft: "3px solid #4775D5",
                      marginBottom: "1em",
                    }}
                    expanded={expanded[index] || false}
                    onChange={handleAccordionChange(index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <div className="d-flex w-100 align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <OutlinedPlusIcon
                            style={{
                              height: "1.714rem",
                              width: "1.714rem",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddToSelectedItems(item);
                            }}
                          />

                          <Box
                            style={{
                              marginLeft: "1.143rem",
                              marginRight: "0.571rem",
                            }}
                            sx={{
                              position: "relative",
                              display: "inline-flex",
                            }}
                          >
                            <CircularProgress
                              size={40}
                              thickness={4}
                              value={Math.floor(item?.score)}
                              sx={{ color: "#1BB470" }}
                              variant="determinate"
                            />
                            <Box
                              sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="caption"
                                component="div"
                                color="black"
                                className="progress-value"
                              >
                                {Math.floor(item?.score)}%
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            sx={{ fontSize: 14 }}
                            className="file-name"
                          >
                            {item?.fileName}
                          </Typography>
                        </div>
                        <div className="d-flex align-items-center gap-3 me-3 ">
                          <ChartIcon onClick={stopPropagation} />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        borderTop: "1px solid #CED3D9",
                        padding: "1.143rem",
                      }}
                    >
                      <div
                        className="mt-3"
                        style={{
                          fontSize: "1rem",
                          lineHeight: "1.7rem",
                          fontWeight: "400",
                        }}
                      >
                        {item?.content
                          ?.split(synonymsRegex)
                          .filter(Boolean)
                          .map((chunk, index) => {
                            return (
                              <span
                                key={index}
                                className={
                                  lowercasedCleanedWordsArray?.includes(
                                    chunk.toLowerCase()
                                  )
                                    ? "highlight-text"
                                    : lowercasedSynonymsArray?.includes(
                                        chunk.toLowerCase()
                                      )
                                    ? "orange-text"
                                    : ""
                                }
                              >
                                {chunk}
                              </span>
                            );
                          })}
                      </div>
                      <div className="d-flex gap-2 align-items-center justify-content-between mt-3">
                        <div
                          style={{
                            color: "#76828B",
                            fontSize: "1rem",
                          }}
                        >
                          <span>
                            Created by{" "}
                            <span style={{ color: "#051D2C" }}>21/04/2023</span>{" "}
                            by{" "}
                            <span style={{ color: "#051D2C" }}>M. Korpásh</span>
                          </span>
                          <span className="px-2">|</span>
                          <span>
                            Updated on{" "}
                            <span style={{ color: "#051D2C" }}>21/04/2023</span>{" "}
                            by{" "}
                            <span style={{ color: "#051D2C" }}>
                              B. Zimmatore
                            </span>
                          </span>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )
            )}
          </div>
        )}
      </div>

      {documentList.length === 0 && (
        <>
          {!isBrandGuide ? (
            <GenratingBrand
              setIsBrnadGuide={setIsBrnadGuide}
              selectedItems={selectedItems}
              selectedUrls={selectedUrls}
              formData={formData}
              setTextData={setTextData}
              setResponseData={setResponseData}
            />
          ) : (
            <ConetentBlocks
              textData={rearrangedTextData}
              setTextData={setTextData}
              isLoading={LoadingState}
              responseData={responseData}
            />
          )}
        </>
      )}
      <BootstrapModal
        show={showAddResourceModal}
        onHide={() => {
          setShowAddResourceModal(false);
          setResourceType({ label: "Document" });
          setURL("");
          setSelectedFileResource();
        }}
        centered
      >
        <BootstrapModal.Header
          style={{ border: "none", backgroundColor: "#D4E2FF" }}
        >
          <div
            style={{
              backgroundColor: "#D4E2FF",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBlock: "1rem",
              position: "relative",
            }}
          >
            <CloseIcon
              onClick={() => {
                setShowAddResourceModal(false);
                setResourceType({ label: "Document" });
                setURL("");
                setSelectedFileResource();
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: -5,
                right: -5,
              }}
            />
            <img style={{ width: "250px" }} src={addresource} />
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div className="mb-3">
            <p
              style={{
                color: "#051D2C",
                fontWeight: "500",
                fontSize: "1.5rem",
              }}
            >
              Add a resource
            </p>
          </div>
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ background: "white", cursor: "pointer" }}
            options={resourceTypes}
            value={resourceType}
            onChange={handleResourceTypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type of resource"
                placeholder="Select resource"
              />
            )}
          />
          {resourceType?.label === "Document" ? (
            <div onClick={handleUploadClick} className="icon-button gap-2 mt-4">
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={handleFileUpload}
              />
              {/* Upload icon with onClick handler */}
              <IconButton>
                <UploadIcon />
              </IconButton>
              <p
                style={{
                  color: "white",
                  fontWeight: "500",
                  fontSize: "1rem",
                  marginBottom: 0,
                }}
              >
                {selectedFileResource?.name || "UPLOAD FILE"}
              </p>
            </div>
          ) : (
            <div className="w-100 mt-4">
              <TextField
                value={url}
                onChange={(e) => setURL(e.target.value)}
                size="small"
                label="URL"
                placeholder="Enter url here"
                sx={{ width: "100%" }}
              />
            </div>
          )}

          <div className=" mt-4 d-flex gap-3 align-items-center justify-content-end">
            <Button
              onClick={() => {
                setShowAddResourceModal(false);
                setResourceType({ label: "Document" });
                setURL("");
                setSelectedFileResource();
              }}
              sx={{ color: "#000000", fontWeight: "600" }}
              variant="text"
            >
              Cancel
            </Button>
            <ReusableButton
              // isDisabled={!selectedFileResource && !url}
              onClick={() => {
                if (resourceType?.label === "Document") {
                  handleUploadFileResource();
                } else if (resourceType?.label === "External Link") {
                  handleUploadLinkResource();
                } else {
                }
              }}
              buttonText={"SAVE RESOURCE"}
            />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </>
  );
}

export default LandingPage;
