import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./AnalizeMoment.css";
import NavBar from "../NavBar/NavBar";
import UserHeader from "../UserProfile/userHeader";
import SideNav from "../sideNav/sideNav";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import Swal from "sweetalert2";
import {
  Box,
  Button as ChakraButton,
  ChakraProvider,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Stack,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { MdVerifiedUser } from "react-icons/md";
import CustomFloatingEdgeGraph from "../knowledgegraph/CustomFloatingEdgeGraph";
import { FaBackward } from "react-icons/fa";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import {
  getDocumentsTextService,
  getGraphDataService,
  getKeywordsService,
  getSummaryService,
} from "../../Service/service";
import { processData } from "../knowledgegraph/utils";
import { ImEnlarge } from "react-icons/im";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../feedback/Feedback";
import CustomToaster from "../../utils/toaster/CustomToaster";
const AnalizeMoment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowCount, setIsShowCount] = useState(false);
  const [loadGraphData, setLoadGraphData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isSummaryError, setisSummaryError] = useState("");
  const [isKeywordsEmpty, setisKeywordsEmpty] = useState(false);
  const [momentData, setmomentData] = useState(null);
  const [documnetUrls, setdocumnetUrls] = useState(null);
  const [summary, setSummary] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const containerRef = useRef(null);
  const [paragraphsArray, setparagraphsArray] = useState([]);
  const [keywordsArray, setKeywordsArray] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedKeyword, setSelectedKeyword] = useState(null); // State to keep track of the selected keyword
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const bottomRef = useRef(null);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < paragraphsArray.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const currentDocumentNumber = currentIndex + 1;
  const totalDocuments = paragraphsArray.length;

  const keywordsPerPage = 4;

  const handleKeywordClick = (categoryIndex, keywordIndex) => {
    const updatedKeywords = keywordsArray.map((category, cIndex) => ({
      ...category,
      people: category.people.map((keywordObj, kIndex) => ({
        ...keywordObj,
        isSelected:
          cIndex === categoryIndex && kIndex === keywordIndex ? true : false,
      })),
    }));

    setSelectedKeyword(
      keywordsArray[categoryIndex].people[keywordIndex].keyword
    );
    setKeywordsArray(updatedKeywords);

    // Find the paragraph index with the first occurrence of the selected keyword
    const targetIndex = paragraphsArray.findIndex((paragraph) =>
      paragraph?.text?.toLowerCase().includes(selectedKeyword?.toLowerCase())
    );

    if (targetIndex !== -1) {
      setCurrentIndex(targetIndex);

      // Calculate the scroll position needed to bring the target paragraph to the top
      const targetParagraphRef = containerRef.current.children[targetIndex];
      const scrollPosition = targetParagraphRef.offsetTop;

      // Scroll the container to the calculated position
      try {
        // Scroll the container to the calculated position
        containerRef.current.scrollTop = scrollPosition;
      } catch (error) {
        console.log("Error while scrolling:", error);
      }
    }
  };

  useEffect(() => {
    if (location?.state?.momentData) {
      setmomentData(location?.state?.momentData);
      const docUrls = location?.state?.momentData.documents.map(
        (doc) => doc.docUrl
      );
      setdocumnetUrls(docUrls);
    }
  }, [location]);
  function areAllArraysEmpty(obj) {
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return false; // If any array is not empty, return false
      }
    }
    return true; // All arrays are empty
  }
  useEffect(() => {
    const getKeywords = async () => {
      try {
        setLoading(true);
        const res = await getDocumentsTextService(documnetUrls);
        if (res.success) {
          setparagraphsArray(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
        if (
          areAllArraysEmpty(location?.state?.momentData?.keywords) ||
          location?.state?.momentData?.updated?.keywords == true
        ) {
          const res2 = await getKeywordsService(momentData?._id, false);

          if (res2.success && res2.data !== null) {
            const inputData2 = res2?.data;
            const newCategories = Object.keys(inputData2).map((categoryKey) => {
              return {
                title: categoryKey,
                people: inputData2[categoryKey].map((keyword) => ({
                  keyword,
                  isSelected: false,
                })),
              };
            });

            const nonEmptyCategories = newCategories
              .map((category) => ({
                ...category,
                people: category.people.filter(
                  (person) => person.keyword.length >= 3
                ),
              }))
              .filter((category) => category.people.length > 0);

            setKeywordsArray(nonEmptyCategories);
            setIsShowCount(true);
          } else {
            setisKeywordsEmpty(true);
          }
        } else {
          const inputData2 = location?.state?.momentData?.keywords;
          const newCategories = Object.keys(inputData2).map((categoryKey) => {
            return {
              title: categoryKey,
              people: inputData2[categoryKey].map((keyword) => ({
                keyword,
                isSelected: false,
              })),
            };
          });

          const nonEmptyCategories = newCategories
            .map((category) => ({
              ...category,
              people: category.people.filter(
                (person) => person.keyword.length >= 3
              ),
            }))
            .filter((category) => category.people.length > 0);

          setKeywordsArray(nonEmptyCategories);
          setIsShowCount(true);
        }

        const summaryResponse = await getSummaryService(momentData?._id);
        if (summaryResponse.success) {
          setisSummaryError("");
          setSummary(summaryResponse.data);
          setLoading(false);
        } else {
          setisSummaryError(summaryResponse.message);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(
          "🚀 ~ file: AnalizeMoment.js:250 ~ getKeywords ~ error:",
          error
        );
      }
    };
    if (documnetUrls && documnetUrls.length > 0) {
      getKeywords();
    }
  }, [documnetUrls]);

  const ReAnalyzeKeywordsHandler = async () => {
    const res2 = await getKeywordsService(momentData?._id, true);

    if (res2.success) {
      setisKeywordsEmpty(true);
      setKeywordsArray([]);
      const inputData2 = res2?.data;
      const newCategories = Object.keys(inputData2).map((categoryKey) => {
        return {
          title: categoryKey,
          people: inputData2[categoryKey].map((keyword) => ({
            keyword,
            isSelected: false,
          })),
        };
      });

      const nonEmptyCategories = newCategories
        .map((category) => ({
          ...category,
          people: category.people.filter(
            (person) => person.keyword.length >= 3
          ),
        }))
        .filter((category) => category.people.length > 0);

      setKeywordsArray(nonEmptyCategories);
      setIsShowCount(true);
    } else {
      setisKeywordsEmpty(true);
    }
  };
  const getGraphDataHandler = async (keyword) => {
    setLoading(true);
    try {
      const res = await getGraphDataService(
        momentData?.momentName,
        keyword,
        loadGraphData
      );
      if (res.success) {
        setLoadGraphData(false);
        setrefresh(refresh + 1);
        const processedGraphData = processData(res.graph, keyword);
        setGraphData(processedGraphData);
        setLoading(false);
      } else {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AnalizeMoment.js:308 ~ getGraphDataHandler ~ error:",
        error
      );
    }
  };
  const countKeywordOccurrences = (keyword) => {
    const regex = new RegExp(
      `\\b${keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}\\b`,
      "gi"
    );
    let totalCount = 0;

    paragraphsArray.forEach((paragraph) => {
      const matches = paragraph.text.match(regex);
      totalCount += matches ? matches.length : 0;
    });

    return totalCount;
  };

  useEffect(() => {
    const updateKeywordCounts = async () => {
      const updatedKeywordsArray = keywordsArray
        .map((category) => {
          const keywordMap = new Map();

          category.people.forEach((keywordObj) => {
            const count = countKeywordOccurrences(keywordObj.keyword);
            if (count > 0) {
              if (keywordMap.has(keywordObj.keyword)) {
                keywordMap.get(keywordObj.keyword).count += count;
              } else {
                keywordMap.set(keywordObj.keyword, { ...keywordObj, count });
              }
            }
          });

          // Convert the Map to an array for sorting
          const people = Array.from(keywordMap.values());

          // Sort the people array by count in descending order
          people.sort((a, b) => b.count - a.count);

          return { ...category, people };
        })
        .filter((category) => category.people.length > 0);

      setKeywordsArray(updatedKeywordsArray);
    };
    if (isShowCount) {
      updateKeywordCounts();
    }
  }, [isShowCount]);

  useEffect(() => {
    return () => {
      processData({
        edges: [],
        nodes: [],
      });
    };
  }, []);

  const [refresh, setrefresh] = useState(1);
  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}

      <Feedback />
      <NavBar />
      <div
        // style={{ maxHeight: "100vh", overflow: "hidden" }}
        className={"persona-container-light"}
        ref={bottomRef}
      >
        <div className="mainContainer">
            {" "}
            <input type="checkbox" id="sideNavDrawer-toggle" name="sideNavDrawer-toggle"/>
            <label for="sideNavDrawer-toggle" id="sideNavDrawer-toggle-label"></label>
            <SideNav />
          <div className="Container-right">
            <ChakraProvider>
              <Stack minHeight="100vh" w="100%" direction={"row"}>
                <Stack minH="100vh" w="65%" borderRight={"2px solid black"}>
                  {/* header */}
                  <Stack
                    py={4}
                    borderBottom={"2px solid #051D2C"}
                    direction={"row"}
                    alignItems={"center"}
                    marginInline={8}
                    justifyContent={"space-between"}
                  >
                    <Stack spacing={0}>
                      <Heading fontSize={"xl"}>
                        {momentData?.momentName}
                      </Heading>
                      <Text fontSize={"sm"} color="gray.700">
                        Last updated on {momentData?.momentDate}
                      </Text>
                    </Stack>
                    <Stack
                      bg="#D0F2E2"
                      border={"1px solid rgba(40, 233, 140, 0.08)"}
                      spacing={2}
                      px={2}
                      py={1}
                      direction="row"
                      alignItems="center"
                      rounded="sm"
                    >
                      <MdVerifiedUser fontSize={"16px"} color="#23BD73" />
                      <Text fontSize={"0.857rem"}>Verified</Text>
                    </Stack>
                  </Stack>
                  {/* summary */}
                  <Stack
                    borderBottom={"2px solid #051D2C"}
                    spacing={6}
                    py={4}
                    px={8}
                  >
                    <Stack spacing={-2}>
                      <Text fontSize={"sm"} fontWeight={"semibold"}>
                        SUMMARY
                      </Text>
                      {isSummaryError ? (
                        <Text>{isSummaryError}</Text>
                      ) : summary !== null ? (
                        <Text
                          maxH={"50vh"}
                          pr={3}
                          overflow={"auto"}
                          fontSize="sm"
                        >
                          {summary}
                        </Text>
                      ) : (
                        <SkeletonText
                          mt="4"
                          noOfLines={6}
                          spacing="4"
                          skeletonHeight="2"
                        />
                      )}
                    </Stack>
                  </Stack>

                  {/* keywords */}
                  <Stack spacing={6} py={4} px={8}>
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      w="100%"
                      alignItems="center"
                    >
                      <Text margin={0} fontSize={"md"} fontWeight={"semibold"}>
                        KEYWORDS
                      </Text>
                      <ChakraButton
                        size="sm"
                        fontWeight={"500"}
                        fontSize={"1rem"}
                        type="submit"
                        borderRadius={"5px"}
                        bg="#4775D5"
                        w="fit-content"
                        color="white"
                        _hover={{ bg: "#051D2C" }}
                        // textTransform={"uppercase"}
                        onClick={ReAnalyzeKeywordsHandler}
                      >
                        Re-Analyze
                      </ChakraButton>
                    </Stack>

                    <Stack maxW={"100%"} pb={4} overflow={"auto"} spacing={3}>
                      {keywordsArray && keywordsArray.length > 0 ? (
                        keywordsArray?.map((category, categoryIndex) => (
                          <Stack
                            key={category.title}
                            alignItems={"center"}
                            direction="row"
                            w="100%"
                            spacing={4}
                          >
                            <Text
                              fontWeight={"semibold"}
                              mb={0}
                              fontSize="sm"
                              minWidth={"25%"}
                            >
                              {category.title}
                            </Text>
                            <Stack w={"70%"} direction="row" spacing={3}>
                              {category?.people.map(
                                (keywordObj, keywordIndex) =>
                                  keywordObj?.count > 0 && (
                                    <Stack
                                      key={keywordObj.keyword}
                                      bg={
                                        keywordObj.isSelected
                                          ? "#4775D52"
                                          : "transparent"
                                      }
                                      onClick={() => {
                                        handleKeywordClick(
                                          categoryIndex,
                                          keywordIndex
                                        );
                                        getGraphDataHandler(keywordObj.keyword);
                                      }}
                                      // Other props for styling
                                      border={"1px solid #051D2C"}
                                      spacing={2}
                                      py={1}
                                      direction="row"
                                      alignItems="center"
                                      justifyContent={"space-between"}
                                      rounded="md"
                                      px={2}
                                      height={"35px"}
                                      minWidth={"fit-content"}
                                      cursor={"pointer"}
                                    >
                                      {/* Your keyword rendering code */}
                                      <Text
                                        margin={0}
                                        fontWeight={"semibold"}
                                        fontSize={"0.857rem"}
                                      >
                                        {keywordObj.keyword}
                                      </Text>

                                      <Box
                                        fontSize={"0.857rem"}
                                        width="25px"
                                        height="25px"
                                        borderRadius="50%"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        fontWeight={"semibold"}
                                        backgroundColor="#D3F8E6"
                                      >
                                        {keywordObj?.count}
                                      </Box>
                                    </Stack>
                                  )
                              )}
                            </Stack>
                          </Stack>
                        ))
                      ) : loading ? null : isKeywordsEmpty ? (
                        <Text>
                          Keywords are currently generating, you will receive an
                          email notification upon completion.
                        </Text>
                      ) : (
                        <SkeletonText
                          mt="4"
                          noOfLines={4}
                          spacing="4"
                          skeletonHeight="2"
                        />
                      )}
                    </Stack>
                  </Stack>
                  {/* graph */}
                  <Stack
                    height={"70vh"}
                    style={{ position: "relative" }}
                    borderBlock={"2px solid #051D2C"}
                  >
                    {/* <KnowledgeGraph /> */}
                    {/* <ReactFlowGraph/> */}

                    <CustomFloatingEdgeGraph
                      graphData={graphData}
                      refresh={refresh}
                    />
                    <ImEnlarge
                      onClick={onOpen}
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        right: 15,
                        top: 7,
                      }}
                    />
                  </Stack>
                </Stack>
                {/* Transcript */}
                <Stack px={2} pt={4} pb={6} h="100%" w="35%">
                  <Text fontSize="lg" fontWeight={"semibold"}>
                    Transcript
                  </Text>
                  <Box>
                    {paragraphsArray && paragraphsArray.length > 0 && (
                      <Stack alignItems={"center"} direction="row" spacing={3}>
                        <IconButton
                          icon={<FaBackward fontSize={20} />}
                          onClick={handleBack}
                          disabled={currentIndex === 0}
                          variant="pagination-button"
                        />
                        <Text>
                          <chakra.span fontWeight={"600"}>
                            {currentDocumentNumber}
                          </chakra.span>{" "}
                          of{" "}
                          <chakra.span fontWeight={"600"}>
                            {totalDocuments}
                          </chakra.span>{" "}
                          Documents
                        </Text>
                        <IconButton
                          icon={<TbPlayerTrackNextFilled fontSize={24} />}
                          onClick={handleNext}
                          disabled={
                            currentIndex === paragraphsArray &&
                            paragraphsArray?.length - 1
                          }
                          variant="pagination-button"
                        />
                      </Stack>
                    )}

                    <Box
                      ref={containerRef}
                      justifyContent={"center"}
                      fontSize="md  "
                      lineHeight="26px"
                      p={4}
                      maxH={"130vh"}
                      overflow={"auto"}
                    >
                      {/* {paragraphsArray &&
                        paragraphsArray[currentIndex]?.text
                          .split(new RegExp(`(${selectedKeyword})`, "gi"))
                          .map((chunk, index) =>
                            chunk.toLowerCase() ===
                            selectedKeyword?.toLowerCase() ? (
                              <span key={index} className="highlight">
                                {chunk}
                              </span>
                            ) : (
                              <span key={index}>{chunk}</span>
                            )
                          )} */}
                      {paragraphsArray &&
                        paragraphsArray[currentIndex]?.text
                          .split(new RegExp(`(\\b${selectedKeyword}\\b)`, "gi"))
                          .filter(Boolean) // Remove empty strings
                          .map((chunk, index) => (
                            <span
                              key={index}
                              className={
                                chunk?.toLowerCase() ===
                                selectedKeyword?.toLowerCase()
                                  ? "highlight"
                                  : ""
                              }
                            >
                              {chunk}
                            </span>
                          ))}

                      {/* {paragraphsArray &&
                        paragraphsArray[currentIndex]?.text
                          .split(" ")
                          .map((word, index) => (
                            <span key={index}>
                              {index > 0 && " "}{" "}
                              {word.toLowerCase() ===
                              selectedKeyword?.toLowerCase() ? (
                                <span className="highlight">{word}</span>
                              ) : (
                                word
                              )}
                            </span>
                          ))} */}

                      {/* {paragraphsArray &&
                        paragraphsArray[currentIndex]?.text
                          .split(/\b/g) // Split the text into words using word boundaries
                          .map((word, index) =>
                            word.toLowerCase() ===
                            selectedKeyword?.toLowerCase() ? (
                              <span key={index} className="highlight">
                                {word}
                              </span>
                            ) : (
                              <span key={index}>{word}</span>
                            )
                          )} */}
                    </Box>
                    {/* <Box
                      ref={containerRef}
                      justifyContent="center"
                      fontSize="md"
                      lineHeight="26px"
                      p={4}
                      maxH="130vh"
                      overflow="auto"
                    >
                      {paragraphsArray.map((paragraph, index) => (
                        <span
                          key={index}
                          onClick={() => handleKeywordClick(selectedKeyword)}
                          className={currentIndex === index ? "highlight" : ""}
                        >
                          {paragraph.text
                            .split(new RegExp(`(${selectedKeyword})`, "gi"))
                            .map((chunk, index) =>
                              chunk.toLowerCase() ===
                              selectedKeyword?.toLowerCase() ? (
                                <span key={index} className="highlight">
                                  {chunk}
                                </span>
                              ) : (
                                <span key={index}>{chunk}</span>
                              )
                            )}
                        </span>
                      ))}
                    </Box> */}
                  </Box>
                </Stack>
              </Stack>
            </ChakraProvider>
            <div></div>
          </div>
        </div>{" "}
      </div>
      <>
        <Modal isCentered onClose={onClose} isOpen={isOpen} size={"full"}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Stack height={"100vh"}>
                {/* <KnowledgeGraph /> */}
                {/* <ReactFlowGraph/> */}

                <CustomFloatingEdgeGraph
                  graphData={graphData}
                  refresh={refresh}
                />
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </>
  );
};

export default AnalizeMoment;
