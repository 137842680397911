import React, { useState, useEffect, useRef, useContext } from "react";
import "./Moments.css";
import NavBar from "../NavBar/NavBar";
import documents from "../../Images/file.svg";
import citation from "../../Images/citation.svg";
import voice from "../../Images/voice-prefernce.svg";
import human_note from "../../Images/human-note.svg";
import external_link from "../../Images/external-link.svg";
import upload from "../../Images/upload.svg";
import SideNav from "../sideNav/sideNav";
import UserHeader from "../UserProfile/userHeader";
import female from "../../Images/created-by.png";
import Brook from "../../Images/brook.png";
import cross from "../../Images/x.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import Swal from "sweetalert2";
import validator from "validator";
import { format } from "date-fns";
import PersonaMomentContext from "../../context/MomentsContext";
import {
  Button,
  ChakraProvider,
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CiFileOn } from "react-icons/ci";
import { GoLinkExternal } from "react-icons/go";
import { RotatingLines } from "react-loader-spinner";
import Unknown from "../../Images/Unknown.jpg";
import Feedback from "../feedback/Feedback";
import moment from "moment";
import CustomToaster from "../../utils/toaster/CustomToaster";

const SinglePerson = () => {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const { isDarkMode } = useContext(PersonaMomentContext);
  const buttonRef = useRef(null);
  const docModalCloseBtn = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setIsEdit(location.state.myVariable);
    }
  }, [location.state]);
  const [isLoading, setIsLoading] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [date, setDate] = useState("");
  const [file, setFile] = useState(null);
  const [link, setLink] = useState("");
  const [linkObj, setLinkObj] = useState([]);
  const [objects, setObjects] = useState([]);
  const [docObj, setDocObj] = useState([]);
  const [momentName, setMomentName] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [showDocTable, setShowDocTable] = useState(true);
  const [files, setFiles] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const isFileFormatValid = (file) => {
    const validFormats = [".json", ".txt", ".pdf", ".doc", ".docx"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };
  const handleInputChange = (e) => {
    if (e.target.name === "documentName") {
      setDocumentName(e.target.value);
    } else if (e.target.name === "date") {
      setDate(e.target.value);
    } else if (e.target.name === "file") {
      const selectedFile = e.target.files[0];
      const selectedFiles = Array.from(e.target.files);
      // Check if the number of files exceeds the limit (e.g., 50)
      if (files.length + selectedFiles.length > 50 || objects?.length > 50) {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(
          "You cannot upload more than 50 documents inside a single moment."
        );

        return;
      }
      // Check if each file's format is valid
      for (const selectedFile of selectedFiles) {
        if (!isFileFormatValid(selectedFile)) {
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage(
            "Please upload text, json, pdf, or word files only."
          );
          return;
        }
      }

      let duplicateFileFound = false;

      if (objects.length !== 0) {
        duplicateFileFound = objects.some((oneFile) => {
          if (selectedFile.name === oneFile.file.name) {
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("File already uploaded");
            return true;
          }
          return false;
        });
      }
      if (!duplicateFileFound) {
        setFile(selectedFile);
        // Update the files state with the selected files
        setFiles([...files, ...selectedFiles]);
        // uploadFile(selectedFile);
      }
    }
  };

  const hanldeMomentName = (event) => {
    setMomentName(event.target.value);
  };
  const handleLinkChange = (e) => {
    if (e.target.name === "link") {
      setLink(e.target.value);
    }
  };

  const handleLinkSubmit = (e) => {
    e.preventDefault();
    const check = validator.isURL(link);

    if (!check) {
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Invalid Url");
    } else {
      let duplicateFound = false;

      if (linkObj.length !== 0) {
        duplicateFound = linkObj.some((oneLink) => {
          if (link === oneLink.url) {
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Url already uploaded");
            return true;
          }
          return false;
        });
      }

      if (!duplicateFound) {
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format("DD/MM/YYYY");
        const newObject = {
          linkDate: formattedDate,
          url:
            link.startsWith("http://") || link.startsWith("https://")
              ? link
              : "http://" + link,
        };

        setLinkObj([...linkObj, newObject]);
        // Reset form fields
        setLink("");
        buttonRef.current.click();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const formattedDate = moment(currentDate).format("DD/MM/YYYY");

    // Create an array of new objects for each selected file
    const newObjects = files.map((selectedFile) => ({
      documentName: "Document_Name",
      date: formattedDate,
      file: selectedFile,
    }));

    // Update the objects and docObj arrays with the new objects
    setObjects([...objects, ...newObjects]);
    setDocObj([...docObj, ...newObjects]);

    // Reset form fields and clear the file input
    inputRef.current.value = "";
    setDocumentName("");
    setDate("");
    setFile(null);
    setFiles([]);
    document.getElementById("file-upload").value = null;
  };
  const isFormValid = () => {
    return file !== null;
  };
  const handleRemove = async (index) => {
    const updatedObjects = [...objects];
    updatedObjects.splice(index, 1);
    setObjects(updatedObjects);
  };

  // remove link from list
  const handleLinkRemove = (index) => {
    const updatedObjects = [...linkObj];
    updatedObjects.splice(index, 1);
    setLinkObj(updatedObjects);
  };
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  // create new moment handler
  const createMoment = async () => {
    setIsLoading(true);
    const name = momentName.trim();
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "dd/mm/yyyy");
      const token = localStorage.getItem("auth_token");
      const parsedToken = await JSON.parse(token);
      const activePersona = JSON.parse(
        localStorage.getItem("active_persona_data")
      );
      // const personaId = localStorage.getItem("active_persona");
      const myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth_token", parsedToken.auth_token);
      const urls = linkObj.map((link) => {
        return link.url;
      });

      const formData = new FormData();
      formData.append("momentName", name);
      formData.append("personaId", activePersona?._id);
      formData.append("makerId", parsedToken?.data?._id);
      formData.append("externalUrls", urls);
      docObj.forEach((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {
          formData.append(`${key}`, value);
        });
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/moments/createMoment`,
        {
          method: "POST",
          headers: myHeaders,
          body: formData,
        }
      );
      if (response.status == 401) {
        window.location = "/";
      } else {
        const json = await response.json();
        if (json.success) {
          setIsLoading(false);
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
          setSnackbarMessage(json.message);
          navigate("/mind",{state:'momentPage'});
        } else {
          setIsLoading(false);
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage(json.message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(error);
    }
  };
  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <NavBar />
      <Feedback />
      <div
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >

        <div className="mainContainer">
            {" "}
            <input type="checkbox" id="sideNavDrawer-toggle" name="sideNavDrawer-toggle"/>
            <label for="sideNavDrawer-toggle" id="sideNavDrawer-toggle-label"></label>
            <SideNav />
          <div className="mainContainer-right">
            {isEdit ? (
              <>
                <div className="d-flex align-items-center mb-4 edit-header">
                  <div>
                    <img src={Brook} className="female-img-big" alt="" />
                  </div>
                  <span className="mx-3">
                    Created by <b>Mykael Korpȧsh</b> on <b>21st April 2023</b>
                  </span>
                </div>
                <div className="momentContainer">
                  <div className="moment-span px-4 py-2">
                    <span>
                      Interview - Fearless Leadership is the Start of Growth
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h2>
                      Add resources that are directly related to this moment
                    </h2>
                    <div className="momentResource-container">
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#momentModal"
                        role="button"
                      >
                        <div className="user-input-div">
                          <img src={documents} alt=""></img>
                          <span>Documents</span>
                        </div>
                      </div>

                      <div>
                        <div className="disable-resource-div user-input-div">
                          <img src={citation} alt=""></img>
                          <span>Citation</span>
                        </div>
                      </div>
                      <div>
                        <div className="disable-resource-div user-input-div">
                          <img src={voice} alt=""></img>
                          <span>Voice Preference</span>
                        </div>
                      </div>
                      <div>
                        <div className="disable-resource-div user-input-div">
                          <img src={human_note} alt=""></img>
                          <span>Human Note</span>
                        </div>
                      </div>
                      <div>
                        <div
                          className="user-input-div"
                          data-bs-toggle="modal"
                          data-bs-target="#momentLinkModal"
                          role="button"
                        >
                          <img src={external_link} alt=""></img>
                          <span>External Link</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between px-4 py-4">
                    <table className="table">
                      <thead className="table-light moments-table-head">
                        <tr
                          style={{
                            backgroundColor: isDarkMode
                              ? "black !important"
                              : "#f8f9fa !important",
                          }}
                        >
                          <th scope="col">NAME</th>
                          <th scope="col">TYPE</th>
                          <th style={{ minWidth: "200px" }} scope="col">
                            ADDED BY
                          </th>
                          <th scope="col" className="text-center">
                            ADDED ON
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="moments-table-body">
                        <tr>
                          <td>
                            <div className="align-items-center d-flex gap-3">
                              <span>
                                <input type="checkbox" className="checkbox" />
                              </span>
                              <span className="moments-name mb-1">
                                Document name
                              </span>
                            </div>
                          </td>
                          <td>txt</td>
                          <td>
                            <div className="d-flex mx-3">
                              <img
                                src={female}
                                className="avatar"
                                alt="person"
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <span>21 April 2023</span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end">
                              <img src={cross} alt="more-option" />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="align-items-center d-flex gap-3">
                              <span>
                                <input type="checkbox" className="checkbox" />
                              </span>
                              <span className="moments-name mb-1">
                                Document name
                              </span>
                            </div>
                          </td>
                          <td>txt</td>
                          <td>
                            <div className="d-flex mx-3">
                              <img
                                src={Brook}
                                className="avatar"
                                alt="person"
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <span>21 April 2023</span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end">
                              <img src={cross} alt="more-option" />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="align-items-center d-flex gap-3">
                              <span>
                                <input type="checkbox" className="checkbox" />
                              </span>
                              <span className="moments-name mb-1">
                                Document name
                              </span>
                            </div>
                          </td>
                          <td>txt</td>
                          <td>
                            <div className="d-flex mx-3">
                              <img
                                src={Brook}
                                className="avatar"
                                alt="person"
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <span>21 April 2023</span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end">
                              <img src={cross} alt="more-option" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div
                    role="button"
                    className="cancel-btn d-flex justify-content-center align-items-center"
                    onClick={() => window.history.back()}
                  >
                    <span>Cancel</span>
                  </div>
                  <div
                    role="button"
                    className="update-btn d-flex justify-content-center align-items-center"
                    onClick={() => window.history.back()}
                  >
                    Update
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="momentContainer">
                  <h1>Create a moment</h1>
                  <div className="momentUploads">
                    <div className="momentForm">
                      <div className="form-field pe-0">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Name your moment"
                          onChange={hanldeMomentName}
                        />
                      </div>
                    </div>
                    <div className="resourceContainer">
                      <h2>
                        Add resources that are directly related to this moment
                      </h2>
                      <div className="momentResource-container">
                        <div
                          data-bs-toggle="modal"
                          data-bs-target="#momentModal"
                          role="button"
                          onClick={() => setShowDocTable(true)}
                        >
                          <div className="active-resource-div user-input-div">
                            {/* <img src={documents} alt=""></img> */}
                            <CiFileOn fontSize={"27px"} />
                            <span>Documents</span>
                          </div>
                        </div>

                        <div>
                          <div className="disable-resource-div user-input-div">
                            <img src={citation} alt=""></img>
                            <span>Citation</span>
                          </div>
                        </div>
                        <div>
                          <div className="disable-resource-div user-input-div">
                            <img src={voice} alt=""></img>
                            <span>Voice Preference</span>
                          </div>
                        </div>
                        <div>
                          <div className="disable-resource-div user-input-div">
                            <img src={human_note} alt=""></img>
                            <span>Human Note</span>
                          </div>
                        </div>
                        <div>
                          <div
                            className="active-resource-div user-input-div"
                            data-bs-toggle="modal"
                            data-bs-target="#momentLinkModal"
                            role="button"
                          >
                            <GoLinkExternal fontSize={"27px"} />
                            <span>External Link</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {objects.length > 0 && showDocTable && (
                    <ChakraProvider>
                      <TableContainer
                        maxH={"50vh"}
                        paddingRight={"10px"}
                        overflowY={"auto"}
                        mt={0}
                        mx={0}
                      >
                        <Table variant="simple">
                          <Thead
                            fontWeight={"600"}
                            bgColor={
                              isDarkMode
                                ? "black !important"
                                : "#f8f9fa !important"
                            }
                          >
                            <Tr>
                              <Th
                                color={
                                  isDarkMode
                                    ? "white !important"
                                    : "black !important"
                                }
                              >
                                NAME
                              </Th>
                              {/* <Th
                                color={
                                  isDarkMode
                                    ? "white !important"
                                    : "black !important"
                                }
                              >
                                TYPE
                              </Th> */}
                              <Th
                                color={
                                  isDarkMode
                                    ? "white !important"
                                    : "black !important"
                                }
                              >
                                ADDED BY
                              </Th>
                              <Th
                                color={
                                  isDarkMode
                                    ? "white !important"
                                    : "black !important"
                                }
                              >
                                ADDED ON
                              </Th>
                              <Th minW={"70px"}></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {objects.map((obj, index) => (
                              <Tr>
                                <Td maxW={"fit-content"}>
                                  <div className="align-items-center d-flex gap-3">
                                    {/* <span>
                                      <Checkbox colorScheme="green" />
                                    </span> */}
                                    <span className="moments-name mb-1">
                                      {obj.file.name
                                        ?.split(".")
                                        .slice(0, -1)
                                        .join(".")}
                                    </span>
                                  </div>
                                </Td>
                                <Td>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={
                                        parsedToken?.data?.image === null
                                          ? Unknown
                                          : `${
                                              process.env.REACT_APP_IMAGES_URL
                                            }/Users/${
                                              parsedToken?.data?.image
                                            }?random=${Math.random()}`
                                      }
                                      style={{
                                        width: "2rem",
                                        height: "2rem",
                                      }}
                                      className="brendan me-2"
                                      alt="person"
                                    />
                                    <div>{parsedToken?.data?.name}</div>
                                  </div>
                                </Td>
                                <Td> {obj.date}</Td>
                                <Td>
                                  <div
                                    role="button"
                                    className="d-flex justify-content-end"
                                    onClick={() => handleRemove(index)}
                                  >
                                    <img
                                      src={cross}
                                      alt="more-option"
                                      style={{
                                        width: "1em",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </ChakraProvider>
                  )}

                  {linkObj.length > 0 && (
                    <ChakraProvider>
                      <TableContainer
                        maxH={"50vh"}
                        paddingRight={"10px"}
                        overflowY={"auto"}
                        mt={6}
                      >
                        <Table variant="simple">
                          <Thead
                            fontWeight={"600"}
                            bgColor={
                              isDarkMode
                                ? "black !important"
                                : "#f8f9fa !important"
                            }
                          >
                            <Tr>
                              <Th
                                color={
                                  isDarkMode
                                    ? "white !important"
                                    : "black !important"
                                }
                              >
                                URL
                              </Th>
                              <Th
                                color={
                                  isDarkMode
                                    ? "white !important"
                                    : "black !important"
                                }
                              >
                                ADDED BY
                              </Th>
                              <Th
                                color={
                                  isDarkMode
                                    ? "white !important"
                                    : "black !important"
                                }
                              >
                                ADDED ON
                              </Th>
                              <Th minW={"70px"}></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {linkObj.map((obj, index) => (
                              <Tr>
                                <Td>
                                  <div className="align-items-center d-flex gap-3">
                                    <span className="moments-name">
                                      {obj.url && obj.url?.length > 49
                                        ? `${obj.url?.slice(0, 49)}...`
                                        : obj.url}
                                    </span>
                                  </div>
                                </Td>
                                <Td maxW={"300px"}>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={
                                        parsedToken?.data?.image === null
                                          ? Unknown
                                          : `${
                                              process.env.REACT_APP_IMAGES_URL
                                            }/Users/${
                                              parsedToken?.data?.image
                                            }?random=${Math.random()}`
                                      }
                                      style={{
                                        width: "2rem",
                                        height: "2rem",
                                      }}
                                      className="brendan me-2"
                                      alt="person"
                                    />
                                    <div>{parsedToken?.data?.name}</div>
                                  </div>
                                </Td>
                                <Td>
                                  <span> {obj.linkDate}</span>
                                </Td>
                                <Td>
                                  {" "}
                                  <div
                                    role="button"
                                    className="d-flex justify-content-end"
                                    onClick={() => handleLinkRemove(index)}
                                  >
                                    <img
                                      src={cross}
                                      alt="more-option"
                                      style={{
                                        width: "1em",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </ChakraProvider>
                  )}
                </div>
                {/* <div>
                  <input type="file" multiple onChange={handleFileChange} />
                  <button onClick={handleUpload}>Upload</button>
                  <ul>
                    {myFiles.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </div> */}
                {(objects.length > 0 || linkObj.length > 0) && (
                  <div className="d-flex justify-content-center ">
                    <button
                      style={{
                        border: isDarkMode ? "1px solid white" : "",
                        cursor: momentName == "" ? "not-allowed" : "pointer",
                      }}
                      className="btn btn-primary create-moment-btn mt-4"
                      onClick={createMoment}
                      disabled={momentName === "" ? true : false}
                    >
                      Create Moment
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      {/* <------Moment modal-----> */}
      <div
        className="modal fade"
        id="momentModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="momentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="momentModalLabel">
                Upload a Document
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setFile(null);
                }}
              ></button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                {/* <div className="form-field">
                  <label className="form-label">Document Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="documentName"
                    value={documentName}
                    onChange={handleInputChange}
                  />
                </div> */}
                <div className="body-div">
                  <span>Upload Document</span>
                  <div className="modal-upload-div mt-2 mb-2">
                    <label
                      htmlFor="file-upload"
                      className="w-100 h- cursor-pointer d-flex flex-column justify-content-center align-items-center"
                    >
                      <img src={upload} alt="" />
                      <input
                        ref={inputRef}
                        id="file-upload"
                        type="file"
                        name="file"
                        multiple
                        accept=".json,.txt,.pdf,.doc,.docx"
                        onChange={handleInputChange}
                      />
                      <div className="modal-bottom-text">
                        {/* Upload a file here{" "} */}
                        <u>{file ? file.name : "Upload File"}</u>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="d-flex justify-content-center py-3">
                  <ChakraProvider>
                    <Button
                      size="lg"
                      type="submit"
                      className={
                        !isFormValid()
                          ? "create-moment-btn w-100 disabled-btn"
                          : "create-moment-btn w-100"
                      }
                      data-bs-dismiss="modal"
                      isDisabled={!isFormValid()}
                    >
                      Save Documents
                    </Button>
                  </ChakraProvider>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="momentLinkModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="momentLinkModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="momentLinkModalLabel">
                Upload an External link
              </h5>
              <button
                type="button"
                ref={buttonRef}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleLinkSubmit}>
              <div className="modal-body px-4 d-flex flex-column">
                <div className="form-field">
                  <label className="form-label">External Link</label>
                  <input
                    type="text"
                    className="form-control"
                    name="link"
                    value={link}
                    onChange={handleLinkChange}
                  />
                </div>
                <div className="d-flex justify-content-center py-3">
                  <ChakraProvider>
                    <Button
                      size="lg"
                      type="submit"
                      className={
                        link === ""
                          ? "create-moment-btn w-30 disabled-btn"
                          : "create-moment-btn w-30"
                      }
                      isDisabled={link === ""}
                    >
                      Save Links
                    </Button>
                  </ChakraProvider>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePerson;
