import React, { createContext, useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { getSingleUserService } from "../Service/service";

const UserProfileContext = createContext();
export function UserContext({ children }) {
  const [activeUserData, setActiveUserData] = useState();
  // console.log(
  //   "🚀 ~ file: UserContext.js:8 ~ UserContext ~ activeUserData:",
  //   activeUserData
  // );

  //   useLayoutEffect(() => {
  async function getUserDetailsFunction(userId, isUpdateUser=false) {
    // console.log("🚀 ~ file: UserContext.js:15 ~ getUserDetailsFunction ~ isUpdateUser:", isUpdateUser)
    try {
      const res = await getSingleUserService(userId);
      if (res.success) {
        if(!isUpdateUser) {
          setActiveUserData(res.data)
        } 
      } else {
        // console.log(res.message);
      }
      return res;
    } catch (error) {
      console.log("🚀 ~ file: MyProfile.js:157 ~ getUserData ~ error:", error);
      return error;
    }
  }

  //   }, [])

  return (
    <UserProfileContext.Provider
      value={{
        activeUserData,
        getUserDetailsFunction,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
}

export default UserProfileContext;
