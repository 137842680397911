import React, { useState, useEffect, useContext } from "react";
import LazyLoad from "react-lazy-load";
import "./userHeader.css";
import { useNavigate } from "react-router-dom";
import Unknown from "../../Images/Unknown.jpg";
import { AiOutlineEye } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { ChakraProvider, Tooltip } from "@chakra-ui/react";
const UserHeader = () => {
  //const { isDarkMode } = useContext(PersonaMomentContext);
  const currentPath = window.location.pathname;
  const navigate = useNavigate();
  const showProfile = () => {
    if (currentPath !== "/client-profile") {
      navigate("/client-profile");
    }
  };
  const [currentPersona, setCurrentPersona] = useState({});
  const [currentEmail, setCurrentEmail] = useState("");
  useEffect(() => {
    const personas = JSON.parse(localStorage.getItem("persona_list"));
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    const persona = personas.find((item) => item._id === activePersona?._id);
    // console.log(persona)
    setCurrentPersona(persona);
    setCurrentEmail(persona?.epClientEmail);
  }, []);

  return (
    <div className="user-header">
      <div className="user-header-img">
        <LazyLoad once>
          <img
            src={
              currentPersona.image === null
                ? Unknown
                : currentPersona?.resizedImage
                ? `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                    currentPersona?.creatorEmail
                  }/${currentPersona?.resizedImage}?random=${Math.random()}`
                : `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                    currentPersona?.creatorEmail
                  }/${currentPersona?.image}?random=${Math.random()}`
            }
            className="brendan-img-big"
            alt="Demo"
          />
        </LazyLoad>
      </div>
      <div className="userHeader-info">
        <div>
          <h3 className="name-user">
            {currentPersona.firstName} {currentPersona.lastName}
          </h3>
          <p className="user-designation">{currentPersona.epClientCompany} </p>
        </div>
        {currentPath !== "/client-profile" && (
          <ChakraProvider>
            <Tooltip
              placement="top"
              hasArrow
              label="Module under process!"
              aria-label="A tooltip"
            >
              <span className="cursor-pointer">
                <AiOutlineEye
                  onClick={() => navigate("/client-profile")}
                  fontSize={"1.5rem"}
                />
              </span>
            </Tooltip>
          </ChakraProvider>
        )}
      </div>
    </div>
  );
};

export default UserHeader;
