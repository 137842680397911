import React, { useState } from "react";
import {
  Flex,
  Text,
  Input,
  Button,
  Link,
  Stack,
  Img,
  useToast,
  ChakraProvider,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../Images/logo-full.svg";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import "../SignIn/Login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetPasswordService } from "../../Service/service";
import ReusableTextField from "../reusableComponents/ReuseableTextField";
import ReusableButton from "../reusableComponents/ReusableButton";
import Swal from "sweetalert2";
import CustomToaster from "../../utils/toaster/CustomToaster";
const ResetPassword = () => {
  const toast = useToast();
  const nav = useNavigate();
  const { token } = useParams();
  // console.log("🚀 ~ file: ResetPassword.js:21 ~ ResetPassword ~ token:", token)
  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  // console.log("🚀 ~ file: ResetPassword.js:27 ~ ResetPassword ~ passwordError:", passwordError)
  const [password, setPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleConfirmPasswordChange = (event) => {
    const newPassword = event.target.value;
    setConfirmPassword(newPassword);
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    const isValidPassword = passwordRegex.test(password);
    let arr = [0, 0];
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required.");
      arr = [1, 0];
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
      arr = [1, 0];
    } else {
      setConfirmPasswordError("");
      arr = [0, 0];
    }

    if (!isValidPassword) {
      setPasswordError(
        "Password must contain at least 8 characters, one uppercase, one lowercase, one digit, and one special character."
      );
      arr = [arr[0], 1];
    } else {
      setPasswordError("");
      arr = [arr[0], 0];
    }

    if (arr[0] === 0 && arr[1] === 0) {
      // Only set loading to true if validations pass
      setloading(true);
      // Handle successful form submission
      // console.log('Form submitted successfully.');

      // Additional check before API call

      const res = await resetPasswordService(password, confirmPassword, token);
      if (res.success) {
        setloading(false);
        setSnackbarOpen(true);
          setSnackbarSeverity("success");
          setSnackbarMessage(res.message);
        nav("/");
        setPassword("");
        setConfirmPassword("");
      } else {
        setloading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
      }
    } else {
      console.log("Form has errors. Please correct them.");
    }
  };

  const isSubmitDisabled = !confirmPassword || !password;
  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <div className="signupContainer">
        <div className="signupContainer-inner">
          <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          <Stack spacing={1}  textAlign={"center"}>
            {/* <Text fontWeight={"600"} fontSize={"xl"} color={"#0D1821"}>
              Reset Password
            </Text> */}
            <div className="login-title"> Reset Password</div>
          </Stack>
          <div>
            <div className="signupContainer-form">
              <form className="loginForm">
                <div className="mb-4">
                  {/* password */}
                  <ReusableTextField
                    type={"password"}
                    label="Password"
                    placeholder="Enter password"
                    onChange={handlePasswordChange}
                    value={password}
                    error={passwordError}
                    helperText={passwordError}
                    showEyeIcon
                  />
                </div>

                <div className="mb-4">
                  {/* confirm password */}
                  <ReusableTextField
                    type={"password"}
                    label="Confirm Password"
                    placeholder="Enter confirm password"
                    onChange={handleConfirmPasswordChange}
                    value={confirmPassword}
                    error={confirmPasswordError}
                    helperText={confirmPasswordError}
                    showEyeIcon
                  />
                </div>
                {/* login button */}
                <ReusableButton
                  buttonText="SUBMIT"
                  onClick={handleSubmit}
                  loading={loading}
                  height="55px"
                  width="100%"
                  isDisabled={isSubmitDisabled || loading}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
