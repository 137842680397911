import React from 'react'

function Notifications() {
    return (
        <div className="mt-3 d-flex justify-content-between align-items-center" style={{minHeight:'460px', border:'1px solid #CED3D9', background:'#FAFAFB', borderRadius:'12px'}}>
            <div className='d-flex justify-content-between align-items-center flex-column text-center w-100 gap-3'>
            <span>
                <svg width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.2" cx="48" cy="48.5" r="44" stroke="#4775D5" strokeWidth="8" />
                    <rect x="9" y="9.5" width="78" height="78" rx="39" stroke="#4775D5" strokeWidth="2" />
                    <path d="M48 51.5C46.8954 51.5 46 50.6046 46 49.5L46 34.5C46 33.3954 46.8954 32.5 48 32.5C49.1046 32.5 50 33.3954 50 34.5L50 49.5C50 50.6046 49.1046 51.5 48 51.5Z" fill="#4775D5" />
                    <path d="M48 56.5C46.3431 56.5 45 57.8431 45 59.5C45 61.1569 46.3431 62.5 48 62.5C49.6569 62.5 51 61.1569 51 59.5C51 57.8431 49.6569 56.5 48 56.5Z" fill="#4775D5" />
                </svg>
            </span>
            <div>
              <h2 style={{fontSize:'1.286rem', fontWeight:'500'}}>Nothing to see here</h2>
                {/* <p style={{fontSize:'1.071rem', color:'#76828B'}}>Nemo enim ipsam voluptatem quia voluptas</p> */}
              </div>
            </div>
        </div>
    )
}

export default Notifications