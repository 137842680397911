import React from "react";
import line from "../../../../../Images/line.svg";
import audiencepic from "../../../../../Images/audience.png";
import { useMediaQuery } from "@material-ui/core";

const TheAudience = ({ data }) => {
  const audienceData = data?.The_Audience;
  // console.log("audienceData",audienceData)
  const smallLaptopScreen = useMediaQuery("(max-width:1200px)");
  const mobileScreen = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {audienceData?.map((audience, index) => {
        // Split the response string into separate bullet points
        const bulletPoints = audience?.Response?.split(/\d+\./).filter(point => /\S/.test(point.trim()));
        return (
          <div className="audience-section mt-4" key={index}>
            <img
              src={audiencepic}
              style={{display:mobileScreen?"none":"inherit", position: "absolute", top: 0, right: 0, width: "28rem" }}
              alt="Audience"
            />
            <div className="d-flex align-items-center">
              <div className="who-is-heading "></div>
              <div  className="d-flex align-items-center">
                <p
                  style={{
                    whiteSpace: "nowrap",
                    marginBottom: "0",
                    paddingLeft: mobileScreen?"1.3rem":"2.357rem",
                    color: "#051D2C",
                    fontSize: "1.25rem",
                    fontWeight: "350",
                  }}
                >
                  The <span style={{ fontWeight: "500" }}>Audience</span>{" "}
                </p>
                <span style={{ paddingLeft: "1rem" }}>
                  <img src={line} alt="line" />
                </span>
              </div>
            </div>
            <div
              className="d-flex align-items-center gap-3 mt-1"
              style={{ flexDirection: mobileScreen ? "column" : "row" }}
            >
               <img
              src={audiencepic}
              style={{ width: mobileScreen
                ? "14rem"
                : "18rem",display:mobileScreen?"inherit":"none",marginTop:20 }}
              alt="Audience"
            />
            {/* Rendering bullet points */}
            {audience?.Response && (
              <div
                key={index}
                style={{
                  paddingLeft: mobileScreen?"1.3rem":"2.357rem",
                  paddingRight: mobileScreen?"1.3rem":"28rem",
                  letterSpacing: "1px",
                  width:mobileScreen?'100%':"fit-content"
                }}
                className="d-flex flex-column align-items-start gap-3 mt-3"
              >
                {bulletPoints?.map((point, i) => {
                  const [heading, description] = point.trim().split(":");
                  return (
                    <div key={i}>
                      <p
                        style={{
                          color: "#212529",
                          fontSize: mobileScreen
                          ? "0.85rem"
                          : smallLaptopScreen
                          ? "1rem"
                          : "1.15rem",
                          marginBottom: "0.5rem",
                          fontWeight: mobileScreen?"600":"500",
                          fontFamily: "monospace",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: mobileScreen?"500":"350",
                            marginRight: "1rem",
                            fontSize: mobileScreen
                            ? "0.85rem"
                            : smallLaptopScreen
                            ? "0.85rem"
                            : "1rem",
                          }}
                        >
                          {(i + 1 < 10 ? `0${i + 1}` : i + 1)}
                        </span>
                        {heading}
                      </p>
                      <p
                        style={{
                          color: "#212529",
                          fontSize: mobileScreen
                          ? "0.75rem"
                          : smallLaptopScreen
                          ? "0.75rem"
                          : "0.85rem",
                          marginBottom: "0.3rem",
                          marginLeft: "2.4rem",
                        }}
                      >
                        {description}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TheAudience;
