import React from "react";
import line from "../../../../../Images/line.svg";
import goals from "../../../../../Images/goals.png";
import { useMediaQuery } from "@material-ui/core";

const Goals = ({ data }) => {
  const programGoalsData = data?.Program_Goals;
  const smallLaptopScreen = useMediaQuery("(max-width:1200px)");
  const mobileScreen = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {programGoalsData.map((goal, index) => (
        <div key={index} className="preview-header-lower-section mt-4">
          <div className="d-flex align-items-center">
            <div className="who-is-heading "></div>
            <div className="d-flex align-items-center">
              <p
                style={{
                  whiteSpace: "nowrap",
                  marginBottom: "0",
                  paddingLeft: mobileScreen?"1.3rem":"2.357rem",
                  color: "#051D2C",
                  fontSize: "1.25rem",
                  fontWeight: "350",
                }}
              >
                Program <span style={{ fontWeight: "500" }}>Goals</span>{" "}
              </p>
              <span style={{ paddingLeft: "1rem" }}>
                <img src={line} alt="line" />
              </span>
            </div>
          </div>
          <div
            className="d-flex align-items-start gap-5 mt-4"
            style={{ paddingLeft: mobileScreen?"1.3rem":"2.357rem" }}
          >
            <div
              className="d-flex align-items-center gap-3 mt-1"
              style={{ flexDirection: mobileScreen ? "column" : "row" }}
            >
              <img
                style={{
                  width: mobileScreen
                    ? "8rem"
                    : smallLaptopScreen
                    ? "14rem"
                    : "18rem",
                }}
                src={goals}
                alt="goals"
              />
              <div>
                {goal.Response.split("\n").map((item, idx) => {
                  const textAfterNumber = item.replace(/^\d+\.\s*/, "").trim();
                  if (textAfterNumber) {
                    return (
                      <div
                        key={idx}
                        className="d-flex align-items-start gap-3 mt-1"
                      >
                        <p
                          style={{
                            letterSpacing: "1px",
                            fontSize: mobileScreen
                              ? "0.85rem"
                              : smallLaptopScreen
                              ? "1rem"
                              : "1.15rem",
                            color: "#212529",
                            fontFamily: "monospace",
                          }}
                        >
                          {`${(idx + 1).toString().padStart(2, "0")}`}{" "}
                          {/* Display 01, 02, 03 */}
                        </p>
                        <p
                          style={{
                            paddingTop: mobileScreen
                              ? "0"
                              : smallLaptopScreen
                              ? "0"
                              : "2px",
                            fontSize: mobileScreen
                              ? "0.85rem"
                              : smallLaptopScreen
                              ? "1rem"
                              : "1.15rem",
                          }}
                        >
                          {textAfterNumber}
                        </p>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Goals;
