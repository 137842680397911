import React from "react";
import line from "../../../../../Images/line.svg";
import campaignPic from "../../../../../Images/campaign.jpg";
import { useMediaQuery } from "@material-ui/core";

const BlankCampaign = ({ data }) => {
  const BlankCampaignData = data?.Blank_Campaign;
  const smallLaptopScreen = useMediaQuery("(max-width:1200px)");
  const mobileScreen = useMediaQuery("(max-width:768px)");
//   console.log("blank preview");
  return (
    <div>
      {BlankCampaignData?.map(
        (campaign, index) =>
          campaign?.Response?.trim() !== "" && (
            <div key={index} className="preview-header-lower-section mt-4">
              <div className="d-flex align-items-center">
                <div className="who-is-heading "></div>
                <div className="d-flex align-items-center">
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      marginBottom: "0",
                      paddingLeft: mobileScreen ? "1.3rem" : "2.357rem",
                      color: "#051D2C",
                      fontSize: "1.25rem",
                      fontWeight: "350",
                    }}
                  >
                    Blank <span style={{ fontWeight: "500" }}>Campaign</span>{" "}
                  </p>
                  <span style={{ paddingLeft: "1rem" }}>
                    <img src={line} alt="line" />
                  </span>
                </div>
              </div>
              <div
                className="d-flex gap-5 "
                style={{
                  paddingLeft: mobileScreen ? "1.3rem" : "2.357rem",
                  marginTop: "4rem",
                  alignItems: mobileScreen ? "center" : "start",
                  flexDirection: mobileScreen ? "column" : "row",
                }}
              >
                <img
                  src={campaignPic}
                  style={{
                    width: mobileScreen
                      ? "12rem"
                      : smallLaptopScreen
                      ? "16rem"
                      : "18rem",
                    height: mobileScreen
                      ? "12rem"
                      : smallLaptopScreen
                      ? "16rem"
                      : "18rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
                <div style={{ fontSize: "1rem", color: "#212529" }}>
                  {campaign?.Response.split("\n").map((item, idx) => (
                    <p
                      style={{
                        paddingTop: mobileScreen
                          ? "0"
                          : smallLaptopScreen
                          ? "0"
                          : "2px",
                        fontSize: mobileScreen
                          ? "0.75rem"
                          : smallLaptopScreen
                          ? "0.95rem"
                          : "1rem",
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default BlankCampaign;
