import React, { useState } from 'react'
import ReusableButton from '../../../reusableComponents/ReusableButton'
import brandguide from "../../../../Images/brandguide.svg";
import comingsoon from "../../../../Images/comingsoon.svg";
import { Autocomplete, Button, TextField, useMediaQuery } from "@mui/material";
function SelectTheme({ formData, setFormData, NextStepHandle }) {
  const matches = useMediaQuery('(min-width:1400px)');
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };
  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === "title") {
      // Trim and limit title to 40 characters
      updatedValue = value.slice(0, 40);
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: updatedValue,
    }));

    // Check if both fields are not empty
    setIsNextDisabled(!(updatedValue && formData.isMale));
  };
  // const handleDesignChange = (event, value) => {
  //   value = value || '';
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     isMale: value, // Update selectedDesign directly without handling null or undefined
  //   }));
  // };
  const handleDesignChange = (event, value) => {
    setFormData(prevFormData => ({
        ...prevFormData,
        isMale: value || '', // Ensure value is always defined
    }));

    // Check if both fields are not empty
    setIsNextDisabled(!(formData.title && value));
};
  return (
    <div className="massive-alliance-container">
      <div className="massive-alliance-inner">
        {/* {/ features /} */}

        <div className="massive-features">
          <div className="brand-guide-box">
            <div className="box-inner">
              <img src={brandguide} />
              <p>Brand Guide</p>
            </div>
          </div>
          <div className="coming-soon-box">
            <div className="box-inner">
              <img src={comingsoon} />
              <p>coming soon</p>
            </div>
          </div>
          <div className="coming-soon-box">
            <div className="box-inner">
              <img src={comingsoon} />
              <p>coming soon</p>
            </div>
          </div>
          <div className="coming-soon-box">
            <div className="box-inner">
              <img src={comingsoon} />
              <p>coming soon</p>
            </div>
          </div>
        </div>
        <div className="newproject-form">
          <p>Brand Guide Title</p>
          <TextField
            size={matches ? "medium" : "small"}
            label="Enter Title"
            placeholder="Enter Title"
            sx={{ width: "100%", marginBottom: "20px" }}
            name="title"
            value={formData.title}
            onChange={handleChange}
          />

          <Autocomplete
            sx={{ background: "white" }}
            size={matches ? "medium" : "small"}
            disablePortal
            id="combo-box-demo"
            options={[
              "Brand Guide - Male",
              "Brand Guide - Female"
            ]}

            onChange={handleDesignChange}
            value={formData.isMale}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select design"
                label="Select Design"
                name="isMale"

              />
            )}

          />
          <div className="mt-4 d-flex gap-1 align-items-center justify-content-between">
            <Button

              sx={{
                color: "#000000",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "transparent", // Change the background color on hover
                },
              }}
              variant="text"
            >
              CANCEL
            </Button>
            <ReusableButton
              onClick={NextStepHandle}
              isDisabled={isNextDisabled}
              buttonText={"NEXT"}
              height={"30px"}
              width="50px"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectTheme