import React, { useState } from "react";
import Delete from "../../Images/trash-2.svg";
import UploadAndNextBtn from "../UploadAndNextBtn/UploadAndNextBtn";
import CommonFascimile from "../CommonFascimile/CommonFascimile";
import NavBar from "../NavBar/NavBar";
import "./EditFascimile.css";
import Footer from "../Footer/Footer";
const EditFacsimile = () => {
  const [showSecondComponent, setShowSecondComponent] = useState(true);
  const handleDelete = () => {
    setShowSecondComponent(false);
  };
  return (
    <>
      <NavBar />
      <div className="mt-80">
        <UploadAndNextBtn />
        <h2 style={{ marginLeft: "350px" }}>Fascimile</h2>
        {showSecondComponent && <CommonFascimile onDelete={handleDelete} />}
        {showSecondComponent && (
          <button className="btn edit-fascimile-link-btn">
            <img src={Delete} alt="Delete Button" />
          </button>
        )}
        {showSecondComponent && (
          <button className="btn edit-fascimile-approved-btn">
            <img src={Delete} alt="Delete Button" />
          </button>
        )}
      </div>
      <Footer />
    </>
  );
};

export default EditFacsimile;
