import "./ProfileTabs.css";
import React, { useState } from "react";
import edit from "../../../Images/edit-2.svg";
import link from "../../../Images/external-link.svg";
import { Link } from "react-router-dom";
import {
  UpdateCompanyDetailsService,
  getCompanyDetailsService,
} from "../../../Service/service";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import LoadingSpinner from "../../../Assests/loaderspinner.gif";
import { RotatingLines } from "react-loader-spinner";
function CompanyDetails() {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");

  useEffect(() => {
    getCompanyDetailsService().then((res) => {
      if (res.success) {
        setCompanyDetails(res?.data[0].text);
        setCompanyWebsite(res?.data[0].companyWebsite);
      }
    });
  }, []);

  const UpdateDetailsHandler = async (event) => {
    event.preventDefault();

    // Perform other form submission logic...
    const regex = /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,9}(\/.*)?$/;

    // Check if the website URL is not empty and doesn't match the regex
    if (companyWebsite && !regex.test(companyWebsite)) {
      Swal.fire({
        title: "Invalid Website URL",
        text: "Please enter a valid website URL.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      setLoading(true);
      const res = await UpdateCompanyDetailsService(
        companyDetails,
        companyWebsite == ""
          ? companyWebsite
          : companyWebsite.startsWith("http://") ||
            companyWebsite.startsWith("https://")
          ? companyWebsite
          : "http://" + companyWebsite
      );
      if (res.success) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: res.message,
        });
        setIsEdit(false);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: res.message,
        });
      }
    }
    // Validate website URL
  };
  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width='50'
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="bg-white mb-16 company-details moment-border p-0 moment-text d-flex justify-content-between flex-column">
        {!isEdit ? (
          <>
            <div className="px-4 py-3 mt-2 d-flex justify-content-between color-darkGray box-header">
              <span>Company Details</span>
              <div
                role="button"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                <img src={edit} className="edit-img" alt="" />
              </div>
            </div>
            <div className="px-4 py-3 paragraph-text">
              <p>
                {companyDetails && companyDetails !== ""
                  ? companyDetails
                  : "No details yet"}
              </p>
            </div>
            <div className="px-4 mb-4">
              {companyWebsite && companyWebsite !== "" ? (
                <a
                  href={
                    companyWebsite.startsWith("http://") ||
                    companyWebsite.startsWith("https://")
                      ? companyWebsite
                      : "http://" + companyWebsite
                  }
                  target="_blank"
                  className="d-flex"
                >
                  <img src={link} alt="" className="edit-img mt-1" />
                  <span className="px-2 link-span">{companyWebsite}</span>
                </a>
              ) : (
                "No website url added"
              )}
            </div>
          </>
        ) : (
          <>
            <div className="px-4 py-3 mt-2 d-flex justify-content-between  color-darkGray box-header">
              <span>Company Details</span>
            </div>
            <div className="px-4 py-4">
              <textarea
                placeholder="Write your company details here"
                className="w-100 p-2 rounded border border-dark"
                value={companyDetails}
                onChange={(e) => setCompanyDetails(e.target.value)}
              ></textarea>
            </div>
            <div className="row mb-3 align-items-center edit-div">
              <div className="col-md-3 mx-3 px-4">
                <span className="input-text">Company Website</span>
              </div>
              <div className="col-md-8 mx-2 ">
                <input
                  placeholder="Company url"
                  value={companyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                  type="text"
                  className="w-100 p-2"
                />
              </div>
            </div>
            <div className="footer-div mt-4 d-flex justify-content-end gap-3 px-4 py-4">
              <div
                role="button"
                className="footer-cancel-btn d-flex justify-content-center align-items-center"
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                Cancel
              </div>
              <div
                role="button"
                className="footer-update-btn d-flex justify-content-center align-items-center"
                onClick={UpdateDetailsHandler}
              >
                Update
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CompanyDetails;
