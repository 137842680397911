import React from "react";
import line from "../../../../../Images/line.svg";
import challenges from "../../../../../Images/challenges.png";
import { useMediaQuery } from "@material-ui/core";

const Challenges = ({ data }) => {
  const audienceChallenges = data?.Audience_Challenges;
  // console.log("audienceChallenges", audienceChallenges);
  const smallLaptopScreen = useMediaQuery("(max-width:1200px)");
  const mobileScreen = useMediaQuery("(max-width:768px)");
  return (
    <>
      {audienceChallenges?.map((challenge, index) => (
        <div key={index} className="preview-header-lower-section mt-4">
          <div className="d-flex align-items-center">
            <div className="who-is-heading "></div>
            <div className="d-flex align-items-center">
              <p
                style={{
                  whiteSpace: "nowrap",
                  marginBottom: "0",
                  paddingLeft: mobileScreen ? "1.3rem" : "2.357rem",
                  color: "#051D2C",
                  fontSize: "1.25rem",
                  fontWeight: "350",
                }}
              >
                Audience <span style={{ fontWeight: "500" }}>Challenges</span>{" "}
              </p>
              <span style={{ paddingLeft: "1rem" }}>
                <img src={line} alt="line" />
              </span>
            </div>
          </div>
          <div
            className="d-flex gap-5 mt-4"
            style={{
              paddingLeft: mobileScreen ? "1.3rem" : "2.357rem",
              flexDirection: mobileScreen ? "column" : "row",
              alignItems: mobileScreen ? "center" : "start",
            }}
          >
            <img
              style={{
                width: mobileScreen
                  ? "8rem"
                  : smallLaptopScreen
                  ? "14rem"
                  : "18rem",
              }}
              src={challenges}
              alt="challenges"
            />
            <div>
              {challenge?.Response.split("\n").map((line, idx) => {
                const textAfterNumber = line?.replace(/^\d+\.\s*/, "").trim();
                // console.log("textAfterNumber");
                if (textAfterNumber) {
                  return (
                    <div key={idx} className="mt-1">
                      <div className="d-flex align-items-start gap-3">
                        <p
                          style={{
                            letterSpacing: "1px",
                            fontSize: mobileScreen
                              ? "1rem"
                              : smallLaptopScreen
                              ? "1rem"
                              : "1.15rem",
                            color: "#212529",
                            fontFamily: "monospace",
                          }}
                        >
                          {`${(idx + 1).toString().padStart(2, "0")}`}
                        </p>
                        <p
                          style={{
                            letterSpacing: "1px",
                            fontSize: mobileScreen
                              ? "0.85rem"
                              : smallLaptopScreen
                              ? "1rem"
                              : "1.15rem",
                            color: "#212529",
                          }}
                        >
                          {line.includes(":")
                            ? line.split(":")[1]?.trim()
                            : line.split(".")[1]}{" "}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Challenges;
