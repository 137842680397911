import React from "react";
import "./pdf.css";
import user from "../../../../../Images/rane.png";
import MAText from "../../../../../Images/MAText.png";

const PdfSecondPage = ({
  userImage = user,
}) => {
  const [loaded, setLoaded] = React.useState(false);

  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const previewStyle = {
    width: "768px", // A4 landscape width in pixels
    height: "100vh", // A4 landscape height in pixels
    margin: "0 auto", // Center the container
    overflow: "hidden", // Hide overflow content
    border: "1px solid #ccc", // Visual boundary
    backgroundColor: "#fff", // Simulate paper color
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Add some shadow for better visualization
    // padding: '20px', // Simulate some inner spacing
  };
  return (
    <div style={previewStyle}>
      <table
        style={{
          height: "100%",
          width: "100%",
          borderCollapse: "collapse",
          borderSpacing: 0,
        }}
      >
        <tr>
          
          <td style={{ padding: 0 }}>
            <div className="profile-section">
              <p className="pdf-header-text">EXECUTIVE BRANDING </p>
              <h1 className="pdf-username">{`${activePersona?.jobTitle} ${activePersona?.lastName}`}</h1>
              <div className="pdf-leadership-btn">
                <p style={{ paddingBottom: "3px" }}>Redefining Leadership</p>
              </div>
              <div class="pdf-info-container">
                <div class="pdf-info-title">For</div>
                <div class="pdf-info-desc">{`${activePersona?.firstName} ${activePersona?.lastName}`}</div>
              </div>
              {activePersona?.jobTitle &&
               <div class="pdf-info-container">
               <div class="pdf-info-title">Title</div>
               <div class="pdf-info-desc">{activePersona?.jobTitle}</div>
             </div>
              }
              {activePersona?.epClientCompany &&
               <div class="pdf-info-container">
               <div class="pdf-info-title">Company</div>
               <div class="pdf-info-desc">{activePersona?.epClientCompany}</div>
             </div>
              }
              {activePersona?.socialLinks?.websiteUrl &&
               <div class="pdf-info-container">
               <div class="pdf-info-title">Website</div>
               <div class="pdf-info-desc">{activePersona?.socialLinks?.websiteUrl}</div>
             </div>
              }
               {activePersona?.socialLinks?.linkedin &&
               <div class="pdf-info-container">
               <div class="pdf-info-title">Linkedin</div>
               <div class="pdf-info-desc">{activePersona?.socialLinks?.linkedin?.slice(0,28) }</div>
             </div>
              }
              <p style={{marginBottom:0,marginTop:'2rem',textAlign:'start'}} className="pdf-header-text">- MASSIVE ALLIANCE</p>
             
            </div>
          </td>
          <td style={{ width: "38%", padding: 0 }}>
            <div className="profile-image-section">
              <img
                src={
                  activePersona?.image === null
                    ? userImage
                    : `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                        activePersona?.creatorEmail
                      }/${activePersona?.image}?random=${Math.random()}`
                }
                onLoad={() => setLoaded(true)}
                alt="Profile"
                style={{
                  width: "100%",
                  height: "70vh",
                  marginRight: "-3px",
                  objectFit: "cover",
                  visibility: loaded ? "visible" : "hidden",
                }}
                className="pdf-profile-image"
              />
            </div>
          </td>
          <td style={{ width: "4rem", padding: 0 }}>
            <div className="black-right-div"></div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PdfSecondPage;
