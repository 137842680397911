import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "../../Images/moment.svg";
import "./sideNav.css";
import PersonaMomentContext from "../../context/MomentsContext";
import { ChakraProvider, Divider, Menu, MenuButton, MenuItem, MenuList, Tooltip } from "@chakra-ui/react";
import { AiOutlineEdit } from "react-icons/ai";
import CustomToaster from "../../utils/toaster/CustomToaster";
import {
  CreateProjectIcon,
  MassiveAllianceIcon,
  MenuIcon,
  MindIcon,
  RSSFeedIcon,
  SideBarChatIcon,
  SideBarPersonaIcon,
  SideBarTeamsIcon,
} from "../../customIcons/customIcons";
const SideNav = ({isShowMenu=false}) => {
  const nav = useNavigate();
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  // const [momentsList, setmomentsList] = useState([]);
  const [myPersonaData, setMyPersonaData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // const { getMoments, moments } = useContext(PersonaMomentContext);
  // useEffect(() => {
  //   if (moment) {
  //     setmomentsList(moments);
  //   }
  // }, [moments]);

  useEffect(() => {
    const personaList = localStorage.getItem("my_persona_list");
    if (personaList) {
      const myPersonas = JSON.parse(personaList);
      setMyPersonaData(myPersonas);
    }
    // getMoments().then(() => {});
  }, []);
  const currentPath = window.location.pathname;
  const handleLinkClick = () => {
    // Your custom logic, if needed

    // Check some condition before navigating
    const shouldNavigate = true; // Replace with your condition

    if (shouldNavigate) {
      // Perform the navigation
      nav("/rss-feeds");
    }
  };
  return (
    <>
      <div id="sideNavDrawer">
        <CustomToaster
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />{" "}
        <div className="personaNav">
          {currentPath.startsWith("/admin") ? null : (
            <>
              <Link className="personaNav-item" to={"/chat"}>
                {currentPath === "/chat" ? (
                  <span className="item current">
                    <SideBarChatIcon />
                    Chat
                  </span>
                ) : (
                  <span className="item">
                    <SideBarChatIcon />
                    Chat
                  </span>
                )}
              </Link>
              <Link className="personaNav-item" to={"/projects"}>
                {currentPath === "/projects" ? (
                  <span className="item current">
                    <CreateProjectIcon />
                    Projects
                  </span>
                ) : (
                  <span className="item">
                    <CreateProjectIcon />
                    Projects
                  </span>
                )}
              </Link>
              <Link className="personaNav-item" to={"/team"}>
                {currentPath === "/team" ? (
                  <span className="item current">
                    <SideBarTeamsIcon />
                    Teams
                  </span>
                ) : (
                  <span className="item">
                    <SideBarTeamsIcon />
                    Teams
                  </span>
                )}
              </Link>
              <Link className="personaNav-item" to={"/brand-guide"}>
                {currentPath === "/brand-guide" ? (
                  <span className="item current">
                    <MassiveAllianceIcon />
                    Massive Alliance
                  </span>
                ) : (
                  <span className="item">
                    <MassiveAllianceIcon />
                    Massive Alliance
                  </span>
                )}
              </Link>
              <Divider />
              {parsedToken?.data?.role !== "Collaborator" &&
              parsedToken?.data?.role !== "User" ? (
                <Link className="personaNav-item" to={"/edit-persona"}>
                  {currentPath === "/edit-persona" ? (
                    <span className="item current">
                      <SideBarPersonaIcon /> Persona
                    </span>
                  ) : (
                    <span className="item">
                      <SideBarPersonaIcon /> Persona
                    </span>
                  )}
                </Link>
              ) : myPersonaData?.some(
                  (persona) =>
                    JSON.stringify(persona) === JSON.stringify(activePersona)
                ) ? (
                <Link className="personaNav-item" to={"/edit-persona"}>
                  {currentPath === "/edit-persona" ? (
                    <span className="item current">
                      <SideBarPersonaIcon /> Persona
                    </span>
                  ) : (
                    <span className="item">
                      <SideBarPersonaIcon /> Persona
                    </span>
                  )}
                </Link>
              ) : (
                <ChakraProvider>
                  <Tooltip hasArrow label="Not Allowed" fontSize="sm">
                    <Link className="notallowed-link">
                      <span className="notallowed-item d-flex">
                        <AiOutlineEdit
                          style={{ marginRight: "0.6rem", fontSize: "16px" }}
                        />{" "}
                        PERSONA
                      </span>
                    </Link>
                  </Tooltip>
                </ChakraProvider>
              )}

              <Link className="personaNav-item" to={"/resources"}>
                {currentPath === "/resources" ? (
                  <span className="item current">
                    <MindIcon
                      style={{
                        height: "1.143rem",
                        width: "1.143rem",
                      }}
                    />
                    Resources
                  </span>
                ) : (
                  <span className="item">
                    <MindIcon
                      style={{
                        height: "1.143rem",
                        width: "1.143rem",
                      }}
                    />
                    Resources
                  </span>
                )}
              </Link>
              <Link className="personaNav-item" to={"#"}>
                {currentPath === "/rss-feeds" ? (
                  <span className="item current">
                    <RSSFeedIcon
                      style={{
                        height: "1.143rem",
                        width: "1.143rem",
                      }}
                    />
                    RSS Feeds
                  </span>
                ) : (
                  <span className="item">
                    <RSSFeedIcon
                      style={{
                        height: "1.143rem",
                        width: "1.143rem",
                      }}
                    />
                    RSS Feeds
                  </span>
                )}
              </Link>
              {isShowMenu &&
               <ChakraProvider>
               <Menu bg="#F9F9F9" isLazy>
                 <MenuButton
                   style={{
                     position: "fixed",
                     background: "white",
                     bottom: "0",
                     paddingBottom: "10px",
                     width: "15rem",
                   }}
                 >
                   <div className="menuButton">
                     <MenuIcon />
                     <span className="mb-0">Menu</span>
                   </div>
                 </MenuButton>
                 <MenuList bg="#F9F9F9">
                   <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                     <Link className="personaNav-item" to={"/team"}>
                       <span className="d-flex align-items-center gap-3">
                         <SideBarTeamsIcon
                           style={{
                             height: "1.286rem",
                             width: "1.286rem",
                             fontWeight: "bold",
                           }}
                         />
                         Teams
                       </span>
                     </Link>
                   </MenuItem>
                   <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                     {" "}
                     <Link className="personaNav-item" to={"/resources"}>
                       <span className="d-flex align-items-center gap-3">
                         <MindIcon
                           style={{
                             height: "1.286rem",
                             width: "1.286rem",
                             fontWeight: "bold",
                           }}
                         />
                         Resources
                       </span>
                     </Link>
                   </MenuItem>
                   <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                   {parsedToken?.data?.role !== "Collaborator" &&
                parsedToken?.data?.role !== "User" ? (
                  <Link
                    className="chatNav-item custom-link"
                    to={"/edit-persona"}
                  >
                    <span className="d-flex align-items-center gap-3">
                      <SideBarPersonaIcon
                        style={{
                          height: "1.1rem",
                          width: "1.1rem",
                          fontWeight: "bold",
                        }}
                      />
                      Persona
                    </span>
                  </Link>
                ) : myPersonaData?.some(
                    (persona) =>
                      JSON.stringify(persona) === JSON.stringify(activePersona)
                  ) ? (
                  <Link
                    className="chatNav-item custom-link"
                    to={"/edit-persona"}
                  >
                    <span className="d-flex align-items-center gap-3">
                      <SideBarPersonaIcon
                        style={{
                          height: "1.1rem",
                          width: "1.1rem",
                          fontWeight: "bold",
                        }}
                      />
                      Persona
                    </span>
                  </Link>
                ) : (
                  <ChakraProvider>
                    <Tooltip hasArrow label="Not Allowed" fontSize="sm">
                      <Link className="notallowed-link">
                      <span className="d-flex align-items-center gap-3">
                      <SideBarPersonaIcon
                        style={{
                          height: "1.1rem",
                          width: "1.1rem",
                          fontWeight: "bold",
                        }}
                      />
                      Persona
                    </span>
                      </Link>
                    </Tooltip>
                  </ChakraProvider>
                )}
                   </MenuItem>
                   <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                     {" "}
                     <Link className="personaNav-item" to={"/projects"}>
                       <span className="d-flex align-items-center gap-3">
                         <CreateProjectIcon
                           style={{
                             height: "1.286rem",
                             width: "1.286rem",
                             fontWeight: "bold",
                           }}
                         />
                         Projects
                       </span>
                     </Link>
                   </MenuItem>
                   <MenuItem bg="#F9F9F9" px={4} _hover={{}}>
                     {" "}
                     <Link className="personaNav-item" to={"/brand-guide"}>
                       <span className="d-flex align-items-center gap-3">
                         <MassiveAllianceIcon
                           style={{
                             height: "1.286rem",
                             width: "1.286rem",
                             fontWeight: "bold",
                           }}
                         />
                         Massive Alliance
                       </span>
                     </Link>
                   </MenuItem>
                 </MenuList>
               </Menu>
             </ChakraProvider>
              }
             
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SideNav;
