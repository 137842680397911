import { React, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import logo from "../../Images/logo-full.svg";
import "../signup/Register.css";
import Swal from "sweetalert2";
import { ChakraProvider, Button as ChakraButton } from "@chakra-ui/react";
import {
  joinEmissaryService,
  requestAccessService,
  signUpUserService,
} from "../../Service/service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ReusableButton from "../reusableComponents/ReusableButton";
import ReusableTextField from "../reusableComponents/ReuseableTextField";
const JoinEmissaryComp = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const ParamsEmail = searchParams.get("email");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: ParamsEmail,
    name: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    // name validation
    if (formData.name.length < 3) {
      validationErrors.name = "Username must be at least 3 characters long.";
    }
    // Password validation
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;
    if (!formData.password || !formData.password.match(passwordPattern)) {
      validationErrors.password =
        "Password should be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, and 1 number. It should not include spaces.";
    }

    // Confirm password validation
    if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Handle successful form submission here
      try {
        setLoader(true);
        const res = await joinEmissaryService(formData, token);
        if (res.success) {
          // signup successful
          setFormData({
            // Reset the form fields
            email: "",
            password: "",
            confirmPassword: "",
            name: "",
          });
          setLoader(false);
          Swal.fire({
            icon: "success",
            title: res.message,
          });
          navigate("/");
        } else {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: res.message,
          });
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <>
      <div className="signupContainer">
        <div className="signupContainer-inner">
          <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          <div>
            <div className="signupContainer-form">
              <form
                className="loginForm"
                id="SignUpForm"
                onSubmit={handleSubmit}
              >
                <div>
                  {/* name */}
                  <div className="mb-4">
                    <ReusableTextField
                      label="Username"
                      type="text"
                      id="form2Example11"
                      name="name"
                      placeholder="Enter name"
                      value={formData.name}
                      onChange={handleChange}
                      error={errors.name}
                      helperText={errors.name}
                      focused={true}
                      required
                    />
                  </div>

                  {/* email */}
                  <div className="mb-4">
                    <ReusableTextField
                      type="text"
                      id="form2Example11"
                      name="email"
                      placeholder="Enter email"
                      defaultValue={ParamsEmail}
                      label="Email"
                      required
                      disabled
                    />
                  </div>

                  {/* password */}
                  <div className="mb-4">
                    <ReusableTextField
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="form2Example22"
                      name="password"
                      placeholder="Enter password"
                      value={formData.password}
                      onChange={handleChange}
                      error={errors.password}
                      focused={true}
                      helperText={errors.password}
                      showEyeIcon
                      required
                    />
                  </div>

                  {/* confirm password */}
                  <div className="mb-4">
                    <ReusableTextField
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      id="form2Example33"
                      name="confirmPassword"
                      placeholder="Confirm password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      error={errors.confirmPassword}
                      helperText={errors.confirmPassword}
                      showEyeIcon
                      required
                    />
                  </div>

                  {/* login button */}
                  <div className="mb-4">
                    <ReusableButton
                      buttonText="Join Emissary"
                      onClick={handleSubmit}
                      loading={loader}
                      height="55px"
                      width="100%"
                      isDisabled={
                        !ParamsEmail ||
                        !formData.name ||
                        !formData.password ||
                        !formData.confirmPassword
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinEmissaryComp;
