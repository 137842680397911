import {
  Button,
  ChakraProvider,
  Divider,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import "../Persona/Persona.css";
import uploadPhoto from "../../Images/uploadPhoto.svg";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import Swal from "sweetalert2";
import pluscircle from "../../Images/plus-circle.svg";
import { useNavigate } from "react-router-dom";
import CustomTextField from "./CustomTextField";
const CreatePersonaModal = ({
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  const nav = useNavigate();
  const toast = useToast();
  const [profilePic, setProfilePic] = useState(null);
  const [f_Name, setF_Name] = useState("");
  const [l_Name, setL_Name] = useState("");
  const [comapnyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [documentName, setDocumentName] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const active_user = JSON.parse(localStorage.getItem("active_user"));
  const [jobTitle, setJobTitle] = useState("");
  const [inputValues, setInputValues] = useState({
    websiteUrl: "",
    instagram: "",
    linkedin: "",
    tikTok: "",
    twitter: "",
  });
  const handleInputChange = (e) => {
    if (e.target.name === "profilePic") {
      const selectedFile = e.target.files[0];
      if (selectedFile && !isFileFormatValid(selectedFile)) {
        Swal.fire(
          "Invalid file format!",
          "Please upload png, jpg and jpeg files only",
          "error"
        );
        return;
      }

      setProfilePic(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
      }
    } else if (e.target.name === "f_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setF_Name(sanitizedValue);
    } else if (e.target.name === "l_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setL_Name(sanitizedValue);
    } else if (e.target.name === "comapnyName") {
      setCompanyName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "documentName") {
      setDocumentName(e.target.files[0]);
      // console.log(e.target.files[0]);
    }
  };

  const handleCloseModal = () => {
    // Reset the file input when closing the modal
    setProfilePic(null);
    setPreviewImage(null);
  };

  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };

  const validateForm = () => {
    const linkValidationRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const nameRegex = /^[a-zA-Z\s_]+$/;
    const companyNameRegex = /^[a-zA-Z0-9\s_]+$/;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    let errors = {};

    if (!f_Name?.trim()) {
      errors.firstName = "First name is required.";
    } else if (!nameRegex.test(f_Name)) {
      errors.firstName = "Only alphabets, underscore, and spaces are allowed.";
    } else if (f_Name?.length > 30) {
      errors.firstName = "First name should not exceed 30 characters.";
    }
    if (!l_Name?.trim()) {
      errors.lastName = "Last name is required.";
    } else if (!nameRegex.test(l_Name)) {
      errors.lastName = "Only alphabets, underscore, and spaces are allowed.";
    } else if (l_Name?.length > 30) {
      errors.lastName = "Last name should not exceed 30 characters.";
    }
    if (jobTitle !== "" && !nameRegex.test(jobTitle)) {
      errors.jobTitle = "Only alphabets, underscore, and spaces are allowed.";
    } else if (jobTitle?.length > 50) {
      errors.jobTitle = "Job Title should not exceed 50 characters.";
    }
    if (!comapnyName?.trim()) {
      errors.companyName = "Company name is required.";
    } else if (!companyNameRegex.test(comapnyName)) {
      errors.companyName =
        "Only alphabets, underscore, and spaces are allowed.";
    } else if (comapnyName?.length > 50) {
      errors.companyName = "Company name should not exceed 50 characters.";
    }
    if (!email?.trim()) {
      errors.personaEmail = "Email is required.";
    } else if (!emailRegex.test(email) || !email) {
      errors.personaEmail = "Please enter valid email";
    }
    if (
      inputValues?.websiteUrl !== "" &&
      !linkValidationRegex.test(inputValues?.websiteUrl)
    ) {
      errors.websiteUrl = "Invalid website URL";
    }
    if (
      inputValues?.instagram !== "" &&
      !linkValidationRegex.test(inputValues?.instagram)
    ) {
      errors.instagram = "Invalid instagram URL";
    }
    if (
      inputValues?.linkedin !== "" &&
      !linkValidationRegex.test(inputValues?.linkedin)
    ) {
      errors.linkedin = "Invalid linkedin URL";
    }
    if (
      inputValues?.tikTok !== "" &&
      !linkValidationRegex.test(inputValues?.tikTok)
    ) {
      errors.tikTok = "Invalid tikTok URL";
    }
    if (
      inputValues?.twitter !== "" &&
      !linkValidationRegex.test(inputValues?.twitter)
    ) {
      errors.twitter = "Invalid twitter URL";
    }

    // Add other validations here

    setError(errors);
    return Object.keys(errors).length === 0;
  };
  const buttonRef = useRef(null);

  const createPersona = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }
    setLoading(true);
    setError({});
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const formData = new FormData();
    formData.append("firstName", f_Name);
    formData.append("lastName", l_Name);
    formData.append("epClientEmail", email);
    formData.append("epClientCompany", comapnyName);
    formData.append("userId", active_user?._id);
    formData.append("image", profilePic);
    formData.append("jobTitle", jobTitle ?? "");
    formData.append("socialLinks[websiteUrl]", inputValues?.websiteUrl ?? "");
    formData.append("socialLinks[instagram]", inputValues?.instagram ?? "");
    formData.append("socialLinks[tikTok]", inputValues?.tikTok ?? "");
    formData.append("socialLinks[linkedin]", inputValues?.linkedin ?? "");
    formData.append("socialLinks[twitter]", inputValues?.twitter ?? "");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/personas/createpersona`,
        {
          method: "POST",
          headers: parsedToken,
          body: formData,
        }
      );
      let json = await response.json();

      if (json.success) {
        setError({});
        setF_Name("");
        setL_Name("");
        setCompanyName("");
        setEmail("");
        setLoading(false);
        setProfilePic(null);
        setPreviewImage(null);
        onClose();
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(json.message);
        setJobTitle("");
        setInputValues({
          websiteUrl: "",
          instagram: "",
          linkedin: "",
          tikTok: "",
          twitter: "",
        });
      } else {
        setLoading(false);
        if (json.message === "Email already exists!") {
          const errors = {};
          errors.personaEmail = json.message;
          setError(errors);
        }
        else {
          toast({
            description: json.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      toast({
        description: error,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleChange = (fieldName) => (event) => {
    const newValue = event.target.value;

    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };

  return (
    <>
      <ChakraProvider>
        <Stack onClick={onOpen} direction={"row"}>
          <Img className="plusCircle" src={pluscircle} />
          <Text fontWeight="600">Create a persona</Text>
        </Stack>

        <Modal
          onClose={() => {
            setF_Name("");
            setL_Name("");
            setCompanyName("");
            setEmail("");
            setProfilePic(null);
            setPreviewImage(null);
            onClose();
          }}
          size={"full"}
          isOpen={isOpen}
        >
          <ModalOverlay />
          <ModalContent
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            style={{ height: "100%" }}
          >
            <ModalCloseButton />
            <ModalBody style={{ background: "#FAFAFB" }}>
              <div className="h-100 d-flex">
                <div className="align-items-center create-persona-container d-flex flex-column justify-content-center">
                  <div className=" d-flex flex-column inner-wrapper">
                    <h1 className="create-persona-title">Create a Persona</h1>
                    {/* <Divider/> */}
                    <div className="d-flex  upload-image-container align-items-center">
                      <div>
                        <label
                          htmlFor="img-upload"
                          className="d-flex cursor-pointer"
                        >
                          <input
                            id="img-upload"
                            type="file"
                            name="profilePic"
                            className="fileUpload-Custom"
                            accept=".png,.jpg,.jpeg"
                            onChange={handleInputChange}
                          />
                          {previewImage ? (
                            <img
                              src={previewImage}
                              alt="preview"
                              style={{
                                borderRadius: "100%",
                              }}
                            />
                          ) : (
                            <img
                              style={{
                                borderRadius: "100%",
                              }}
                              src={uploadPhoto}
                              alt=""
                            />
                          )}
                        </label>
                      </div>
                      <div className="ms-3">
                        <h1 className="upload-heading">Upload your photo</h1>
                        <div className="upload-desc">
                          Photos help your teammates recognize you in emissary
                        </div>
                      </div>
                    </div>

                    <div className="personaForm">
                      <CustomTextField
                        id="f_name"
                        label="First Name"
                        placeholder="First Name"
                        value={f_Name}
                        name="f_name"
                        onChange={(e) => setF_Name(e.target.value)}
                        error={error.firstName}
                        errorMessage={error.firstName}
                      />

                      <CustomTextField
                        id="l_name"
                        label="Last Name"
                        placeholder="Last Name"
                        name="l_name"
                        value={l_Name}
                        onChange={(e) => setL_Name(e.target.value)}
                        error={error.lastName}
                        errorMessage={error.lastName}
                      />
                      <CustomTextField
                        id="comapnyName"
                        label=" Company Name"
                        placeholder=" Company Name"
                        name="comapnyName"
                        value={comapnyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        error={error.companyName}
                        errorMessage={error.companyName}
                      />
                      <CustomTextField
                        id="email"
                        label="Email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={error.personaEmail}
                        errorMessage={error.personaEmail}
                      />
                      <CustomTextField
                        id="jobTitle"
                        label="Job Title"
                        placeholder="Job Title"
                        name="jobTitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        error={error.jobTitle}
                        errorMessage={error.jobTitle}
                      />
                      <CustomTextField
                        id="WebsiteURL"
                        label="Website URL"
                        placeholder="Website URL"
                        value={inputValues?.websiteUrl}
                        onChange={handleChange("websiteUrl")}
                        error={error.websiteUrl} // Add this line if you want to show an error state
                        errorMessage={error.websiteUrl}
                      />
                      <CustomTextField
                        id="Instagram"
                        label="Instagram"
                        placeholder="Instagram"
                        value={inputValues?.instagram}
                        onChange={handleChange("instagram")}
                        error={error.instagram} // Add this line if you want to show an error state
                        errorMessage={error.instagram}
                      />
                      <CustomTextField
                        id="Linkedin"
                        label="Linkedin"
                        placeholder="Linkedin"
                        value={inputValues?.linkedin}
                        onChange={handleChange("linkedin")}
                        error={error.linkedin} // Add this line if you want to show an error state
                        errorMessage={error.linkedin}
                      />
                      <CustomTextField
                        id="Tiktok"
                        label="Tiktok"
                        placeholder="Tiktok"
                        value={inputValues?.tikTok}
                        onChange={handleChange("tikTok")}
                        error={error.tikTok} // Add this line if you want to show an error state
                        errorMessage={error.tikTok}
                      />
                      <CustomTextField
                        id="Twitter"
                        label="Twitter/X"
                        placeholder="Twitter/X"
                        value={inputValues?.twitter}
                        onChange={handleChange("twitter")}
                        error={error.twitter} // Add this line if you want to show an error state
                        helperText={error.twitter}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="close-btn"
                        size="md"
                        fontWeight={"500"}
                        fontSize={"1rem"}
                        padding={"12px 20px"}
                        type="submit"
                        borderRadius={"5px"}
                        bg="#051D2C"
                        w="100px"
                        color="white"
                        _hover={{ bg: "#051D2C" }}
                        onClick={createPersona}
                        isLoading={loading}
                      >
                        CREATE
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};

export default CreatePersonaModal;
