import React, { useRef, useState } from "react";
import "../Persona/Persona.css";
import uploadPhoto from "../../Images/uploadPhoto.svg";
import { Button as ChakraButton, ChakraProvider } from "@chakra-ui/react";
import { UpdatePersonaProfileService } from "../../Service/service";
import { useEffect } from "react";
import { useContext } from "react";
import UserProfileContext from "../../context/UserContext";
import moment from "moment/moment";
import SideNav from "../sideNav/sideNav";
import NavBar from "../NavBar/NavBar";
import PersonaTab from "../ClientProfile/ClientProfileTabs/PersonaTab";
import CompanyDetails from "../ClientProfile/ClientProfileTabs/CompanyDetails";
import PersonaChatContext from "../../context/ChatContext";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import CustomToaster from "../../utils/toaster/CustomToaster";

import { TextField, useMediaQuery, Button as MuiButton } from "@mui/material";
import alertIcon from "../../Images/alert.svg";
import { makeStyles } from "@mui/styles";
import ReusableButton from "../reusableComponents/ReusableButton";
import Feedback from "../feedback/Feedback";

const EditPersona = () => {
  const matches = useMediaQuery("(min-width:1400px)");
  const { getUserDetailsFunction } = useContext(UserProfileContext);
  const { emptyChatArray, updateChatArray } = useContext(PersonaChatContext);
  const nav = useNavigate();
  const [isComponentUpdated, setisComponentUpdated] = useState(false);

  const [activePersona, setActivePersona] = useState();
  const [profilePic, setProfilePic] = useState(null);
  const [f_Name, setF_Name] = useState("");
  const [l_Name, setL_Name] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [about, setAbout] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState({});
  // console.log("🚀 ~ EditPersona ~ error:", error);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [inputValues, setInputValues] = useState({
    websiteUrl: "",
    instagram: "",
    linkedin: "",
    tikTok: "",
    twitter: "",
  });
  // console.log(inputValues);
  const nameRegex = /^[a-zA-Z\s_]+$/;
  const companyNameRegex = /^[a-zA-Z0-9\s_]+$/;
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const activePersonaData = await JSON.parse(
        localStorage.getItem("active_persona_data")
      );
      setActivePersona(activePersonaData);
      setF_Name(activePersonaData?.firstName);
      setL_Name(activePersonaData?.lastName);
      setJobTitle(activePersonaData?.jobTitle ?? "");
      setAbout(activePersonaData?.about ?? "");
      setCompanyName(activePersonaData?.epClientCompany);
      setInputValues(activePersonaData?.socialLinks??{
        websiteUrl: "",
        instagram: "",
        linkedin: "",
        tikTok: "",
        twitter: "",
      });
    };
    fetchData();
  }, [updateChatArray]);

  useEffect(() => {
    setisComponentUpdated(!isComponentUpdated)
  }, [updateChatArray]);
  const [validateLinksOnSubmit, setValidateLinksOnSubmit] = useState(false);

  const linkValidationRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  const handleChange = (fieldName) => (event) => {
    const newValue = event.target.value;

    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (!f_Name?.trim()) {
      errors.firstName = "First name is required.";
    } else if (!nameRegex.test(f_Name)) {
      errors.firstName = "Only alphabets, underscore, and spaces are allowed.";
    } else if (f_Name?.length > 30) {
      errors.firstName = "First name should not exceed 30 characters.";
    }
    if (!l_Name?.trim()) {
      errors.lastName = "Last name is required.";
    } else if (!nameRegex.test(l_Name)) {
      errors.lastName = "Only alphabets, underscore, and spaces are allowed.";
    } else if (l_Name?.length > 30) {
      errors.lastName = "Last name should not exceed 30 characters.";
    }
    if (jobTitle !== "" && !nameRegex.test(jobTitle)) {
      errors.jobTitle = "Only alphabets, underscore, and spaces are allowed.";
    } else if (jobTitle?.length > 50) {
      errors.jobTitle = "Job Title should not exceed 50 characters.";
    }
    if (!companyName?.trim()) {
      errors.companyName = "Company name is required.";
    } else if (!companyNameRegex.test(companyName)) {
      errors.companyName =
        "Only alphabets, underscore, and spaces are allowed.";
    } else if (companyName?.length > 50) {
      errors.companyName = "Company name should not exceed 50 characters.";
    }
    if (
      inputValues?.websiteUrl !== "" &&
      !linkValidationRegex.test(inputValues?.websiteUrl)
    ) {
      errors.websiteUrl = "Invalid website URL";
    }
    if (
      inputValues?.instagram !== "" &&
      !linkValidationRegex.test(inputValues?.instagram)
    ) {
      errors.instagram = "Invalid instagram URL";
    }
    if (
      inputValues?.linkedin !== "" &&
      !linkValidationRegex.test(inputValues?.linkedin)
    ) {
      errors.linkedin = "Invalid linkedin URL";
    }
    if (
      inputValues?.tikTok !== "" &&
      !linkValidationRegex.test(inputValues?.tikTok)
    ) {
      errors.tikTok = "Invalid tikTok URL";
    }
    if (
      inputValues?.twitter !== "" &&
      !linkValidationRegex.test(inputValues?.twitter)
    ) {
      errors.twitter = "Invalid twitter URL";
    }

    // Add other validations here

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    if (e.target.name === "profilePic") {
      const selectedFile = e.target.files[0];
      if (selectedFile && !isFileFormatValid(selectedFile)) {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Please upload png, jpg and jpeg files only");
        return;
      }

      setProfilePic(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
      }
    } else if (e.target.name === "f_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      // const sanitizedValue = newValue.replace(/\s/g, "");
      setF_Name(newValue);
    } else if (e.target.name === "l_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      // const sanitizedValue = newValue.replace(/\s/g, "");
      setL_Name(newValue);
    } else if (e.target.name === "companyName") {
      setCompanyName(e.target.value);
    }
  };

  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };

  const updatePersona = () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    editPersonaProfile();
  };

  //   setValidateLinksOnSubmit(true);
  //   const linksAreValid = validateLinksSequentially();

  //   let errors = {};

  //   if (f_Name === "" || !nameRegex.test(f_Name)) {
  //     errors.firstName = true;
  //     return
  //   }
  //   if (l_Name === "") {
  //     errors.lastName = true;
  //   }
  //   if (companyName === "") {
  //     errors.companyName = true;
  //   }
  //   if (Object.keys(errors).length !== 0) {
  //     setError(errors);
  //     console.log(errors);
  //     return;
  //   } else if (!linksAreValid) {
  //     return;
  //   } else {
  //     editPersonaProfile();
  //   }
  // };
  const editPersonaProfile = async () => {
    setLoading(true);
    setError({});
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const formData = new FormData();
    formData.append("firstName", f_Name);
    formData.append("lastName", l_Name);
    formData.append("epClientCompany", companyName);
    formData.append("personaId", activePersona?._id);
    formData.append("image", profilePic);
    formData.append("jobTitle", jobTitle ?? "");
    formData.append("about", about ?? "");
    formData.append("socialLinks[websiteUrl]", inputValues?.websiteUrl ?? "");
    formData.append("socialLinks[instagram]", inputValues?.instagram ?? "");
    formData.append("socialLinks[tikTok]", inputValues?.tikTok ?? "");
    formData.append("socialLinks[linkedin]", inputValues?.linkedin ?? "");
    formData.append("socialLinks[twitter]", inputValues?.twitter ?? "");

    try {
      const response = await UpdatePersonaProfileService(formData);

      if (response.success) {
        setPreviewImage(null);
        setValidateLinksOnSubmit(false); // Reset the flag after validation
        emptyChatArray();
        localStorage.setItem(
          "active_persona_data",
          JSON.stringify(response.data)
        );
        setError({});
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(response.message);
      } else {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(response.message);
      }
    } catch (error) {
      setLoading(false);
      // Handle fetch error
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(error);
    }
  };
  async function deletePersonaHandler() {
    const activePersonaData = await JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    try {
      var requestOptions = {
        method: "DELETE",
        headers: parsedToken,
        redirect: "follow",
      };
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/personas/deletePersona/${activePersonaData?._id}?random=${Math.random()}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setLoading(false);
        nav("/persona");
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(result.message);
      } else {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(result.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  const parsedToken = JSON.parse(localStorage.getItem("auth_token"));
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    async function getUserData() {
      setLoading(true);
      try {
        const res = await getUserDetailsFunction(parsedToken?.data?._id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          "🚀 ~ file: MyProfile.js:157 ~ getUserData ~ error:",
          error
        );
      }
    }
    getUserData();
  }, []);

 
  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback/>
      <NavBar />
      <div className="persona-container-light ">
        <div className="userHeader hide-desktop">
          <SideNav />
        </div>
        <div className="mainContainer">
          <div className="mainContainer-left show-on-desktop">
            {" "}
            <SideNav />
          </div>
          <div className="mainContainer-right">
            <div>
              {/* edit top section */}
              <div className="edit-top-section">
                <div>
                  <label
                    htmlFor="img-upload"
                    className="w-100 h-100 d-flex cursor-pointer"
                  >
                    <input
                      id="img-upload"
                      type="file"
                      name="profilePic"
                      className="fileUpload-Custom"
                      accept=".png,.jpg,.jpeg"
                      onChange={handleInputChange}
                    />
                    {previewImage ? (
                      <img
                        src={previewImage}
                        alt="preview"
                        style={{
                          borderRadius: "100%",
                        }}
                      />
                    ) : activePersona?.image === null ? (
                      <img
                        style={{
                          borderRadius: "100%",
                        }}
                        src={uploadPhoto}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{
                          borderRadius: "100%",
                        }}
                        src={
                          activePersona?.resizedImage
                            ? `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                                activePersona?.creatorEmail
                              }/${
                                activePersona?.resizedImage
                              }?random=${Math.random()}`
                            : `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                                activePersona?.creatorEmail
                              }/${activePersona?.image}?random=${Math.random()}`
                        }
                        alt=""
                      />
                    )}
                  </label>
                </div>
                <div>
                  <h3>{`${activePersona?.firstName} ${activePersona?.lastName}`}</h3>
                  <p>
                    Created by {activePersona?.ownerName} on{" "}
                    {moment(activePersona?.personaDate).format("ll")}
                  </p>
                </div>
              </div>
              <div className="mt-4 d-flex flex-column gap-3 px-4 pb-3 edit-persona-form">
                <div
                  style={{
                    height:
                      error.firstName || error.lastName ? "3.8rem" : "auto",
                  }}
                  className="d-flex align-items-start gap-3 edit-form-row"
                >
                  <TextField
                    size={matches ? "medium" : "small"}
                    value={f_Name}
                    onChange={handleInputChange}
                    fullWidth
                    name="f_name"
                    label="First Name"
                    sx={{ background: "white" }}
                    error={error.firstName} // Add this line if you want to show an error state
                    helperText={error.firstName}
                  />
                  <TextField
                    size={matches ? "medium" : "small"}
                    value={l_Name} // Limit to 25 characters
                    onChange={handleInputChange}
                    fullWidth
                    name="l_name"
                    label="Last Name"
                    sx={{ background: "white" }}
                    error={error.lastName} // Add this line if you want to show an error state
                    helperText={error.lastName}
                  />
                </div>
                <div
                  style={{
                    height:
                      error.companyName || error.jobTitle ? "3.8rem" : "auto",
                  }}
                  className="d-flex align-items-start gap-3 edit-form-row"
                >
                  <TextField
                    size={matches ? "medium" : "small"}
                    value={companyName}
                    name="companyName"
                    onChange={handleInputChange}
                    fullWidth
                    label="Organization"
                    sx={{ background: "white" }}
                    error={error.companyName} // Add this line if you want to show an error state
                    helperText={error.companyName}
                  />
                  <TextField
                    size={matches ? "medium" : "small"}
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                    fullWidth
                    label="Job Title"
                    sx={{ background: "white" }}
                    error={error.jobTitle} // Add this line if you want to show an error state
                    helperText={error.jobTitle}
                  />
                </div>
                <div className="d-flex align-items-center gap-3">
                  <TextField
                    size={matches ? "medium" : "small"}
                    id="outlined-multiline-static"
                    label="About"
                    multiline
                    fullWidth
                    rows={4}
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    height:
                      error.websiteUrl || error.instagram ? "3.8rem" : "auto",
                  }}
                  className="d-flex align-items-start gap-3 edit-form-row"
                >
                  <TextField
                    size={matches ? "medium" : "small"}
                    name="websiteUrl"
                    fullWidth
                    label="Website URL"
                    value={inputValues?.websiteUrl || ""}
                    onChange={handleChange("websiteUrl")}
                    sx={{ background: "white" }}
                    error={error.websiteUrl} // Add this line if you want to show an error state
                    helperText={error.websiteUrl}
                  />
                  <TextField
                    size={matches ? "medium" : "small"}
                    fullWidth
                    label="Instagram"
                    value={inputValues?.instagram || ""}
                    onChange={handleChange("instagram")}
                    sx={{ background: "white" }}
                    error={error.instagram} // Add this line if you want to show an error state
                    helperText={error.instagram}
                  />
                </div>
                <div
                  style={{
                    height: error.linkedin || error.tikTok ? "3.8rem" : "auto",
                  }}
                  className="d-flex align-items-start gap-3 edit-form-row"
                >
                  <TextField
                    size={matches ? "medium" : "small"}
                    fullWidth
                    label="Linkedin"
                    value={inputValues?.linkedin || ""}
                    onChange={handleChange("linkedin")}
                    sx={{ background: "white" }}
                    error={error.linkedin} // Add this line if you want to show an error state
                    helperText={error.linkedin}
                  />
                  <TextField
                    size={matches ? "medium" : "small"}
                    fullWidth
                    label="Tiktok"
                    value={inputValues?.tikTok || ""}
                    onChange={handleChange("tikTok")}
                    sx={{ background: "white" }}
                    error={error.tikTok} // Add this line if you want to show an error state
                    helperText={error.tikTok}
                  />
                </div>
                <div className="d-flex w-50  align-items-start gap-3">
                  <TextField
                    size={matches ? "medium" : "small"}
                    fullWidth
                    label="Twitter/X"
                    value={inputValues?.twitter || ""}
                    onChange={handleChange("twitter")}
                    sx={{ background: "white",marginRight:'0.5rem' }}
                    error={error.twitter} // Add this line if you want to show an error state
                    helperText={error.twitter}
                  />
                </div>
                <div className="d-flex mt-3 align-items-center justify-content-between ">
                  <ChakraProvider>
                    <ChakraButton
                      px={"0"}
                      border={"none"}
                      _hover={{}}
                      variant={"outline"}
                      fontSize={"0.92rem"}
                      color="#C51333"
                      bg="transparent"
                      onClick={openModal}
                    >
                      DELETE PERSONA
                    </ChakraButton>
                  </ChakraProvider>
                  <ReusableButton
                    onClick={updatePersona}
                    buttonText={"UPDATE"}
                    width={matches ? "9.2rem" : "7.143rem"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header
            style={{ border: "none", paddingBottom: 0 }}
            closeButton
          ></Modal.Header>
          <Modal.Body style={{ padding: 0 }}>
            <div className="d-flex flex-column gap-4 px-5  align-items-center">
              <img src={alertIcon} />
              <h6
                style={{
                  color: "#051D2C",
                  fontSize: "1.429rem",
                  textAlign: "center",
                  lineHeight: "1.4",
                }}
              >
                Are you sure you want to delete {activePersona?.firstName} {activePersona?.lastName}?
              </h6>
            </div>
            <div
              style={{ borderTop: "1px solid #CED3D9" }}
              className="mt-4 d-flex gap-1 align-items-center justify-content-between"
            >
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "1px solid #CED3D9",
                }}
              >
                <MuiButton
                  onClick={closeModal}
                  sx={{ color: "#051D2C", fontWeight: "600" }}
                  variant="text"
                >
                  CANCEL
                </MuiButton>
              </div>
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MuiButton
                  onClick={deletePersonaHandler}
                  sx={{ color: "#C51333", fontWeight: "600" }}
                  variant="text"
                >
                  YES, DELETE
                </MuiButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default EditPersona;
