import React from 'react'
import "./Footer.css"
import chatbot from "../../Images/chat-squircle.svg"
function Footer() {
  return (
    <div className='page-footer'>

    </div>
  )
}

export default Footer