import "./App.css";
import "./Assests/Fonts/icomoon/style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChatContext } from "./context/ChatContext";
import { MomentsContext } from "./context/MomentsContext";
import { UserContext } from "./context/UserContext";
import MainRoutes from "./routes/MainRoutes.js";
import { PersonaContext } from "./context/PersonaContext.js";
function App() {
  return (
    <div className="App">
      <Router>
        <PersonaContext>
          <ChatContext>
            <MomentsContext>
              <UserContext>
                <MainRoutes />
              </UserContext>
            </MomentsContext>
          </ChatContext>
        </PersonaContext>
      </Router>
    </div>
  );
}

export default App;
