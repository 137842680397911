export function ChartIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
    >
      <path
        d="M27.8669 19.9814C26.9958 21.9725 25.7513 23.5903 24.1336 24.9592C22.5158 26.3281 20.5247 27.1992 18.5336 27.5725C17.6624 27.697 16.9158 27.8214 16.0447 27.8214C14.8002 27.8214 13.5558 27.697 12.3113 27.3236C10.3202 26.7014 8.45355 25.5814 6.96022 24.2125C5.46688 22.7192 4.34688 20.977 3.72466 18.8614C3.35133 17.617 3.10244 16.3725 3.10244 15.0036C3.10244 14.257 3.22688 13.3859 3.35133 12.6392C3.72466 10.5236 4.59577 8.65698 5.96466 7.0392C7.20911 5.42142 8.95133 4.05253 10.818 3.30587C11.1913 3.18142 11.3158 2.68365 11.1913 2.31031C11.1913 1.93698 10.6936 1.68809 10.3202 1.81253C8.20466 2.80809 6.338 4.30142 4.84466 6.04365C3.35133 7.91031 2.35577 10.0259 1.98244 12.3903C1.85799 13.2614 1.73355 14.1325 1.73355 15.0036C1.73355 16.497 1.98244 17.8659 2.35577 19.2348C3.10244 21.4748 4.34688 23.4659 5.96466 25.0836C7.58244 26.7014 9.698 27.9459 11.938 28.5681C13.3069 28.9414 14.6758 29.1903 16.0447 29.1903C16.9158 29.1903 17.9113 29.0659 18.7824 28.9414C21.0224 28.4436 23.2624 27.4481 25.0047 25.9548C26.8713 24.4614 28.2402 22.5948 29.1113 20.4792C29.2358 20.1059 29.1113 19.6081 28.738 19.4836C28.4891 19.4836 27.9913 19.6081 27.8669 19.9814Z"
        fill="#4775D5"
      />
      <path
        d="M23.5113 14.6303C23.5113 14.6303 22.8891 13.2614 21.6447 12.017C21.0224 11.3948 20.2758 10.6481 19.2802 10.1503C18.4091 9.65254 17.2891 9.2792 16.0447 9.2792C14.8002 9.2792 13.6802 9.65254 12.8091 10.1503C10.0713 11.7681 8.70244 14.6303 8.578 14.6303C8.45355 14.8792 8.45355 15.1281 8.578 15.2525C8.578 15.2525 9.20022 16.6214 10.4447 17.8659C11.0669 18.4881 11.8135 19.2348 12.8091 19.7325C13.6802 20.2303 14.8002 20.6036 16.0447 20.6036C17.2891 20.6036 18.4091 20.2303 19.2802 19.7325C22.018 18.2392 23.3869 15.377 23.5113 15.2525C23.6358 15.1281 23.6358 14.8792 23.5113 14.6303ZM20.2758 17.1192C19.1558 18.2392 17.7869 19.1103 16.0447 19.1103C15.1736 19.1103 14.3024 18.8614 13.5558 18.4881C12.4358 17.8659 11.5647 16.8703 10.9424 16.1236C10.6936 15.7503 10.4447 15.377 10.1958 15.1281C10.1958 15.1281 10.1958 15.1281 10.1958 15.0036C10.4447 14.5059 11.0669 13.6348 11.8136 12.8881C12.9336 11.7681 14.3024 10.897 16.0447 10.897C16.9158 10.897 17.7869 11.1459 18.5336 11.5192C19.6536 12.1414 20.5247 13.137 21.1469 13.8836C21.3958 14.257 21.6447 14.6303 21.8936 14.8792C21.8936 14.8792 21.8936 14.8792 21.8936 15.0036C21.6447 15.5014 21.1469 16.3725 20.2758 17.1192Z"
        fill="#4775D5"
      />
      <path
        d="M16.0447 12.5148C14.6758 12.5148 13.5558 13.6348 13.5558 15.0036C13.5558 16.3725 14.6758 17.4925 16.0447 17.4925C17.4136 17.4925 18.5336 16.3725 18.5336 15.0036C18.5336 13.6348 17.4136 12.5148 16.0447 12.5148ZM16.0447 16.2481C15.4224 16.2481 14.8002 15.7503 14.8002 15.0036C14.8002 14.3814 15.298 13.7592 16.0447 13.7592C16.6669 13.7592 17.2891 14.257 17.2891 15.0036C17.2891 15.6259 16.6669 16.2481 16.0447 16.2481Z"
        fill="#4775D5"
      />
      <path
        d="M29.2358 9.52809C28.4891 7.78587 27.4936 6.16809 26.1247 4.92365C24.7558 3.55476 23.2624 2.5592 21.5202 1.81253C19.778 1.06587 17.9113 0.692535 16.0447 0.692535C15.7958 0.692535 15.6713 0.816979 15.5469 0.941424C15.4224 1.06587 15.298 1.31476 15.298 1.4392V8.28365C15.5469 8.28365 15.7958 8.28365 16.0447 8.28365C16.2936 8.28365 16.5424 8.28365 16.7913 8.28365V2.18587C18.1602 2.31031 19.6536 2.5592 20.898 3.18142C22.5158 3.80365 23.8847 4.7992 25.0047 5.9192C26.2491 7.16365 27.1202 8.53253 27.7424 10.0259C28.2402 11.3948 28.6136 12.7636 28.738 14.1325H24.7558C24.8802 14.3814 24.8802 14.5059 25.0047 14.5059C25.1291 14.7548 25.1291 15.0036 25.0047 15.2525C25.0047 15.2525 24.8802 15.377 24.7558 15.6259H29.7336C29.9824 15.6259 30.1069 15.5014 30.2313 15.377C30.3558 15.2525 30.4802 15.0036 30.4802 14.8792C30.3558 13.137 29.9824 11.2703 29.2358 9.52809Z"
        fill="#4775D5"
      />
    </svg>
  );
}
export function ChartIconDsiabled(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
    >
      <path
        d="M27.8669 19.9814C26.9958 21.9725 25.7513 23.5903 24.1336 24.9592C22.5158 26.3281 20.5247 27.1992 18.5336 27.5725C17.6624 27.697 16.9158 27.8214 16.0447 27.8214C14.8002 27.8214 13.5558 27.697 12.3113 27.3236C10.3202 26.7014 8.45355 25.5814 6.96022 24.2125C5.46688 22.7192 4.34688 20.977 3.72466 18.8614C3.35133 17.617 3.10244 16.3725 3.10244 15.0036C3.10244 14.257 3.22688 13.3859 3.35133 12.6392C3.72466 10.5236 4.59577 8.65698 5.96466 7.0392C7.20911 5.42142 8.95133 4.05253 10.818 3.30587C11.1913 3.18142 11.3158 2.68365 11.1913 2.31031C11.1913 1.93698 10.6936 1.68809 10.3202 1.81253C8.20466 2.80809 6.338 4.30142 4.84466 6.04365C3.35133 7.91031 2.35577 10.0259 1.98244 12.3903C1.85799 13.2614 1.73355 14.1325 1.73355 15.0036C1.73355 16.497 1.98244 17.8659 2.35577 19.2348C3.10244 21.4748 4.34688 23.4659 5.96466 25.0836C7.58244 26.7014 9.698 27.9459 11.938 28.5681C13.3069 28.9414 14.6758 29.1903 16.0447 29.1903C16.9158 29.1903 17.9113 29.0659 18.7824 28.9414C21.0224 28.4436 23.2624 27.4481 25.0047 25.9548C26.8713 24.4614 28.2402 22.5948 29.1113 20.4792C29.2358 20.1059 29.1113 19.6081 28.738 19.4836C28.4891 19.4836 27.9913 19.6081 27.8669 19.9814Z"
        fill="#99C3EA"
      />
      <path
        d="M23.5113 14.6303C23.5113 14.6303 22.8891 13.2614 21.6447 12.017C21.0224 11.3948 20.2758 10.6481 19.2802 10.1503C18.4091 9.65254 17.2891 9.2792 16.0447 9.2792C14.8002 9.2792 13.6802 9.65254 12.8091 10.1503C10.0713 11.7681 8.70244 14.6303 8.578 14.6303C8.45355 14.8792 8.45355 15.1281 8.578 15.2525C8.578 15.2525 9.20022 16.6214 10.4447 17.8659C11.0669 18.4881 11.8135 19.2348 12.8091 19.7325C13.6802 20.2303 14.8002 20.6036 16.0447 20.6036C17.2891 20.6036 18.4091 20.2303 19.2802 19.7325C22.018 18.2392 23.3869 15.377 23.5113 15.2525C23.6358 15.1281 23.6358 14.8792 23.5113 14.6303ZM20.2758 17.1192C19.1558 18.2392 17.7869 19.1103 16.0447 19.1103C15.1736 19.1103 14.3024 18.8614 13.5558 18.4881C12.4358 17.8659 11.5647 16.8703 10.9424 16.1236C10.6936 15.7503 10.4447 15.377 10.1958 15.1281C10.1958 15.1281 10.1958 15.1281 10.1958 15.0036C10.4447 14.5059 11.0669 13.6348 11.8136 12.8881C12.9336 11.7681 14.3024 10.897 16.0447 10.897C16.9158 10.897 17.7869 11.1459 18.5336 11.5192C19.6536 12.1414 20.5247 13.137 21.1469 13.8836C21.3958 14.257 21.6447 14.6303 21.8936 14.8792C21.8936 14.8792 21.8936 14.8792 21.8936 15.0036C21.6447 15.5014 21.1469 16.3725 20.2758 17.1192Z"
        fill="#99C3EA"
      />
      <path
        d="M16.0447 12.5148C14.6758 12.5148 13.5558 13.6348 13.5558 15.0036C13.5558 16.3725 14.6758 17.4925 16.0447 17.4925C17.4136 17.4925 18.5336 16.3725 18.5336 15.0036C18.5336 13.6348 17.4136 12.5148 16.0447 12.5148ZM16.0447 16.2481C15.4224 16.2481 14.8002 15.7503 14.8002 15.0036C14.8002 14.3814 15.298 13.7592 16.0447 13.7592C16.6669 13.7592 17.2891 14.257 17.2891 15.0036C17.2891 15.6259 16.6669 16.2481 16.0447 16.2481Z"
        fill="#99C3EA"
      />
      <path
        d="M29.2358 9.52809C28.4891 7.78587 27.4936 6.16809 26.1247 4.92365C24.7558 3.55476 23.2624 2.5592 21.5202 1.81253C19.778 1.06587 17.9113 0.692535 16.0447 0.692535C15.7958 0.692535 15.6713 0.816979 15.5469 0.941424C15.4224 1.06587 15.298 1.31476 15.298 1.4392V8.28365C15.5469 8.28365 15.7958 8.28365 16.0447 8.28365C16.2936 8.28365 16.5424 8.28365 16.7913 8.28365V2.18587C18.1602 2.31031 19.6536 2.5592 20.898 3.18142C22.5158 3.80365 23.8847 4.7992 25.0047 5.9192C26.2491 7.16365 27.1202 8.53253 27.7424 10.0259C28.2402 11.3948 28.6136 12.7636 28.738 14.1325H24.7558C24.8802 14.3814 24.8802 14.5059 25.0047 14.5059C25.1291 14.7548 25.1291 15.0036 25.0047 15.2525C25.0047 15.2525 24.8802 15.377 24.7558 15.6259H29.7336C29.9824 15.6259 30.1069 15.5014 30.2313 15.377C30.3558 15.2525 30.4802 15.0036 30.4802 14.8792C30.3558 13.137 29.9824 11.2703 29.2358 9.52809Z"
        fill="#99C3EA"
      />
    </svg>
  );
}
export function MassiveAllianceIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M0.726562 13.451C0.726563 12.9688 0.91812 12.5063 1.2591 12.1653C1.60007 11.8244 2.06253 11.6328 2.54474 11.6328H12.3629"
        stroke="#76828B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.54474 0.726562H12.3629V15.272H2.54474C2.06253 15.272 1.60007 15.0805 1.2591 14.7395C0.91812 14.3985 0.726563 13.936 0.726562 13.4538V2.54474C0.726563 2.06253 0.91812 1.60007 1.2591 1.2591C1.60007 0.91812 2.06253 0.726563 2.54474 0.726562Z"
        stroke="#76828B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FileIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="29"
      viewBox="0 0 23 29"
      fill="none"
    >
      <g clipPath="url(#clip0_4908_1970)">
        <path
          d="M12.7 1.75H3.6C2.91044 1.75 2.24912 2.02393 1.76152 2.51152C1.27393 2.99912 1 3.66044 1 4.35V25.15C1 25.8396 1.27393 26.5009 1.76152 26.9885C2.24912 27.4761 2.91044 27.75 3.6 27.75H19.2C19.8896 27.75 20.5509 27.4761 21.0385 26.9885C21.5261 26.5009 21.8 25.8396 21.8 25.15V10.85M12.7 1.75L21.8 10.85M12.7 1.75V10.85H21.8"
          stroke="#4775D5"
          strokeWidth="1.2"
          strokeWinecap="round"
          strokeWinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4908_1970">
          <rect
            width="23"
            height="28"
            fill="white"
            transform="translate(0 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function FileGreyIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
    >
      <rect x="1" width="12" height="16" fill="white" />
      <path
        d="M5.5 2H2C1.73478 2 1.48043 2.11589 1.29289 2.32218C1.10536 2.52847 1 2.80826 1 3.1V11.9C1 12.1917 1.10536 12.4715 1.29289 12.6778C1.48043 12.8841 1.73478 13 2 13H8C8.26522 13 8.51957 12.8841 8.70711 12.6778C8.89464 12.4715 9 12.1917 9 11.9V5.85M5.5 2L9 5.85M5.5 2V5.85H9"
        stroke="#76828B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DeleteIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_5600_6477)">
        <path
          d="M1.61328 5.57812H3.92097H22.3825"
          stroke="#051D2C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0757 5.57632V21.7302C20.0757 22.3422 19.8326 22.9292 19.3998 23.362C18.967 23.7947 18.3801 24.0379 17.768 24.0379H6.22957C5.61753 24.0379 5.03056 23.7947 4.59778 23.362C4.16501 22.9292 3.92188 22.3422 3.92188 21.7302V5.57632M7.38341 5.57632V3.26863C7.38341 2.65659 7.62655 2.06962 8.05932 1.63684C8.4921 1.20407 9.07907 0.960938 9.69111 0.960938H14.3065C14.9185 0.960938 15.5055 1.20407 15.9383 1.63684C16.3711 2.06962 16.6142 2.65659 16.6142 3.26863V5.57632"
          stroke="#051D2C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5600_6477">
          <rect
            width="22.1538"
            height="24"
            fill="white"
            transform="translate(0.921875 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function FileIconFilled(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <circle cx="9.5" cy="9.5" r="9.5" fill="#051D2C" fillOpacity="0.26" />
      <path
        d="M10.1133 5H6.91406C6.67164 5 6.43914 5.09482 6.26772 5.2636C6.0963 5.43239 6 5.66131 6 5.9V13.1C6 13.3387 6.0963 13.5676 6.26772 13.7364C6.43914 13.9052 6.67164 14 6.91406 14H12.3984C12.6409 14 12.8734 13.9052 13.0448 13.7364C13.2162 13.5676 13.3125 13.3387 13.3125 13.1V8.15M10.1133 5L13.3125 8.15M10.1133 5V8.15H13.3125"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ProjectIconFilled(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M16.5 13.25C16.5 13.6478 16.342 14.0294 16.0607 14.3107C15.7794 14.592 15.3978 14.75 15 14.75H3C2.60218 14.75 2.22064 14.592 1.93934 14.3107C1.65804 14.0294 1.5 13.6478 1.5 13.25V2.75C1.5 2.35218 1.65804 1.97064 1.93934 1.68934C2.22064 1.40804 2.60218 1.25 3 1.25H6.75L8.25 3.5H15C15.3978 3.5 15.7794 3.65804 16.0607 3.93934C16.342 4.22064 16.5 4.60218 16.5 5V13.25Z"
        stroke="#76828B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChatIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
    >
      <g clipPath="url(#clip0_4908_1972)">
        <mask
          id="mask0_4908_1972"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="0"
          width="30"
          height="30"
        >
          <path
            d="M30.8476 0.210938H1.05469V30.0038H30.8476V0.210938Z"
            fill="white"
          />
          <path
            d="M17.4114 15.1C17.4114 15.9034 16.7601 16.5547 15.9567 16.5547C15.1533 16.5547 14.502 15.9034 14.502 15.1C14.502 14.2965 15.1533 13.6452 15.9567 13.6452C16.7601 13.6452 17.4114 14.2965 17.4114 15.1Z"
            fill="white"
          />
          <path
            d="M23.2317 15.1C23.2317 15.9034 22.5804 16.5547 21.777 16.5547C20.9736 16.5547 20.3223 15.9034 20.3223 15.1C20.3223 14.2965 20.9736 13.6452 21.777 13.6452C22.5804 13.6452 23.2317 14.2965 23.2317 15.1Z"
            fill="white"
          />
          <path
            d="M11.5931 15.1C11.5931 15.9034 10.9417 16.5547 10.1383 16.5547C9.33491 16.5547 8.68359 15.9034 8.68359 15.1C8.68359 14.2965 9.33491 13.6452 10.1383 13.6452C10.9417 13.6452 11.5931 14.2965 11.5931 15.1Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_4908_1972)">
          <mask
            id="mask1_4908_1972"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="0"
            width="30"
            height="31"
          >
            <path
              d="M1.05469 0.222755H30.8476V30.0156H1.05469V0.222755Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_4908_1972)">
            <path
              d="M15.9455 1.39407C8.36122 1.39407 2.21289 7.5424 2.21289 15.1267C2.21289 17.7967 2.97569 20.2882 4.29409 22.3967L2.21289 28.8594L8.67556 26.7782C10.784 28.0966 13.2755 28.8594 15.9455 28.8594C23.5299 28.8594 29.6782 22.711 29.6782 15.1267C29.6782 7.5424 23.5299 1.39407 15.9455 1.39407Z"
              fill="#4775D5"
              stroke="#4775D5"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.3938 15.1234C17.3938 15.9268 16.7425 16.5781 15.9391 16.5781C15.1357 16.5781 14.4844 15.9268 14.4844 15.1234C14.4844 14.32 15.1357 13.6687 15.9391 13.6687C16.7425 13.6687 17.3938 14.32 17.3938 15.1234Z"
              fill="white"
            />
            <path
              d="M23.2141 15.1234C23.2141 15.9268 22.5628 16.5781 21.7594 16.5781C20.956 16.5781 20.3047 15.9268 20.3047 15.1234C20.3047 14.32 20.956 13.6687 21.7594 13.6687C22.5628 13.6687 23.2141 14.32 23.2141 15.1234Z"
              fill="white"
            />
            <path
              d="M11.5813 15.1234C11.5813 15.9268 10.93 16.5781 10.1266 16.5781C9.32319 16.5781 8.67188 15.9268 8.67188 15.1234C8.67188 14.32 9.32319 13.6687 10.1266 13.6687C10.93 13.6687 11.5813 14.32 11.5813 15.1234Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4908_1972">
          <rect
            width="29.9259"
            height="30"
            fill="white"
            transform="translate(0.962891)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function SideBarPersonaIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5714_11682)">
        <path
          d="M12.9522 14.8605V13.3367C12.9522 12.5284 12.6311 11.7532 12.0596 11.1817C11.488 10.6102 10.7129 10.2891 9.90458 10.2891H3.80934C3.00106 10.2891 2.22589 10.6102 1.65435 11.1817C1.08281 11.7532 0.761719 12.5284 0.761719 13.3367V14.8605"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.85621 7.23586C8.53937 7.23586 9.90383 5.8714 9.90383 4.18824C9.90383 2.50509 8.53937 1.14062 6.85621 1.14062C5.17306 1.14062 3.80859 2.50509 3.80859 4.18824C3.80859 5.8714 5.17306 7.23586 6.85621 7.23586Z"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5714_11682">
          <rect width="13.7143" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function SideBarChatIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5720_11889)">
        <mask
          id="mask0_5720_11889"
          mask-type="luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <path d="M16 0H0V16H16V0Z" fill="white" />
          <path
            d="M8.78125 8C8.78125 8.43147 8.43147 8.78125 8 8.78125C7.56853 8.78125 7.21875 8.43147 7.21875 8C7.21875 7.56853 7.56853 7.21875 8 7.21875C8.43147 7.21875 8.78125 7.56853 8.78125 8Z"
            fill="white"
          />
          <path
            d="M11.9062 8C11.9062 8.43147 11.5565 8.78125 11.125 8.78125C10.6935 8.78125 10.3438 8.43147 10.3438 8C10.3438 7.56853 10.6935 7.21875 11.125 7.21875C11.5565 7.21875 11.9062 7.56853 11.9062 8Z"
            fill="white"
          />
          <path
            d="M5.65625 8C5.65625 8.43147 5.30647 8.78125 4.875 8.78125C4.44353 8.78125 4.09375 8.43147 4.09375 8C4.09375 7.56853 4.44353 7.21875 4.875 7.21875C5.30647 7.21875 5.65625 7.56853 5.65625 8Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5720_11889)">
          <mask
            id="mask1_5720_11889"
            mask-type="luminance"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <path d="M0 1.90735e-06H16V16H0V1.90735e-06Z" fill="white" />
          </mask>
          <g mask="url(#mask1_5720_11889)">
            <path
              d="M8 0.625002C3.92691 0.625002 0.625 3.92691 0.625 8C0.625 9.43391 1.03466 10.7719 1.74269 11.9043L0.625 15.375L4.09572 14.2573C5.22806 14.9653 6.56609 15.375 8 15.375C12.0731 15.375 15.375 12.0731 15.375 8C15.375 3.92691 12.0731 0.625002 8 0.625002Z"
              stroke="#76828B"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.78125 8C8.78125 8.43147 8.43147 8.78125 8 8.78125C7.56853 8.78125 7.21875 8.43147 7.21875 8C7.21875 7.56853 7.56853 7.21875 8 7.21875C8.43147 7.21875 8.78125 7.56853 8.78125 8Z"
              fill="#76828B"
            />
            <path
              d="M11.9062 8C11.9062 8.43147 11.5565 8.78125 11.125 8.78125C10.6935 8.78125 10.3438 8.43147 10.3438 8C10.3438 7.56853 10.6935 7.21875 11.125 7.21875C11.5565 7.21875 11.9062 7.56853 11.9062 8Z"
              fill="#76828B"
            />
            <path
              d="M5.65625 8C5.65625 8.43147 5.30647 8.78125 4.875 8.78125C4.44353 8.78125 4.09375 8.43147 4.09375 8C4.09375 7.56853 4.44353 7.21875 4.875 7.21875C5.30647 7.21875 5.65625 7.56853 5.65625 8Z"
              fill="#76828B"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5720_11889">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function SideBarTeamsIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5714_11658)">
        <path
          d="M12.75 14.75V13.25C12.75 12.4544 12.4339 11.6913 11.8713 11.1287C11.3087 10.5661 10.5456 10.25 9.75 10.25H3.75C2.95435 10.25 2.19129 10.5661 1.62868 11.1287C1.06607 11.6913 0.75 12.4544 0.75 13.25V14.75"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 7.25C8.40685 7.25 9.75 5.90685 9.75 4.25C9.75 2.59315 8.40685 1.25 6.75 1.25C5.09315 1.25 3.75 2.59315 3.75 4.25C3.75 5.90685 5.09315 7.25 6.75 7.25Z"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.25 14.7462V13.2462C17.2495 12.5815 17.0283 11.9358 16.621 11.4105C16.2138 10.8851 15.6436 10.5099 15 10.3438"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 1.34375C12.6453 1.50898 13.2173 1.88428 13.6257 2.41048C14.0342 2.93669 14.2559 3.58387 14.2559 4.25C14.2559 4.91613 14.0342 5.56331 13.6257 6.08952C13.2173 6.61572 12.6453 6.99102 12 7.15625"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5714_11658">
          <rect width="18" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
    >
      <path
        d="M0.855469 6H16.284"
        stroke="#76828B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.855469 0.859375H16.284"
        stroke="#76828B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.855469 11.1406H16.284"
        stroke="#76828B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChatIconOutlined(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_5600_6443)">
        <mask
          id="mask0_5600_6443"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="25"
        >
          <path d="M24.5 0.5H0.5V24.5H24.5V0.5Z" fill="white" />
          <path
            d="M13.6719 12.5C13.6719 13.1472 13.1472 13.6719 12.5 13.6719C11.8528 13.6719 11.3281 13.1472 11.3281 12.5C11.3281 11.8528 11.8528 11.3281 12.5 11.3281C13.1472 11.3281 13.6719 11.8528 13.6719 12.5Z"
            fill="white"
          />
          <path
            d="M18.3594 12.5C18.3594 13.1472 17.8347 13.6719 17.1875 13.6719C16.5403 13.6719 16.0156 13.1472 16.0156 12.5C16.0156 11.8528 16.5403 11.3281 17.1875 11.3281C17.8347 11.3281 18.3594 11.8528 18.3594 12.5Z"
            fill="white"
          />
          <path
            d="M8.98437 12.5C8.98437 13.1472 8.4597 13.6719 7.8125 13.6719C7.1653 13.6719 6.64062 13.1472 6.64062 12.5C6.64062 11.8528 7.1653 11.3281 7.8125 11.3281C8.4597 11.3281 8.98437 11.8528 8.98437 12.5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5600_6443)">
          <mask
            id="mask1_5600_6443"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="25"
            height="25"
          >
            <path d="M0.5 0.500004H24.5V24.5H0.5V0.500004Z" fill="white" />
          </mask>
          <g mask="url(#mask1_5600_6443)">
            <path
              d="M12.5 1.4375C6.39036 1.4375 1.4375 6.39036 1.4375 12.5C1.4375 14.6509 2.05198 16.6579 3.11403 18.3564L1.4375 23.5625L6.64358 21.886C8.34209 22.948 10.3491 23.5625 12.5 23.5625C18.6096 23.5625 23.5625 18.6096 23.5625 12.5C23.5625 6.39036 18.6096 1.4375 12.5 1.4375Z"
              stroke="#051D2C"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.6719 12.5C13.6719 13.1472 13.1472 13.6719 12.5 13.6719C11.8528 13.6719 11.3281 13.1472 11.3281 12.5C11.3281 11.8528 11.8528 11.3281 12.5 11.3281C13.1472 11.3281 13.6719 11.8528 13.6719 12.5Z"
              fill="#051D2C"
            />
            <path
              d="M18.3594 12.5C18.3594 13.1472 17.8347 13.6719 17.1875 13.6719C16.5403 13.6719 16.0156 13.1472 16.0156 12.5C16.0156 11.8528 16.5403 11.3281 17.1875 11.3281C17.8347 11.3281 18.3594 11.8528 18.3594 12.5Z"
              fill="#051D2C"
            />
            <path
              d="M8.98437 12.5C8.98437 13.1472 8.4597 13.6719 7.8125 13.6719C7.1653 13.6719 6.64062 13.1472 6.64062 12.5C6.64062 11.8528 7.1653 11.3281 7.8125 11.3281C8.4597 11.3281 8.98437 11.8528 8.98437 12.5Z"
              fill="#051D2C"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5600_6443">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function CreateProjectIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_5600_6469)">
        <path
          d="M10 1.25H1.25V10H10V1.25Z"
          stroke="#76828B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.75 1.25H15V10H23.75V1.25Z"
          stroke="#76828B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.75 15H15V23.75H23.75V15Z"
          stroke="#76828B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15H1.25V23.75H10V15Z"
          stroke="#76828B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5600_6469">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CopyIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <g clipPath="url(#clip0_4908_760)">
        <path
          d="M10.1855 14.1546C10.6826 14.8191 11.3167 15.3689 12.045 15.7668C12.7732 16.1646 13.5785 16.4012 14.4062 16.4605C15.2339 16.5198 16.0647 16.4004 16.8422 16.1103C17.6197 15.8203 18.3257 15.3665 18.9124 14.7796L22.3846 11.3074C23.4388 10.2159 24.0221 8.75409 24.0089 7.23675C23.9957 5.71941 23.3871 4.26795 22.3141 3.19498C21.2412 2.12202 19.7897 1.5134 18.2724 1.50022C16.755 1.48703 15.2932 2.07033 14.2018 3.12449L12.211 5.10366"
          stroke="#B921B4"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8158 11.8442C14.3187 11.1797 13.6846 10.6298 12.9563 10.232C12.2281 9.8341 11.4228 9.5975 10.5951 9.53822C9.76738 9.47894 8.9366 9.59837 8.1591 9.8884C7.3816 10.1784 6.67557 10.6323 6.0889 11.2192L2.61668 14.6914C1.56252 15.7828 0.979221 17.2447 0.992406 18.762C1.00559 20.2793 1.61421 21.7308 2.68717 22.8038C3.76014 23.8767 5.2116 24.4853 6.72894 24.4985C8.24628 24.5117 9.7081 23.9284 10.7995 22.8743L12.7787 20.8951"
          stroke="#B921B4"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4908_760">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function BiAnalyseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M0.6,10h1.5c0-0.8,0.2-1.6,0.4-2.3C2.8,7,3.3,6.4,3.8,5.8c0.5-0.5,1.2-1,1.9-1.3c1.5-0.6,3.1-0.6,4.5,0
        c0,0.5,0.2,0.9,0.6,1.3c0.3,0.3,0.8,0.5,1.3,0.5c1,0,1.9-0.8,1.9-1.9s-0.8-1.9-1.9-1.9c-0.5,0-1,0.2-1.3,0.5C9,2.4,6.9,2.4,5.1,3.2
        C4.2,3.5,3.4,4.1,2.8,4.8C2.1,5.4,1.5,6.2,1.2,7.1C0.8,8,0.6,9,0.6,10z M13.5,12.3c-0.3,0.7-0.7,1.3-1.3,1.9
        c-0.5,0.5-1.2,1-1.9,1.3c-1.5,0.6-3.1,0.6-4.5,0c0-0.5-0.2-0.9-0.6-1.3c-0.3-0.3-0.8-0.5-1.3-0.5c-1,0-1.9,0.8-1.9,1.9
        s0.8,1.9,1.9,1.9c0.5,0,1-0.2,1.3-0.5c0.9,0.4,1.8,0.5,2.8,0.5c1.5,0,2.9-0.4,4.1-1.2c1.2-0.8,2.2-1.9,2.7-3.3
        c0.4-0.9,0.6-1.9,0.6-2.9h-1.5C13.9,10.8,13.8,11.6,13.5,12.3z"
        fill="#4775D5"
      />
      <path
        d="M8,6.6c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4C11.4,8.1,9.9,6.6,8,6.6z M8,11.9
        c-1,0-1.9-0.8-1.9-1.9C6.1,9,7,8.1,8,8.1C9,8.1,9.9,9,9.9,10C9.9,11,9,11.9,8,11.9z"
        fill="#4775D5"
      />
    </svg>
  );
}
export function ThreedotMenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
    >
      <circle cx="8" cy="2" r="2" fill="#76828B" />
      <path
        d="M16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2Z"
        fill="#76828B"
      />
      <circle cx="2" cy="2" r="2" fill="#76828B" />
    </svg>
  );
}
export function ThreedotActionButton(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
    >
      <circle cx="8" cy="2" r="2" fill="#76828B" />
      <path
        d="M16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2Z"
        fill="#76828B"
      />
      <circle cx="2" cy="2" r="2" fill="#76828B" />
    </svg>
  );
}
export function PassowordMenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M6.66628 14.6693C6.66628 14.6693 11.7456 12.0026 11.7456 8.0026V3.33594L6.66628 1.33594L1.58691 3.33594V8.0026C1.58691 12.0026 6.66628 14.6693 6.66628 14.6693Z"
        stroke="#76828B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DeleteIconRed(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5825_10734)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.207031 3.29504C0.207031 3.00265 0.449951 2.76562 0.749608 2.76562H13.7714C14.0711 2.76562 14.314 3.00265 14.314 3.29504C14.314 3.58742 14.0711 3.82445 13.7714 3.82445H0.749608C0.449951 3.82445 0.207031 3.58742 0.207031 3.29504Z"
          fill="#C51333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.8121 1.05882C5.57226 1.05882 5.34225 1.15179 5.17266 1.31726C5.00308 1.48273 4.9078 1.70716 4.9078 1.94118V3.35294C4.9078 3.64533 4.66488 3.88235 4.36523 3.88235C4.06557 3.88235 3.82265 3.64533 3.82265 3.35294V1.94118C3.82265 1.42634 4.03225 0.932598 4.40535 0.568557C4.77844 0.204516 5.28446 0 5.8121 0H8.70584C9.23347 0 9.7395 0.204516 10.1126 0.568557C10.4857 0.932598 10.6953 1.42634 10.6953 1.94118V3.35294C10.6953 3.64533 10.4524 3.88235 10.1527 3.88235C9.85305 3.88235 9.61013 3.64533 9.61013 3.35294V1.94118C9.61013 1.70716 9.51486 1.48273 9.34527 1.31726C9.17568 1.15179 8.94567 1.05882 8.70584 1.05882H5.8121ZM2.19492 2.82353C2.49458 2.82353 2.7375 3.06056 2.7375 3.35294V13.2353C2.7375 13.4693 2.83277 13.6937 3.00236 13.8592C3.17195 14.0247 3.40196 14.1176 3.64179 14.1176H10.8761C11.116 14.1176 11.346 14.0247 11.5156 13.8592C11.6852 13.6937 11.7804 13.4693 11.7804 13.2353V3.35294C11.7804 3.06056 12.0234 2.82353 12.323 2.82353C12.6227 2.82353 12.8656 3.06056 12.8656 3.35294V13.2353C12.8656 13.7501 12.656 14.2439 12.2829 14.6079C11.9098 14.972 11.4038 15.1765 10.8761 15.1765H3.64179C3.11416 15.1765 2.60813 14.972 2.23504 14.6079C1.86195 14.2439 1.65234 13.7501 1.65234 13.2353V3.35294C1.65234 3.06056 1.89526 2.82353 2.19492 2.82353Z"
          fill="#C51333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.81211 6.29688C6.11176 6.29688 6.35468 6.5339 6.35468 6.82629V11.0616C6.35468 11.354 6.11176 11.591 5.81211 11.591C5.51245 11.591 5.26953 11.354 5.26953 11.0616V6.82629C5.26953 6.5339 5.51245 6.29688 5.81211 6.29688Z"
          fill="#C51333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.70664 6.29688C9.0063 6.29688 9.24922 6.5339 9.24922 6.82629V11.0616C9.24922 11.354 9.0063 11.591 8.70664 11.591C8.40698 11.591 8.16406 11.354 8.16406 11.0616V6.82629C8.16406 6.5339 8.40698 6.29688 8.70664 6.29688Z"
          fill="#C51333"
        />
      </g>
      <defs>
        <clipPath id="clip0_5825_10734">
          <rect width="15" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SignalIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M5 13.75C7.98369 13.75 10.8452 14.9353 12.955 17.045C15.0647 19.1548 16.25 22.0163 16.25 25"
        stroke="#F4771C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5C10.3043 5 15.3914 7.10714 19.1421 10.8579C22.8929 14.6086 25 19.6957 25 25"
        stroke="#F4771C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 25C6.94036 25 7.5 24.4404 7.5 23.75C7.5 23.0596 6.94036 22.5 6.25 22.5C5.55964 22.5 5 23.0596 5 23.75C5 24.4404 5.55964 25 6.25 25Z"
        stroke="#F4771C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function RSSFeedIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.71094 8.71875C5.4159 8.71875 7.05103 9.39604 8.25662 10.6016C9.46221 11.8072 10.1395 13.4424 10.1395 15.1473"
        stroke="#76828B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.71094 3.71875C6.74198 3.71875 9.64888 4.92283 11.7922 7.0661C13.9354 9.20937 15.1395 12.1163 15.1395 15.1473"
        stroke="#76828B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42522 15.1473C4.81971 15.1473 5.13951 14.8275 5.13951 14.433C5.13951 14.0385 4.81971 13.7188 4.42522 13.7188C4.03073 13.7188 3.71094 14.0385 3.71094 14.433C3.71094 14.8275 4.03073 15.1473 4.42522 15.1473Z"
        stroke="#76828B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UploadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
    >
      <g clipPath="url(#clip0_4967_40609)">
        <path
          d="M15 13L11.5 9.5L8 13"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 9.5V17.375"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.842 15.0891C19.6954 14.6238 20.3696 13.8876 20.7581 12.9966C21.1467 12.1057 21.2274 11.1107 20.9877 10.1687C20.7479 9.22673 20.2013 8.39142 19.4341 7.79462C18.6669 7.19781 17.7228 6.87349 16.7508 6.87285H15.6483C15.3834 5.84844 14.8898 4.89739 14.2045 4.09122C13.5192 3.28504 12.66 2.64472 11.6916 2.21838C10.7232 1.79204 9.67074 1.59079 8.61336 1.62975C7.55598 1.6687 6.52119 1.94686 5.58679 2.44331C4.65238 2.93975 3.84268 3.64156 3.21855 4.49598C2.59442 5.3504 2.17211 6.33519 1.98337 7.37632C1.79462 8.41745 1.84435 9.48781 2.12882 10.507C2.41329 11.5261 2.9251 12.4675 3.62576 13.2603"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 13L11.5 9.5L8 13"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4967_40609">
          <rect width="23" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function EditIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <g clipPath="url(#clip0_6717_7626)">
        <path
          d="M7.80469 13.2812H14.4269"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2178 1.53005C11.9502 1.53005 11.6935 1.63378 11.5043 1.81841L2.41487 10.6873L1.93922 12.5438L3.84184 12.0797L12.9313 3.21076C13.025 3.11934 13.0993 3.0108 13.15 2.89135C13.2007 2.7719 13.2268 2.64388 13.2268 2.51459C13.2268 2.38529 13.2007 2.25727 13.15 2.13782C13.0993 2.01837 13.025 1.90984 12.9313 1.81841C12.8376 1.72699 12.7264 1.65447 12.6039 1.60499C12.4815 1.55551 12.3503 1.53005 12.2178 1.53005ZM10.7239 1.05691C11.1201 0.670314 11.6575 0.453125 12.2178 0.453125C12.4952 0.453125 12.77 0.506446 13.0263 0.610044C13.2826 0.713642 13.5155 0.865489 13.7117 1.05691C13.9079 1.24834 14.0635 1.47559 14.1697 1.7257C14.2759 1.97581 14.3305 2.24387 14.3305 2.51459C14.3305 2.7853 14.2759 3.05336 14.1697 3.30347C14.0635 3.55358 13.9079 3.78083 13.7117 3.97226L4.51419 12.9466C4.44347 13.0156 4.35485 13.0646 4.25782 13.0883L1.31461 13.8062C1.12656 13.8521 0.92762 13.7983 0.790551 13.6646C0.653482 13.5308 0.598381 13.3367 0.645395 13.1532L1.3812 10.2814C1.40545 10.1867 1.45563 10.1003 1.52635 10.0313L10.7239 1.05691Z"
          fill="#76828B"
        />
      </g>
      <defs>
        <clipPath id="clip0_6717_7626">
          <rect width="15.0769" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function DeleteRedIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <g clipPath="url(#clip0_6717_7633)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.207031 3.29504C0.207031 3.00265 0.449951 2.76562 0.749608 2.76562H13.7714C14.0711 2.76562 14.314 3.00265 14.314 3.29504C14.314 3.58742 14.0711 3.82445 13.7714 3.82445H0.749608C0.449951 3.82445 0.207031 3.58742 0.207031 3.29504Z"
          fill="#C51333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.8121 1.05882C5.57226 1.05882 5.34225 1.15179 5.17266 1.31726C5.00308 1.48273 4.9078 1.70716 4.9078 1.94118V3.35294C4.9078 3.64533 4.66488 3.88235 4.36523 3.88235C4.06557 3.88235 3.82265 3.64533 3.82265 3.35294V1.94118C3.82265 1.42634 4.03225 0.932598 4.40535 0.568557C4.77844 0.204516 5.28446 0 5.8121 0H8.70584C9.23347 0 9.7395 0.204516 10.1126 0.568557C10.4857 0.932598 10.6953 1.42634 10.6953 1.94118V3.35294C10.6953 3.64533 10.4524 3.88235 10.1527 3.88235C9.85305 3.88235 9.61013 3.64533 9.61013 3.35294V1.94118C9.61013 1.70716 9.51486 1.48273 9.34527 1.31726C9.17568 1.15179 8.94567 1.05882 8.70584 1.05882H5.8121ZM2.19492 2.82353C2.49458 2.82353 2.7375 3.06056 2.7375 3.35294V13.2353C2.7375 13.4693 2.83277 13.6937 3.00236 13.8592C3.17195 14.0247 3.40196 14.1176 3.64179 14.1176H10.8761C11.116 14.1176 11.346 14.0247 11.5156 13.8592C11.6852 13.6937 11.7804 13.4693 11.7804 13.2353V3.35294C11.7804 3.06056 12.0234 2.82353 12.323 2.82353C12.6227 2.82353 12.8656 3.06056 12.8656 3.35294V13.2353C12.8656 13.7501 12.656 14.2439 12.2829 14.6079C11.9098 14.972 11.4038 15.1765 10.8761 15.1765H3.64179C3.11416 15.1765 2.60813 14.972 2.23504 14.6079C1.86195 14.2439 1.65234 13.7501 1.65234 13.2353V3.35294C1.65234 3.06056 1.89526 2.82353 2.19492 2.82353Z"
          fill="#C51333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.81211 6.29688C6.11176 6.29688 6.35468 6.5339 6.35468 6.82629V11.0616C6.35468 11.354 6.11176 11.591 5.81211 11.591C5.51245 11.591 5.26953 11.354 5.26953 11.0616V6.82629C5.26953 6.5339 5.51245 6.29688 5.81211 6.29688Z"
          fill="#C51333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.70664 6.29688C9.0063 6.29688 9.24922 6.5339 9.24922 6.82629V11.0616C9.24922 11.354 9.0063 11.591 8.70664 11.591C8.40698 11.591 8.16406 11.354 8.16406 11.0616V6.82629C8.16406 6.5339 8.40698 6.29688 8.70664 6.29688Z"
          fill="#C51333"
        />
      </g>
      <defs>
        <clipPath id="clip0_6717_7633">
          <rect width="15" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SaveIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z"
        stroke="#4775D5"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21V13H7V21"
        stroke="#4775D5"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 3V8H15"
        stroke="#4775D5"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function OutlinedPlusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5262_7613)">
        <path
          d="M11.8448 22.6505C17.7911 22.6505 22.6115 17.8301 22.6115 11.8839C22.6115 5.93759 17.7911 1.11719 11.8448 1.11719C5.89853 1.11719 1.07812 5.93759 1.07812 11.8839C1.07812 17.8301 5.89853 22.6505 11.8448 22.6505Z"
          stroke="#051D2C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8438 7.57812V16.1915"
          stroke="#051D2C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.53516 11.8828H16.1485"
          stroke="#051D2C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5262_7613">
          <rect
            width="23.6867"
            height="23.6867"
            fill="white"
            transform="translate(0 0.0390625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SendChatIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
    >
      <g opacity="0.5" clipPath="url(#clip0_5252_18368)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.8,3.6l0.8,5.5h4.7C8.7,9.1,9,9.5,9,10c0,0.5-0.3,0.9-0.7,0.9H3.5l-0.8,5.5l12-6.4L2.8,3.6z M2.2,10L1.3,3.6
          C1.2,3,1.3,2.5,1.6,2.1C2,1.7,2.6,1.5,3.1,1.8l13,7c0.4,0.2,0.7,0.7,0.7,1.3c0,0.5-0.3,1-0.7,1.3l-13,7c-0.5,0.3-1.1,0.1-1.4-0.3
          c-0.3-0.4-0.4-0.9-0.4-1.4L2.2,10z"
          fill="#24292F"
        />
      </g>
      <defs>
        <clipPath id="clip0_5252_18368">
          <rect
            width="18"
            height="20"
            fill="white"
            transform="translate(0.601562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function BlueChatIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="red"
    >
      <g clipPath="url(#clip0_5186_54421)">
        <mask
          id="mask0_5186_54421"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <path
            d="M24.0179 0.164062H0.183594V23.9984H24.0179V0.164062Z"
            fill="white"
          />
          <path
            d="M13.269 12.0784C13.269 12.7211 12.7479 13.2422 12.1052 13.2422C11.4625 13.2422 10.9414 12.7211 10.9414 12.0784C10.9414 11.4357 11.4625 10.9146 12.1052 10.9146C12.7479 10.9146 13.269 11.4357 13.269 12.0784Z"
            fill="white"
          />
          <path
            d="M17.9213 12.0784C17.9213 12.7211 17.4003 13.2422 16.7575 13.2422C16.1148 13.2422 15.5938 12.7211 15.5938 12.0784C15.5938 11.4357 16.1148 10.9146 16.7575 10.9146C17.4003 10.9146 17.9213 11.4357 17.9213 12.0784Z"
            fill="white"
          />
          <path
            d="M8.61272 12.0784C8.61272 12.7211 8.09167 13.2422 7.44894 13.2422C6.80621 13.2422 6.28516 12.7211 6.28516 12.0784C6.28516 11.4357 6.80621 10.9146 7.44894 10.9146C8.09167 10.9146 8.61272 11.4357 8.61272 12.0784Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5186_54421)">
          <mask
            id="mask1_5186_54421"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="25"
            height="25"
          >
            <path
              d="M0.183594 0.18133H24.0179V24.0156H0.183594V0.18133Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_5186_54421)">
            <path
              d="M12.0955 1.12151C6.02804 1.12151 1.10938 6.04017 1.10938 12.1076C1.10938 14.2436 1.71962 16.2368 2.77433 17.9236L1.10938 23.0938L6.27951 21.4288C7.9663 22.4835 9.95949 23.0938 12.0955 23.0938C18.163 23.0938 23.0816 18.1751 23.0816 12.1076C23.0816 6.04017 18.163 1.12151 12.0955 1.12151Z"
              fill="#4775D5"
              stroke="#4775D5"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.2533 12.1097C13.2533 12.7524 12.7323 13.2734 12.0896 13.2734C11.4468 13.2734 10.9258 12.7524 10.9258 12.1097C10.9258 11.4669 11.4468 10.9459 12.0896 10.9459C12.7323 10.9459 13.2533 11.4669 13.2533 12.1097Z"
              fill="white"
            />
            <path
              d="M17.9096 12.1097C17.9096 12.7524 17.3885 13.2734 16.7458 13.2734C16.1031 13.2734 15.582 12.7524 15.582 12.1097C15.582 11.4669 16.1031 10.9459 16.7458 10.9459C17.3885 10.9459 17.9096 11.4669 17.9096 12.1097Z"
              fill="white"
            />
            <path
              d="M8.60491 12.1097C8.60491 12.7524 8.08386 13.2734 7.44113 13.2734C6.79839 13.2734 6.27734 12.7524 6.27734 12.1097C6.27734 11.4669 6.79839 10.9459 7.44113 10.9459C8.08386 10.9459 8.60491 11.4669 8.60491 12.1097Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5186_54421">
          <rect
            width="23.9407"
            height="24"
            fill="white"
            transform="translate(0.109375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function DisableBlueChatIcon(props) {
  return (
    <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
      <mask
        id="mask0_1007_460"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <path d="M24.9647 0H0.921875V24H24.9647V0Z" fill="white" />
        <path
          d="M14.1214 12C14.1214 12.6472 13.5958 13.1719 12.9474 13.1719C12.299 13.1719 11.7734 12.6472 11.7734 12C11.7734 11.3528 12.299 10.8281 12.9474 10.8281C13.5958 10.8281 14.1214 11.3528 14.1214 12Z"
          fill="white"
        />
        <path
          d="M18.8167 12C18.8167 12.6472 18.2911 13.1719 17.6427 13.1719C16.9944 13.1719 16.4688 12.6472 16.4688 12C16.4688 11.3528 16.9944 10.8281 17.6427 10.8281C18.2911 10.8281 18.8167 11.3528 18.8167 12Z"
          fill="white"
        />
        <path
          d="M9.42606 12C9.42606 12.6472 8.90046 13.1719 8.2521 13.1719C7.60374 13.1719 7.07812 12.6472 7.07812 12C7.07812 11.3528 7.60374 10.8281 8.2521 10.8281C8.90046 10.8281 9.42606 11.3528 9.42606 12Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1007_460)">
        <mask
          id="mask1_1007_460"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="25"
          height="25"
        >
          <path
            d="M0.921875 -0.0390625H24.9647V23.9609H0.921875V-0.0390625Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_1007_460)">
          <path
            d="M12.9455 0.898438C6.82498 0.898438 1.86328 5.8513 1.86328 11.9609C1.86328 14.1118 2.47886 16.1188 3.54281 17.8174L1.86328 23.0234L7.07865 21.3469C8.7802 22.409 10.7908 23.0234 12.9455 23.0234C19.0661 23.0234 24.0278 18.0706 24.0278 11.9609C24.0278 5.8513 19.0661 0.898438 12.9455 0.898438Z"
            fill="#99C3EA"
            stroke="#99C3EA"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.1214 11.9609C14.1214 12.6081 13.5958 13.1328 12.9474 13.1328C12.299 13.1328 11.7734 12.6081 11.7734 11.9609C11.7734 11.3137 12.299 10.7891 12.9474 10.7891C13.5958 10.7891 14.1214 11.3137 14.1214 11.9609Z"
            fill="white"
          />
          <path
            d="M18.8128 11.9609C18.8128 12.6081 18.2872 13.1328 17.6388 13.1328C16.9905 13.1328 16.4648 12.6081 16.4648 11.9609C16.4648 11.3137 16.9905 10.7891 17.6388 10.7891C18.2872 10.7891 18.8128 11.3137 18.8128 11.9609Z"
            fill="white"
          />
          <path
            d="M9.42997 11.9609C9.42997 12.6081 8.90436 13.1328 8.256 13.1328C7.60764 13.1328 7.08203 12.6081 7.08203 11.9609C7.08203 11.3137 7.60764 10.7891 8.256 10.7891C8.90436 10.7891 9.42997 11.3137 9.42997 11.9609Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
export function SearchGreyIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_5395_19030)">
        <path
          d="M8.16667 14.8333C11.8486 14.8333 14.8333 11.8486 14.8333 8.16667C14.8333 4.48477 11.8486 1.5 8.16667 1.5C4.48477 1.5 1.5 4.48477 1.5 8.16667C1.5 11.8486 4.48477 14.8333 8.16667 14.8333Z"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 16.5L12.875 12.875"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5395_19030">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function MindIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5478_4716)">
        <path
          d="M3.88822 13.5917C2.78265 13.5917 1.85026 12.7063 1.85026 11.6178C1.85026 11.1422 2.02137 10.706 2.30621 10.3655C1.31509 10.2476 0.546875 9.41711 0.546875 8.40945C0.546875 7.3505 1.39511 6.48693 2.45905 6.44073C2.1804 6.10208 2.01319 5.67091 2.01319 5.20117C2.01319 4.11264 2.91243 3.22982 4.018 3.22982C4.00672 3.14509 4.0009 3.05868 4.0009 2.97094C4.0009 1.88241 4.89713 1 6.00267 1C7.1082 1 8.00447 1.88241 8.00447 2.97094H8.00679V13.2999C8.00679 14.4254 7.0801 15.3378 5.93698 15.3378C4.89446 15.3378 4.032 14.5789 3.88822 13.5917Z"
          stroke="#76828B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.90234 6.625C4.76505 6.625 5.46439 7.31359 5.46439 8.16298C5.46439 9.01239 4.76505 9.70096 3.90234 9.70096"
          stroke="#76828B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.01953 3.23438C4.1433 4.16317 4.9503 4.88017 5.92736 4.88017"
          stroke="#76828B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.88825 13.5953C3.87438 13.5 3.86719 13.4026 3.86719 13.3035C3.86719 12.1781 4.79387 11.2656 5.93699 11.2656"
          stroke="#76828B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00394 13.2999C8.00394 14.4253 8.93062 15.3378 10.0737 15.3378C11.1162 15.3378 11.9787 14.5789 12.1225 13.5916C13.228 13.5916 14.1604 12.7062 14.1604 11.6177C14.1604 11.1422 13.9893 10.706 13.7045 10.3655C14.6956 10.2476 15.4638 9.41711 15.4638 8.40944C15.4638 7.3505 14.6156 6.48693 13.5516 6.44073C13.8303 6.10208 13.9975 5.67091 13.9975 5.20117C13.9975 4.11264 13.0983 3.22982 11.9927 3.22982C12.004 3.14509 12.0098 3.05868 12.0098 2.97094C12.0098 1.88241 11.1136 1 10.008 1C8.90243 1 8.00623 1.88241 8.00623 2.97094H8.00391"
          stroke="#76828B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.105 6.625C11.2423 6.625 10.543 7.31359 10.543 8.16298C10.543 9.01239 11.2423 9.70096 12.105 9.70096"
          stroke="#76828B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9938 3.23438C11.87 4.16317 11.063 4.88017 10.0859 4.88017"
          stroke="#76828B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.123 13.5953C12.1368 13.5 12.144 13.4026 12.144 13.3035C12.144 12.1781 11.2173 11.2656 10.0742 11.2656"
          stroke="#76828B"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5478_4716">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function ExternalLinkIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.63672 6.43015C4.82177 6.67755 5.05787 6.88225 5.329 7.03038C5.60013 7.17851 5.89994 7.2666 6.2081 7.28867C6.51627 7.31074 6.82557 7.26627 7.11504 7.15829C7.40451 7.05031 7.66737 6.88134 7.88579 6.66284L9.17853 5.37011C9.571 4.96375 9.78817 4.4195 9.78326 3.85459C9.77835 3.28967 9.55175 2.74928 9.15228 2.34981C8.75281 1.95033 8.21242 1.72374 7.6475 1.71883C7.08258 1.71392 6.53834 1.93109 6.13198 2.32356L5.39081 3.06042"
        stroke="#76828B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.36146 5.56715C6.17641 5.31975 5.94031 5.11504 5.66918 4.96692C5.39805 4.81879 5.09824 4.7307 4.79008 4.70863C4.48191 4.68656 4.17261 4.73102 3.88314 4.839C3.59367 4.94698 3.33081 5.11595 3.11239 5.33446L1.81965 6.62719C1.42718 7.03355 1.21002 7.57779 1.21493 8.14271C1.21983 8.70763 1.44643 9.24802 1.8459 9.64749C2.24537 10.047 2.78576 10.2736 3.35068 10.2785C3.9156 10.2834 4.45984 10.0662 4.8662 9.67374L5.60306 8.93688"
        stroke="#76828B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function FolderBlackIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <g clipPath="url(#clip0_5633_19896)">
        <path
          d="M19.1388 16.1548C19.1388 16.637 18.9473 17.0995 18.6063 17.4405C18.2653 17.7814 17.8029 17.973 17.3207 17.973H2.77521C2.293 17.973 1.83054 17.7814 1.48956 17.4405C1.14859 17.0995 0.957031 16.637 0.957031 16.1548V3.42756C0.957031 2.94534 1.14859 2.48288 1.48956 2.14191C1.83054 1.80093 2.293 1.60938 2.77521 1.60938H7.32067L9.13885 4.33665H17.3207C17.8029 4.33665 18.2653 4.5282 18.6063 4.86918C18.9473 5.21016 19.1388 5.67262 19.1388 6.15483V16.1548Z"
          stroke="#051D2C"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5633_19896">
          <rect
            width="20"
            height="17.5758"
            fill="white"
            transform="translate(0 0.960938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function UserBlackIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
    >
      <path
        d="M18.8872 22.8259V20.5402C18.8872 19.3278 18.4189 18.165 17.5854 17.3077C16.7519 16.4504 15.6214 15.9688 14.4427 15.9688H5.55382C4.37508 15.9688 3.24462 16.4504 2.41112 17.3077C1.57763 18.165 1.10938 19.3278 1.10938 20.5402V22.8259"
        stroke="#051D2C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99913 11.3929C12.4537 11.3929 14.4436 9.34616 14.4436 6.82143C14.4436 4.2967 12.4537 2.25 9.99913 2.25C7.54453 2.25 5.55469 4.2967 5.55469 6.82143C5.55469 9.34616 7.54453 11.3929 9.99913 11.3929Z"
        stroke="#051D2C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

// social icons

export function FacebookIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
    >
      <path
        d="M5.88339 6.48748H4.10373V13H1.40739V6.48748H0.125V4.19872H1.40739V2.71763C1.40739 1.6585 1.91106 0 4.12773 0L6.125 0.00834647V2.22998H4.67585C4.43815 2.22998 4.1039 2.34861 4.1039 2.85384V4.20085H6.11896L5.88339 6.48748Z"
        fill="#051D2C"
      />
    </svg>
  );
}

export function InstagramIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M11.5957 0.981445H2.65432C1.22715 0.981445 0.0660248 2.14257 0.0660248 3.56974V12.5111C0.0660248 13.9383 1.22715 15.0997 2.65432 15.0997H11.5957C13.0229 15.0997 14.1843 13.9386 14.1843 12.5111V3.56974C14.1843 2.14257 13.0232 0.981445 11.5957 0.981445ZM7.12501 12.6883C4.56245 12.6883 2.47751 10.6033 2.47751 8.04043C2.47751 5.47787 4.56245 3.39293 7.12501 3.39293C9.6879 3.39293 11.7728 5.47787 11.7728 8.04043C11.7728 10.603 9.68756 12.6883 7.12501 12.6883ZM11.9222 4.35385C11.3156 4.35385 10.8223 3.86052 10.8223 3.25422C10.8223 2.64793 11.3156 2.1546 11.9222 2.1546C12.5285 2.1546 13.0219 2.64793 13.0219 3.25422C13.0219 3.86052 12.5285 4.35385 11.9222 4.35385Z"
        fill="#051D2C"
      />
      <path
        d="M7.12501 5.35693C5.6457 5.35693 4.44112 6.56084 4.44112 8.04015C4.44112 9.52013 5.6457 10.7244 7.12501 10.7244C8.60499 10.7244 9.80856 9.52013 9.80856 8.04015C9.80856 6.56117 8.60465 5.35693 7.12501 5.35693Z"
        fill="#051D2C"
      />
    </svg>
  );
}

export function TwitterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <g clipPath="url(#clip0_5633_20008)">
        <path
          d="M9.0535 6.35218L14.6385 0H13.3155L8.464 5.51439L4.592 0H0.125L5.9815 8.33952L0.125 14.9999H1.448L6.568 9.17532L10.658 14.9999H15.125M1.9255 0.976183H3.958L13.3145 14.0717H11.2815"
          fill="#051D2C"
        />
      </g>
      <defs>
        <clipPath id="clip0_5633_20008">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0.125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function BulbIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_4850_9678)">
        <path d="M13.0675 9.0019C13.0675 10.7782 11.942 12.2833 10.3555 12.8393V14.4258H7.64358V12.8393C6.05713 12.2833 4.93164 10.7782 4.93164 9.0019C4.93164 6.75103 6.74867 4.934 8.99955 4.934C11.2504 4.934 13.0675 6.75103 13.0675 9.0019Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.99805 0.999499V2.35547" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 9H2.35597" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.6406 9H16.9966" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.24805 3.25213L4.20685 4.21094" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.793 4.21094L14.7518 3.25213" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.64258 17H10.3545" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_4850_9678">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}


export function LinkedInIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M14.5259 14.4004V9.71244C14.5259 7.40844 14.0299 5.64844 11.3419 5.64844C10.0459 5.64844 9.18191 6.35244 8.82991 7.02444H8.79791V5.85644H6.25391V14.4004H8.90991V10.1604C8.90991 9.04044 9.11791 7.96844 10.4939 7.96844C11.8539 7.96844 11.8699 9.23244 11.8699 10.2244V14.3844H14.5259V14.4004Z"
        fill="#051D2C"
      />
      <path
        d="M1.93359 5.85938H4.58959V14.4034H1.93359V5.85938Z"
        fill="#051D2C"
      />
      <path
        d="M3.26256 1.60156C2.41456 1.60156 1.72656 2.28956 1.72656 3.13756C1.72656 3.98556 2.41456 4.68956 3.26256 4.68956C4.11056 4.68956 4.79856 3.98556 4.79856 3.13756C4.79856 2.28956 4.11056 1.60156 3.26256 1.60156Z"
        fill="#051D2C"
      />
    </svg>
  );
}

export function YoutubeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <g clipPath="url(#clip0_5633_20021)">
        <path
          d="M17.207 4.77848C17.0125 4.05555 16.4425 3.48566 15.7197 3.291C14.3992 2.92969 9.11698 2.92969 9.11698 2.92969C9.11698 2.92969 3.835 2.92969 2.51447 3.2773C1.80555 3.47176 1.22166 4.05565 1.0272 4.77848C0.679688 6.0989 0.679688 8.83728 0.679688 8.83728C0.679688 8.83728 0.679688 11.5895 1.0272 12.8961C1.22186 13.6189 1.79164 14.1888 2.51458 14.3834C3.8489 14.7449 9.11719 14.7449 9.11719 14.7449C9.11719 14.7449 14.3992 14.7449 15.7197 14.3973C16.4426 14.2027 17.0125 13.6328 17.2072 12.91C17.5546 11.5895 17.5546 8.85118 17.5546 8.85118C17.5546 8.85118 17.5685 6.0989 17.207 4.77848ZM7.43525 11.3671V6.30747L11.8277 8.83728L7.43525 11.3671Z"
          fill="#051D2C"
        />
      </g>
      <defs>
        <clipPath id="clip0_5633_20021">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function TiktokIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M14.6175 4.17164C14.5036 4.11277 14.3927 4.04824 14.2853 3.97828C13.9728 3.77172 13.6864 3.52833 13.432 3.25336C12.7957 2.52527 12.558 1.78664 12.4705 1.26949H12.474C12.4009 0.840234 12.4311 0.5625 12.4357 0.5625H9.53742V11.7696C9.53742 11.9201 9.53742 12.0688 9.53109 12.2157C9.53109 12.234 9.52934 12.2509 9.52828 12.2706C9.52828 12.2787 9.52828 12.2871 9.52652 12.2955C9.52652 12.2977 9.52652 12.2998 9.52652 12.3019C9.49597 12.704 9.36707 13.0924 9.15116 13.433C8.93525 13.7736 8.63895 14.0559 8.28832 14.2552C7.92289 14.4631 7.50957 14.5721 7.08914 14.5716C5.73879 14.5716 4.64437 13.4705 4.64437 12.1106C4.64437 10.7508 5.73879 9.64969 7.08914 9.64969C7.34476 9.64945 7.5988 9.68967 7.84184 9.76887L7.84535 6.81785C7.10755 6.72255 6.358 6.78118 5.644 6.99006C4.92999 7.19894 4.26702 7.55352 3.69691 8.03145C3.19737 8.46548 2.7774 8.98337 2.4559 9.5618C2.33355 9.77273 1.87195 10.6204 1.81605 11.996C1.7809 12.7768 2.01539 13.5858 2.12719 13.9201V13.9271C2.1975 14.124 2.46996 14.7959 2.91398 15.3622C3.27203 15.8165 3.69505 16.2156 4.16941 16.5466V16.5396L4.17645 16.5466C5.57953 17.5001 7.1352 17.4375 7.1352 17.4375C7.40449 17.4266 8.3066 17.4375 9.33106 16.952C10.4673 16.4137 11.1142 15.6118 11.1142 15.6118C11.5274 15.1327 11.856 14.5866 12.0859 13.9971C12.3482 13.3077 12.4357 12.4808 12.4357 12.1504V6.20473C12.4709 6.22582 12.9391 6.53555 12.9391 6.53555C12.9391 6.53555 13.6138 6.96797 14.6664 7.24957C15.4215 7.44996 16.4389 7.49215 16.4389 7.49215V4.61496C16.0825 4.65363 15.3586 4.54113 14.6175 4.17164Z"
        fill="#051D2C"
      />
    </svg>
  );
}

export function BrowserIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <g clipPath="url(#clip0_5633_20031)">
        <mask
          id="mask0_5633_20031"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="19"
          height="18"
        >
          <path d="M18.625 0H0.625V18H18.625V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_5633_20031)">
          <path
            d="M18.0977 8.99987H17.5703C17.5703 10.0983 17.348 11.1422 16.9462 12.0925C16.3435 13.5176 15.3354 14.7318 14.0669 15.5887C13.4328 16.0172 12.7338 16.3563 11.9879 16.5883C11.242 16.8203 10.4488 16.9453 9.62513 16.9453C8.52668 16.9453 7.48281 16.723 6.53254 16.3212C5.1074 15.7185 3.8932 14.7104 3.03627 13.4419C2.6078 12.8078 2.26872 12.1088 2.03669 11.3629C1.80466 10.617 1.67968 9.82384 1.67968 9.00013C1.67968 7.90168 1.90196 6.85781 2.30379 5.90754C2.90654 4.4824 3.91455 3.2682 5.18307 2.41127C5.8172 1.9828 6.51619 1.64372 7.26212 1.41169C8.00804 1.17966 8.80116 1.05468 9.62487 1.05468C10.7233 1.05468 11.7672 1.27696 12.7175 1.67879C14.1426 2.28154 15.3568 3.28955 16.2137 4.55807C16.6422 5.1922 16.9813 5.89119 17.2133 6.63712C17.4453 7.38304 17.5703 8.17616 17.5703 8.99987H18.625C18.625 7.75877 18.3732 6.57357 17.9176 5.49648C17.2341 3.8807 16.0935 2.50751 14.6575 1.5372C13.9396 1.05205 13.1472 0.667614 12.3014 0.404471C11.4553 0.141591 10.5559 0 9.62487 0C8.38377 0 7.19857 0.251805 6.12148 0.707428C4.5057 1.39086 3.13251 2.5315 2.1622 3.96745C1.67705 4.68543 1.29261 5.47776 1.02947 6.32361C0.766591 7.16973 0.625 8.06911 0.625 8.99987C0.625 10.241 0.876805 11.4262 1.33243 12.5033C2.01586 14.119 3.1565 15.4922 4.59245 16.4625C5.31043 16.9477 6.10276 17.3321 6.94861 17.5953C7.79447 17.8584 8.69385 18 9.62461 18C10.8657 18 12.0509 17.7482 13.128 17.2926C14.7438 16.6091 16.117 15.4685 17.0873 14.0325C17.5724 13.3146 17.9569 12.5222 18.22 11.6764C18.4831 10.8305 18.6247 9.93115 18.6247 9.0004H18.0974L18.0977 8.99987Z"
            fill="#051D2C"
          />
          <path
            d="M9.62338 16.9453C9.40242 16.9453 9.18726 16.9013 8.96947 16.8124C8.7791 16.7346 8.58741 16.6213 8.39678 16.471C8.06324 16.2084 7.73444 15.8308 7.43465 15.3535C7.20974 14.9957 7.00091 14.5826 6.81555 14.123C6.53711 13.434 6.31115 12.6414 6.15558 11.7771C6.00001 10.9128 5.91458 9.97703 5.91458 9.00013C5.91458 7.86609 6.02981 6.78688 6.236 5.8113C6.39077 5.07961 6.5967 4.4062 6.84191 3.81189C7.02569 3.36628 7.23162 2.96524 7.45284 2.61772C7.61869 2.35695 7.79297 2.1265 7.97227 1.92901C8.24095 1.63265 8.51991 1.41196 8.79571 1.26852C8.93388 1.19654 9.07125 1.14354 9.20862 1.10794C9.34599 1.07261 9.48337 1.05495 9.62338 1.05495V0C9.25951 0 8.90355 0.0746188 8.56975 0.211464C8.27734 0.33117 8.00154 0.497283 7.7434 0.7011C7.29147 1.05785 6.89201 1.52797 6.54133 2.08563C6.0161 2.92226 5.59923 3.95822 5.30946 5.13156C5.02021 6.30489 4.85964 7.61586 4.85938 9.0004C4.85938 10.2059 4.98145 11.3558 5.20373 12.4075C5.37063 13.1965 5.59396 13.93 5.8666 14.591C6.07121 15.087 6.30376 15.5421 6.56269 15.9492C6.75701 16.2545 6.9661 16.5327 7.19075 16.7803C7.52746 17.1512 7.8995 17.4545 8.30792 17.6675C8.512 17.774 8.72505 17.8574 8.94548 17.914C9.16564 17.9707 9.39293 18.0005 9.62311 18.0005V16.9458L9.62338 16.9453Z"
            fill="#051D2C"
          />
          <path
            d="M9.625 1.05468C9.84596 1.05468 10.0611 1.09872 10.2789 1.18757C10.4693 1.26536 10.661 1.37873 10.8516 1.52903C11.1851 1.79164 11.5139 2.16922 11.8137 2.64646C12.0386 3.00426 12.2475 3.41743 12.4328 3.87701C12.7113 4.56598 12.9372 5.35858 13.0928 6.22289C13.2484 7.0872 13.3338 8.02297 13.3338 8.99987C13.3338 10.1339 13.2186 11.2131 13.0124 12.1887C12.8576 12.9204 12.6517 13.5938 12.4065 14.1881C12.2227 14.6337 12.0168 15.0348 11.7955 15.3823C11.6297 15.6431 11.4554 15.8735 11.2761 16.071C11.0074 16.3674 10.7285 16.588 10.4527 16.7315C10.3145 16.8035 10.1771 16.8565 10.0398 16.8921C9.90238 16.9274 9.76501 16.9451 9.625 16.9451V17.9997C9.98887 17.9997 10.3448 17.9251 10.6786 17.7883C10.971 17.6686 11.2468 17.5025 11.505 17.2986C11.9569 16.9419 12.3564 16.4718 12.707 15.9141C13.2323 15.0775 13.6491 14.0415 13.9389 12.8682C14.2282 11.6948 14.3887 10.3839 14.389 8.99934C14.389 7.79384 14.2669 6.64397 14.0446 5.59219C13.8777 4.80329 13.6544 4.06976 13.3818 3.40873C13.1772 2.91277 12.9446 2.45767 12.6857 2.05057C12.4914 1.74524 12.2823 1.46706 12.0576 1.21948C11.7209 0.848492 11.3489 0.545271 10.9405 0.332225C10.7364 0.225702 10.5233 0.142382 10.3029 0.0856929C10.0825 0.0297948 9.85518 0 9.625 0V1.05468Z"
            fill="#051D2C"
          />
          <path d="M9.09766 0.523438V17.4688H10.1523V0.523438" fill="#051D2C" />
          <path d="M1.15234 9.52343H18.0977V8.46875H1.15234" fill="#051D2C" />
          <path
            d="M15.9611 3.25781C15.2448 3.705 14.309 4.08547 13.2287 4.3502C12.1482 4.61545 10.9235 4.76601 9.62672 4.76601C8.32999 4.76601 7.10524 4.61545 6.02472 4.3502C4.94446 4.08547 4.00869 3.705 3.2923 3.25781L2.73438 4.15271C3.57074 4.67372 4.60406 5.08716 5.77344 5.3743C6.94282 5.66117 8.24825 5.82043 9.62672 5.82043C11.0049 5.82043 12.3106 5.66117 13.48 5.3743C14.6494 5.0869 15.6827 4.67346 16.5191 4.15271L15.9611 3.25781Z"
            fill="#051D2C"
          />
          <path
            d="M3.2923 14.7423C4.00869 14.2951 4.94446 13.9146 6.02472 13.6499C7.10524 13.3847 8.32999 13.2341 9.62672 13.2341C10.9235 13.2341 12.1482 13.3847 13.2287 13.6499C14.309 13.9146 15.2448 14.2951 15.9611 14.7423L16.5191 13.8474C15.6827 13.3264 14.6494 12.913 13.48 12.6258C12.3106 12.3389 11.0052 12.1797 9.62672 12.1797C8.24852 12.1797 6.94282 12.3389 5.77344 12.6258C4.60406 12.9132 3.57074 13.3267 2.73438 13.8474L3.2923 14.7423Z"
            fill="#051D2C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5633_20031">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ListViewIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
    >
      <path
        d="M8.71484 2.65625H24.5006"
        stroke="#051D2C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.71484 10H24.5006"
        stroke="#051D2C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.71484 17.3438H24.5006"
        stroke="#051D2C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.64453 2.65625H2.65667"
        stroke="#051D2C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.64453 10H2.65667"
        stroke="#051D2C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.64453 17.3438H2.65667"
        stroke="#051D2C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function GridViewIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_5713_23305)">
        <path
          d="M8.20573 1.42969H1.53906V8.09635H8.20573V1.42969Z"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6823 1.42969H12.0156V8.09635H18.6823V1.42969Z"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6823 11.9062H12.0156V18.5729H18.6823V11.9062Z"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.20573 11.9062H1.53906V18.5729H8.20573V11.9062Z"
          stroke="#76828B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5713_23305">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.109375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function CalenderIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <g clipPath="url(#clip0_6368_36920)">
        <path
          d="M11.7641 1.80859H2.35235C1.60978 1.80859 1.00781 2.41056 1.00781 3.15313V12.5649C1.00781 13.3075 1.60978 13.9094 2.35235 13.9094H11.7641C12.5067 13.9094 13.1087 13.3075 13.1087 12.5649V3.15313C13.1087 2.41056 12.5067 1.80859 11.7641 1.80859Z"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.74805 0.460938V3.15001"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.36914 0.460938V3.15001"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.00781 5.83984H13.1087"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6368_36920">
          <path
            d="M0 0H10.1176C12.3268 0 14.1176 1.79086 14.1176 4V11C14.1176 13.2091 12.3268 15 10.1176 15H0V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function UploadIconBlue(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <g clipPath="url(#clip0_6368_17457)">
        <path
          d="M14.25 9.75V12.75C14.25 13.1478 14.092 13.5294 13.8107 13.8107C13.5294 14.092 13.1478 14.25 12.75 14.25H2.25C1.85218 14.25 1.47064 14.092 1.18934 13.8107C0.908035 13.5294 0.75 13.1478 0.75 12.75V9.75"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 4.5L7.5 0.75L3.75 4.5"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 0.75V9.75"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6368_17457">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H15V15H4C1.79086 15 0 13.2091 0 11V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SaveFileBlue(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <g clipPath="url(#clip0_6368_17463)">
        <path
          d="M12.5708 14.02H2.42584C2.04147 14.02 1.67284 13.8673 1.40105 13.5956C1.12925 13.3238 0.976563 12.9551 0.976562 12.5708V2.42584C0.976562 2.04147 1.12925 1.67284 1.40105 1.40105C1.67284 1.12925 2.04147 0.976563 2.42584 0.976562H10.3969L14.02 4.59975V12.5708C14.02 12.9551 13.8673 13.3238 13.5956 13.5956C13.3238 13.8673 12.9551 14.02 12.5708 14.02Z"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.58713 10.7619V6.41406H3.15234V10.7619"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.15234 0.976562V3.69395H7.50017"
          stroke="#4775D5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6368_17463">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MinusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M2.91602 7.5H11.0827"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function MinusIconRed(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
  <path d="M2.91602 7.5H11.0827" stroke="#E80E0E" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  );
}
export function DragPlusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M2.91602 5.75L1.16602 7.5L2.91602 9.25"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 3.41797L7 1.66797L8.75 3.41797"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 11.582L7 13.332L5.25 11.582"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.084 5.75L12.834 7.5L11.084 9.25"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16602 7.5H12.8327"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 1.66797V13.3346"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function ArrowRightCircleIcon(props){
  return(
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#051D2C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12 16L16 12L12 8" stroke="#051D2C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8 12H16" stroke="#051D2C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}
export function UpArrowCircleIcon(props){
  return(
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M9.99935 18.6901C14.6017 18.6901 18.3327 14.9591 18.3327 10.3568C18.3327 5.7544 14.6017 2.02344 9.99935 2.02344C5.39698 2.02344 1.66602 5.7544 1.66602 10.3568C1.66602 14.9591 5.39698 18.6901 9.99935 18.6901Z" stroke="#4ABEA7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3327 10.3568L9.99935 7.02344L6.66602 10.3568" stroke="#4ABEA7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 13.6901V7.02344" stroke="#4ABEA7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  )
}
export function DownArrowCircleIcon(props){
  return(
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M9.99935 18.6901C14.6017 18.6901 18.3327 14.9591 18.3327 10.3568C18.3327 5.7544 14.6017 2.02344 9.99935 2.02344C5.39698 2.02344 1.66602 5.7544 1.66602 10.3568C1.66602 14.9591 5.39698 18.6901 9.99935 18.6901Z" stroke="#D61E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M6.66602 10.3594L9.99935 13.6927L13.3327 10.3594" stroke="#D61E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M10 7.02344V13.6901" stroke="#D61E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}
export function SearchPlaceholderIcon(props){
  return(
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="106" height="94" viewBox="0 0 106 94" fill="none">
    <g clipPath="url(#clip0_5743_17125)">
      <path d="M98.5779 14.1932C97.8054 12.7608 96.2919 11.787 94.5503 11.787C92.1605 11.787 40.8236 11.787 36.4371 11.787L30.7781 2.29724H27.6017L27.5976 2.28923C27.0059 1.15823 25.8348 0.449219 24.5584 0.449219H3.83645C1.94199 0.449424 0.40625 1.98516 0.40625 3.87962V17.6234L2.84022 20.5928V78.1001C3.47876 78.4389 4.20646 78.6327 4.97978 78.6327H103.163V14.1932H98.5779Z" fill="#4775D5"/>
      <path d="M103.163 78.6308V33.0813L105.597 30.1025C105.597 22.5861 105.597 17.0469 105.597 16.3587C105.597 13.8327 103.55 11.7852 101.024 11.7852H94.5508C97.0768 11.7852 99.1243 13.8327 99.1243 16.3587V78.6308H103.163Z" fill="#4775D5"/>
      <path d="M27.5976 2.28923L32.5672 11.787H39.0402L34.0705 2.28923C33.4788 1.15823 32.3078 0.449219 31.0313 0.449219H24.5586C25.8349 0.449424 27.0059 1.15823 27.5976 2.28923Z" fill="#4775D5"/>
      <path d="M37.2394 25.529L31.7992 15.6228H27.3459C26.7076 14.7324 25.6735 14.1914 24.5588 14.1914H3.83645C1.94199 14.1914 0.40625 15.7271 0.40625 17.6216V80.0228C0.40625 82.5488 2.45377 84.5963 4.97978 84.5963H94.5505C95.6961 84.5963 96.7408 84.172 97.5433 83.476H101.535V26.706H97.6055C96.7952 25.9768 95.7267 25.5292 94.5509 25.5292L37.2394 25.529Z" fill="#D6E2FC"/>
      <path d="M101.024 25.5312H94.5508C97.0766 25.5312 99.1243 27.5788 99.1243 30.1048V80.0248C99.1243 82.5509 97.0768 84.5984 94.5508 84.5984H101.024C103.549 84.5984 105.597 82.5509 105.597 80.0248V30.1048C105.597 27.5788 103.55 25.5312 101.024 25.5312Z" fill="#D6E2FC"/>
      <path d="M27.5978 16.0314L32.5675 25.5292H39.0404L34.0707 16.0314C33.479 14.9004 32.308 14.1914 31.0315 14.1914H24.5586C25.8351 14.1916 27.0061 14.9004 27.5978 16.0314Z" fill="#D6E2FC"/>
      <path d="M79.968 92.8815L83.7335 92.0059L86.5748 87.1483L52.7851 53.7148L46.3266 60.0537C46.2766 60.1028 46.2766 60.1824 46.3266 60.2315C81.1346 94.3946 78.8563 92.3199 79.968 92.8815Z" fill="#4775D5"/>
      <path d="M86.0892 83.9522L54.4662 52.002C54.4172 51.9525 54.3377 51.9525 54.2886 52.002L51.8789 54.4367L81.0921 83.9522C83.2246 86.1066 83.2246 89.5998 81.0921 91.7543C80.6809 92.1698 80.2202 92.5042 79.7296 92.7594C81.7837 93.8278 84.3679 93.4934 86.0892 91.7543C88.2215 89.5998 88.2215 86.1066 86.0892 83.9522Z" fill="#004281"/>
      <path d="M36.1624 42.3182C27.2792 51.0699 27.2792 65.2594 36.1624 74.0111C40.511 78.2953 46.1821 80.4807 51.881 80.5705C57.5798 80.4807 63.2511 78.2951 67.5995 74.0111C76.4827 65.2594 76.4827 51.0699 67.5995 42.3182C58.8426 33.691 45.025 33.5866 36.1624 42.3182Z" fill="#4775D5"/>
      <path d="M67.7782 42.3628C62.8237 37.3546 56.1054 35.2174 49.6445 35.9483C67.8967 38.013 76.0048 60.6938 62.8249 74.0163C59.1323 77.7488 54.4598 79.8861 49.6445 80.4308C56.1054 81.1617 62.8237 79.0245 67.7782 74.0163C76.4254 65.2753 76.4254 51.1036 67.7782 42.3628Z" fill="#004281"/>
      <path d="M40.7382 46.896C34.7071 52.8017 34.7071 62.411 40.7382 68.3168C43.662 71.1798 47.4748 72.6507 51.3146 72.7377C64.8759 72.4302 71.4865 56.292 61.8911 46.8962C55.9969 41.1244 46.7517 41.0077 40.7382 46.896Z" fill="white"/>
      <path d="M63.043 46.9406C59.4015 43.3681 54.3765 41.9648 49.6445 42.7228C61.9831 44.699 66.8961 59.6314 58.039 68.3206C55.6722 70.6425 52.7201 72.0458 49.6445 72.5384C54.3765 73.2963 59.4015 71.8931 63.043 68.3206C69.0513 62.4263 69.0515 52.8352 63.043 46.9406Z" fill="#E3EAFB"/>
    </g>
    <defs>
      <clipPath id="clip0_5743_17125">
        <rect width="105.19" height="94" fill="white" transform="translate(0.40625)"/>
      </clipPath>
    </defs>
  </svg>
  )
}
