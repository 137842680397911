import React, { useState } from "react";
import SideNav from "../../sideNav/sideNav";
import NavBar from "../../NavBar/NavBar";
import { ChakraProvider, Text } from "@chakra-ui/react";
import AdminNav from "../../sideNav/AdminNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Feedback from "../../feedback/Feedback";
import ReusableTextField from "../../reusableComponents/ReuseableTextField";
import ReusableButton from "../../reusableComponents/ReusableButton";
import { changePasswordService } from "../../../Service/service";
import CustomToaster from "../../../utils/toaster/CustomToaster";
import { RotatingLines } from "react-loader-spinner";
import { useMediaQuery } from "@mui/material";

const ChangeUserPassword = () => {
  const matches = useMediaQuery("(max-width:766px)");
  const matches2 = useMediaQuery("(min-width:1366px)");

  const nav = useNavigate()
  const [loading, setloading] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [errorToast, setErrorToast] = useState(false);
  // State variables
  const [existingPassword, setExistingPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [existingPasswordError, setExistingPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // Function to handle input changes
  const handleExistingPasswordChange = (e) => {
    setExistingPassword(e.target.value);
    setExistingPasswordError("");
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setNewPasswordError("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Basic validation
    if (existingPassword === "") {
      setExistingPasswordError("Existing password is required.");
      return;
    }
    if (newPassword === "") {
      setNewPasswordError("New password is required.");
      return;
    }
    if (newPassword.length < 8) {
      setNewPasswordError("New password must be at least 8 characters long.");
      return;
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("New password and confirm password do not match.");
      return;
    }
    setloading(true)
    // Proceed with password change logic
    // Reset form fields
    const res = await changePasswordService(existingPassword,newPassword,confirmPassword)
    if(res.success){
      setExistingPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setloading(false)
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
    }
    else {
      setloading(false)
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
    }
    // Optionally, perform other actions such as sending data to the server
  };
  const handleCloseErrorToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorToast(false);
  };
  return (
    <>
     {loading && (
          <div className="overlay">
            <div className="loaderImage-container">
              {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
              <RotatingLines
                strokeColor="#051D2C"
                strokeWidth="4"
                animationDuration="0.85"
                width="50"
                visible={true}
              />
            </div>
          </div>
        )}
    <CustomToaster
        open={errorToast}
        onClose={handleCloseErrorToast}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <Feedback />
      <NavBar />
      <div className={"persona-container-light"}>
        <div className="mainContainer">
          {" "}
          <input
            type="checkbox"
            id="sideNavDrawer-toggle"
            name="sideNavDrawer-toggle"
          />
          <label
            htmlFor="sideNavDrawer-toggle"
            id="sideNavDrawer-toggle-label"
          ></label>
          <AdminNav />
          <div className="mainContainer-right">
            {" "}
            <div className="mainContainer-Inner">
              <div className="d-flex justify-content-between mb-3">
                <ChakraProvider>
                  <Text color='#051D2C' fontWeight={"bold"} fontSize={"1.4rem"}>
                    Change your password
                  </Text>
                </ChakraProvider>
              </div>
              <form onSubmit={handleSubmit}>
                <div style={{ width: matches2?"50%":"100%" }}>
                  <ReusableTextField
                    label={"Enter existing password"}
                    type={"password"}
                    value={existingPassword}
                    onChange={handleExistingPasswordChange}
                    error={!!existingPasswordError}
                    helperText={existingPasswordError}
                    showEyeIcon={true}
                  />
                </div>
                <div style={{flexDirection:matches?"column":"row",width: matches2?"50%":"100%"}} className="d-flex mt-3 gap-4">
                  <ReusableTextField
                    label={"Enter new password"}
                    type={"password"}
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    error={!!newPasswordError}
                    helperText={newPasswordError}
                    showEyeIcon={true}
                  />
                  <ReusableTextField
                    label={"Confirm new password"}
                    type={"password"}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                    showEyeIcon={true}
                  />
                </div>
                <div style={{ width: matches2?"50%":"100%" }} className="d-flex justify-content-end mt-4 mb-1">
                  <p style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                    Forgot your password?{" "}
                    <span onClick={()=>{
                      localStorage.clear()
                      nav('/forgot-password')
                    }} style={{ cursor: "pointer", color: "#4775D5" }}>
                      Reset password via email
                    </span>
                  </p>
                </div>
                <div style={{ width: matches2?"50%":"100%" }} className="d-flex justify-content-end">
                  <ReusableButton buttonText={"SAVE"} type="submit" />
                </div>
              </form>
            </div>
          </div>{" "}
        </div>{" "}
      </div>
    </>
  );
};

export default ChangeUserPassword;
