import React, { useEffect, useState } from "react";
import NavBar from "../../NavBar/NavBar";
import SideNav from "../../sideNav/sideNav";
import {
  Box,
  ChakraProvider,
  Flex,
  Img,
  Spacer,
  Square,
  Stack,
  Text,
} from "@chakra-ui/react";
import arrowrightcircle from "../../../Images/arrow-right-circle.svg";
import { Link, useNavigate } from "react-router-dom";
import "./Usage.css";
import { getUsageStatsService } from "../../../Service/service";
// import LoadingSpinner from "../../../Assests/loaderspinner.gif";
import AdminNav from "../../sideNav/AdminNav";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../../feedback/Feedback";
import { ArrowRightCircleIcon, DownArrowCircleIcon, UpArrowCircleIcon } from "../../../customIcons/customIcons";
import KeywordAnalysisChart from "./KeywordAnalysisChart";
import UsageGraph from "./UsageGraph";

const UsageStatistics = () => {
  const nav = useNavigate();
  const [usageStats, setUsageStats] = useState();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        setloading(true);
        const res = await getUsageStatsService();
        if (res.success) {
          setUsageStats(res.data);
          setloading(false);
        }
      } catch (error) {
        setloading(false);

        console.log(
          "🚀 ~ file: UsageStatistics.js:30 ~ getData ~ error:",
          error
        );
      }
    };
    getData();
  }, []);

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback />
      <NavBar />
      <div className={"persona-container-light"}>
        <div className="mainContainer">
          {" "}
          <input type="checkbox" id="sideNavDrawer-toggle" name="sideNavDrawer-toggle"/>
          <label for="sideNavDrawer-toggle" id="sideNavDrawer-toggle-label"></label>
          <AdminNav />
          <div className="usageContainer">
            <div className="d-flex justify-content-between">
              <ChakraProvider>
                <Text color='#051D2C' fontWeight={"bold"}  fontSize={"1.4rem"}>
                General
                </Text>
              </ChakraProvider>
            </div>
            <ChakraProvider>
              <Stack w={{ base: "100%", lg: "90%" }}>
                <Stack
                  spacing={6}
                  w="full"
                  direction={{ base: "column", md: "row" }}
                  color="#051D2C"
                >
                  <Box
                    w={{ base: "100%", md: "25%" }}
                    h="300px"
                    border={"1.5px solid #F0F0F0"}
                    borderRadius={'0.571rem'}
                    bg="#F0F0F0"
                    p="5"
                  >
                    <Stack h="full" justifyContent={"space-between"}>
                      <div>
                      <Text mb="0" fontSize={"sm"}>
                          Total users
                        </Text>
                      <Text fontSize={"2.5rem"}>{usageStats?.users}</Text>
                      </div>
                      <Stack alignItems={"end"} direction="row">
                       <div>
                       <Text  mb="0" fontSize={"sm"} marginBottom={'0.5rem'}>
                        Previous period
                        </Text>
                       <Text  mb="0" fontSize={"1rem"} fontWeight={'400'}>
                        --
                        </Text>
                        </div>
                        <Spacer />
                       
                        <span
                         onClick={() =>
                          nav("/admin/users", { state: "users" })
                        }
                        role="button"
                        >
                          <ArrowRightCircleIcon/>
                        </span>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box
                    w={{ base: "100%", md: "50%" }}
                    h="300px"
                    border={"1.5px solid #4775D5"}
                    borderRadius={'0.571rem'}
                    bg="#4775D5"
                    color={'#fff'}
                    p="5"
                  >
                    <Stack>
                      <div>
                      <Text mb="0" fontSize={"sm"}>
                      Total keywords analysed
                        </Text>
                      <Text fontSize={"2.5rem"}>{usageStats?.totalKeywords}</Text>
                      </div>
                      <div>
                        <KeywordAnalysisChart
                        keywordData={usageStats?.monthlyKeywords.map(function(item) {
                          return item.totalKeywords;
                        })}
                        />
                        </div>
                    </Stack>
                  </Box>
                  <Box
                    w={{ base: "100%", md: "25%" }}
                    h="300px"
                    border={"1.5px solid #F0F0F0"}
                    borderRadius={'0.571rem'}
                    bg="#F0F0F0"
                    p="5"
                  >
                    <Stack h="full" justifyContent={"space-between"}>
                      <div>
                      <Text mb="0" fontSize={"sm"}>
                      Total Personas
                        </Text>
                      <Text fontSize={"2.5rem"}>{usageStats?.personas}</Text>
                      </div>
                      <Stack alignItems={"end"} direction="row">
                       <div>
                       <Text  mb="0" fontSize={"sm"} marginBottom={'0.5rem'}>
                        Previous period
                        </Text>
                       <Text  mb="0" fontSize={"1rem"} fontWeight={'400'}>
                        --
                        </Text>
                        </div>
                        <Spacer />
                       
                        <span
                         onClick={() =>
                          nav("/persona")
                        }
                        role="button"
                        >
                          <ArrowRightCircleIcon/>
                        </span>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
                <Stack
                  spacing={6}
                  w="full"
                  direction={{ base: "column", md: "row" }}
                  paddingTop="6"
                  color="#051D2C"
                >
                  
                   <Box
                    w={{ base: "100%", md: "50%" }}
                    h="300px"
                    border={"1.5px solid #CED3D9"}
                    borderRadius={'0.571rem'}
                    bg="#FFF"
                    p="5"
                  >
                    <Stack h="full" justifyContent={"space-between"}>
                      <div>
                      <Text mb="0" fontSize={"sm"}>
                      Usage
                        </Text>
                      <Text mb={0} fontSize={"2.5rem"}>55%</Text>
                      </div>
                      <UsageGraph/>
                    </Stack>
                  </Box>
                  <Box
                    w={{ base: "100%", md: "25%" }}
                    h="300px"
                    border={"1.5px solid #0F2043"}
                    borderRadius={'0.571rem'}
                    bg="#0F2043"
                    color={'#FFFFFF'}
                    p="5"
                  >
                    <Stack h="full" justifyContent={"space-between"}>
                      <div>
                      <Text mb="0" fontSize={"sm"}>
                      Documents uploaded
                        </Text>
                      <Text fontSize={"2.5rem"}>{usageStats?.documents}</Text>
                      </div>
                      <Stack alignItems={"end"} direction="row">
                       <div>
                       <Text  mb="0" fontSize={"sm"} marginBottom={'0.5rem'}>
                        Previous period
                        </Text>
                       <Text  mb="0" fontSize={"1rem"} fontWeight={'400'}>
                       --
                        </Text>
                        </div>
                           
                      </Stack>
                    </Stack>
                  </Box>
                  <Box
                    w={{ base: "100%", md: "25%" }}
                    h="300px"
                    border={"1.5px solid #CED3D9"}
                    borderRadius={'0.571rem'}
                    bg="#fff"
                    p="5"
                  >
                    <Stack  justifyContent={"space-between"}>
                      <Text mb="0.929rem" fontSize={"1.143rem"} color={"#76828B"}>
                      Keyword trends
                        </Text>
                      </Stack>
                      <Stack direction={"row"} marginTop={'0.929rem'} fontSize={"1rem"}>
                        <span>
                          <UpArrowCircleIcon/>
                          </span>
                          <span>
                          Keyword
                          </span>
                      </Stack>
                      <Stack direction={"row"} marginTop={'0.929rem'} fontSize={"1rem"}>
                        <span>
                          <UpArrowCircleIcon/>
                          </span>
                          <span>
                          Keyword
                          </span>
                      </Stack>
                      <Stack direction={"row"} marginTop={'0.929rem'} fontSize={"1rem"}>
                        <span>
                          <UpArrowCircleIcon/>
                          </span>
                          <span>
                          Keyword
                          </span>
                      </Stack>
                      <Stack direction={"row"} marginTop={'0.929rem'} fontSize={"1rem"}>
                        <span>
                          <DownArrowCircleIcon/>
                          </span>
                          <span>
                          Keyword
                          </span>
                      </Stack>
                      <Stack direction={"row"} marginTop={'0.929rem'} fontSize={"1rem"}>
                        <span>
                          <DownArrowCircleIcon/>
                          </span>
                          <span>
                          Keyword
                          </span>
                      </Stack>
                      <Stack direction={"row"} marginTop={'0.929rem'} fontSize={"1rem"}>
                        <span>
                          <DownArrowCircleIcon/>
                          </span>
                          <span>
                          Keyword
                          </span>
                      </Stack>
                  </Box>
                </Stack>
              </Stack>
            </ChakraProvider>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default UsageStatistics;
