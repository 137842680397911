import { Button, ChakraProvider, Heading, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../Images/logo-full.svg";
import "../SignIn/Login.css";
import { approveUserService } from "../../Service/service";
import Swal from "sweetalert2";
import ReusableTextField from "../reusableComponents/ReuseableTextField";
import ReusableButton from "../reusableComponents/ReusableButton";
import { Typography } from "@mui/material";
import CustomToaster from "../../utils/toaster/CustomToaster";
const ApproveUser = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [loading, setloading] = useState(false);
  const [isShowApproveButton, setIsShowApproveButton] = useState(false);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const name = searchParams.get("name");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const approveRequestHandler = async () => {
    try {
      setloading(true);
      const res = await approveUserService(token, email, name);
      if (res.success) {
        setloading(false);
        setIsShowApproveButton(true);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(res.message);
      } else {
        setloading(false);

        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: ApproveUser.js:17 ~ approveRequestHandler ~ error:",
        error
      );
    }
  };
  return (
    <div className="signupContainer">
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <div className="signupContainer-inner">
        {!isShowApproveButton ? (
          <>
            <div className="signupContainer-header">
              <img src={logo} alt="Logo" className="logo" />
            </div>
            <Stack spacing={1} pt={4} textAlign={"center"}>
              <Text fontWeight={"600"} fontSize={"xl"} color={"#0D1821"}>
                Approve User
              </Text>
            </Stack>
            <div className="mb-3">
              {/* Username */}
              <ReusableTextField
                label="Username"
                name="username"
                value={name}
                placeholder="Enter username"
                required
                disabled
              />
            </div>
            {/* Email */}
            <div className="mb-3">
              <ReusableTextField
                label="Email"
                name="email"
                value={email}
                placeholder="Enter email"
                required
                disabled
              />
            </div>
            {/* Approve button */}
            <div className="mb-3">
              <ReusableButton
                onClick={approveRequestHandler}
                loading={loading}
                buttonText="Approve"
                isDisabled={!email || !name}
                height={"55px"}
                width="100%"
              />
            </div>
          </>
        ) : (
          <Typography
            marginBottom={"0 !important"}
            textAlign={"center"}
            fontWeight={"semibold"}
          >
            You can now close this tab
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ApproveUser;
