import React, { useEffect, useRef, useState } from "react";
import "../Persona/Persona.css";
import Swal from "sweetalert2";
import key from "../../Images/key.svg";
import magic from "../../Images/magic.svg";
import { VscKey } from "react-icons/vsc";
import {
  Button as ChakraButton,
  ChakraProvider,
  Heading,
  Image,
  Modal as ChakraModal,
  //   Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  RadioGroup,
  Radio,
  useDisclosure,
  Img,
  Input,
} from "@chakra-ui/react";
import SideNav from "../sideNav/sideNav";
import NavBar from "../NavBar/NavBar";
import { RotatingLines } from "react-loader-spinner";
import { FaUserCircle } from "react-icons/fa";
import {
  addCollaboratorService,
  deletePersonaCollaboratorService,
  getAllUsersService,
  getPersonaEmailsService,
  getPersonalCollaboratorsService,
  makePersonaOwnerService,
} from "../../Service/service";
import moment from "moment";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { useContext } from "react";
import PersonaChatContext from "../../context/ChatContext";
import Feedback from "../feedback/Feedback";
import emailIcon from "../../Images/email.png";
import alertIcon from "../../Images/alert.svg";
import { AiOutlineUser } from "react-icons/ai";
import CustomToaster from "../../utils/toaster/CustomToaster";
const PersonaTeam = () => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1.5px solid #051D2C", // Customize the border
      borderRadius: "4px", // Customize the border radius
      boxShadow: "none", // Add a box shadow when focused
      cursor: "pointer",
    }),
    container: (provided) => ({
      ...provided,
      cursor: "pointer",
      width: "100%",
    }),
    option: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
  };

  const { updateChatArray } = useContext(PersonaChatContext);

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const [loading, setLoading] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [personaDetails, setPersonaDetails] = useState({});
  console.log(personaDetails);
  const [options, setOptions] = useState([]);
  const [userEmailOptions, setUserEmailOptions] = useState([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState(null);
  const [collaboratorEmail, setCollaboratorEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [email, setemail] = useState();
  const [error, setErrors] = useState({});
  const [userType, setUserType] = useState("existing");
  const [value, setValue] = useState("Collaborator");
  const [personaEmailsList, setPersonaEmailsList] = useState({});
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [selectedPersonaEmailIds, setSelectedPersonaEmailIds] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenChangeOwner,
    onOpen: onOpenChangeOwner,
    onClose: onCloseChangeOwner,
  } = useDisclosure();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // invite user fucntionality
  const handleSelectChange = (selectedOptions) => {
    // Extract the IDs from the selected options
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedPersonaEmailIds(selectedIds);
  };

  const handleInputChangePersonas = (inputValue, actionMeta) => {
    // Check if the action type is "remove-value" (when the cross icon is clicked)
    if (actionMeta.action === "remove-value") {
      const removedId = actionMeta.removedValue.value;
      setSelectedPersonaEmailIds((prevSelectedPersonaEmailIds) =>
        prevSelectedPersonaEmailIds.filter((id) => id !== removedId)
      );
    }
  };

  const formatDataForReactSelect = (data) => {
    const options = [];

    for (const userEmail in data) {
      const personas = data[userEmail];
      const userOption = {
        label: userEmail,
        value: userEmail,
        isDisabled: true, // Disable the user email as an option
      };

      const personaOptions = personas.map((persona) => ({
        label: persona.PersonaEmail,
        value: persona.PersonaId,
      }));

      options.push(userOption, ...personaOptions);
    }

    return options;
  };

  const addCollaboratorHandler = async () => {
    let errors = {};
    if (userType === "existing" && email === undefined) {
      errors.userEmailError = true;
    }
    if (userType === "new" && !selectedUserEmail) {
      errors.userEmailError = true;
    }
    // if (userType === "new" && value === undefined) {
    //   errors.roleError = true;
    // }
    // if (
    //   userType === "new" &&
    //   value === "Collaborator" &&
    //   selectedPersonaEmailIds.length == 0
    // ) {
    //   errors.personaEmailError = true;
    // }
    // if (userType === "existing" && selectedPersonaEmailIds.length == 0) {
    //   errors.personaEmailError = true;
    // }

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      console.log(errors);
    } else {
      setErrors({});
      try {
        setLoading(true);
        const res = await addCollaboratorService(
          userType == "existing" ? email?.value : selectedUserEmail,
          [activePersona?._id],
          userType == "new" ? true : false,
          userType == "new" ? "Collaborator" : null
        );
        if (res.success) {
          setemail();
          setSelectedUserEmail("");
          setUserType("existing");
          setValue();
          setSelectedPersonaEmailIds([]);
          setErrors({});
          setRerender(!rerender);
          onClose();
          setLoading(false);
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
          setSnackbarMessage(res.message);
          // Swal.fire({
          //   icon: "success",
          //   title: res.message,
          // });
        } else {
          setLoading(false);
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage(res.message);
          // Swal.fire({
          //   icon: "error",
          //   title: res.message,
          // });
        }
      } catch (error) {
        setLoading(false);
        console.log(
          "🚀 ~ file: UsersDataTable.js:262 ~ addCollaboratorHandler ~ error:",
          error
        );
      }
    }
  };

  const getPersonaEmailsHandler = async (email) => {
    const response = await getPersonaEmailsService(email);
    if (response.success) {
      setPersonaEmailsList(response.data);
    }
  };
  const getUsersDataHandler = async () => {
    const res = await getAllUsersService("All");
    setUsersData(res.data);
    const newOptions = res?.data?.map((user) => ({
      label: user.email,
      value: user.email,
    }));
    setUserEmailOptions(newOptions);
  };

  const handleInputChange = (inputValue) => {
    const filtered = userEmailOptions.filter((option) =>
      option.label.includes(inputValue)
    );

    setFilteredOptions(filtered);
  };

  const handleChange = (selectedOption) => {
    setemail(selectedOption);
    getPersonaEmailsHandler(selectedOption?.value);
  };
  const formattedData = formatDataForReactSelect(personaEmailsList);

  useEffect(() => {
    getPersonaEmailsHandler();
    getUsersDataHandler();
  }, []);

  // persona team fucntions
  const handleChangePersonaOwner = (option) => {
    setSelectedUserEmail(option);
  };
  const makePersonaOwnerHandler = async () => {
    try {
      setLoading(true);
      const res = await makePersonaOwnerService(
        selectedUserEmail?.value,
        personaDetails?.personaOwner !== null ? true : false
      );
      if (res.success) {
        onCloseChangeOwner();
        setSelectedUserEmail();
        setShowModal(false);
        setRerender(!rerender);
        setSelectedUserEmail(null);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(res.message);
        // Swal.fire({
        //   icon: "success",
        //   title: res.message,
        // });
      } else {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
        // Swal.fire({
        //   icon: "error",
        //   title: res.message,
        // });
      }
    } catch (error) {
      setLoading(false);
      console.log(
        "🚀 ~ file: PersonaTeam.js:82 ~ makePersonaOwnerHandler ~ error:",
        error
      );
    }
  };
  const removePersonaCollaboratorHandler = async () => {
    try {
      setLoading(true);
      const res = await deletePersonaCollaboratorService(collaboratorEmail);
      if (res.success) {
        setShowModal2(false);
        setRerender(!rerender);
        setCollaboratorEmail(null);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(res.message);
        // Swal.fire({
        //   icon: "success",
        //   title: res.message,
        // });
      } else {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
        // Swal.fire({
        //   icon: "error",
        //   title: res.message,
        // });
      }
    } catch (error) {
      setLoading(false);
      console.log(
        "🚀 ~ file: PersonaTeam.js:82 ~ makePersonaOwnerHandler ~ error:",
        error
      );
    }
  };
  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        setLoading(true);
        const res = await getPersonalCollaboratorsService();
        if (res.success) {
          setLoading(false);
          setPersonaDetails(res.data);
          const concatenatedArray = [
            res.data.personaCreator,
            ...res.data.personaCollaborators,
          ];
          const newOptions = concatenatedArray?.map((user) => ({
            label: user.email,
            value: user.email,
          }));
          setOptions(newOptions);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(
          "🚀 ~ file: PersonaTeam.js:49 ~ fetchCollaborators ~ error:",
          error
        );
      }
    };

    fetchCollaborators();
  }, [rerender, updateChatArray]);

  const activePersonaOptions = [
    {
      value: activePersona?.epClientEmail,
      label: activePersona?.epClientEmail,
    },
  ];
  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback />
      <NavBar />
      <div className="persona-container-light ">
        <div className="mainContainer">
          {" "}
          <input
            type="checkbox"
            id="sideNavDrawer-toggle"
            name="sideNavDrawer-toggle"
          />
          <label
            for="sideNavDrawer-toggle"
            id="sideNavDrawer-toggle-label"
          ></label>
          <SideNav isShowMenu={false} />
          <div className="mainContainer-right">
            {" "}
            <div className="mainContainer-Inner">
              <ChakraProvider>
                <Stack>
                  {/* header */}
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <h1>Persona Team</h1>
                    </Stack>
                    {parsedToken?.data.role == "Admin" ||
                    parsedToken?.data.role == "SuperAdmin" ||
                    parsedToken?.data?._id ===
                      personaDetails?.personaOwner?._id ? (
                      <ChakraButton
                        onClick={onOpen}
                        size="md"
                        fontWeight={"500"}
                        fontSize={"1rem"}
                        type="submit"
                        borderRadius={"5px"}
                        bg="#4775D5"
                        w="fit-content"
                        color="white"
                        _hover={{ bg: "#051D2C" }}
                        textTransform={"uppercase"}
                      >
                        Invite Team Member
                      </ChakraButton>
                    ) : null}
                  </Stack>
                  {/* creator and owner */}
                  <Stack pt={6} spacing={4}>
                    <Stack
                      borderRadius={"0.571rem"}
                      border="1px solid #051D2C"
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      px={5}
                      py={2.5}
                    >
                      <Stack w="40%" direction="row" alignItems={"center"}>
                        {personaDetails?.personaCreator?.image === null ? (
                          <FaUserCircle
                            style={{
                              height: "2.5rem",
                              width: "2.5rem",
                            }}
                            className="brook-img"
                          />
                        ) : (
                          <Image
                            borderRadius="full"
                            boxSize="2.5rem"
                            src={
                              personaDetails?.personaCreator?.resizedImage
                                ? `${process.env.REACT_APP_IMAGES_URL}/Users/${
                                    personaDetails?.personaCreator?.resizedImage
                                  }?random=${Math.random()}`
                                : `${process.env.REACT_APP_IMAGES_URL}/Users/${
                                    personaDetails?.personaCreator?.image
                                  }?random=${Math.random()}`
                            }
                            alt="user-avatar"
                          />
                        )}
                        <Text fontSize={"md"} fontWeight={"semibold"}>
                          {personaDetails?.personaCreator?.name}
                        </Text>
                      </Stack>
                      <Stack w="20%" direction="row" alignItems={"center"}>
                        <Image
                          borderRadius="full"
                          // boxSize="1.5rem"
                          src={magic}
                          alt="user-avatar"
                        />
                        <Text fontWeight={"400"}>Creator</Text>
                        {personaDetails?.personaCreator?.email ===
                          personaDetails?.personaOwner?.email && <VscKey />}
                      </Stack>
                      <Text w="15%" color="#3C3C46">
                        21/04/2023
                      </Text>
                      <Text
                        cursor={"pointer"}
                        onClick={onOpenChangeOwner}
                        w="15%"
                        fontWeight={"semibold"}
                        fontSize={"sm"}
                      >
                        {personaDetails?.personaCreator?.email ===
                          personaDetails?.personaOwner?.email && "CHANGE OWNER"}
                      </Text>
                    </Stack>
                    {personaDetails?.personaCreator?.email !==
                      personaDetails?.personaOwner?.email && (
                      <Stack
                        borderRadius={"0.571rem"}
                        border="1px solid #051D2C"
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        px={5}
                        py={2.5}
                      >
                        {personaDetails?.personaOwner !== null ? (
                          <Stack w="40%" direction="row" alignItems={"center"}>
                            {personaDetails?.personaOwner?.image === null ? (
                              <FaUserCircle
                                style={{
                                  height: "2.5rem",
                                  width: "2.5rem",
                                }}
                                className="brook-img"
                              />
                            ) : (
                              <Image
                                borderRadius="full"
                                boxSize="2.5rem"
                                src={`${
                                  process.env.REACT_APP_IMAGES_URL
                                }/Users/${
                                  personaDetails?.personaOwner?.image
                                }?random=${Math.random()}`}
                                alt="user-avatar"
                              />
                            )}
                            <Text fontSize={"md"} fontWeight={"semibold"}>
                              {personaDetails?.personaOwner?.name}
                            </Text>
                          </Stack>
                        ) : parsedToken?.data.role == "Admin" ||
                          parsedToken?.data.role == "SuperAdmin" ? (
                          <Stack w="40%">
                            <Stack className="dropdown-container">
                              <Select
                                styles={customStyles}
                                options={options}
                                value={selectedUserEmail}
                                onChange={(option) => {
                                  handleChangePersonaOwner(option);
                                  setShowModal(true);
                                }}
                                isSearchable={true}
                                placeholder="Search a persona Owner"
                              />
                            </Stack>
                          </Stack>
                        ) : (
                          <Stack w="40%" direction="row" alignItems={"center"}>
                            <FaUserCircle
                              style={{
                                height: "2.5rem",
                                width: "2.5rem",
                              }}
                              className="brook-img"
                            />
                            <Text fontSize={"md"} fontWeight={"light"}>
                              No persona owner yet
                            </Text>
                          </Stack>
                        )}

                        <Stack w="20%" direction="row" alignItems={"center"}>
                          <VscKey />
                          <Text fontWeight={"400"}>Owner</Text>
                        </Stack>
                        <Text w="15%" color="#3C3C46">
                          21/04/2023
                        </Text>
                        <Text
                          cursor={"pointer"}
                          onClick={onOpenChangeOwner}
                          w="15%"
                          fontWeight={"semibold"}
                          fontSize={"sm"}
                        >
                          {parsedToken?.data.role == "Admin" ||
                            (parsedToken?.data.role == "SuperAdmin" &&
                              personaDetails?.personaOwner !== null &&
                              "CHANGE OWNER")}
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                  {/* collaborators */}
                  {personaDetails?.personaCollaborators?.length > 0 && (
                    <>
                      <div className="person-team">
                        <div className="person-team-header">
                          <div className="column">Title</div>
                          <div className="column columnType">Type</div>
                          <div className="column columnInvitedBy">
                            Invited By
                          </div>
                          <div className="column columnDate">Invited on</div>
                          <div className="column columnRemove">X</div>
                        </div>

                        {personaDetails?.personaCollaborators?.map(
                          (item, index) => (
                            <div className="person-team-row">
                              <div className="column">
                                {item?.image === null ? (
                                  <FaUserCircle
                                    style={{
                                      height: "2rem",
                                      width: "2rem",
                                    }}
                                    className="brook-img"
                                  />
                                ) : (
                                  <Image
                                    borderRadius="full"
                                    boxSize="2rem"
                                    src={
                                      item?.resizedImage
                                        ? `${
                                            process.env.REACT_APP_IMAGES_URL
                                          }/Users/${
                                            item?.resizedImage
                                          }?random=${Math.random()}`
                                        : `${
                                            process.env.REACT_APP_IMAGES_URL
                                          }/Users/${
                                            item?.image
                                          }?random=${Math.random()}`
                                    }
                                    alt="user-avatar"
                                  />
                                )}
                                <Text fontSize={"sm"} fontWeight={"semibold"}>
                                  {item.name ? item.name : "No username yet"}
                                </Text>
                              </div>
                              <div className="column columnType">
                                Collaborator
                              </div>
                              <div className="column columnInvitedBy">
                                {item.invitedBy
                                  ? item.invitedBy
                                  : "Brook Zimmatore"}
                              </div>
                              <div className="column columnDate">
                                {moment(item.timestamp).format("DD/MM/YYYY")}
                              </div>

                              <div className="column columnRemove">
                                {parsedToken?.data?.role === "Admin" ||
                                parsedToken?.data?.role === "SuperAdmin" ||
                                parsedToken?.data?._id ===
                                  personaDetails?.personaOwner?._id ? (
                                  <ChakraButton
                                    rounded={"md"}
                                    border={"1.5px solid #051D2C"}
                                    _hover={{ bg: "#051D2C", color: "white" }}
                                    variant={"outline"}
                                    fontSize={"sm"}
                                    onClick={() => {
                                      setCollaboratorEmail(item.email);
                                      setShowModal2(true);
                                    }}
                                  >
                                    X
                                  </ChakraButton>
                                ) : null}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                </Stack>
              </ChakraProvider>
            </div>
          </div>
        </div>
      </div>

      {/* confirm assign persona modal */}
      <div>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header
            style={{ border: "none", paddingBottom: 0 }}
            closeButton
          ></Modal.Header>
          <Modal.Body style={{ padding: 0 }}>
            <div className="d-flex flex-column gap-4 px-5  align-items-center">
              <img src={alertIcon} />
              <h6
                style={{
                  color: "#051D2C",
                  fontSize: "20px",
                  textAlign: "center",
                  lineHeight: "1.4",
                }}
              >
                Are you sure you want to change persona owner?
              </h6>
            </div>
            <div
              style={{ borderTop: "1px solid #CED3D9" }}
              className="mt-4 d-flex gap-1 align-items-center justify-content-between"
            >
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "1px solid #CED3D9",
                }}
              >
                <Button
                  onClick={() => {
                    setShowModal(false);
                  }}
                  style={{ color: "#051D2C", fontWeight: "600" }}
                  variant="text"
                >
                  CANCEL
                </Button>
              </div>
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={makePersonaOwnerHandler}
                  style={{ color: "#C51333", fontWeight: "600" }}
                  variant="text"
                >
                  YES
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/* confirm delete collaborator */}
      <div>
        <Modal show={showModal2} onHide={() => setShowModal2(false)} centered>
          <Modal.Header style={{ border: "none" }} closeButton>
            {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "1.5rem", fontSize: "1.3rem" }}>
              Are you sure you want to remove persona collaborator?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal2(false);
              }}
            >
              No
            </Button>
            <Button variant="danger" onClick={removePersonaCollaboratorHandler}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* invite user modal */}
      <ChakraProvider>
        <ChakraModal
          isCentered
          isOpen={isOpen}
          onClose={() => {
            setemail();
            setSelectedUserEmail("");
            setUserType("existing");
            setValue();
            setSelectedPersonaEmailIds([]);
            setErrors({});
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              alignItems={"center"}
              fontSize={"xl"}
              fontWeight={"bold"}
            >
              Invite user to Emissary
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack pb={4} spacing={6}>
                {/* user type */}
                <div>
                  <RadioGroup
                    size={"md"}
                    colorScheme="blackAlpha"
                    onChange={(user) => {
                      if (user == "new") {
                        if (value !== "Collaborator") {
                          setErrors({ ...error, personaEmailError: false });
                        }
                      } else {
                        setErrors({ ...error, roleError: false });
                      }
                      // console.log(
                      //   "🚀 ~ file: NavBar.js:647 ~ NavBar ~ value:",
                      //   user
                      // );
                      setUserType(user);
                    }}
                    value={userType}
                  >
                    <Stack spacing={6} direction="row">
                      <Radio value="existing">Existing User</Radio>
                      <Radio value="new">New User</Radio>
                    </Stack>
                  </RadioGroup>
                </div>
                {/* user email */}
                <div>
                  <div
                    className="d-flex align-items-center mb-2"
                    style={{ color: "#051D2C" }}
                  >
                    <span>
                      <Img src={emailIcon} />
                    </span>{" "}
                    <span style={{ paddingLeft: "10px" }}>
                      Invite with email
                    </span>
                  </div>
                  {userType == "existing" ? (
                    <>
                      <Select
                        styles={{ border: "1.5px solid #051D2C" }}
                        options={userEmailOptions}
                        value={email}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isSearchable={true}
                        placeholder="Search and select an email..."
                      />
                      {error.userEmailError === true && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          This field is required
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <div>
                        <input
                          type="email"
                          className="form-control"
                          name="comapnyName"
                          style={{
                            height: "38px",
                            border: "1.5px solid #1D1E24",
                          }}
                          placeholder="Enter user email"
                          value={selectedUserEmail}
                          onChange={(e) => setSelectedUserEmail(e.target.value)}
                        />
                        {error.userEmailError === true && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            This field is required
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* userrole */}
                {userType == "new" && (
                  <div>
                    <div
                      className="d-flex align-items-center mb-2"
                      style={{ color: "#051D2C" }}
                    >
                      <span>
                        <AiOutlineUser fontSize={"1.286rem"} />
                      </span>
                      <span style={{ paddingLeft: "10px" }}>User Role</span>
                    </div>
                    <Input disabled={true} value="Collaborator" />
                    {/* <RadioGroup
                        size={"md"}
                        colorScheme="blackAlpha"
                        onChange={setValue}
                        value={value}
                      >
                        <Stack spacing={6} direction="row">
                          <Radio checked={true} value="Collaborator">Collaborator</Radio>
                        </Stack>
                      </RadioGroup>
                      {error.roleError === true && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          This field is required
                        </div>
                      )} */}
                  </div>
                )}
                {/* persona emails */}
                <div className="">
                  <label className="form-label" style={{ color: "#051D2C" }}>
                    Persona Email{" "}
                  </label>
                  <Select
                    isDisabled={true}
                    styles={{ cursor: "pointer" }}
                    // isMulti={true}
                    defaultValue={activePersonaOptions[0]}
                    // onChange={handleSelectChange}
                    // onInputChange={handleInputChangePersonas}
                    options={activePersonaOptions}
                  />
                  {error.personaEmailError === true && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      This field is required
                    </div>
                  )}
                </div>
                <ChakraButton
                  onClick={addCollaboratorHandler}
                  alignSelf={"end"}
                  borderRadius={"5px"}
                  fontSize={"sm"}
                  size="md"
                  bg="#4775D5"
                  color="white"
                  // isDisabled={isDisabledButton}
                  _hover={{ bg: "#051D2C" }}
                >
                  SEND INVITE
                </ChakraButton>
              </Stack>
            </ModalBody>
          </ModalContent>
        </ChakraModal>
      </ChakraProvider>
      {/* change owner modal */}

      <ChakraProvider>
        <ChakraModal
          isCentered
          isOpen={isOpenChangeOwner}
          onClose={() => {
            onCloseChangeOwner();
            setSelectedUserEmail();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              alignItems={"center"}
              fontSize={"xl"}
              fontWeight={"bold"}
            >
              Change Persona Owner
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody py={4} alignItems={"center"}>
              <Stack w="100%" className="dropdown-container">
                <Select
                  styles={customStyles}
                  options={options}
                  value={selectedUserEmail}
                  onChange={(option) => {
                    handleChangePersonaOwner(option);
                  }}
                  isSearchable={true}
                  placeholder="Search a persona Owner"
                />
              </Stack>
              <Stack w="100%" alignItems={"center"}>
                <ChakraButton
                  className="close-btn align-self-center mt-5"
                  size="md"
                  fontWeight={"500"}
                  fontSize={"1rem"}
                  type="submit"
                  borderRadius={"5px"}
                  bg="#4775D5"
                  w="fit-content"
                  color="white"
                  _hover={{ bg: "#051D2C" }}
                  onClick={makePersonaOwnerHandler}
                >
                  CHANGE OWNER
                </ChakraButton>
              </Stack>
            </ModalBody>
          </ModalContent>
        </ChakraModal>
      </ChakraProvider>
    </>
  );
};

export default PersonaTeam;
