import React from "react";
import { Navigate } from "react-router-dom";

const LoginRoutes = ({ children }) => {

    const userData = JSON.parse(localStorage.getItem("auth_token"));

    if (!userData) {
        return children
    }
    else {
        return <Navigate to={"/persona"} />
    }

};

export default LoginRoutes;