import React from "react";
import linkedin from "../../../../../Images/linkedinfilled.svg";
import websiteicon from "../../../../../Images/website.svg";
import { BulbIcon } from "../../../../../customIcons/customIcons";
import { useMediaQuery } from "@material-ui/core";
const PreviewPersonaHeader = ({ data }) => {
  // console.log("dataa", data);
  const smallScreen = useMediaQuery("(max-width:1200px)");
  const mobileScreen = useMediaQuery("(max-width:768px)");
  return (
    <div>
      <p className="preview-header-text">EXECUTIVE BRANDING</p>
      {/* upper section */}
      <div className="preview-header-section ">
        <div className="preview-persona-info d-flex align-items-start justify-content-between">
          <div className="d-flex flex-column align-items-center">
            <img src={data?.img} />
            <div className="d-flex flex-column align-items-center">
              <p className="preview-persona-name">{data?.name}</p>

              <p className="preview-persona-company">
                {data?.jobTitle} {data?.jobTitle && "@"} {data?.companyName}
              </p>
            </div>
            <div className="leadership-btn">
              <BulbIcon />
              <p>Redefining Leadership</p>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            {data?.socialLinks?.websiteUrl && (
              <a href={data?.socialLinks?.websiteUrl} target="_blank">
                {" "}
                <img
                  style={{ width: "2rem", height: "2rem" }}
                  src={websiteicon}
                />
              </a>
            )}
            {data?.socialLinks?.linkedin && (
              <a href={data?.socialLinks?.linkedin} target="_blank">
                {" "}
                <img style={{ width: "2rem", height: "2rem" }} src={linkedin} />
              </a>
            )}
          </div>
        </div>
      </div>
      {/* lower section */}
      {data?.who_is_1?.map((item) => (
        <div className="preview-header-lower-section mt-4">
          <div className="d-flex align-items-center">
            <div className="who-is-heading "></div>
            <p
              style={{
                whiteSpace: "nowrap",
                marginBottom: "0",
                paddingLeft: "2rem",
                color: "#051D2C",
                fontSize: mobileScreen
                ? "0.95rem"
                : smallScreen
                ? "1.15rem"
                : "1.25rem",
                fontWeight: "350",
              }}
            >
              Who is <span style={{ fontWeight: "500" }}>{data?.name}</span>
            </p>
          </div>
          <div style={{ paddingLeft: "2.357rem" }}>
            <p className="who-is-upper-para">{item?.Who_is_Answer_1}</p>
            <p className="who-is-lower-para">{item?.Who_is_Answer_1}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PreviewPersonaHeader;
