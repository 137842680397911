import React from "react";
import NavBar from "../../NavBar/NavBar";
import SideNav from "../../sideNav/sideNav";
import AdminNav from "../../sideNav/AdminNav";
import { ChakraProvider, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Feedback from "../../feedback/Feedback";

const Customization = () => {
  return (
    <>
      <Feedback />
      <NavBar />
      <div className={"persona-container-light"}>
        <div className="mainContainer">
          {" "}
          <input
            type="checkbox"
            id="sideNavDrawer-toggle"
            name="sideNavDrawer-toggle"
          />
          <label
            for="sideNavDrawer-toggle"
            id="sideNavDrawer-toggle-label"
          ></label>
          <AdminNav />
          <div className="mainContainer-right">
            <div className="mainContainer-Inner">
              <div className="d-flex justify-content-between">
                <ChakraProvider>
                  <Text fontWeight={"bold"} fontSize={"1.4rem"}>
                    Customization
                  </Text>
                </ChakraProvider>
              </div>
            </div>{" "}
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Customization;
