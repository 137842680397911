import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import "./Login.css";
import { loginService } from "../../Service/service";
import ReusableTextField from "../reusableComponents/ReuseableTextField";
import ReusableButton from "../reusableComponents/ReusableButton";
import CustomToaster from "../../utils/toaster/CustomToaster";

const Login = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [error, seterror] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // console.log("🚀 ~ Login ~ formData:", formData)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const signInUser = async () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email === "" || !formData.email.match(emailPattern)) {
      errors.email = true;
    } else {
      errors.email = false;
    }

    if (formData.password === "") {
      errors.pass = true;
    } else {
      errors.pass = false;
    }

    const hasErrors = Object.values(errors).some((value) => value);

    if (hasErrors) {
      seterror(errors);
      console.log(errors);
    } else {
      seterror({});
      try {
        setLoader(true);
        const res = await loginService(formData);
        if (res.success) {
          // signup successful
          localStorage.setItem("auth_token", JSON.stringify(res));
          localStorage.setItem("active_user", JSON.stringify(res.data));
          localStorage.setItem("user_info", JSON.stringify(res.data));
          seterror({});
          setFormData({
            // Reset the form fields
            email: "",
            password: "",
          });
          setLoader(false);
          navigate("/persona");
        } else {
          setLoader(false);
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarMessage(res.message); // You can customize this message
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  };

  const handleKeyPressUser = (event) => {
    if (event.key === "Enter") {
      signInUser();
    }
  };

  return (
    <>
      <div className="signupContainer">
        <div className="signupContainer-inner">
          <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          <div className="login-title">Login</div>
          <div>
            <div className="signupContainer-form">
              {/* <ChakraProvider>
                  <Stack alignItems={"center"}> */}
              <div>
                {/* email */}
                <div className="mb-4">
                  <ReusableTextField
                    label="Email Address"
                    type="text"
                    name="email"
                    value={formData.email}
                    focused={true}
                    onChange={handleInputChange}
                    placeholder="Enter email"
                    error={error.email}
                    helperText={error.email && "Please use a valid email"}
                  />
                </div>

                {/* password */}
                <div className="mb-4">
                  <ReusableTextField
                    label="Password"
                    type={"password"}
                    name="password"
                    focused={true}
                    value={formData.password}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPressUser}
                    placeholder="Enter password"
                    error={error.pass}
                    helperText={error.pass && "Please use a valid password"}
                    showEyeIcon
                    required
                  />
                </div>
                {/*login button  */}
                <div className="mb-4">
                  <ReusableButton
                    buttonText="Login"
                    loading={loader}
                    onClick={signInUser}
                    width="100%"
                    height="53px"
                    // You can override any other default props here if needed
                  />
                </div>
                <p>
                  <span
                    onClick={() => navigate("/forgot-password")}
                    className="signup-link login-blue-text"
                  >
                    Forgot your password?
                  </span>
                </p>
                <p className="forget-text">
                  Don't have an account?{" "}
                  <span
                    onClick={() => navigate("/signup")}
                    className="signup-link login-blue-text"
                  >
                    Request Access
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* snackbar */}

      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default Login;
