import React from "react";
import { Link } from "react-router-dom";
import "./ProfileTabs.css";
import female from "../../../Images/created-by.png";
import cross from "../../../Images/x.svg";
import edit from "../../../Images/edit-2.svg";
function GlobalRules() {
  return (
    <div className="bg-white mb-16 global-rules-tab moment-border p-0 moment-text d-flex justify-content-between flex-column">
      <div className="px-4 py-4 color-darkGray box-header">
        <span>Create Rules</span>
      </div>
      <div className="px-4 py-4 color-darkGray">
        <div className="d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column">
            <label className="mb-3">Lorium ispms</label>
            <div className="dropdown">
              <button
                className="btn dropdownMenuButton  dropdown-toggle d-flex justify-content-between align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>Do not use</span>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <Link className="dropdown-item" to="/">
                    Action
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/`">
                    Another action
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/">
                    Something else here
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex flex-column">
            <label className="mb-3">Add word/phrase</label>
            <input className="global-rules-input" type="text" />
          </div>
          <div className="d-flex flex-column">
            <label className="mb-3">Lorium ispms</label>
            <div className="dropdown">
              <button
                className="btn dropdownMenuButton dropdown-toggle d-flex justify-content-between align-items-center"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Select Moment
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <Link className="dropdown-item" to="/">
                    Action
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" href="/">
                    Another action
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/">
                    Something else here
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <button className="btn createRuleBtn">Create rule</button>
          </div>
        </div>
      </div>
      <div className="px-4 py-4 color-darkGray">
        <table className="table">
          <thead className="table-light moments-table-head">
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">CREATED BY</th>
              <th scope="col" className="text-center">
                CREATED ON
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className="moments-table-body">
            <tr>
              <td>
                <div className="align-items-center d-flex gap-3">
                  <span>
                    <input type="checkbox" className="checkbox" />
                  </span>
                  <span className="mb-1">Do not use words in titles</span>
                </div>
              </td>

              <td>
                <div className="d-flex mx-3">
                  <img src={female} className="female-img" alt="person" />
                </div>
              </td>
              <td className="text-center">
                <span>21 April 2023</span>
              </td>
              <td>
                <div className="d-flex justify-content-end">
                  <img src={edit} className="edit-img mx-3" alt="more-option" />
                  <img src={cross} className="cross-img" alt="more-option" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="align-items-center d-flex gap-3">
                  <span>
                    <input type="checkbox" className="checkbox" />
                  </span>
                  <span className="mb-1">Do not use words in titles</span>
                </div>
              </td>

              <td>
                <div className="d-flex mx-3">
                  <img src={female} className="female-img" alt="person" />
                </div>
              </td>
              <td className="text-center">
                <span>21 April 2023</span>
              </td>
              <td>
                <div className="d-flex justify-content-end">
                  <img src={edit} className="edit-img mx-3" alt="more-option" />
                  <img src={cross} className="cross-img" alt="more-option" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="align-items-center d-flex gap-3">
                  <span>
                    <input type="checkbox" className="checkbox" />
                  </span>
                  <span className="mb-1">Do not use words in titles</span>
                </div>
              </td>

              <td>
                <div className="d-flex mx-3">
                  <img src={female} className="female-img" alt="person" />
                </div>
              </td>
              <td className="text-center">
                <span>21 April 2023</span>
              </td>
              <td>
                <div className="d-flex justify-content-end">
                  <img src={edit} className="edit-img mx-3" alt="more-option" />
                  <img src={cross} className="cross-img" alt="more-option" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GlobalRules;
