import React, { useState } from "react";
import BrandGenartion from "../../../../../Images/emptyBrandGuideIcon.svg";
import ReusableButton from "../../../../reusableComponents/ReusableButton";
import { SearchPlaceholderIcon } from "../../../../../customIcons/customIcons";
import { CreateBrandGuide } from "../../../../../Service/service";
function GenratingBrand({
  setResponseData,
  setIsBrnadGuide,
  selectedItems,
  formData,
  setTextData,
  selectedUrls,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const updatedObject = selectedItems?.map((obj) => ({
    fileName: obj.fileName,
    url: obj.url,
  }));
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const UserInfo = JSON.parse(localStorage.getItem("user_info"));
  const submitData = () => {
    setIsLoading(true);
    const data = {
      title: formData.title.trim(),
      personaId: activePersona?._id,
      makerId: UserInfo?._id,
      isMale: formData.isMale === "Brand Guide - Male" ? true : false,
      selectedData: updatedObject,
      urls: selectedUrls,
    };
    CreateBrandGuide(data).then((result) => {
      setIsLoading(false);
      if (result.success) {
        setIsBrnadGuide(true);
        setTextData(result?.data?.brandGuideQueries);
        setResponseData(result?.data);
      }
    });
  };
  return (
    <div className="brand-genration-step">
      <div className="genration-inner-block">
        {isLoading ? (
          <img
            style={{ width: "70px", height: "70px", marginBottom: "2rem" }}
            src={BrandGenartion}
          />
        ) : (
          <SearchPlaceholderIcon
            style={{
              width: "80px",
              height: "80px",
              marginBottom: "2rem",
            }}
          />
        )}
        {selectedItems?.length > 0 || selectedUrls?.length > 0 ? (
          <>
            {isLoading ? (
              <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
                Generating brand guide...
                <br />
                This may take few seconds to process.
              </p>
            ) : (
              <ReusableButton
                size="small"
                fontSize="0.8rem"
                buttonText={"GENERATE BRAND GUIDE"}
                onClick={submitData}
              />
            )}
          </>
        ) : (
          <p style={{ fontSize: "1.2rem" }}>
            <strong>Search</strong> above or <strong>upload</strong> assets for
            this campaign
          </p>
        )}
      </div>
    </div>
  );
}

export default GenratingBrand;
