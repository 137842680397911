import React, { useCallback, useEffect, useRef, useState } from "react";
import "../Persona/Persona.css";
import "./style.css";
import SideNav from "../sideNav/sideNav";
import NavBar from "../NavBar/NavBar";
import { RotatingLines } from "react-loader-spinner";
import addresource from "../../Images/addresource.svg";
import emptyResource from "../../Images/emptyResource.svg";
import { useContext } from "react";
import PersonaChatContext from "../../context/ChatContext";
import Feedback from "../feedback/Feedback";
import alertIcon from "../../Images/alert.svg";
import {
  BlueChatIcon,
  ChartIcon,
  ChartIconDsiabled,
  ChatIconOutlined,
  CreateProjectIcon,
  DeleteIcon,
  ExternalLinkIcon,
  FileGreyIcon,
  UploadIcon,
} from "../../customIcons/customIcons";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  AddResourceService,
  CreateProjectService,
  deleteResourceService,
  elasticSearchService,
  getAllResourcesService,
  getResourceGraphDataService,
  getResourceKeywordsService,
  getResourceSummaryService,
} from "../../Service/service";
import { Modal as BootstrapModal } from "react-bootstrap";
import ReusableButton from "../reusableComponents/ReusableButton";
import {
  ChakraProvider,
  IconButton,
  Button as ChakraButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Input,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Stack,
  Text,
  SkeletonText,
  chakra,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Tooltip,
} from "@chakra-ui/react";
import CustomToaster from "../../utils/toaster/CustomToaster";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ImEnlarge } from "react-icons/im";
import CustomFloatingEdgeGraph from "../knowledgegraph/CustomFloatingEdgeGraph";
import { FaBackward } from "react-icons/fa";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { processData } from "../knowledgegraph/utils";
import { MdInfo } from "react-icons/md";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
const PersonaResources = () => {
  const tabletDevices = useMediaQuery("(max-width:767px)");
  const { updateChatArray } = useContext(PersonaChatContext);
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenGraphModal,
    onOpen: onOpenGraphModal,
    onClose: onCloseGraphModal,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSearchMethod, setSelectedSearchMethod] = useState({
    label: "Search exact phrase",
  });
  const [tempPersonaResources, setTempPersonaResources] = useState([]);
  // console.log("temp personas", tempPersonaResources);
  const [isClickedSearch, setisClickedSearch] = useState(false);
  const [searchMethodError, setSearchMethodError] = useState(false);
  const [elasticSearchText, setElasticSearchText] = useState();
  const [targetResource, setTargetResource] = useState();
  const [searchedText, setSearchedText] = useState();
  const [synonymsArray, setSynonymsArray] = useState([]);
  // console.log("🚀 ~ PersonaResources ~ synonymsArray:", synonymsArray)
  const [cleanedWordsArray, setcleanedWordsArray] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [personaResources, setPersonaResources] = useState([]);
  const [showpersonaResources, setShowPersonaResources] = useState(true);
  const [selectedPersonaResources, setSelectedPersonaResources] = useState([]);
  // console.log("selected resources", selectedPersonaResources);
  const [isSearchResultEmpty, setIsSearchResultEmpty] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [resourcesExpanded, setResourcesExpanded] = useState({});
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [confirmDeleteFileModal, setconfirmDeleteFileModal] = useState(false);
  const [resourceType, setResourceType] = useState({
    label: "Document",
  });
  const [synonymsRegex, setSynonymsRegex] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isContextChecked, setIsContextChecked] = useState(false);
  const [showMoments, setShowMoments] = useState(true);
  const [targetFile, settTargetFile] = useState();
  const [selectedResource, setSelectedResource] = useState();
  const [selectedFileResource, setSelectedFileResource] = useState("");
  const [url, setURL] = useState("");
  const [projectName, setprojectName] = useState("");
  const [projectDescription, setprojectDescription] = useState("");
  const [searchedMethod, setSearchedMethod] = useState();
  const [showMore, setShowMore] = useState(false);
  const [showResourceContentMore, setShowResourceContentMore] = useState(false);
  const [keywordsArray, setKeywordsArray] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState();
  const [searchedKeyword, setSearchedKeyword] = useState();
  const [isKeywordsEmpty, setisKeywordsEmpty] = useState(false);
  const [isSummaryError, setisSummaryError] = useState("");
  const [summary, setSummary] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [refresh, setrefresh] = useState(1);
  const [loadGraphData, setLoadGraphData] = useState(true);
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
  });
  // console.log("date range", filterData);
  const [paragraphsArray, setparagraphsArray] = useState([
    {
      text: "Lorem Ipsum is simply dummy text of the typesetting industry. This is Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ]);
  const [isShowCount, setIsShowCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const datePickerRef = useRef(null);

  const containerRef = useRef(null);
  const searchComponentRef = useRef(null);
  const closeSearchComponent = useCallback(() => {
    // Retrieve the Flatpickr calendar element
    const flatpickrCalendar = document.querySelector('.flatpickr-calendar');
  
    // Check if the Flatpickr calendar is open
    const isDatePickerOpen = flatpickrCalendar && flatpickrCalendar.classList.contains('open');
  
    const createModal = document.getElementById("create-modal");
  
    // If the create modal is not present and the date picker is not open, close the search component
    if (!createModal && !isDatePickerOpen) {
      closeSearch();
    }
  }, []);
  

  useEffect(() => {
    const handleOverlayClick = (e) => {
      if (
        searchComponentRef.current &&
        !searchComponentRef.current.contains(e.target) 
      ) {
        closeSearchComponent();
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);

    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, [closeSearchComponent]);

  const truncatedText = showMore ? summary : summary?.slice(0, 200);
  const truncatedContentText = showResourceContentMore
    ? targetResource?.processedFileContent
    : targetResource?.processedFileContent?.slice(0, 1250);

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };
  const toggleResourceContentShowMore = () => {
    setShowResourceContentMore((prevShowMore) => !prevShowMore);
  };
  const btnRef = React.useRef();

  const handleSearchMethodChange = (_, newValue) => {
    setSelectedSearchMethod(newValue);
    setSearchMethodError(isClickedSearch && newValue === null);
  };

  useEffect(() => {
    setShowPersonaResources(true);
    setSelectedItems([]);
    setSelectedPersonaResources([]);
    setDocumentList([]);
    setElasticSearchText("");
    const getAllResources = async () => {
      setIsLoading(true);
      const res = await getAllResourcesService();
      if (res.success) {
        setPersonaResources(res.data);
        setTempPersonaResources(res.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    getAllResources();
  }, [updateChatArray]);
  const resourceTypes = [
    { label: "Document" },
    { label: "External Link" },
    // { label: "RSS" },
  ];
  const stopPropagation = (event) => {
    // Prevent the event from reaching the parent (AccordionSummary)
    event.stopPropagation();
  };

  const elasticSearchHandler = async () => {
    setisClickedSearch(true);
    // Check if there is text in the search document field and a search method is selected
    if (
      !elasticSearchText ||
      (elasticSearchText && elasticSearchText.trim().length === 0)
    ) {
      // Set an error state for the search document field
      setErrorToast(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Search requires input text");
      setisClickedSearch(false);
      return;
    }

    try {
      const words = elasticSearchText?.match(
        /(?:[^\s"']+|"([^"]*)"|'([^']*)')/g
      );

      // Filter out null values, flatten the array, and update the search array
      const filteredWords = words
        ? words.flat().filter((word) => word !== null)
        : [];

      // Remove quotes and other characters attached to words
      const cleanedWords = filteredWords?.map((word) =>
        word.replace(/['"\\]+/g, "")
      );
      // console.log("🚀 ~ elasticSearchHandler ~ cleanedWords:", cleanedWords)
      setcleanedWordsArray(cleanedWords);
      closeAllAccordions();
      setIsLoading(true);
      setSearchedText(elasticSearchText);
      setSearchedMethod(selectedSearchMethod.label);
      const startDateObject = new Date(filterData?.start_date);
      const startFormattedDate = startDateObject?.toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      const endDateObject = new Date(filterData?.end_date);
      const endFormattedDate = endDateObject?.toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      // console.log("formatted dates", startFormattedDate, endFormattedDate);
      const res = await elasticSearchService(
        activePersona?.creatorEmail,
        activePersona?.epClientEmail,
        cleanedWords,
        filterData?.start_date !== "" ? startFormattedDate : "",
        filterData?.end_date !== "" ? endFormattedDate : ""
      );

      if (res.success) {
        setSelectedItems([]);
        setShowPersonaResources(false);
        setSelectedPersonaResources([]);
        if (res.data.length > 0) {
          setIsSearchResultEmpty(false);
        } else {
          setIsSearchResultEmpty(true);
        }
        setShowMoments(false);

        setDocumentList(res.data);
        if (res.synonyms !== null) {
          const highlightSynonyms = res.synonyms.join("|");
          const synnysmRegex = new RegExp(`\\b(${highlightSynonyms})\\b`, "gi");
          setSynonymsRegex(synnysmRegex);
          const lowercasedSynonymsArray = res.synonyms.map((synonym) =>
            synonym.toLowerCase()
          );
          setSynonymsArray(lowercasedSynonymsArray);
        }
      }

      setIsLoading(false);
      setisClickedSearch(false);
    } catch (error) {
      setIsLoading(false);
      setisClickedSearch(false);
      console.log("Error in elasticSearchHandler:", error);
    }
  };

  const handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      // Call your function here

      elasticSearchHandler();
    }
  };

  const closeAllAccordions = () => {
    const newExpandedState = {};
    documentList.forEach((_, index) => {
      newExpandedState[index] = false;
    });
    setExpanded(newExpandedState);
  };
  const closeAllResourcesAccordions = () => {
    const newExpandedState = {};
    personaResources?.forEach((_, index) => {
      newExpandedState[index] = false;
    });
    setResourcesExpanded(newExpandedState);
  };
  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: isExpanded,
    }));
  };

  const handleResourcesAccordionChange = (index) => (event, isExpanded) => {
    setResourcesExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: isExpanded,
    }));
  };

  const fileInputRef = useRef(null);
  // console.log("🚀 ~ PersonaResources ~ fileInputRef:", fileInputRef);

  const handleUploadClick = () => {
    // Trigger click on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event) => {
    // Handle file upload logic here
    const selectedFile = event.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      // Check if the file size is greater than 6MB
      if (fileSizeInMB > 6) {
        setErrorToast(true);
        setSnackbarMessage("Please upload a file with a size less than 6MB.");
        setSnackbarSeverity("error");
        // Reset the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      } else {
        setSelectedFileResource(selectedFile);
        // Call your API here with the selected file
        // Example: callApi(selectedFile);
      }
    }
    // Perform your file upload logic with the selectedFile
  };

  const handleResourceTypeChange = (_, newValue) => {
    setResourceType(newValue);
  };
  const handleUploadLinkResource = async () => {
    clearDate();
    if (url?.trim().length === 0) {
      setErrorToast(true);
      setSnackbarMessage("Please provide a link");
      setSnackbarSeverity("error");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("personaId", activePersona?._id);
      formData.append("makerId", parsedToken?.data?._id);
      formData.append("url", url);
      const res = await AddResourceService(formData);
      if (res.success) {
        const res2 = await getAllResourcesService();
        setPersonaResources(res2.data);
        setTempPersonaResources(res2.data);
        setURL();
        setShowAddResourceModal(false);
        closeAllResourcesAccordions();
        setIsLoading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
        // const res3 = await getResourceKeywordsService(res.data?._id, false);
      } else {
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
        setIsLoading(false);
      }
    }
  };

  const handleUploadFileResource = async () => {
    if (!selectedFileResource) {
      setErrorToast(true);
      setSnackbarMessage("Please upload a document");
      setSnackbarSeverity("error");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("personaId", activePersona?._id);
      formData.append("makerId", parsedToken?.data?._id);
      formData.append("files", selectedFileResource);
      const res = await AddResourceService(formData);
      if (res.success) {
        const res2 = await getAllResourcesService();
        setShowPersonaResources(true);
        setSelectedPersonaResources([]);
        setElasticSearchText("");
        setPersonaResources(res2.data);
        setTempPersonaResources(res2.data);
        closeAllResourcesAccordions();
        setSelectedFileResource();
        setIsLoading(false);
        setShowAddResourceModal(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
        const res3 = await getResourceKeywordsService(res.data?._id, false);
      } else {
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
        setIsLoading(false);
      }
    }
    // Check if the URL already exists in the array
  };

  const handleCloseErrorToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorToast(false);
  };

  const CreateProjectHandler = async () => {
    clearDate();
    if (projectName?.trim().length === 0) {
      setSnackbarMessage("Project name is required");
      setSnackbarSeverity("error");
      setErrorToast(true);
      return;
    } else if (projectDescription?.trim().length === 0) {
      setSnackbarMessage("Project description is required");
      setSnackbarSeverity("error");
      setErrorToast(true);
      return;
    } else {
      setIsLoading(true);
      const tempSelectedUrlsArray = selectedPersonaResources?.filter(
        (item) => item.isFile !== true
      );
      const tempSelectedFilesArray = selectedPersonaResources?.filter(
        (item) => item.isFile === true
      );
      const formData = new FormData();
      formData.append("makerId", parsedToken?.data?._id);
      formData.append("personaId", activePersona?._id);
      formData.append("projectName", projectName);
      formData.append("description", projectDescription);
      showpersonaResources
        ? tempSelectedFilesArray.forEach((item, index) => {
            // Iterate through the keys of each object
            Object.keys(item).forEach((key) => {
              // Check if the key is either "isprocessedFileName" or "processedFileUrl"
              if (key === "processedFileName") {
                // Append the key-value pair to formData with a new key "fileName"
                formData.append(`selectedData[${index}][fileName]`, item[key]);
              } else if (key === "processedFileUrl") {
                // Append the key-value pair to formData with a new key "fileUrl"
                formData.append(`selectedData[${index}][url]`, item[key]);
              }
            });
          })
        : selectedItems.forEach((item, index) => {
            Object.entries(item).forEach(([key, value]) => {
              formData.append(`selectedData[${index}][${key}]`, value);
            });
          });

      showpersonaResources &&
        tempSelectedUrlsArray?.forEach((item, index) => {
          formData.append(`urls[${index}]`, item.url);
        });

      const res = await CreateProjectService(formData);
      if (res.success) {
        setCreateProjectModal(false);
        // setDocumentList([]);
        // setUploadedResourceFiles([]);
        // setSelectedUrls([]);
        setSelectedItems([]);
        setSelectedPersonaResources([]);
        // setSelectedPersonaResources([])f
        // setElasticSearchText("");
        setprojectName("");
        setprojectDescription("");
        //   const res2 = await getProjects();
        setSnackbarMessage("Project added successfully");
        setSnackbarSeverity("success");
        setErrorToast(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
        setErrorToast(true);
        // console.log(res.message);
      }
    }
  };

  const getTruncatedLabel = (item) => {
    if (item.name) {
      return item.name.length > 15
        ? `${item.name.substring(0, 15)}...`
        : item.name;
    } else if (item.fileName) {
      return item.fileName.length > 15
        ? `${item.fileName.substring(0, 15)}...`
        : item.fileName;
    } else if (item.documentName) {
      return item.documentName.length > 15
        ? `${item.documentName.substring(0, 15)}...`
        : item.documentName;
    }

    return ""; // Default label if none of the keys exist
  };
  const handleCheckboxChangeAccordion = (item) => {
    if (isSelected(item)) {
      // If the item is selected, remove it from the selectedItems array
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (selectedItem) => selectedItem.url !== item.url
        )
      );
    } else {
      // If the item is not selected, add it to the selectedItems array
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    }

    // Toggle the 'show' property for the added/removed item
    setDocumentList((prevDocumentList) =>
      prevDocumentList.map((doc) =>
        doc.url === item.url ? { ...doc, show: !doc.show } : doc
      )
    );
  };

  const handleResourceCheckboxChangeAccordion = (item) => {
    if (isSelectedResource(item)) {
      // If the item is selected, remove it from the selectedItems array
      setSelectedPersonaResources((prevSelectedItems) =>
        prevSelectedItems.filter(
          (selectedItem) => selectedItem._id !== item._id
        )
      );
    } else {
      // If the item is not selected, add it to the selectedItems array
      setSelectedPersonaResources((prevSelectedItems) => [
        ...prevSelectedItems,
        item,
      ]);
    }
  };

  // Function to check if an item is selected
  const isSelected = (item) => {
    return selectedItems.some((selectedItem) => selectedItem.url === item.url);
  };

  const isSelectedResource = (item) => {
    return selectedPersonaResources.some(
      (selectedItem) => selectedItem._id === item._id
    );
  };

  const handleDeleteResource = async () => {
    setIsLoading(true);
    const res = await deleteResourceService([selectedResource?._id]);
    if (res.success) {
      const res2 = await getAllResourcesService();
      setPersonaResources(res2.data);
      setTempPersonaResources(res2.data);
      setSelectedResource();
      setSelectedPersonaResources([]);
      setconfirmDeleteFileModal(false);
      closeAllResourcesAccordions();
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
    } else {
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
    }
  };
  const handleDeleteMultipleResource = async () => {
    setIsLoading(true);
    const selectedResourcesIds =
      selectedPersonaResources &&
      selectedPersonaResources?.map((resource) => resource._id);
    // console.log(selectedResourcesIds);
    const res = await deleteResourceService(selectedResourcesIds);
    if (res.success) {
      const res2 = await getAllResourcesService();
      setPersonaResources(res2.data);
      setTempPersonaResources(res2.data);
      setSelectedResource();
      setSelectedPersonaResources([]);
      setconfirmDeleteFileModal(false);
      closeAllResourcesAccordions();
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("success");
    } else {
      setIsLoading(false);
      setErrorToast(true);
      setSnackbarMessage(res.message);
      setSnackbarSeverity("error");
    }
  };

  // const highlightWords = searchedText?.split(/\s+/).join("|");
  // const highlightRegex = new RegExp(`\\b(${highlightWords})\\b`, "gi");

  const handleKeywordClick = (categoryIndex, keywordIndex) => {
    const updatedKeywords = keywordsArray?.map((category, cIndex) => ({
      ...category,
      people: category.people.map((keywordObj, kIndex) => ({
        ...keywordObj,
        isSelected:
          cIndex === categoryIndex && kIndex === keywordIndex ? true : false,
      })),
    }));

    // setSelectedKeyword(
    //   keywordsArray[categoryIndex].people[keywordIndex].keyword
    // );
    setKeywordsArray(updatedKeywords);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < paragraphsArray.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };
  const currentDocumentNumber = currentIndex + 1;
  const totalDocuments = paragraphsArray.length;

  const isMatchingObject = (searchObject) => {
    const fileNameToMatch = searchObject.fileName;

    const matchingObject = personaResources?.find(
      (resource) =>
        resource.fileName === fileNameToMatch ||
        resource.originalFileName === fileNameToMatch ||
        resource.processedFileName === fileNameToMatch
    );
    if (matchingObject) {
      nav("/chat", {
        state: {
          from: "resources",
          resourceData: matchingObject,
        },
      });
    }
    return Boolean(matchingObject);
  };

  const getResourceKeywords = async (item) => {
    try {
      setIsLoading(true);
      const res2 = await getResourceKeywordsService(item?._id, false);

      if (res2.success && res2.data !== null) {
        const inputData2 = res2?.data;
        const newCategories = Object.keys(inputData2).map((categoryKey) => {
          return {
            title: categoryKey,
            people: inputData2[categoryKey].map((keyword) => ({
              keyword,
              isSelected: false,
            })),
          };
        });

        const nonEmptyCategories = newCategories
          .map((category) => ({
            ...category,
            people: category.people.filter(
              (person) => person.keyword.length >= 3
            ),
          }))
          .filter((category) => category.people.length > 0);

        setKeywordsArray(nonEmptyCategories);
        setIsShowCount(isShowCount + 1);
      } else {
        setisKeywordsEmpty(true);
      }

      const summaryResponse = await getResourceSummaryService(item?._id);
      if (summaryResponse.success) {
        setisSummaryError("");
        setSummary(summaryResponse.data);
        setIsLoading(false);
      } else {
        setisSummaryError(summaryResponse.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(
        "🚀 ~ file: AnalizeMoment.js:250 ~ getKeywords ~ error:",
        error
      );
    }
  };

  useEffect(() => {
    const updateKeywordCounts = async () => {
      const updatedKeywordsArray = keywordsArray
        ?.map((category) => {
          const keywordMap = new Map();

          category.people.forEach((keywordObj) => {
            const count = countKeywordOccurrences(keywordObj.keyword);
            if (count > 0) {
              if (keywordMap.has(keywordObj.keyword)) {
                keywordMap.get(keywordObj.keyword).count += count;
              } else {
                keywordMap.set(keywordObj.keyword, { ...keywordObj, count });
              }
            }
          });

          // Convert the Map to an array for sorting
          const people = Array.from(keywordMap.values());

          // Sort the people array by count in descending order
          people.sort((a, b) => b.count - a.count);

          return { ...category, people };
        })
        .filter((category) => category.people.length > 0);

      setKeywordsArray(updatedKeywordsArray);
    };
    if (isShowCount > 0) {
      updateKeywordCounts();
    }
  }, [isShowCount]);

  useEffect(() => {
    return () => {
      processData({
        edges: [],
        nodes: [],
      });
    };
  }, []);
  const countKeywordOccurrences = (keyword) => {
    const regex = new RegExp(
      `\\b${keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}\\b`,
      "gi"
    );

    const matches = targetResource?.processedFileContent?.match(regex);
    return matches ? matches.length : 0;
  };

  const getGraphDataHandler = async (keyword) => {
    setIsLoading(true);
    try {
      const res = await getResourceGraphDataService(
        targetResource?.correctedResourceName,
        null,
        keyword,
        loadGraphData
      );
      if (res.success) {
        setLoadGraphData(false);
        setrefresh(refresh + 1);
        const processedGraphData = processData(res.graph, keyword);
        setGraphData(processedGraphData);
        setIsLoading(false);
      } else {
        setErrorToast(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AnalizeMoment.js:308 ~ getGraphDataHandler ~ error:",
        error
      );
    }
  };

  const ReAnalyzeKeywordsHandler = async () => {
    const res2 = await getResourceKeywordsService(targetResource?._id, true);

    if (res2.success) {
      setisKeywordsEmpty(true);
      setKeywordsArray([]);
      const inputData2 = res2?.data;
      const newCategories = Object.keys(inputData2).map((categoryKey) => {
        return {
          title: categoryKey,
          people: inputData2[categoryKey].map((keyword) => ({
            keyword,
            isSelected: false,
          })),
        };
      });

      const nonEmptyCategories = newCategories
        .map((category) => ({
          ...category,
          people: category.people.filter(
            (person) => person.keyword.length >= 3
          ),
        }))
        .filter((category) => category.people.length > 0);

      setKeywordsArray(nonEmptyCategories);
      setIsShowCount(isShowCount + 1);
    } else {
      setisKeywordsEmpty(true);
    }
  };

  const initializeFlatpickr = () => {
    const options = {
      mode: "range",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr) {
        const [startDate, endDate] = dateStr.split(" to ");
        if (endDate) {
          setFilterData((prev) => ({
            ...prev,
            start_date: startDate || "",
            end_date: endDate || startDate || "",
          }));
        }
      },
    };
    flatpickrInstanceRef.current = flatpickr(datePickerRef.current, options);
  };
  const clearDate = () => {
    flatpickrInstanceRef.current.clear();
    setFilterData({
      start_date: "",
      end_date: "",
    });
  };
  const filterResourcesByDateRange = (startDate, endDate) => {
    const filteredResources = personaResources?.filter((resource) => {
      const parsedDate = new Date(resource?.parsedDate);
      // console.log("🚀  filteredResources  parsedDate:", parsedDate);
      const start = new Date(startDate);
      // console.log("🚀  filteredResources  start:", start);
      const end = new Date(endDate);
      // console.log("🚀  filteredResources  end:", end);
      // Set hours, minutes, seconds, and milliseconds to 0 to compare only the date part
      parsedDate.setHours(0, 0, 0, 0);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);

      return parsedDate >= start && parsedDate <= end;
    });

    return filteredResources;
  };

  const flatpickrInstanceRef = useRef(null);

  useEffect(() => {
    const options = {
      mode: "range",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr) {
        const [startDate, endDate] = dateStr.split(" to ");
        // console.log("🚀  useEffect  endDate:", endDate);
        // console.log("🚀  useEffect  startDate:", startDate);

        if (endDate) {
          setFilterData((prev) => ({
            ...prev,
            start_date: startDate || "",
            end_date: endDate || startDate || "",
          }));
        }
      },
    };
    flatpickrInstanceRef.current = flatpickr(datePickerRef.current, options);
    flatpickr(datePickerRef.current, options);
  }, []);

  useEffect(() => {
    console.log("enter1")
    if (filterData.start_date || filterData.end_date) {
      const filteredResources = filterResourcesByDateRange(
        filterData.start_date,
        filterData.end_date || filterData.start_date
      );
      setTempPersonaResources(filteredResources);
    }
  }, [filterData, personaResources]);
  useEffect(() => {
    initializeFlatpickr();
    return () => {
      // Cleanup Flatpickr instance
      flatpickrInstanceRef.current.destroy();
    };
  }, []);
  const lowercasedCleanedWordsArray = cleanedWordsArray?.map((word) =>
    word.toLowerCase()
  );

  // Convert synonymsArray to lowercase
  const lowercasedSynonymsArray = synonymsArray?.map((word) =>
    word.toLowerCase()
  );
  const OpenProjectModal = (e) => {
    e.stopPropagation();
    setprojectName("");
    setprojectDescription("");
    setCreateProjectModal(true);
  };
  const closeSearch = () => {
    setElasticSearchText("");
    setSelectedItems([]);
    setDocumentList([]);
    setShowPersonaResources(true);
    setIsSearchResultEmpty(false);
    // setTempPersonaResources(personaResources);
    clearDate();
  };
  return (
    <>
      <CustomToaster
        open={errorToast}
        onClose={handleCloseErrorToast}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback />
      <NavBar />
      <div className="persona-container-light ">
        <div className="mainContainer">
          {" "}
          <input
            type="checkbox"
            id="sideNavDrawer-toggle"
            name="sideNavDrawer-toggle"
          />
          <label
            htmlFor="sideNavDrawer-toggle"
            id="sideNavDrawer-toggle-label"
          ></label>
          <SideNav isShowMenu={false} />
          <div className="mainContainer-right">
            {/* elastic search  */}
            <div ref={searchComponentRef}>
              <div className="d-flex w-100 search-container gap-2">
                <Box sx={{ flexGrow: "1", position: "relative" }}>
                  <TextField
                    size="small"
                    value={elasticSearchText}
                    onChange={(e) => setElasticSearchText(e.target.value)}
                    fullWidth
                    label="Search"
                    onKeyDown={handleKeyPressSearch}
                    sx={{ background: "white" }}
                  />
                  <ChakraProvider>
                    <ChakraButton
                      isDisabled={showpersonaResources}
                      onClick={closeSearch}
                      className="clearSearch"
                      _hover={{}}
                    >
                      <CloseIcon />
                    </ChakraButton>{" "}
                  </ChakraProvider>
                  <ChakraProvider>
                    <Tooltip
                      placement="top"
                      hasArrow
                      label="Search for multiple words exactly by wrapping them in single or double quotations for precise results. For example: 'United States' America"
                      aria-label="A tooltip"
                    >
                      <span
                        style={{
                          position: "absolute",
                          right: "7px",
                          bottom: "8px",
                        }}
                        className="cursor-pointer"
                      >
                        <MdInfo fontSize={"1.5rem"} />
                      </span>
                    </Tooltip>
                  </ChakraProvider>
                </Box>
                <Box sx={{ width: tabletDevices ? "150px" : "250px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: 7,
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <input
                        style={{
                          fontSize: tabletDevices ? "0.8rem" : "1rem",
                          height: "2.7rem",
                          borderRadius: "4px",
                          width: "100%",
                          border: "1px solid #C7C7C7",
                          cursor: !showpersonaResources ? "pointer" : "pointer",
                        }}
                        className="bg-whiteGrey filter-date rounded-lg outline-none p-3 W-100"
                        type="text"
                        placeholder="Filter by date"
                        ref={datePickerRef}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setShowAddResourceModal(true);
                      }}
                    >
                      {/* Upload icon with onClick handler */}
                      <IconButton className="upload-button">
                        <UploadIcon />
                      </IconButton>
                    </div>
                  </div>
                </Box>
              </div>

              <div
                style={{ background: "#F5F6F7" }}
                className="mainContainer-Inner"
              >
                <h1 className="pageHeader">Resources</h1>
                <div className="resources-container">
                  {/* uploaded resources */}
                  {showpersonaResources && tempPersonaResources?.length > 0 ? (
                    tempPersonaResources?.map((item, index) => (
                      <Accordion
                        key={index}
                        sx={{
                          border: "1px solid #CED3D9",
                          boxShadow: "none",
                          marginBottom: "1rem",
                          borderLeft: item.isFile
                            ? "3px solid #4775D5"
                            : "3px solid #B921B4",
                        }}
                        expanded={resourcesExpanded[index] || false}
                        onChange={handleResourcesAccordionChange(index)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          sx={{
                            margin: 0,
                          }}
                        >
                          <div className="d-flex w-100 align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <Checkbox
                                checked={isSelectedResource(item)}
                                onClick={stopPropagation}
                                onChange={() => {
                                  handleResourceCheckboxChangeAccordion(item);
                                }}
                                style={{
                                  height: "1.714rem4rem4rem4rem4rem4rem",
                                  width: "1.714rem",
                                }}
                              />
                              <div className="d-flex flex-column gap-1 ms-3">
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  className="file-name"
                                >
                                  {item.isFile ? item.fileName : item.url}
                                </Typography>
                                {item.isFile ? (
                                  <div className="d-flex align-items-center gap-1 ">
                                    <FileGreyIcon />
                                    <div className="resource-type">
                                      Document
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center gap-1 ">
                                    <ExternalLinkIcon
                                      style={{
                                        height: "1.143rem",
                                        width: "18px",
                                      }}
                                    />
                                    <div className="resource-type">
                                      External link
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 me-3 ">
                              <div
                                ref={btnRef}
                                onClick={(e) => {
                                  stopPropagation(e);
                                  if (item?.isFile) {
                                    onOpen();
                                    setTargetResource(item);
                                    getResourceKeywords(item);
                                  }
                                }}
                              >
                                {item?.isFile ? (
                                  <ChartIcon
                                    style={{
                                      height: "1.714rem",
                                      widht: "1.714rem",
                                    }}
                                  />
                                ) : (
                                  <ChartIconDsiabled
                                    style={{
                                      height: "1.714rem",
                                      widht: "1.714rem",
                                      cursor: "default",
                                    }}
                                  />
                                )}
                              </div>
                              <BlueChatIcon
                                onClick={(e) => {
                                  stopPropagation(e);
                                  nav("/chat", {
                                    state: {
                                      from: "resources",
                                      resourceData: item,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            borderTop: "1px solid #CED3D9",
                            padding: "1.143rem",
                          }}
                        >
                          <div className="mt-3" style={{ fontSize: "1rem" }}>
                            {item.isFile ? item.processedFileContent : null}
                          </div>
                          <div className="d-flex gap-2 align-items-center justify-content-between mt-3">
                            <div style={{ color: "#76828B", fontSize: "1rem" }}>
                              <span>
                                Created on{" "}
                                <span style={{ color: "#051D2C" }}>
                                  {moment(item?.resourceDate).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                </span>
                              </span>
                              by{" "}
                              <span style={{ color: "#051D2C" }}>
                                {item.resourceCreator?.name}
                              </span>{" "}
                            </div>
                            <Button
                              onClick={() => {
                                setSelectedResource(item);
                                setconfirmDeleteFileModal(true);
                              }}
                              variant="text"
                              sx={{
                                color: "#C51333",
                                fontWeight: "600",
                                textTransform: "uppercase",
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : showpersonaResources &&
                    personaResources?.length === 0 &&
                    isLoading === false ? (
                    <>
                      <div className="empty-resource">
                        <img
                          style={{ width: "70px", height: "70px" }}
                          src={emptyResource}
                        />
                        <p className="my-3">
                          You have no resources added for this persona
                        </p>
                        <ReusableButton
                          size="small"
                          fontSize="0.8rem"
                          buttonText={"ADD ONE NOW"}
                          onClick={() => setShowAddResourceModal(true)}
                        />
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          color: "#76828B",
                          fontSize: "0.9rem",
                          marginTop: "2rem",
                        }}
                      >
                        Supported file types are pdf, docx, and txt files.
                      </div>
                    </>
                  ) : showpersonaResources &&
                    personaResources?.length > 0 &&
                    datePickerRef.current.value ? (
                    <div className="empty-resource">
                      <img
                        style={{ width: "70px", height: "70px" }}
                        src={emptyResource}
                      />
                      <p className="my-3">
                        There are no resources added within the specified date
                        range.{" "}
                      </p>
                    </div>
                  ) : null}

                  {/* search results */}
                  {!showpersonaResources && documentList?.length > 0 ? (
                    documentList?.map(
                      (item, index) => (
                        // item?.show && (
                        <Accordion
                          key={index}
                          sx={{
                            borderLeft: "3px solid #4775D5",
                            marginBottom: "1em",
                          }}
                          expanded={expanded[index] || false}
                          onChange={handleAccordionChange(index)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{
                              borderTop: "1px solid #CED3D9",
                              margin: 0,
                            }}
                          >
                            <div className="d-flex w-100 align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <Checkbox
                                  checked={isSelected(item)}
                                  onClick={stopPropagation}
                                  onChange={() => {
                                    handleCheckboxChangeAccordion(item);
                                  }}
                                  style={{
                                    height: "1.714rem",
                                    width: "1.714rem",
                                  }}
                                />

                                <Box
                                  style={{ marginLeft: "1.5rem" }}
                                  sx={{
                                    position: "relative",
                                    display: "inline-flex",
                                  }}
                                >
                                  <CircularProgress
                                    size={44}
                                    thickness={4}
                                    value={Math.floor(item?.score)}
                                    sx={{ color: "#21DC82" }}
                                    variant="determinate"
                                  />
                                  {/* CircularProgress for the empty portion */}
                                  <CircularProgress
                                    size={44}
                                    thickness={4}
                                    value={item?.score}
                                    sx={{
                                      color: "rgba(33, 45, 44, 0.1)",
                                      position: "absolute",
                                    }}
                                    variant="determinate"
                                  />
                                  <Box
                                    sx={{
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      position: "absolute",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      component="div"
                                      color="#051D2C"
                                      className="progress-value"
                                    >
                                      {Math.floor(item?.score)}%
                                    </Typography>
                                  </Box>
                                </Box>
                                <div className="d-flex flex-column gap-1 ms-3">
                                  <Typography
                                    sx={{ fontSize: 14 }}
                                    className="file-name"
                                  >
                                    {item.fileName}
                                  </Typography>

                                  <div className="d-flex align-items-center gap-1 ">
                                    <FileGreyIcon />
                                    <div className="resource-type">
                                      Document
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center gap-2 me-3 ">
                                {/* <ChartIcon
                              style={{ height: "1.714rem", widht: "1.714rem" }}
                              onClick={stopPropagation}
                            /> */}
                                <BlueChatIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    isMatchingObject(item);
                                  }}
                                />
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              borderTop: "1px solid #CED3D9",
                              padding: "1.143rem",
                            }}
                          >
                            <div
                              className="mt-3"
                              style={{
                                fontSize: "1rem",
                                lineHeight: "1.7rem",
                                fontWeight: "400",
                              }}
                            >
                              {item?.content
                                ?.split(synonymsRegex)
                                .filter(Boolean)
                                .map((chunk, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className={
                                        lowercasedCleanedWordsArray?.includes(
                                          chunk.toLowerCase()
                                        )
                                          ? "highlight-text"
                                          : lowercasedSynonymsArray?.includes(
                                              chunk.toLowerCase()
                                            )
                                          ? "orange-text"
                                          : ""
                                      }
                                    >
                                      {chunk}
                                    </span>
                                  );
                                })}
                            </div>
                            <div className="d-flex gap-2 align-items-center justify-content-between mt-3">
                              <div
                                style={{ color: "#76828B", fontSize: "1rem" }}
                              >
                                <span>
                                  Created on{" "}
                                  <span style={{ color: "#051D2C" }}>
                                    {moment(item?.resourceDate).format(
                                      "DD/MM/YYYY"
                                    )}{" "}
                                  </span>
                                </span>
                                by{" "}
                                <span style={{ color: "#051D2C" }}>
                                  {item?.userName}
                                </span>{" "}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                      // )
                    )
                  ) : !showpersonaResources ? (
                    <div className="mt-5">
                      <Typography sx={{ textAlign: "center" }}>
                        No results found
                      </Typography>
                    </div>
                  ) : null}
                </div>
                {/* selected items */}
                {showpersonaResources &&
                selectedPersonaResources?.length > 0 ? (
                  <div className="selected-resources-container">
                    <div className="selected-resources ">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="resources-count">
                          <h4>
                            {selectedItems?.length ||
                              selectedPersonaResources?.length}
                          </h4>
                        </div>
                        <p className="resources-text">
                          {selectedItems?.length ||
                          selectedPersonaResources?.length > 1
                            ? "Resources selected"
                            : "Resource Selected"}
                        </p>
                      </div>
                      <div className="icons-container d-flex gap-3 align-items-center">
                        <div
                          onClick={() => {
                            nav("/chat", {
                              state: {
                                from: "resources",
                                resourceData:
                                  selectedPersonaResources?.length > 1
                                    ? selectedPersonaResources
                                    : selectedPersonaResources[0],
                              },
                            });
                          }}
                          className="action-item gap-1"
                        >
                          <ChatIconOutlined
                            style={{ height: "1.25rem", width: "1.25rem" }}
                          />
                          <span>Chat</span>
                        </div>
                        <div
                          onClick={OpenProjectModal}
                          className="action-item gap-1"
                        >
                          <CreateProjectIcon
                            style={{ height: "1.25rem", width: "1.25rem" }}
                          />
                          <span>Create Project</span>
                        </div>
                        {/* {selectedPersonaResources?.length > 0 && ( */}
                        <div
                          onClick={() => setconfirmDeleteFileModal(true)}
                          className="action-item gap-1"
                        >
                          <DeleteIcon
                            style={{ height: "1.25rem", width: "1.25rem" }}
                          />
                          <span>Delete</span>
                        </div>
                        {/* )} */}
                      </div>
                      <div
                        onClick={() => {
                          setSelectedPersonaResources([]);
                        }}
                        className="mx-2 cursor-pointer"
                      >
                        <CloseIcon style={{ height: "2rem", width: "2rem" }} />
                      </div>
                    </div>
                  </div>
                ) : !showpersonaResources && selectedItems?.length > 0 ? (
                  <div className="selected-resources-container">
                    <div className="selected-resources ">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="resources-count">
                          <h4>
                            {selectedItems?.length ||
                              selectedPersonaResources?.length}
                          </h4>
                        </div>
                        <p className="resources-text">
                          {selectedItems?.length ||
                          selectedPersonaResources?.length > 1
                            ? "Resources selected"
                            : "Resource Selected"}
                        </p>
                      </div>
                      <div className="icons-container d-flex gap-3 align-items-center">
                        {/* <div className="action-item gap-1">
                        <ChatIconOutlined
                          style={{ height: "1.25rem", width: "1.25rem" }}
                        />
                        <span>Chat</span>
                      </div> */}
                        <div
                          onClick={OpenProjectModal}
                          className="action-item gap-1"
                        >
                          <CreateProjectIcon
                            style={{ height: "1.25rem", width: "1.25rem" }}
                          />
                          <span>Create Project</span>
                        </div>
                        {/* <div className="action-item gap-1">
                        <DeleteIcon
                          style={{ height: "1.25rem", width: "1.25rem" }}
                        />
                        <span>Delete</span>
                      </div> */}
                      </div>
                      <div
                        onClick={() => {
                          setSelectedItems([]);
                          setSelectedPersonaResources([]);
                        }}
                        className="mx-2 cursor-pointer"
                      >
                        <CloseIcon style={{ height: "2rem", width: "2rem" }} />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* save project modal */}
      <BootstrapModal
        show={showAddResourceModal}
        onHide={() => {
          setShowAddResourceModal(false);
          setResourceType({ label: "Document" });
          setURL("");
          setSelectedFileResource();
        }}
        centered
      >
        <BootstrapModal.Header
          style={{ border: "none", backgroundColor: "#D4E2FF" }}
        >
          <div
            style={{
              backgroundColor: "#D4E2FF",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingBlock: "1rem",
              position: "relative",
            }}
          >
            <CloseIcon
              onClick={() => {
                setShowAddResourceModal(false);
                setResourceType({ label: "Document" });
                setURL("");
                setSelectedFileResource();
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: -5,
                right: -5,
              }}
            />
            <img style={{ width: "250px" }} src={addresource} />
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div className="mb-3">
            <p
              style={{
                color: "#051D2C",
                fontWeight: "500",
                fontSize: "1.5rem",
              }}
            >
              Add a resource
            </p>
            {/* <p style={{ color: "#051D2C", fontSize: "1rem" }}>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo
            </p> */}
          </div>
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ background: "white", cursor: "pointer" }}
            options={resourceTypes}
            value={resourceType}
            onChange={handleResourceTypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type of resource"
                placeholder="Select resource"
              />
            )}
          />
          {resourceType?.label === "Document" ? (
            <div onClick={handleUploadClick} className="icon-button gap-2 mt-4">
              {/* Hidden file input */}
              <input
                // onClick={handleUploadClick}
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={handleFileUpload}
              />
              {/* Upload icon with onClick handler */}
              <IconButton>
                <UploadIcon />
              </IconButton>
              <p
                style={{
                  color: "white",
                  fontWeight: "500",
                  fontSize: "1rem",
                  marginBottom: 0,
                }}
              >
                {selectedFileResource?.name || "UPLOAD FILE"}
              </p>
            </div>
          ) : (
            <div className="w-100 mt-4">
              <TextField
                value={url}
                onChange={(e) => setURL(e.target.value)}
                size="small"
                label="URL"
                placeholder="Enter url here"
                sx={{ width: "100%" }}
              />
            </div>
          )}

          <div className=" mt-4 d-flex gap-3 align-items-center justify-content-end">
            <Button
              onClick={() => {
                setShowAddResourceModal(false);
                setResourceType({ label: "Document" });
                setURL("");
                setSelectedFileResource();
              }}
              sx={{ color: "#000000", fontWeight: "600" }}
              variant="text"
            >
              Cancel
            </Button>
            <ReusableButton
              // isDisabled={!selectedFileResource && !url}
              onClick={() => {
                if (resourceType?.label === "Document") {
                  handleUploadFileResource();
                } else if (resourceType?.label === "External Link") {
                  handleUploadLinkResource();
                } else {
                }
              }}
              buttonText={"SAVE RESOURCE"}
            />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
      {/* create project modal */}
      <div>
        <BootstrapModal
          id="create-modal"
          show={createProjectModal}
          onHide={() => {
            setprojectDescription();
            setprojectName();
            setCreateProjectModal(false);
          }}
          centered
        >
          <BootstrapModal.Header
            style={{ border: "none", backgroundColor: "#D4E2FF" }}
          >
            <div
              style={{
                backgroundColor: "#D4E2FF",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                paddingBlock: "1rem",
                position: "relative",
              }}
            >
              <CloseIcon
                onClick={() => setCreateProjectModal(false)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: -5,
                  right: -5,
                }}
              />
              <img style={{ width: "250px" }} src={addresource} />
            </div>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <p
              style={{
                color: "#051D2C",
                fontWeight: "500",
                fontSize: "1.5rem",
              }}
            >
              Create a project
            </p>

            <div className="w-100 mt-4">
              <TextField
                // disabled={selectedProject?.projectName ? true : false}
                value={projectName}
                onChange={(e) => setprojectName(e.target.value)}
                size="small"
                label="Project Name"
                placeholder="Enter project name"
                sx={{ width: "100%" }}
              />
            </div>
            <div className="w-100 mt-4">
              <TextField
                value={projectDescription}
                onChange={(e) => setprojectDescription(e.target.value)}
                size="small"
                label="Project Description"
                placeholder="Enter project description"
                sx={{ width: "100%" }}
              />
            </div>

            <div className=" mt-4 d-flex gap-3 align-items-center justify-content-end">
              <Button
                onClick={() => setCreateProjectModal(false)}
                sx={{ color: "#000000", fontWeight: "600" }}
                variant="text"
              >
                Cancel
              </Button>
              <ReusableButton
                // isDisabled={!projectName || !projectDescription}
                onClick={CreateProjectHandler}
                buttonText={"CREATE PROJECT"}
              />
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      </div>
      <div>
        <BootstrapModal
          show={confirmDeleteFileModal}
          onHide={() => setconfirmDeleteFileModal(false)}
          centered
        >
          <BootstrapModal.Header
            style={{ border: "none", paddingBottom: 0 }}
            closeButton
          ></BootstrapModal.Header>
          <BootstrapModal.Body style={{ padding: 0 }}>
            <div className="d-flex flex-column gap-4 px-5  align-items-center">
              <img src={alertIcon} />
              <h6
                style={{
                  color: "#051D2C",
                  fontSize: "1.4rem",
                  textAlign: "center",
                  lineHeight: "1.4",
                }}
              >
                {selectedPersonaResources?.length > 1 && showpersonaResources
                  ? "Are you sure you want to delete multiple resources? This process may take time."
                  : "Are you sure you want to permanently delete this resource?"}
              </h6>
            </div>
            <div
              style={{ borderTop: "1px solid #CED3D9" }}
              className="mt-4 d-flex gap-1 align-items-center justify-content-between"
            >
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "1px solid #CED3D9",
                }}
              >
                <Button
                  onClick={() => setconfirmDeleteFileModal(false)}
                  sx={{ color: "#051D2C", fontWeight: "600" }}
                  variant="text"
                >
                  CANCEL
                </Button>
              </div>
              <div
                className="py-2 "
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    selectedPersonaResources?.length > 0 && showpersonaResources
                      ? handleDeleteMultipleResource()
                      : handleDeleteResource();
                  }}
                  sx={{ color: "#C51333", fontWeight: "600" }}
                  variant="text"
                >
                  YES, DELETE
                </Button>
              </div>
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      </div>
      <ChakraProvider>
        <Drawer
          size={"lg"}
          isOpen={isOpen}
          placement="right"
          onClose={() => {
            setSearchedKeyword();
            setSelectedKeyword();
            setLoadGraphData(true);
            // setKeywordsArray([])
            // setIsShowCount(true);
            processData({
              edges: [],
              nodes: [],
            });
            onClose();
          }}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <Tabs
                mt={8}
                orientation="horizontal"
                defaultIndex={0}
                className="tab-names"
              >
                <TabList borderBottom={"none"}>
                  <Tab
                    w="50%"
                    color="#76828B !important"
                    fontWeight={"500"}
                    fontSize={"1rem"}
                    _selected={{
                      color: "#4775D5 !important",
                      borderBottomColor: "#4775D5 !important",
                    }}
                  >
                    DATA
                  </Tab>
                  <Tab
                    w="50%"
                    color="#76828B !important"
                    fontWeight={"500"}
                    fontSize={"1rem"}
                    _selected={{
                      color: "#4775D5 !important",
                      borderBottomColor: "#4775D5 !important",
                    }}
                  >
                    ANALYSIS
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel className="px-0  mx-0">
                    <Stack px={6}>
                      <Text fontSize="lg" fontWeight={"semibold"} mb={0}>
                        Transcript
                      </Text>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <div className="d-flex align-items-center gap-1 ">
                          <FileGreyIcon />
                          <div
                            style={{ color: "#051D2C", fontWeight: "400" }}
                            className="resource-type"
                          >
                            Document
                          </div>
                        </div>
                        <div style={{ color: "#76828B", fontSize: "0.9rem" }}>
                          <span>
                            Created on{" "}
                            <span style={{ color: "#051D2C" }}>
                              {moment(targetResource?.resourceDate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                            </span>
                          </span>
                          by{" "}
                          <span style={{ color: "#051D2C" }}>
                            {targetResource?.resourceCreator?.name}
                          </span>{" "}
                        </div>
                      </div>
                      <Box>
                        {/* {paragraphsArray && paragraphsArray.length > 0 && (
                          <Stack
                            alignItems={"center"}
                            direction="row"
                            spacing={3}
                          >
                            <IconButton
                              icon={<FaBackward fontSize={20} />}
                              onClick={handleBack}
                              disabled={currentIndex === 0}
                              variant="pagination-button"
                            />
                            <Text>
                              <chakra.span fontWeight={"600"}>
                                {currentDocumentNumber}
                              </chakra.span>{" "}
                              of{" "}
                              <chakra.span fontWeight={"600"}>
                                {totalDocuments}
                              </chakra.span>{" "}
                              Documents
                            </Text>
                            <IconButton
                              icon={<TbPlayerTrackNextFilled fontSize={24} />}
                              onClick={handleNext}
                              disabled={
                                currentIndex === paragraphsArray &&
                                paragraphsArray?.length - 1
                              }
                              variant="pagination-button"
                            />
                          </Stack>
                        )} */}
                        <Input
                          mt={2}
                          placeholder="Search"
                          value={selectedKeyword}
                          onChange={(e) => setSelectedKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              // Call your function here
                              setSearchedKeyword(e.target.value);
                            }
                          }}
                          // ... (Textarea props)
                        />
                        <Box
                          ref={containerRef}
                          justifyContent={"center"}
                          fontSize="md"
                          whiteSpace={"pre-line"}
                          pb={3}
                          pt={2}
                        >
                          <Text
                            fontWeight="400"
                            fontSize="1em"
                            lineHeight="23px"
                            letterSpacing="wide"
                            whiteSpace={"pre-line"}
                          >
                            {truncatedContentText
                              ?.split(
                                new RegExp(`(\\b${searchedKeyword}\\b)`, "gi")
                              )
                              .filter(Boolean)
                              .map((chunk, index) => (
                                <span
                                  key={index}
                                  className={
                                    chunk?.toLowerCase() ===
                                    searchedKeyword?.toLowerCase()
                                      ? "highlight-text"
                                      : ""
                                  }
                                >
                                  {chunk}
                                </span>
                              ))}
                            {!showResourceContentMore &&
                              targetResource?.processedFileContent?.length >
                                1250 &&
                              "..."}
                            {targetResource?.processedFileContent?.length >
                              1250 && (
                              <>
                                <span> </span>
                                <span
                                  style={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleResourceContentShowMore}
                                >
                                  {showResourceContentMore
                                    ? "Show Less"
                                    : "Show More"}
                                </span>
                              </>
                            )}
                          </Text>
                        </Box>
                      </Box>
                    </Stack>
                  </TabPanel>

                  <TabPanel className="px-0  mx-0">
                    <Stack px={6} maxWidth={"100%"}>
                      {/* SUMMARY */}
                      <Stack
                        bg="#F6F6F6"
                        w="100%"
                        padding={"10px 1.5rem 10px 1.5rem"}
                        borderRadius={"0.571rem"}
                      >
                        <Text mb={0} fontWeight={"600"}>
                          Summary{" "}
                        </Text>
                        <Stack direction={"row"} alignItems="center">
                          <Text
                            fontWeight="400"
                            fontSize="sm"
                            lineHeight="23px"
                            letterSpacing="wide"
                          >
                            {truncatedText}
                            {!showMore && summary?.length > 200 && "..."}
                            {summary?.length > 200 && (
                              <>
                                <span> </span>
                                <span
                                  style={{
                                    textDecorationLine: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleShowMore}
                                >
                                  {showMore ? "Show Less" : "Show More"}
                                </span>
                              </>
                            )}
                          </Text>
                        </Stack>
                      </Stack>
                      {/* RE-ANALYZE BUTTON */}
                      <div className="d-flex py-3 w-100 justify-content-end">
                        <ChakraButton
                          size="sm"
                          fontWeight={"500"}
                          bg="transparent"
                          w="fit-content"
                          color="#4775D5"
                          _hover={{}}
                          border="1.2px solid #4775D5"
                          textTransform={"uppercase"}
                          onClick={ReAnalyzeKeywordsHandler}
                        >
                          Re-Analyze
                        </ChakraButton>
                      </div>
                      {/* KEYWORDS */}
                      <Stack
                        maxW={"100%"}
                        pb={4}
                        minH={"fit-content"}
                        overflowX={"auto"}
                        spacing={3}
                      >
                        {keywordsArray && keywordsArray.length > 0 ? (
                          keywordsArray?.map((category, categoryIndex) => (
                            <Stack
                              key={category.title}
                              alignItems={"center"}
                              direction="row"
                              w="100%"
                              spacing={2}
                            >
                              <Text
                                fontWeight={"450"}
                                mb={0}
                                fontSize="1rem"
                                minWidth={"23%"}
                                maxWidth={"140px"}
                              >
                                {category.title}
                              </Text>
                              <Stack w={"70%"} direction="row" spacing={3}>
                                {category?.people.map(
                                  (keywordObj, keywordIndex) =>
                                    keywordObj?.count > 0 && (
                                      <Stack
                                        key={keywordObj.keyword}
                                        bg={
                                          keywordObj.isSelected
                                            ? "#4775D5"
                                            : "transparent"
                                        }
                                        onClick={() => {
                                          handleKeywordClick(
                                            categoryIndex,
                                            keywordIndex
                                          );
                                          getGraphDataHandler(
                                            keywordObj.keyword
                                          );
                                        }}
                                        // Other props for styling
                                        border={"1.5px solid #4775D5"}
                                        // spacing={2}
                                        padding={"4px 4px 4px 10px"}
                                        // py={1}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent={"space-between"}
                                        rounded="full"
                                        // px={2}
                                        height={"27px"}
                                        minWidth={"fit-content"}
                                        cursor={"pointer"}
                                      >
                                        {/* Your keyword rendering code */}
                                        <Text
                                          margin={0}
                                          fontWeight={"400"}
                                          fontSize={"0.857rem"}
                                          whiteSpace={"nowrap"}
                                          color={
                                            keywordObj.isSelected
                                              ? "white"
                                              : "#051D2C"
                                          }
                                        >
                                          {keywordObj.keyword}
                                        </Text>

                                        <Box
                                          marginLeft={"0.571rem"}
                                          fontSize={"10px"}
                                          width="19px"
                                          height="19px"
                                          borderRadius="50%"
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          fontWeight={"500"}
                                          backgroundColor={
                                            keywordObj.isSelected
                                              ? "white"
                                              : "#4775D5"
                                          }
                                          color={
                                            keywordObj.isSelected
                                              ? "#4775D5"
                                              : "white"
                                          }
                                        >
                                          <div>{keywordObj?.count}</div>
                                        </Box>
                                      </Stack>
                                    )
                                )}
                              </Stack>
                            </Stack>
                          ))
                        ) : isLoading ? null : isKeywordsEmpty ? (
                          <Text>
                            Keywords are currently generating, you will receive
                            an email notification upon completion.
                          </Text>
                        ) : (
                          <SkeletonText
                            mt="4"
                            noOfLines={4}
                            spacing="4"
                            skeletonHeight="2"
                          />
                        )}
                      </Stack>
                      {/* graph */}
                      <Stack
                        height={"50vh"}
                        style={{ position: "relative" }}
                        // borderBlock={"2px solid black"}
                        border="1.4px solid #CED3D9"
                      >
                        <CustomFloatingEdgeGraph
                          graphData={graphData}
                          refresh={refresh}
                        />
                        <ImEnlarge
                          onClick={onOpenGraphModal}
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            right: 15,
                            top: 7,
                          }}
                        />
                      </Stack>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <>
          <Modal
            isCentered
            onClose={onCloseGraphModal}
            isOpen={isOpenGraphModal}
            size={"full"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <Stack height={"100vh"}>
                  <CustomFloatingEdgeGraph
                    graphData={graphData}
                    refresh={refresh}
                  />
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      </ChakraProvider>
    </>
  );
};

export default PersonaResources;
