import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Mind.css";
import NavBar from "../NavBar/NavBar";
import ChatEdit from "../../Images/edit-icon.svg";
import PieChart from "../../Images/pie-chart.svg";
import SideNav from "../sideNav/sideNav";
import cross from "../../Images/cross-icon.svg";
import { Button, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import PersonaMomentContext from "../../context/MomentsContext";
import {
  ChakraProvider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Button as ChakraButton,
  Tr,
} from "@chakra-ui/react";
import Unknown from "../../Images/Unknown.jpg";
import search from "../../Images/search.svg";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import Feedback from "../feedback/Feedback";
import moment from "moment/moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ChartIcon,
  ChatIcon,
  CopyIcon,
  FileIcon,
  SignalIcon,
} from "../../customIcons/customIcons";
import { CiSearch } from "react-icons/ci";
import {
  elasticSearchService,
  getDocumentsTextService,
} from "../../Service/service";
import CustomToaster from "../../utils/toaster/CustomToaster";
import ClearIcon from "@mui/icons-material/Clear";
const Mind = () => {
  const { isDarkMode, getMoments, moments, deleteMoment } =
    useContext(PersonaMomentContext);
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   console.log('location.state')
  //   if (location.state === "momentPage") {
  //     alert("moment added");
  //   }
  // }, [location]);

  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const [expanded, setExpanded] = useState({});
  const [momentsList, setMomentsList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [tempmomentsList, settempMomentsList] = useState([]);
  const [searchMomentText, setSearchMomentText] = useState();
  const [elasticSearchText, setElasticSearchText] = useState();
  const [searchedText, setSearchedText] = useState();
  const [searchedMethod, setSearchedMethod] = useState();
  const [synonymsArray, setSynonymsArray] = useState();
  const [concatenatedText, setConcatenatedText] = useState();

  const [regex, setRegex] = useState();
  const [synonymsRegex, setSynonymsRegex] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowMoments, setIsShowMoments] = useState(true);
  const [loader, setLoader] = useState(false);
  const [documentsLoader, setdocumentsLoader] = useState(false);
  const [isRenderer, setisRenderer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [noItemFound, setnoItemFound] = useState(false);
  const [targetMoment, setTargetMoment] = useState();
  const [targetMomentIndex, setTargetMomentIndex] = useState();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchMethodError, setSearchMethodError] = useState(false);
  const [isClickedSearch, setisClickedSearch] = useState(false);
  const [isSearchResultEmpty, setIsSearchResultEmpty] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedSearchMethod, setSelectedSearchMethod] = useState({
    label: "Search exact phrase",
  });
  const [isContextChecked, setIsContextChecked] = useState(false);
  const [showMoments, setShowMoments] = useState(true);

  const handleCheckboxChange = (event) => {
    setIsContextChecked(event.target.checked);
    // Additional logic if needed
  };

  const handleSearchMethodChange = (_, newValue) => {
    setSelectedSearchMethod(newValue);
    setSearchMethodError(isClickedSearch && newValue === null);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const closeAllAccordions = () => {
    const newExpandedState = {};
    documentList.forEach((_, index) => {
      newExpandedState[index] = false;
    });
    setExpanded(newExpandedState);
  };
  const handleChange = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: isExpanded,
    }));
    // if (isExpanded && documentList[index].documentText === null) {
    //   try {
    //     setLoader(true);
    //     getDocumentsTextService([documentList[index]?.url]).then((res) => {
    //       const updatedDocumentList = [...documentList];
    //       updatedDocumentList[index].documentText = res?.data[0]?.text;
    //       setDocumentList(updatedDocumentList);
    //       setLoader(false);
    //     });
    //   } catch (error) {
    //     setLoader(false);
    //     console.error("Error fetching document text:", error);
    //   }
    // }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const editMoment = (momentdetails) => {
    navigate("/updatemoment", {
      state: {
        momentDetail: momentdetails,
      },
    });
  };

  useEffect(() => {
    const fetchMoments = async () => {
      setIsLoading(true);
      const res = await getMoments();
      if (res.success) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    fetchMoments();
  }, [isRenderer]);
  useEffect(() => {
    if (moments) {
      setMomentsList(moments);
      settempMomentsList(moments);
    }
  }, [moments]);

  async function DeleteMomnet() {
    setIsLoading(true);
    const res = await deleteMoment(targetMoment?._id);
    if (res.success) {
      setIsLoading(false);
      closeModal();
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(res.message);
    } else {
      setIsLoading(false);
    }
  }
  // search persona handler
  const searchMomentHandler = async (text) => {
    // const activePersona = JSON.parse(
    //   localStorage.getItem("active_persona_data")
    // );
    const updatedArray = momentsList.filter((moment) => {
      const username = moment.momentName;
      if (username.toLowerCase().includes(text.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });
    if (updatedArray?.length == 0) {
      setnoItemFound(true);
    } else {
      setnoItemFound(false);
    }
    settempMomentsList(updatedArray);
  };

  const searchMethods = [
    { label: "Search all words" },
    { label: "Search exact phrase" },
    { label: "Search any phrase" },
    { label: "Context" },
  ];
  const stopPropagation = (event) => {
    // Prevent the event from reaching the parent (AccordionSummary)
    event.stopPropagation();
  };

  const elasticSearchHandler = async () => {
    setisClickedSearch(true);

    // Check if there is text in the search document field and a search method is selected
    if (
      !elasticSearchText ||
      (elasticSearchText && elasticSearchText.trim().length === 0)
    ) {
      // Set an error state for the search document field
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Search requires input text");
      setisClickedSearch(false);
      return;
    }

    if (!selectedSearchMethod) {
      // Set an error state for the search method
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please select a search method");
      setisClickedSearch(false);
      return;
    }

    try {
      closeAllAccordions();
      setIsLoading(true);
      setSearchedText(elasticSearchText);
      setSearchedMethod(selectedSearchMethod.label);

      const res = await elasticSearchService(
        activePersona?.creatorEmail,
        activePersona?.epClientEmail,
        elasticSearchText,
        selectedSearchMethod?.label === "Search all words" ? true : false,
        selectedSearchMethod?.label === "Search any phrase" ? true : false,
        selectedSearchMethod?.label === "Search exact phrase" ? true : false,
        selectedSearchMethod?.label === "Context" ? true : false
      );

      if (res.success) {
        if (res.data.length > 0) {
          setIsSearchResultEmpty(false);
        } else {
          setIsSearchResultEmpty(true);
        }
        setShowMoments(false);

        setDocumentList(res.data);
        if (res.synonyms !== null) {
          const highlightSynonyms = res.synonyms.join("|");
          const synnysmRegex = new RegExp(`\\b(${highlightSynonyms})\\b`, "gi");
          setSynonymsRegex(synnysmRegex);
          const lowercasedSynonymsArray = res.synonyms.map(synonym => synonym.toLowerCase());
          setSynonymsArray(lowercasedSynonymsArray);
        } 
      }

      setIsLoading(false);
      setisClickedSearch(false);
    } catch (error) {
      setIsLoading(false);
      setisClickedSearch(false);
      console.log("Error in elasticSearchHandler:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Call your function here
      elasticSearchHandler();
    }
  };

  const highlightWords = searchedText?.split(/\s+/).join("|");
  const highlightRegex = new RegExp(`\\b(${highlightWords})\\b`, "gi");


  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback />

      <NavBar />
      <div
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <div className="mainContainer">
            {" "}
            <input type="checkbox" id="sideNavDrawer-toggle" name="sideNavDrawer-toggle"/>
            <label for="sideNavDrawer-toggle" id="sideNavDrawer-toggle-label"></label>
            <SideNav />
          <div className="mainContainer-right">
            <div className="mindContainer">
              <div className="d-flex w-100 align-items-center justify-content-between mb-3">
                <h1 className="text-start">Mind</h1>
                <div className="d-flex align-items-center gap-3">
                  <ChakraProvider>
                    <ChakraButton
                      fontSize="sm"
                      bg="#051D2C"
                      color="white"
                      _hover={{}}
                    >
                      UPLOAD
                    </ChakraButton>
                    <ChakraButton
                      isDisabled={showMoments}
                      onClick={() => {
                        setDocumentList([]);
                        setShowMoments(true);
                        setIsSearchResultEmpty(false);
                        setSelectedSearchMethod({
                          label: "Search exact phrase",
                        });
                        setElasticSearchText("");
                      }}
                      fontSize="sm"
                      bg="#051D2C"
                      color="white"
                      _hover={{}}
                    >
                      View Moments
                    </ChakraButton>{" "}
                  </ChakraProvider>
                  <SignalIcon fontSize="1.143rem" />
                </div>
              </div>
              {/* elastic search  */}
              <div className="d-flex w-100 mb-5 gap-3">
                <Box
                  sx={{ width: "60%", alignItems: "center" }}
                  style={{ position: "relative" }}
                >
                  {/* TextField taking 80% of parent width */}
                  <TextField
                    value={elasticSearchText}
                    onChange={(e) => setElasticSearchText(e.target.value)}
                    fullWidth
                    label="Search Documents"
                    onKeyDown={handleKeyPress}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 13,
                      right: 5,
                      cursor: "pointer",
                    }}
                    onClick={elasticSearchHandler}
                  >
                    <CiSearch fontSize={"30px"} />
                  </div>
                </Box>
                <Box sx={{ width: "40%", display: "flex" }}>
                  {/* Another element taking 20% of parent width */}
                  <div style={{ width: "70%", marginRight: 15 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={searchMethods}
                      getOptionLabel={(option) => option.label} // Adjust based on your data structure
                      onChange={handleSearchMethodChange}
                      value={selectedSearchMethod}
                      // defaultValue={{ label: "Search exact phrase" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select search method"
                          error={searchMethodError}
                          helperText={
                            searchMethodError
                              ? "Please select a search method"
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={isContextChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Context"
                  /> */}
                </Box>
              </div>
              {/* accordian */}

              <div
                style={{ maxHeight: "70vh", overflowY: "auto" }}
                className="mb-3 gap-2"
              >
                {isSearchResultEmpty ? (
                  <Typography sx={{ textAlign: "center" }}>
                    No results found
                  </Typography>
                ) : (
                  documentList?.map((item, index) => (
                    <Accordion
                      key={index}
                      sx={{
                        borderLeft: "3px solid #4775D5",
                        marginBottom: "1em",
                      }}
                      expanded={expanded[index] || false}
                      onChange={handleChange(index)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div className="d-flex w-100 align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <Checkbox
                              onClick={(e) => {
                                stopPropagation(e);
                              }}
                            />
                            <Box
                              style={{ marginLeft: "10px" }}
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                              }}
                            >
                              <CircularProgress
                                size={46}
                                thickness={4}
                                value={Math.floor(item?.score)}
                                sx={{ color: "#4775D5" }}
                                variant="determinate"
                              />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="black"
                                  className="progress-value"
                                >
                                  {Math.floor(item?.score)}%
                                </Typography>
                              </Box>
                            </Box>
                            <Typography className="file-name">
                              {item?.fileName}
                            </Typography>
                          </div>
                          <div className="d-flex align-items-center gap-3 me-3 ">
                            <FileIcon onClick={stopPropagation} />
                            <ChatIcon onClick={stopPropagation} />
                            <ChartIcon onClick={stopPropagation} />
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ borderTop: "1px solid #CED3D9", padding: "1.143rem" }}
                      >
                        <div
                          className="mt-3"
                          style={{ fontSize: "1rem", lineHeight: "25px" }}
                        >
                          {searchedMethod !== "Search exact phrase" &&
                          searchedMethod !== "Context"
                            ? item?.content
                                ?.split(highlightRegex)
                                .filter(Boolean)
                                .map((chunk, index) => (
                                  <span
                                    key={index}
                                    className={
                                      searchedText
                                        ?.toLowerCase()
                                        .split(/\s+/)
                                        .includes(chunk.toLowerCase())
                                        ? "highlight-text"
                                        : ""
                                    }
                                  >
                                    {chunk}
                                  </span>
                                ))
                            : searchedMethod === "Context" && item?.content
                            ? item?.content
                                ?.split(synonymsRegex)
                                .filter(Boolean)
                                .map((chunk, index) => {

                                  return (
                                    <span
                                      key={index}
                                      className={
                                        // synonymsArray?.map((ele)=>ele.toLowerCase())
                                        synonymsArray?.includes(chunk.toLowerCase())
                                          ? "highlight-text"
                                          : ""
                                      }
                                    >
                                      {chunk}
                                    </span>
                                  );
                                })
                            : item?.content
                                ?.split(
                                  new RegExp(
                                    `(\\b${elasticSearchText}\\b)`,
                                    "gi"
                                  )
                                )
                                .filter(Boolean)
                                .map((chunk, index) => (
                                  <span
                                    key={index}
                                    className={
                                      chunk?.toLowerCase() ===
                                      elasticSearchText?.toLowerCase()
                                        ? "highlight-text"
                                        : ""
                                    }
                                  >
                                    {chunk}
                                  </span>
                                ))}
                        </div>
                        <div className="d-flex gap-2 align-items-center justify-content-between mt-3">
                          <div style={{ color: "#76828B", fontSize: "1rem" }}>
                            <span>
                              Created by{" "}
                              <span style={{ color: "#051D2C" }}>
                                M. Korpásh
                              </span>
                            </span>
                            <span>
                              on{" "}
                              <span style={{ color: "#051D2C" }}>
                                21/04/2023
                              </span>{" "}
                            </span>
                          </div>
                          {/* <ChakraProvider>
                          <ChakraButton
                            variant="outline"
                            color="#C51333"
                            borderColor="#C51333"
                            fontSize="1rem"
                            _hover={{}}
                          >
                            DELETE
                          </ChakraButton>
                        </ChakraProvider> */}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}
              </div>
              {/* </ChakraProvider> */}

              {/* <div className="d-flex justify-content-between">
                <div className="align-items-center d-flex gap-3 search-input px-2">
                  <span>
                    <img src={search} className="search-img" alt="" />
                  </span>
                  <input
                    type="text"
                    className="border-0"
                    placeholder="Search by title"
                    aria-label="search"
                    value={searchMomentText}
                    // onKeyUp={searchMomentHandler}
                    onChange={(e) => {
                      setSearchMomentText(e.target.value);
                      if (e.target.value.length > 0) {
                        searchMomentHandler(e.target.value);
                      } else {
                        settempMomentsList(momentsList);
                      }
                    }}
                  />
                </div>
                <ChakraProvider>
                  <ChakraButton
                    onClick={() => navigate("/moments")}
                    size="md"
                    fontWeight={"500"}
                    fontSize={"1rem"}
                    type="submit"
                    borderRadius={"5px"}
                    bg="#4775D5"
                    w="fit-content"
                    color="white"
                    _hover={{ bg: "black" }}
                  >
                    CREATE A MOMENT
                  </ChakraButton>
                </ChakraProvider>
              </div> */}
              {/* moments list */}
              {tempmomentsList?.length !== 0 && showMoments ? (
                <ChakraProvider>
                  <TableContainer
                    maxH={"60vh"}
                    overflowY="auto"
                    w="100%"
                    maxW="100%"
                    mt={6}
                    marginRight={8}
                  >
                    <Table variant="simple">
                      <Thead fontWeight={"bold"}>
                        <Tr>
                          <Th
                            className="moments-table"
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            TITLE
                          </Th>
                          <Th
                            className="moments-table"
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            DOCUMENTS
                          </Th>
                          <Th
                            color={"black !important"}
                            className="moments-table"
                          >
                            CREATED BY
                          </Th>
                          <Th
                            color={"black !important"}
                            className="moments-table"
                          >
                            CREATED ON
                          </Th>
                          <Th className="moments-table"></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {tempmomentsList.map((item, index) => (
                          <Tr>
                            <Td className="moments-table">
                              <div className="align-items-center d-flex gap-3">
                                <span
                                  style={{ maxWidth: "250px" }}
                                  className="moments-name"
                                >
                                  {item?.momentName}
                                </span>
                              </div>
                            </Td>
                            <Td
                              fontWeight={"600"}
                              className="moments-table"
                              maxW={"100px"}
                            >
                              <div className="d-flex mx-3">
                                {item?.documents?.length}
                              </div>
                            </Td>
                            <Td className="moments-table">
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    item?.momentOwner?.image === null
                                      ? Unknown
                                      : `${
                                          process.env.REACT_APP_IMAGES_URL
                                        }/Users/${
                                          item?.momentOwner?.image
                                        }?random=${Math.random()}`
                                  }
                                  style={{
                                    width: "1.75rem",
                                    height: "1.75rem",
                                  }}
                                  className="brendan me-2"
                                  alt="person"
                                />
                                <span>{item?.momentOwner?.name}</span>
                              </div>
                            </Td>
                            <Td className="moments-table">
                              <div className="d-flex">
                                {moment(
                                  item?.momentDate,
                                  "MMMM DD, YYYY"
                                ).format("DD/MM/YYYY")}
                              </div>
                            </Td>
                            <Td className="moments-table">
                              <div className="d-flex gap-3">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    item?.documents &&
                                    item?.documents.length > 0
                                      ? navigate("/analyze-moment", {
                                          state: { momentData: item },
                                        })
                                      : setSnackbarOpen(true);
                                    setSnackbarSeverity("error");
                                    setSnackbarMessage(
                                      "There are no documents in this moment."
                                    );
                                  }}
                                >
                                  <img
                                    src={PieChart}
                                    className="edit-img"
                                    alt=""
                                  />
                                </div>
                                <div
                                  className="cursor-pointer"
                                  onClick={() => editMoment(item)}
                                >
                                  <img
                                    src={ChatEdit}
                                    className="edit-img"
                                    alt=""
                                  />
                                </div>
                                <div
                                  className="cursor-pointer"
                                  // data-target="#deletMoment"
                                  onClick={
                                    () => {
                                      setTargetMoment(item);
                                      setTargetMomentIndex(index);
                                      openModal();
                                    }
                                    // DeleteMomnet(item.momentName, item._id, item, index)
                                  }
                                >
                                  <img
                                    src={cross}
                                    className="edit-img"
                                    alt="more-option"
                                  />
                                </div>
                              </div>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </ChakraProvider>
              ) : !showMoments ? null : (
                <div className="no-moments">
                  {noItemFound ? null : (
                    <p style={{ textAlign: "center", marginTop: "200px" }}>
                      No Moments exist yet. Please Create a Moment to view it
                      here
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>{" "}
      </div>
      {/* <!-- Modal --> */}
      {/* confirm delete popup */}
      <div>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header style={{ border: "none" }} closeButton>
            {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "1.5rem", fontSize: "1.3rem" }}>
              Are you sure you want to delete?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal}>
              No
            </Button>
            <Button variant="danger" onClick={DeleteMomnet}>
              Yes
              {/* {loader ? (
                <Spinner animation="border" size="sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Yes"
              )} */}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Mind;
