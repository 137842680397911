import React, { createContext, useEffect, useState } from "react";

const PersonaDataContext = createContext();
export function PersonaContext({ children }) {
  const [personaImageRandomNumbersForAll, setPersonaImageRandomNumbersForAll] =
    useState([]);
    // console.log("All personarandom number",personaImageRandomNumbersForAll)
  const [
    personaImageRandomNumbersForSelf,
    setPersonaImageRandomNumbersForSelf,
  ] = useState([]);
  // console.log("Self personarandom number",personaImageRandomNumbersForSelf)

  // Function to update personaImageRandomNumbersForAll
  const updatePersonaImageRandomNumberForAll = (personaId, randomNumber) => {
    setPersonaImageRandomNumbersForAll((prevState) => [
      ...prevState,
      { personaId, randomNumber },
    ]);
  };

  const updatePersonaImageRandomNumberForSelf = (personaId, randomNumber) => {
    setPersonaImageRandomNumbersForSelf((prevState) => [
      ...prevState,
      { personaId, randomNumber },
    ]);
  };

  // get all chat sessions function

  return (
    <PersonaDataContext.Provider
      value={{
        updatePersonaImageRandomNumberForAll,
        personaImageRandomNumbersForAll,
        updatePersonaImageRandomNumberForSelf,
        personaImageRandomNumbersForSelf
      }}
    >
      {children}
    </PersonaDataContext.Provider>
  );
}

export default PersonaDataContext;
