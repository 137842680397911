import React, { createContext, useEffect, useState } from "react";
import {
  DeleteAllResourceChatService,
  UpdateChatNameService,
  UpdateResourceChatNameService,
  deleteChatSessionsService,
  getAllChatSessionsService,
  getProjectsService,
  getResourceChatSessionsService,
} from "../Service/service";
import { useLocation, useNavigate } from "react-router-dom";

const PersonaChatContext = createContext();
export function ChatContext({ children }) {
  const nav = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const [sessions, setSessions] = useState([]);
  const [resourceChats, setResourceChats] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tempProjects, setTempProjects] = useState([]);
  const [updateChatArray, setChatArray] = useState(false);

  // get all chat sessions function
  const getChatSessions = async (projectId) => {
    const response = await getAllChatSessionsService(projectId);
    if (response.success) {
      setSessions(response.data);
    }

    return response;
  };

  const getResourceChatSessions = async () => {
    const response = await getResourceChatSessionsService();
    if (response.success) {
      setResourceChats(response.data);
    }

    return response;
  };
  const clearChatSessionArray = () => {
    setSessions([]);
    setResourceChats([]);
  };
  const getProjects = async () => {
    const response = await getProjectsService();
    if (response.success) {
      setProjects(response.data);
      setTempProjects(response.data);
    }

    return response;
  };
  // delete single chat session from chat list 
  const deleteSession = async (chatId) => {
    const response = await deleteChatSessionsService(chatId);
    if (response.success) {
      const updatedItems = sessions.filter((chat) => chat._id !== chatId);
      setSessions(updatedItems);
    }

    return response;
  };
  const deleteAllResourceSession = async (chatId) => {
    const response = await DeleteAllResourceChatService(chatId);
    if (response.success) {
      const updatedItems = resourceChats.filter((chat) => chat._id !== chatId);
      setResourceChats(updatedItems);
    }

    return response;
  };
  

  const updateChatTitle = async (chatId, title) => {
    const res = await UpdateChatNameService(chatId, title);
    if (res.success) {
      const updatedItems = sessions.map((item) => {
        if (item._id === chatId) {
          return { ...item, title: title };
        }
        return item;
      });

      setSessions(updatedItems);
    }

    return res;
  };
  const updateAllResourceChatTitle = async (chatId, title) => {
    const res = await UpdateResourceChatNameService(chatId, title);
    if (res.success) {
      const updatedItems = resourceChats.map((item) => {
        if (item._id === chatId) {
          return { ...item, title: title };
        }
        return item;
      });

      setResourceChats(updatedItems);
    }

    return res;
  };
  const emptyChatArray = () => {
    if (pathName.startsWith("/chat")) {
      nav("/chat");
    }
    setChatArray(!updateChatArray);
  };
  const filterProjectsByDateRange = (startDate, endDate) => {
    // alert('invoked')
    const filteredProjects = projects?.filter((resource) => {
      const parsedDate = new Date(resource?.projectDate);
      const start = new Date(startDate);
      const end = new Date(endDate);
      // Set hours, minutes, seconds, and milliseconds to 0 to compare only the date part
      parsedDate.setHours(0, 0, 0, 0);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      
      return parsedDate >= start && parsedDate <= end;
    });
    setTempProjects(filteredProjects)
    return filteredProjects;
  };
  const resetProject = ()=>{
    setTempProjects(projects)
  }
  return (
    <PersonaChatContext.Provider
      value={{
        sessions,
        getChatSessions,
        deleteSession,
        updateChatTitle,
        emptyChatArray,
        updateChatArray,
        getProjects,
        projects,
        clearChatSessionArray,
        getResourceChatSessions,
        resourceChats,
        deleteAllResourceSession,
        updateAllResourceChatTitle,
        filterProjectsByDateRange,
        tempProjects,
        resetProject
      }}
    >
      {children}
    </PersonaChatContext.Provider>
  );
}

export default PersonaChatContext;
