import React, { useCallback, useEffect } from "react";
import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  Controls,
} from "reactflow";
import "reactflow/dist/style.css";


import FloatingEdge from "./FloatingEdge.js";
import FloatingConnectionLine from "./FloatingConnectionLine.js";
import { createNodesAndEdges } from "./utils.js";
import {ImEnlarge} from 'react-icons/im'
import "./KnowledgeGraph.css";

// const { nodes: initialNodes, edges: initialEdges } = createNodesAndEdges();

// const edgeTypes = {
//   floating: FloatingEdge,
// };


const CustomFloatingEdgeGraph = ({ graphData , refresh}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    // Transform graphData into nodes and edges format
    const { nodes: transformedNodes, edges: transformedEdges } = createNodesAndEdges(graphData);
    // console.log("redndereer")
    // Update the nodes and edges states
    setNodes([...transformedNodes]);
    setEdges([...transformedEdges]);
        // Clean up state when component unmounts
        return () => {
          setNodes([]);
          setEdges([]);
        };
  }, [graphData,refresh]);


  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "floating",
            markerEnd: { type: MarkerType.Arrow },
          },
          eds
        )
      ),
    [setEdges]
  );

  return (
    <div className="floatingedges">
      
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        // edgeTypes={{ custom: CustomEdge }}
        connectionLineComponent={FloatingConnectionLine}
      >
        <Background />
        <Controls/>

      </ReactFlow>
      
    </div>
  );
};

export default CustomFloatingEdgeGraph;