import React, { useEffect, useState } from "react";
import "./ProfileTabs.css";
import edit from "../../../Images/edit-2.svg";
import {
  UpdateClientPersonaService,
  getClientPersonaService,
} from "../../../Service/service";
import LoadingSpinner from "../../../Assests/loaderspinner.gif";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { Button, ChakraProvider } from "@chakra-ui/react";
import { useContext } from "react";
import PersonaChatContext from "../../../context/ChatContext";
function PersonaTab() {
  const { updateChatArray } = useContext(PersonaChatContext);

  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientPersona, setClientPersona] = useState({
    audienceCompanySize: "",
    audienceProblem: "",
    audienceTitle: "",
    clientExpertise: "",
    clientIndustry: "",
    clientStylistic: "",
  });

  useEffect(() => {
    setLoading(true)
    getClientPersonaService().then((res) => {
      if (res.success) {
        if(res.data?.length>0)
        {
          setClientPersona(res?.data[0]?.clientPersona);
        }
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    });
  }, [updateChatArray]);

  const handleChangeClientPersona = (event) => {
    const { name, value } = event.target;
    setClientPersona((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateClientPersonaHandler = async () => {

    setLoading(true);
    const res = await UpdateClientPersonaService(clientPersona);
    if (res.success) {
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: res.message,
      });
      getClientPersonaService().then((res) => {
        if (res.success) {
          setClientPersona(res?.data[0]?.clientPersona);
        }
      });
      setIsEdit(false);
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: res.message,
      });
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}

      <div className="bg-white mb-16 persona-tab moment-border p-0 moment-text d-flex justify-content-between flex-column">
        {/* {!isEdit ? (
          <>
            <div
              div
              className="px-4 py-3 mt-2 d-flex justify-content-between color-darkGray box-header"
            >
              <span className="mx-1">Persona</span>
              <div
                role="button"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                <img src={edit} className="edit-img" alt="" />
              </div>
            </div>
            <div className=" not-edit-div mt-3 px-3">
              <div className="col-md-4 px-4">
                <span className="left-text">Client stylistic</span>
              </div>
              <div className="col-md-8 mb-4">
                <span>
                  {clientPersona?.clientStylistic != ""
                    ? clientPersona?.clientStylistic
                    : "No details yet"}
                </span>
              </div>
              <div className="col-md-4 px-4">
                <span className="left-text">Client Expertise</span>
              </div>
              <div className="col-md-8 mb-4">
                <span>
                  {clientPersona?.clientExpertise != ""
                    ? clientPersona?.clientExpertise
                    : "No details yet"}
                </span>
              </div>
              <div className="col-md-4 px-4">
                <span className="left-text">Client Industry</span>
              </div>
              <div className="col-md-8 mb-4">
                <span>
                  {clientPersona?.clientIndustry != ""
                    ? clientPersona?.clientIndustry
                    : "No details yet"}
                </span>
              </div>
              <div className="col-md-4 px-4">
                <span className="left-text">Audience title</span>
              </div>
              <div className="col-md-8 mb-4">
                <span>
                  {clientPersona?.audienceTitle != ""
                    ? clientPersona?.audienceTitle
                    : "No details yet"}
                </span>
              </div>
              <div className="col-md-4 px-4">
                <span className="left-text">Audience company size</span>
              </div>
              <div className="col-md-8 mb-4">
                <span>
                  {clientPersona?.audienceCompanySize != ""
                    ? clientPersona?.audienceCompanySize
                    : "No details yet"}
                </span>
              </div>
              <div className="col-md-4 px-4">
                <span className="left-text">Audience problem</span>
              </div>
              <div className="col-md-8 mb-4">
                <span>
                  {clientPersona?.audienceProblem != ""
                    ? clientPersona?.audienceProblem
                    : "No details yet"}
                </span>
              </div>
            </div>
          </>
        ) : ( */}
          <>
            {/* <div className="px-4 py-3 mt-2 d-flex justify-content-between color-darkGray box-header">
              <span className="mx-1">Persona</span>
            </div> */}
            <div className="edit-div py-2">
              <div className="">
                <div className="mt-2 w-100 persona-input mb-2">
                  Client stylistic
                </div>
              </div>

              <div className="">
                <div className="">
                  <input
                    placeholder="Enter client stylistic"
                    onChange={handleChangeClientPersona}
                    defaultValue={
                      clientPersona && clientPersona?.clientStylistic
                    }
                    name="clientStylistic"
                    type="text"
                    className="w-100 px-2"
                  />
                </div>
              </div>
            </div>
            <div className="edit-div py-2">
              <div className="">
                <div className="mt-2 persona-input mb-2">Client Expertise</div>
              </div>

              <div className="">
                <div className="">
                  <input
                    placeholder="Enter client expertise"
                    onChange={handleChangeClientPersona}
                    defaultValue={
                      clientPersona && clientPersona?.clientExpertise
                    }
                    name="clientExpertise"
                    type="text"
                    className="w-100 px-2"
                  />
                </div>
              </div>
            </div>
            <div className=" edit-div py-2">
              <div className="">
                <div className="mt-2 persona-input mb-2">Client Industry</div>
              </div>

              <div className="">
                <div className="">
                  <input
                    placeholder="Enter client industry"
                    defaultValue={
                      clientPersona && clientPersona?.clientIndustry
                    }
                    name="clientIndustry"
                    type="text"
                    onChange={handleChangeClientPersona}
                    className="w-100 px-2"
                  />
                </div>
              </div>
            </div>
            <div className=" edit-div py-2 ">
              <div className="">
                <div className="mt-2 persona-input mb-2">Audience title</div>
              </div>

              <div className="">
                <div className="">
                  <input
                    placeholder="Enter audience title"
                    name="audienceTitle"
                    defaultValue={clientPersona && clientPersona?.audienceTitle}
                    type="text"
                    onChange={handleChangeClientPersona}
                    className="w-100 px-2"
                  />
                </div>
              </div>
            </div>
            <div className="edit-div py-2">
              <div className="">
                <div className="mt-2 persona-input mb-2">
                  Audience company size
                </div>
              </div>

              <div className="">
                <div className="">
                  <input
                    placeholder="Enter audience company size"
                    defaultValue={
                      clientPersona && clientPersona?.audienceCompanySize
                    }
                    name="audienceCompanySize"
                    type="text"
                    onChange={handleChangeClientPersona}
                    className="w-100 px-2"
                  />
                </div>
              </div>
            </div>
            <div className="edit-div py-2">
              <div className="">
                <div className="mt-2 persona-input mb-2">Audience problem</div>
              </div>

              <div className="">
                <div className="">
                  <input
                    placeholder="Enter audience problem"
                    defaultValue={
                      clientPersona && clientPersona?.audienceProblem
                    }
                    name="audienceProblem"
                    type="text"
                    onChange={handleChangeClientPersona}
                    className="w-100 px-2"
                  />
                </div>
              </div>
            </div>
            <div className="footer-div mt-4 d-flex justify-content-end gap-3">
              <ChakraProvider>
                <Button
                  onClick={updateClientPersonaHandler}
                  size="md"
                  fontWeight={"600"}
                  fontSize={"1rem"}
                  type="submit"
                  borderRadius={"5px"}
                  bg="#4775D5"
                  w="fit-content"
                  color="white"
                  _hover={{ bg: "black" }}
                >
                  SAVE CHANGES
                </Button>
              </ChakraProvider>
            </div>
          </>
        {/* // )} */}
      </div>
    </>
  );
}

export default PersonaTab;
