import React from 'react'
import PreviewPersonaHeader from './PreviewPersonaHeader'
import './preview.css'
import TheAudience from './TheAudience'
import Goals from './Goals'
import Challenges from './Challenges'
import { useLocation } from 'react-router-dom'
import ContentGuideLines from './ContentGuideLines'
import Campaign from './Campaign'
import DowloadCampaign from './DownloadPreview'
import { useMediaQuery } from '@material-ui/core'
import BlankCampaign from './BlankCampaign'
const BrandGuidePreview = ({decodedData}) => {
  const location = useLocation();
  const smallScreen = useMediaQuery("(max-width:1200px)");
  const mobileScreen = useMediaQuery("(max-width:768px)");
  // const queryParams = new URLSearchParams(location.search);
  // const encodedData = queryParams.get('data');
  // const encodedData = localStorage.getItem('preview-key')
  // const decodedData = encodedData ? JSON.parse(decodeURIComponent(encodedData)) : null;
  // console.log(decodedData)
  return (
    <div style={{background:"#F1F2F2",padding:mobileScreen?"0.6rem":smallScreen?"1.2rem":"2.5rem"}}>
      {/* <DowloadCampaign/> */}
      <PreviewPersonaHeader
      data={decodedData}
      />
      {
        decodedData?.Program_Goals &&
      <Goals
      data={decodedData}
      />
      }
      {
        decodedData?.The_Audience &&
      <TheAudience
      data={decodedData}
      />
      }
      {
        decodedData?.Audience_Challenges &&

        <Challenges
        data={decodedData}
        />
      }
      {
        decodedData?.Content_Guidelines &&
        <ContentGuideLines
        data={decodedData}
        />
      }
       {
        decodedData?.Program_Goals &&
      <Campaign
      data={decodedData}
      />
      }
      {decodedData?.Blank_Campaign &&
      <BlankCampaign data={decodedData}/>
      }
    </div>
  )
}

export default BrandGuidePreview
