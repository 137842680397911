import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function UsageGraph() {
  const [state] = useState({
    series: [
      {
        name: 'Blue Line',
        data: [0, 20,0, -20, -60, -20,0 ]
      },
      {
        name: 'Green Line',
        data: [-60, -20, -60, 60, 0,20,-60 ]
      }
    ],
    options: {
      chart: {
        type: 'line',
        height: 156,
        toolbar: {
            show: false // Hide menu button
          },
      },
      colors: ['#0BBAE6', '#4ABEA7'], // Green and blue colors
      legend: {
        show: false // Hide legend
      },
      grid: {
        show: true,
        borderColor: '#e0e0e0', // Grid box color
        strokeDashArray: 0, // Solid grid lines
        position: 'back', // Grid behind the chart
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        row: {
          colors: undefined, // No alternate row colors
          opacity: 0.5 // Grid box opacity
        },
        column: {
          colors: undefined, // No alternate column colors
          opacity: 0.5 // Grid box opacity
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',],
        labels: {
          style: {
            colors: '#000' // Text color black
          }
        }
      },
      yaxis: {
        min: -100,
        max: 100,
        labels: {
          style: {
            colors: '#000' // Text color black
          }
        }
      },
      stroke: {
        width: 2, // Reduce line width
        // curve: 'smooth', // Smoothen the lines
        // lineCap: 'round' // Round line ends
      },
      markers: {
        size: 2, // Adjust marker size
        strokeColors: ['#0BBAE6', '#4ABEA7'], // Green and blue marker colors
        strokeWidth: 1, // Adjust marker stroke width
        strokeOpacity: 1, // Adjust marker stroke opacity
        fillOpacity: 1, // Adjust marker fill opacity
        shape: 'circle' // Set marker shape to circle
      }
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="line" height={200} />
      </div>
    </div>
  );
}

export default UsageGraph;
