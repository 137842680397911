import React, { useContext, useState } from "react";
// import NavBar from "../NavBar/NavBar";
// import SideNav from "../sideNav/sideNav";
import { Tabs, TabList, TabPanels, Tab, TabPanel, ChakraProvider } from "@chakra-ui/react";
import "../ClientProfile/ClientProfile.css";
import "./UserProfile.css";
import PersonaMomentContext from "../../context/MomentsContext";
import MyProfile from "./MyProfile";
// import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import Subscriptions from "./Subscriptions";
import Notifications from "./Notifications";
import CustomToaster from "../../utils/toaster/CustomToaster";
// import Feedback from "../feedback/Feedback";
function UserProfile() {
  const location = useLocation();

  const { isDarkMode } = useContext(PersonaMomentContext);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous page
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <>
      {/* <Feedback/>

      <NavBar /> */}
          <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <div
        className={
          isDarkMode ? "persona-container-dark p-3" : "persona-container-light p-3"
        }
        style={{ background: '#FAFAFB', height: '100vh' }}
      >
        <div className="editProfile">
          {/* <div className="userHeader hide-desktop">
            <div>
              <SideNav />
            </div>
          </div> */}
          <div className="mainContainer">
            {/* <div className="mainContainer-left">
              {" "}
              <SideNav />
            </div> */}
            <div className="userprofile-container justify-content-center">
              <div className="userprofile">
                <div className="d-flex justify-content-center align-items-center">
                  <h1>
                    {location?.state?.userId
                      ? "Update user profile"
                      : "Update your profile"}
                  </h1>
                  
                  <span  
                  className="close-icon"
                  onClick={handleGoBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
                      <path d="M22.5 8.14062L7.5 23.1406" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.5 8.14062L22.5 23.1406" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <ChakraProvider>
                  <Tabs
                    orientation="horizontal"
                    defaultIndex={0}
                    className="tab-names"
                  >
                    <TabList borderBottom={"none"} justifyContent={'space-between'}>
                      <Tab
                      flexGrow={'1'}
                        color={
                          isDarkMode ? "white !important" : "black !important"
                        }
                        fontWeight={"500"}
                      >
                        PROFILE
                      </Tab>
                      <Tab
                      flexGrow={'1'}
                        fontWeight={"500"}
                        color={
                          isDarkMode ? "white !important" : "#051D2C !important"
                        }
                      // isDisabled={true}
                      >
                        NOTIFICATIONS
                      </Tab>
                      <Tab
                      flexGrow={'1'}
                        fontWeight={"500"}
                        // isDisabled={true}
                        color={
                          isDarkMode ? "white !important" : "#051D2C !important"
                        }
                      >
                        SUBSCRIPTIONS
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel className="px-0 pb-0">
                        <MyProfile
                        setSnackbarMessage={setSnackbarMessage}
                        setSnackbarOpen={setSnackbarOpen}
                        setSnackbarSeverity={setSnackbarSeverity}
                        />
                      </TabPanel>
                      <TabPanel className="px-0 pb-0">
                        <Notifications/>
                      </TabPanel>
                      <TabPanel className="px-0 pb-0"><Subscriptions /></TabPanel>
                    </TabPanels>
                  </Tabs>
                </ChakraProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default UserProfile;
