import React from "react";
import Word from "../../Images/word.png";
import Transcript from "../../Images/transcript.png";
import Delete from "../../Images/trash-2.svg";
import "./CommonFascimile.css";
const CommonFascimile = ({ onDelete }) => {
  const handleDeleteClick = () => {
    onDelete();
  };
  return (
    <>
      <div>
        <div className="facsimile-container mx-auto">
          <div className="d-flex justify-content-between px-5 py-2">
            <h5>Ext Link</h5>
            <h5>Approved</h5>
            <h5 style={{ marginRight: "40px" }}>Transcript</h5>
          </div>
          <div className="d-flex justify-content-between">
            <input type="url" className="my-2 h-50" />
            <div className="container d-flex justify-content-around my-1">
              <label
                style={{ width: "1.5rem", height: "1.5rem" }}
                htmlFor="photo-upload"
              >
                <img
                  // className="my-1"
                  src={Word}
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <input
                  id="photo-upload"
                  type="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </label>
            </div>
            <div className="container d-flex justify-content-around my-1">
              <label
                htmlFor="photo-upload"
                style={{ width: "1.5rem", height: "1.5rem" }}
                className=""
              >
                <img
                  className="transcript-img"
                  src={Transcript}
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <input
                  id="photo-upload"
                  type="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </label>
            </div>
            <button className="btn my-2" onClick={handleDeleteClick}>
              <img
                src={Delete}
                alt="Delete Button"
                className="delete-btn"
              ></img>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonFascimile;
