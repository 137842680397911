import React, { useState, useRef, useEffect, useContext } from "react";
import AllPersonas from "../AllPersonas/AllPersonas";
import NavBar from "../NavBar/NavBar";
import "./Persona.css";
import uploadPhoto from "../../Images/uploadPhoto.svg";
// import Swal from "sweetalert2";
import Footer from "../Footer/Footer";
import PersonaMomentContext from "../../context/MomentsContext";
// import { useLocation, useNavigate } from "react-router";
// import { Button, Divider } from "@chakra-ui/react";
import UserProfileContext from "../../context/UserContext";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../feedback/Feedback";
import ReusableButton from "../reusableComponents/ReusableButton";
// import ReusableTextField from "../reusableComponents/ReuseableTextField";
import CustomToaster from "../../utils/toaster/CustomToaster";
// import { TextField, useMediaQuery } from "@mui/material";
import CustomTextField from "./CustomTextField";
const Persona = () => {
  // const matches = useMediaQuery("(min-width:1400px)");
  const { isDarkMode } = useContext(PersonaMomentContext);
  const { getUserDetailsFunction } = useContext(UserProfileContext);
  const [profilePic, setProfilePic] = useState(null);
  const [f_Name, setF_Name] = useState("");
  const [l_Name, setL_Name] = useState("");
  const [comapnyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [documentName, setDocumentName] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState({});
  const buttonRef = useRef(null);
  const [updatePersona, setUpdatePersona] = useState(false);
  const [showUser, setshowUser] = useState(true);
  const [loading, setLoading] = useState(false);
  const [switchMyPersonas, setSwitchMyPersonas] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const active_user = JSON.parse(localStorage.getItem("user_info"));
  const [jobTitle, setJobTitle] = useState("");
  const [inputValues, setInputValues] = useState({
    websiteUrl: "",
    instagram: "",
    linkedin: "",
    tikTok: "",
    twitter: "",
  });
  const handleInputChange = (e) => {
    if (e.target.name === "profilePic") {
      const selectedFile = e.target.files[0];
      if (selectedFile && !isFileFormatValid(selectedFile)) {
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Please upload png, jpg and jpeg files only"); // You can customize this message
        return;
      }

      setProfilePic(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
      }
    } else if (e.target.name === "f_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setF_Name(sanitizedValue);
    } else if (e.target.name === "l_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setL_Name(sanitizedValue);
    } else if (e.target.name === "comapnyName") {
      setCompanyName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "documentName") {
      setDocumentName(e.target.files[0]);
      // console.log(e.target.files[0]);
    }
  };

  const handleCloseModal = () => {
    // Reset the file input when closing the modal
    setF_Name("");
    setL_Name("");
    setCompanyName("");
    setEmail("");
    setProfilePic(null);
    setPreviewImage(null);
    setError({});
  };

  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };
  const validateForm = () => {
    const linkValidationRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const nameRegex = /^[a-zA-Z\s_]+$/;
    const companyNameRegex = /^[a-zA-Z0-9\s_]+$/;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    let errors = {};

    if (!f_Name?.trim()) {
      errors.firstName = "First name is required.";
    } else if (!nameRegex.test(f_Name)) {
      errors.firstName = "Only alphabets, underscore, and spaces are allowed.";
    } else if (f_Name?.length > 30) {
      errors.firstName = "First name should not exceed 30 characters.";
    } 
    if (!l_Name?.trim()) {
      errors.lastName = "Last name is required.";
    } else if (!nameRegex.test(l_Name)) {
      errors.lastName = "Only alphabets, underscore, and spaces are allowed.";
    } else if (l_Name?.length > 30) {
      errors.lastName = "Last name should not exceed 30 characters.";
    } 
    if (jobTitle !== "" && !nameRegex.test(jobTitle)) {
      errors.jobTitle = "Only alphabets, underscore, and spaces are allowed.";
    } else if (jobTitle?.length > 50) {
      errors.jobTitle = "Job Title should not exceed 50 characters.";
    } 
    if (!comapnyName?.trim()) {
      errors.companyName = "Company name is required.";
    } else if (!companyNameRegex.test(comapnyName)) {
      errors.companyName =
        "Only alphabets, underscore, and spaces are allowed.";
    } else if (comapnyName?.length > 50) {
      errors.companyName = "Company name should not exceed 50 characters.";
    } 
    if (!emailRegex.test(email) || !email) {
      errors.personaEmail = "Please enter valid email";
    }
    if (
      inputValues?.websiteUrl !== "" &&
      !linkValidationRegex.test(inputValues?.websiteUrl)
    ) {
      errors.websiteUrl = "Invalid website URL";
    }
    if (
      inputValues?.instagram !== "" &&
      !linkValidationRegex.test(inputValues?.instagram)
    ) {
      errors.instagram = "Invalid instagram URL";
    }
    if (
      inputValues?.linkedin !== "" &&
      !linkValidationRegex.test(inputValues?.linkedin)
    ) {
      errors.linkedin = "Invalid linkedin URL";
    }
    if (
      inputValues?.tikTok !== "" &&
      !linkValidationRegex.test(inputValues?.tikTok)
    ) {
      errors.tikTok = "Invalid tikTok URL";
    }
    if (
      inputValues?.twitter !== "" &&
      !linkValidationRegex.test(inputValues?.twitter)
    ) {
      errors.twitter = "Invalid twitter URL";
    }

    // Add other validations here

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const createPersona = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }
    setLoading(true);
    setError({});
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const formData = new FormData();
    formData.append("firstName", f_Name);
    formData.append("lastName", l_Name);
    formData.append("epClientEmail", email);
    formData.append("epClientCompany", comapnyName);
    formData.append("userId", active_user?._id);
    formData.append("image", profilePic);
    formData.append("jobTitle", jobTitle ?? "");
    formData.append("socialLinks[websiteUrl]", inputValues?.websiteUrl ?? "");
    formData.append("socialLinks[instagram]", inputValues?.instagram ?? "");
    formData.append("socialLinks[tikTok]", inputValues?.tikTok ?? "");
    formData.append("socialLinks[linkedin]", inputValues?.linkedin ?? "");
    formData.append("socialLinks[twitter]", inputValues?.twitter ?? "");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/personas/createpersona`,
        {
          method: "POST",
          headers: parsedToken,
          body: formData,
        }
      );
      let json = await response.json();

      if (json.success) {
        setError({});
        setF_Name("");
        setL_Name("");
        setCompanyName("");
        setEmail("");
        setLoading(false);
        buttonRef.current.click();
        setUpdatePersona(!updatePersona);
        setProfilePic(null);
        setPreviewImage(null);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(json.message);
        setJobTitle("");
        setInputValues({
          websiteUrl: "",
          instagram: "",
          linkedin: "",
          tikTok: "",
          twitter: "",
        });
      } else {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(json.message);
      }
    } catch (error) {
      setLoading(false);
      // Handle fetch error
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(error);
    }
  };
  const childRef = useRef();
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    async function getUserData() {
      // setLoading(true);
      try {
        const res = await getUserDetailsFunction(parsedToken?.data?._id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getUserData();
  }, []);
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleChange = (fieldName) => (event) => {
    const newValue = event.target.value;

    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };
  return (
    <>
      <CustomToaster
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <div
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <NavBar />
        <div className="persona-container">
          <Feedback />
          <div className="pageHeader">
            <h3
              className={
                isDarkMode ? "persona-heading-dark" : "persona-heading-light"
              }
            >
              Personas
            </h3>
            {parsedToken?.data?.role !== "Collaborator" ? (
              <ReusableButton
                data-bs-toggle="modal"
                data-bs-target="#newPersonaModal"
                buttonText="CREATE PERSONA"
                isDisabled={false} // Adjust the logic as needed
                loading={false} // Adjust the logic as needed
              />
            ) : null}
          </div>{" "}
          <AllPersonas
            showUser={(val) => setshowUser(val)}
            updatePersona={updatePersona}
            ref={childRef}
            handleSwitchMyPersonas={(value) => setSwitchMyPersonas(value)}
          />
        </div>
        <div
          className="modal fade"
          id="newPersonaModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="newPersonaModal"
        >
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: isDarkMode ? "#051D2C" : "#fff" }}
            >
              {/* <div className="modal-header border-0">
              
              </div> */}
              <div
                className="align-items-center justify-content-center create-persona-container modal-body d-flex flex-column "
                style={{ background: "rgb(250, 250, 251)", overflow: "unset" }}
              >
                <div className="close-cross">
                  <button
                    onClick={handleCloseModal}
                    type="button"
                    ref={buttonRef}
                    className="btn-close modal-close-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className=" d-flex flex-column inner-wrapper">
                  <h1 className="create-persona-title">Create a Persona</h1>

                  <div className="upload-image-container">
                    <div>
                      <label
                        htmlFor="img-upload"
                        className="d-flex cursor-pointer"
                      >
                        <input
                          id="img-upload"
                          type="file"
                          name="profilePic"
                          className="fileUpload-Custom"
                          accept=".png,.jpg,.jpeg"
                          // value={profilePic}
                          onChange={handleInputChange}
                        />
                        {previewImage ? (
                          <img
                            src={previewImage}
                            alt="preview"
                            style={{
                              // width: "65px",
                              // height: "65px",
                              borderRadius: "100%",
                            }}
                          />
                        ) : (
                          <img
                            style={{
                              // width: "65px",
                              // height: "65px",
                              borderRadius: "100%",
                            }}
                            src={uploadPhoto}
                            alt=""
                          />
                        )}
                      </label>
                    </div>
                    <div className="ms-3">
                      <h1 className="upload-heading">Upload your photo</h1>
                      <div className="upload-desc">
                        Photos help your teammates recognize you in emissary
                      </div>
                    </div>
                  </div>
                  <div className="personaForm">
                    <CustomTextField
                      id="f_name"
                      label="First Name"
                      placeholder="First Name"
                      value={f_Name}
                      name="f_name"
                      onChange={(e) => setF_Name(e.target.value)}
                      error={error.firstName}
                      errorMessage={error.firstName}
                    />

                    <CustomTextField
                      id="l_name"
                      label="Last Name"
                      placeholder="Last Name"
                      name="l_name"
                      value={l_Name}
                      onChange={(e) => setL_Name(e.target.value)}
                      error={error.lastName}
                      errorMessage={error.lastName}
                    />
                    <CustomTextField
                      id="comapnyName"
                      label=" Company Name"
                      placeholder=" Company Name"
                      name="comapnyName"
                      value={comapnyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      error={error.companyName}
                      errorMessage={error.companyName}
                    />
                    <CustomTextField
                      id="email"
                      label="Email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={error.personaEmail}
                      errorMessage={
                        error.personaEmail && !email
                          ? "This field is required"
                          : error.personaEmail
                          ? "Please enter a valid email"
                          : null
                      }
                    />
                    <CustomTextField
                      id="jobTitle"
                      label="Job Title"
                      placeholder="Job Title"
                      name="jobTitle"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      error={error.jobTitle}
                      errorMessage={error.jobTitle}
                    />
                    <CustomTextField
                      id="WebsiteURL"
                      label="Website URL"
                      placeholder="Website URL"
                      value={inputValues?.websiteUrl}
                      onChange={handleChange("websiteUrl")}
                      error={error.websiteUrl} // Add this line if you want to show an error state
                      errorMessage={error.websiteUrl}
                    />
                    <CustomTextField
                      id="Instagram"
                      label="Instagram"
                      placeholder="Instagram"
                      value={inputValues?.instagram}
                      onChange={handleChange("instagram")}
                      error={error.instagram} // Add this line if you want to show an error state
                      errorMessage={error.instagram}
                    />
                    <CustomTextField
                      id="Linkedin"
                      label="Linkedin"
                      placeholder="Linkedin"
                      value={inputValues?.linkedin}
                      onChange={handleChange("linkedin")}
                      error={error.linkedin} // Add this line if you want to show an error state
                      errorMessage={error.linkedin}
                    />
                    <CustomTextField
                      id="Tiktok"
                      label="Tiktok"
                      placeholder="Tiktok"
                      value={inputValues?.tikTok}
                      onChange={handleChange("tikTok")}
                      error={error.tikTok} // Add this line if you want to show an error state
                      errorMessage={error.tikTok}
                    />
                    <CustomTextField
                      id="Twitter"
                      label="Twitter/X"
                      placeholder="Twitter/X"
                      value={inputValues?.twitter}
                      onChange={handleChange("twitter")}
                      error={error.twitter} // Add this line if you want to show an error state
                      helperText={error.twitter}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <ReusableButton
                      buttonText="CREATE"
                      onClick={createPersona}
                      isDisabled={loading} // Adjust the logic as needed
                      // loading={loading}
                      width="100px"
                      // height='45px'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Persona;
