// ReusableTextField.js

import React, { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ReusableTextField = ({
  type,
  label,
  value,
  onChange,
  error,
  helperText,
  showEyeIcon = false,
  focused=false,
  ...otherProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const inputRef = useRef(null);

  // useEffect(() => {
  //   setTimeout(()=>{
  //     // inputRef.current.click()
  //     document.getElementsByTagName('input')[0].click()
  //     console.log(inputRef.current)
  //   },500)
  // }, []);
  return (
    <TextField
      // inputRef={inputRef}
      focused={focused}
      // InputLabelProps={{ shrink: value ? true : false }}
      label={label}
      value={value}
      onChange={onChange}
      type={showPassword ? "text" : type}
      error={error}
      helperText={helperText}
      size="medium"
      fullWidth
      {...otherProps}
      sx={{
        "& .MuiFormHelperText-root": {
          alignSelf: "flex-start", // Adjust vertical alignment to flex-start
          marginInline: 0,
          marginTop: "5px",
        },
      }}
      InputProps={{
        endAdornment: showEyeIcon && (
          <span
            onClick={togglePasswordVisibility}
            style={{ cursor: "pointer", alignSelf: "center" }}
          >
            {showPassword ? (
              <FaEyeSlash color='#76828B' fontSize="1.286rem" />
            ) : (
              <FaEye color='#76828B' fontSize="1.286rem" />
            )}
          </span>
        ),
      }}
    />
  );
};

export default ReusableTextField;
