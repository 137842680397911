import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';


function KeywordAnalysisChart({keywordData}) {
    const [state,setState] = useState(
      {
        series: [
          {
            name: 'Key Words',
            // data: [20000, 18000, 25000, 20000, 15000, 5000, 24000, 22000, 20000,10000,20000,25000]
            data: keywordData
          },
        
        ],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false // Hide menu button
              },
          },
         
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '6px',
              endingShape: 'rounded',
              borderRadius:'2'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
            labels: {
                style: {
                  colors: ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff'] // Change text color,
                }
              },
              axisBorder: {
                show: false // Hide bottom line
              },
              axisTicks: {
                show: false // Hide even lines
              }
          },
          yaxis: {
            title: {
            //   text: 'thousands',
              style: {
                color: '#fff' // Change text color
              }
            },
            labels: {
                formatter: function(value) {
                    return value / 1000 + 'k'; // Append "k" symbol to each y-axis label
                  },
                style: {
                  colors: '#fff'
                }
              }
          },
          fill: {
            opacity: 1,
            colors: ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff'] // Change text color

          },
   
          grid: {
            show: false // Hide gridlines
          },
          tooltip:{
            enabled:false
          }
        }
      }
      );
    useEffect(() => {
      setState({
        series: [
          {
            name: 'Key Words',
            // data: [20000, 18000, 25000, 20000, 15000, 5000, 24000, 22000, 20000,10000,20000,25000]
            data: keywordData
          },
        
        ],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false // Hide menu button
              },
          },
         
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '6px',
              endingShape: 'rounded',
              borderRadius:'2'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
            labels: {
                style: {
                  colors: ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff'] // Change text color,
                }
              },
              axisBorder: {
                show: false // Hide bottom line
              },
              axisTicks: {
                show: false // Hide even lines
              }
          },
          yaxis: {
            title: {
            //   text: 'thousands',
              style: {
                color: '#fff' // Change text color
              }
            },
            labels: {
                formatter: function(value) {
                    return value / 1000 + 'k'; // Append "k" symbol to each y-axis label
                  },
                style: {
                  colors: '#fff'
                }
              }
          },
          fill: {
            opacity: 1,
            colors: ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff'] // Change text color

          },
   
          grid: {
            show: false // Hide gridlines
          },
          tooltip:{
            enabled:false
          }
        }
      })
    }, [keywordData])
    
      return (
        <div>
          <div id="chart">
            <ReactApexChart options={state.options} series={state.series} type="bar" height={156} />
          </div>
       
        </div>
      );
}

export default KeywordAnalysisChart