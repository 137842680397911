import {
  Button as ChakraButton,
  ChakraProvider,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select as ChakraSelect,
  SkeletonCircle,
  Stack,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import React from "react";
import DataTable from "react-data-table-component";
import Unknown from "../../../Images/Unknown.jpg";

import "./usertable.css";
import {
  GetUserPersonasService,
  addCollaboratorService,
  changeUserRoleService,
  changeUserStatusService,
  deleteUserService,
  getAllUsersService,
  getPersonaEmailsService,
  inviteUserService,
} from "../../../Service/service";
import search from "../../../Images/search.png";
import emailIcon from "../../../Images/email.png";
import { useState } from "react";
import { useEffect } from "react";
// import { BiChevronDown } from "react-icons/bi";
// import LoadingSpinner from "../../../Assests/loaderspinner.gif";
import moment from "moment/moment";
// import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import Select from "react-select";
import { RotatingLines } from "react-loader-spinner";
import alertIcon from "../../../Images/alert.svg";
import assignowner from "../../../Images/assignowner.svg";
import CustomToaster from "../../../utils/toaster/CustomToaster";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";
import ReusableButton from "../../reusableComponents/ReusableButton";

const tableStyles = {
  responsiveWrapper: {
    style: {
      border: "1px solid #ced3d9",
      borderRadius: "0.857rem",
    },
  },
  headCells: {
    style: {
      fontWeight: "400", // Example style property
      fontSize: "1rem",
      color: "#76828B",
      background: "#fafafb",
      paddingLeft: "1.429rem",
      paddingRight: "1.429rem",
    },
    divider: {
      color: "red", // Change this color to your desired divider color
    },
  },
  cells: {
    style: {
      padding: "1.286rem 1.429rem",
      // minWidth:'400px !important'
    },
  },

  rows: {
    style: {
      fontSize: "1.143rem",
    },
  },
  pagination: {
    style: {
      border: "unset",
    },
  },
};

const UsersDataTable = ({ toView }) => {
  const loggedInUser = JSON.parse(localStorage.getItem("auth_token"));
  const [loading, setloading] = useState(false);
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenChangeRole,
    onOpen: onOpenChangeRole,
    onClose: onCloseChangeRole,
  } = useDisclosure();
  const [TargetUserId, setTargetUserId] = useState();
  const [TargetUsername, setTargetUsername] = useState();
  const [email, setemail] = useState();
  const [userRole, setUserRole] = useState();
  const [targetuserStatus, setTargetUserStatus] = useState();
  const [userData, setUserData] = useState([]);
  // console.log("🚀 ~ UsersDataTable ~ userData:", userData);

  const [selectedUserPersonas, setselectedUserPersonas] = useState([]);
  // console.log(
  //   "🚀 ~ UsersDataTable ~ selectedUserPersonas:",
  //   selectedUserPersonas
  // );
  const [tempuserData, setTempUserData] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [isRenderer, setisRenderer] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState("");
  const [personaSelection, setPersonaSelection] = useState([]);
  // console.log("seelcted personas", personaSelection);

  useEffect(() => {
    // Initialize personaSelection with the IDs of all personas
    if (selectedUserPersonas && selectedUserPersonas.length > 0) {
      setPersonaSelection(selectedUserPersonas.map((persona) => persona._id));
    }
  }, [selectedUserPersonas]);

  const handlePersonaSelection = (event) => {
    const { name, checked } = event.target;
    const personaId = name;
    // console.log(checked);
    if (name === "selectAll") {
      // alert('called')
      if (checked) {
        // If "Select All" checkbox is checked, update the personaSelection array to contain all persona IDs
        setPersonaSelection(
          selectedUserPersonas?.map((persona) => persona._id)
        );
      } else {
        // If "Select All" checkbox is unchecked, empty the personaSelection array
        setPersonaSelection([]);
      }
    } else {
      let updatedSelection = Array.isArray(personaSelection)
        ? [...personaSelection]
        : [];
      // debugger
      // If checkbox is checked, add the personaId to the selection array
      if (checked && !updatedSelection.some((obj) => obj == personaId)) {
        updatedSelection.push(personaId);
      }
      // If checkbox is unchecked, remove the personaId from the selection array
      else if (!checked && updatedSelection.some((obj) => obj == personaId)) {
        updatedSelection = updatedSelection.filter((id) => id !== personaId);
      }

      // Remove "Select All" from the selection array if it exists
      // updatedSelection = updatedSelection.filter(id => id !== 'selectAll');

      // Update personaSelection array
      setPersonaSelection(updatedSelection);
    }
  };

  const top100Films = userData?.map((itm) => ({
    label: itm?.username,
    email: itm?.email,
  }));

  const handleUserSelect = (event, value) => {
    if (value) {
      setSelectedEmail(value.email);
    } else {
      setSelectedEmail("");
    }
  };

  const fetchUserPersonas = async (id) => {
    setloading(true);
    const res = await GetUserPersonasService(id);
    // console.log(res);
    if (res.success) {
      setloading(false);
      setselectedUserPersonas(res?.data?.yourPersonas);
    } else {
      setloading(false);
    }
  };
  // invite user functionalities
  const [selectedUserEmail, setSelectedUserEmail] = useState();
  const [personaEmailsList, setPersonaEmailsList] = useState({});
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [error, setErrors] = useState({});
  const [value, setValue] = React.useState();
  const [userType, setUserType] = useState("existing");
  const [selectedPersonaEmailIds, setSelectedPersonaEmailIds] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [errorToast, setErrorToast] = useState(false);
  const handleSelectChange = (selectedOptions) => {
    // Extract the IDs from the selected options
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedPersonaEmailIds(selectedIds);
  };

  const handleInputChangePersonas = (inputValue, actionMeta) => {
    // Check if the action type is "remove-value" (when the cross icon is clicked)
    if (actionMeta.action === "remove-value") {
      const removedId = actionMeta.removedValue.value;
      setSelectedPersonaEmailIds((prevSelectedPersonaEmailIds) =>
        prevSelectedPersonaEmailIds.filter((id) => id !== removedId)
      );
    }
  };

  const formatDataForReactSelect = (data) => {
    const options = [];

    for (const userEmail in data) {
      const personas = data[userEmail];
      const userOption = {
        label: userEmail,
        value: userEmail,
        isDisabled: true, // Disable the user email as an option
      };

      const personaOptions = personas.map((persona) => ({
        label: persona.PersonaEmail,
        value: persona.PersonaId,
      }));

      options.push(userOption, ...personaOptions);
    }

    return options;
  };

  const addCollaboratorHandler = async () => {
    let errors = {};
    if (userType === "existing" && email === undefined) {
      errors.userEmailError = true;
    }
    if (userType === "new" && !selectedUserEmail) {
      errors.userEmailError = true;
    }
    if (userType === "new" && value === undefined) {
      errors.roleError = true;
    }
    if (
      userType === "new" &&
      value === "Collaborator" &&
      selectedPersonaEmailIds.length == 0
    ) {
      errors.personaEmailError = true;
    }
    if (userType === "existing" && selectedPersonaEmailIds.length == 0) {
      errors.personaEmailError = true;
    }

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      // console.log(errors);
    } else {
      setErrors({});
      try {
        setloading(true);
        const res = await addCollaboratorService(
          userType == "existing" ? email?.value : selectedUserEmail,
          selectedPersonaEmailIds,
          userType == "new" ? true : false,
          userType == "new" ? value : null
        );
        if (res.success) {
          setemail();
          setSelectedUserEmail("");
          setUserType("existing");
          setValue();
          setSelectedPersonaEmailIds([]);
          setErrors({});
          onClose();
          setloading(false);
          // if (userType == "new") {
          setisRenderer(!isRenderer);
          // }
          setErrorToast(true);
          setSnackbarMessage(res.message);
          setSnackbarSeverity("success");
        } else {
          setloading(false);
          setErrorToast(true);
          setSnackbarMessage(res.message);
          setSnackbarSeverity("error");
        }
      } catch (error) {
        setloading(false);
        console.log(
          "🚀 ~ file: UsersDataTable.js:262 ~ addCollaboratorHandler ~ error:",
          error
        );
      }
    }
  };

  const getPersonaEmailsHandler = async (email) => {
    const response = await getPersonaEmailsService(email);
    if (response.success) {
      setPersonaEmailsList(response.data);
    }
  };
  const getUsersDataHandler = async () => {
    const res = await getAllUsersService("All");
    const filteredUsers = res?.data?.filter(
      (user) => user.status !== "Pending"
    );
    const newOptions = filteredUsers?.map((user) => ({
      label: user.email,
      value: user.email,
    }));
    setOptions(newOptions);
  };

  const handleInputChange = (inputValue) => {
    const filtered = options.filter((option) =>
      option.label.includes(inputValue)
    );

    setFilteredOptions(filtered);
  };

  const handleChange = (selectedOption) => {
    setemail(selectedOption);
    getPersonaEmailsHandler(selectedOption?.value);
  };
  const formattedData = formatDataForReactSelect(personaEmailsList);
  useEffect(() => {
    getPersonaEmailsHandler();
    getUsersDataHandler();
  }, []);
  // table columns
  const columns = [
    {
      name: "Title",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // minWidth: "300px",
          }}
        >
          {row?.image === null ? (
            <span>
              <FaUserCircle
                style={{
                  height: "2rem",
                  width: "2rem",
                  marginRight: "10px",
                }}
                className="brook-img"
              />
            </span>
          ) : (
            <ChakraProvider>
              <Image
                borderRadius="full"
                boxSize="2rem"
                marginRight={"10px"}
                src={
                  row.resizedImage
                    ? `${process.env.REACT_APP_IMAGES_URL}/Users/${
                        row?.resizedImage
                      }?random=${Math.random()}`
                    : `${process.env.REACT_APP_IMAGES_URL}/Users/${
                        row?.image
                      }?random=${Math.random()}`
                }
                alt="user-avatar"
              />
            </ChakraProvider>
          )}
          {/* {row.image != null ? (
            <ChakraProvider>
              <Image
                borderRadius="full"
                boxSize="1.714rem"
                marginRight={"10px"}
                src={`${process.env.REACT_APP_IMAGES_URL}/Users/${
                  row?.image
                }?random=${Math.random()}`}
                alt="user-avatar"
              />
            </ChakraProvider>
          ) : (
            <FaUserCircle
              style={{ height: "1.714rem", width: "1.714rem", marginRight: "10px" }}
              className="brook-img"
            />
          )} */}
          <div
            onClick={
              row.username && row.status !== "Pending"
                ? () => nav(`/user-profile`, { state: { userId: row.id } })
                : () => {}
            }
            style={{
              fontWeight: "600",
              cursor:
                row.username && row.status !== "Pending"
                  ? "pointer"
                  : "default",
            }}
          >
            {row.username ? (
              row.username
            ) : (
              <span style={{ color: "gray" }}>No username yet</span>
            )}
          </div>
        </div>
      ),
    },
    {
      name: "Email",
      sortable: true,
      cell: (row) => (
        <div
          style={{
            // fontWeight: "600",
            color: "#76828B",
            // whiteSpace:'nowrap'
            // minWidth: "400px",
          }}
        >
          {row.email}
        </div>
      ),
    },
    {
      name: "User role",
      cell: (row) => (
        <div
          style={
            {
              // fontWeight: "600",
              // minWidth: "300px"
            }
          }
        >
          {/* <ChakraSelect
            isDisabled={
              (loggedInUser?.data?.role !== "SuperAdmin" &&
                row.role == "Admin") ||
                row.status == "Pending" ||
                row.role === "SuperAdmin"
                ? true
                : false
            }
            border={'unset'}
            borderBottom={"1px solid #76828B"}
            fontSize={'1.143rem'}
            // borderRadius={"md"}
            size="sm"
            cursor="pointer"
            fontWeight={"400"}
            onChange={(e) => {
              setUserRole(e.target.value);
              setTargetUserId(row.id);
              setShowModal3(true);
            }}
            defaultValue={row.role}
            w={"130px"}
          >
            <option style={{ width: "130px" }} value="Admin">
              Admin
            </option>
            <option style={{ width: "130px" }} value="User">
              Editor
            </option>
            <option style={{ width: "130px" }} value="Collaborator">
              Collaborator
            </option>
            {row.role === "SuperAdmin" && (
              <option style={{ width: "130px" }} value="SuperAdmin">
                SuperAdmin
              </option>
            )}
          </ChakraSelect> */}
          <ChakraProvider>
            {(loggedInUser?.data?.role !== "SuperAdmin" &&
              row.role == "Admin") ||
            row.status == "Pending" ||
            row.role === "SuperAdmin" ? (
              <div
              // style={{ fontWeight: "600" }}
              >
                {row.role}
              </div>
            ) : (
              <Menu>
                <MenuButton>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      borderBottom: "1px solid #76828B",
                      width: "120px",
                      justifyContent: "space-between",
                      paddingBottom: "6px",
                    }}
                  >
                    {row.role === "User" ? "Editor" : row.role}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_5713_29735)">
                        <path
                          d="M7 10.3594L12 15.3594L17 10.3594H7Z"
                          fill="#76828B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5713_29735">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0 0.359375)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </MenuButton>
                <MenuList fontSize={"md"}>
                  <MenuItem
                    value="Admin"
                    onClick={() => {
                      setUserRole("Admin");
                      setTargetUserId(row.id);
                      setShowModal3(true);
                    }}
                  >
                    Admin
                  </MenuItem>
                  <MenuItem
                    value="User"
                    onClick={() => {
                      setUserRole("User");
                      setTargetUserId(row.id);
                      setShowModal3(true);
                    }}
                  >
                    Editor
                  </MenuItem>
                  <MenuItem
                    value="Collaborator"
                    onClick={() => {
                      setUserRole("Collaborator");
                      setTargetUserId(row.id);
                      setShowModal3(true);
                    }}
                  >
                    Collaborator
                  </MenuItem>
                  {row.role === "SuperAdmin" && (
                    <MenuItem
                      value="SuperAdmin"
                      onClick={() => {
                        setUserRole("SuperAdmin");
                        setTargetUserId(row.id);
                        setShowModal3(true);
                      }}
                    >
                      SuperAdmin
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            )}
          </ChakraProvider>
        </div>
      ),
      // center:true
    },
    {
      name: "Status",
      cell: (row) => (
        <ChakraProvider>
          {row.role === "SuperAdmin" || row.status == "Pending" ? (
            <div
            // style={{ fontWeight: "600" }}
            >
              {row.status}
            </div>
          ) : row.role == "Admin" && loggedInUser?.data?.role === "Admin" ? (
            <div
            // style={{ fontWeight: "600" }}
            >
              {row.status}
            </div>
          ) : (
            <Menu isLazy>
              <MenuButton>
                <div
                  className="d-flex align-items-center"
                  style={{
                    //  fontWeight: "600"
                    borderBottom: "1px solid #76828B",
                    width: "120px",
                    justifyContent: "space-between",
                    paddingBottom: "6px",
                  }}
                >
                  {row.status}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5713_29735)">
                      <path
                        d="M7 10.3594L12 15.3594L17 10.3594H7Z"
                        fill="#76828B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5713_29735">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0 0.359375)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </MenuButton>
              <MenuList fontSize={"md"}>
                <MenuItem
                  onClick={() => {
                    setTargetUserId(row.id);
                    setTargetUserStatus(row.status);
                    setShowModal4(true);
                  }}
                >
                  {row.status == "Active"
                    ? row.role == "Admin"
                      ? "Deactivate Admin"
                      : "Deactivate User"
                    : row.role == "Admin"
                    ? "Activate Admin"
                    : "Activate User"}
                </MenuItem>
                {row.status === "InActive" ? (
                  <MenuItem
                    isDisabled={
                      loggedInUser?.data?.role == "Admin" &&
                          row.role == "admin"
                        ? true
                        : false
                    }
                    onClick={() => {
                      setTargetUserId(row.id);
                      setTargetUsername(row.username);
                      fetchUserPersonas(row.id);
                      openModal2();
                    }}
                  >
                    Delete user details
                  </MenuItem>
                ) : null}
                <MenuItem isDisabled={true}>
                  Reset Two Factor Authentification
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </ChakraProvider>
      ),
    },
    {
      name: "Personas",
      cell: (row) => (
        <div
        // style={{ fontWeight: "600" }}
        >
          {row.personas?.length===0?"No personas yet":
             <AvatarGroup
             sx={{
               "& .MuiAvatarGroup-avatar": {
                 width: "2rem", // Adjust width as needed
                 height: "2rem", // Adjust height as needed
                 fontSize:'0.65rem'
               },
             }}
             max={4}
           >
             {row?.personas?.map((itm) => (
               <Avatar
                 style={{ width: "2rem", height: "2rem" }}
                 alt="Remy Sharp"
                 src={
                   itm.image === null
                     ? Unknown
                     : itm.resizedImage
                     ? `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                         itm.creatorEmail
                       }/${itm.resizedImage}?random=${Math.random()}`
                     : `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                         itm.creatorEmail
                       }/${itm.image}?random=${Math.random()}`
                 }
               />
             ))}
           </AvatarGroup>
          }
       
        </div>
      ),
    },
    // {
    //   name: "INVITED BY",
    //   cell: (row) => (
    //     <div style={{ fontWeight: "600", minWidth: "300px" }}>
    //       {row.invitedBy}
    //     </div>
    //   ),
    // },
    // {
    //   name: "JOINED",
    //   cell: (row) => (
    //     <div style={{ fontWeight: "600" }}>
    //       {moment(row.joined).format("LL")}
    //     </div>
    //   ),
    // },
    // {
    //   name: "LAST ACTIVE",
    //   cell: (row) => (
    //     <div style={{ fontWeight: "600" }}>
    //       {row.lastActive
    //         ? moment(row.lastActive).format("LL")
    //         : moment(row.joined).format("LL")}
    //     </div>
    //   ),
    // },
    // {
    //   name: "ACTIONS",
    //   center: true,
    //   style: {
    //     zIndex: "999",
    //   },
    //   cell: (row) => (
    //     <div style={{ fontWeight: "600", fontSize: "1.5rem" }}>

    //     </div>
    //   ),
    // },
    {
      name: "",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M13 12.3594C13 11.8071 12.5523 11.3594 12 11.3594C11.4477 11.3594 11 11.8071 11 12.3594C11 12.9117 11.4477 13.3594 12 13.3594C12.5523 13.3594 13 12.9117 13 12.3594Z"
              stroke="#051D2C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 5.35938C13 4.80709 12.5523 4.35938 12 4.35938C11.4477 4.35938 11 4.80709 11 5.35938C11 5.91166 11.4477 6.35938 12 6.35938C12.5523 6.35938 13 5.91166 13 5.35938Z"
              stroke="#051D2C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 19.3594C13 18.8071 12.5523 18.3594 12 18.3594C11.4477 18.3594 11 18.8071 11 19.3594C11 19.9117 11.4477 20.3594 12 20.3594C12.5523 20.3594 13 19.9117 13 19.3594Z"
              stroke="#051D2C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
    },
  ];
  const openModal2 = () => {
    setShowModal2(true);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };
  const getData = async (message) => {
    try {
      setloading(true);
      const res = await getAllUsersService(
        // "All"
        toView == "users" ? "User" : toView == "admins" ? "Admin" : "All"
      );
      if (res.success) {
        setUserData(
          res.data.map((apiEntry) => ({
            id: apiEntry._id,
            email: apiEntry.email,
            role: apiEntry.role,
            username: apiEntry.name,
            status: apiEntry.status,
            personas: [
              ...(apiEntry?.myPersonas?.yourPersonas || []),
              ...(apiEntry?.myPersonas?.allowedPersonas || []),
            ],
            joined: apiEntry.timestamp,
            lastActive: apiEntry.lastActive,
            invitedBy: apiEntry.invitedBy
              ? apiEntry.invitedBy
              : "Brook Zimmatore",
            image: apiEntry.image,
            resizedImage: apiEntry?.resizedImage,
            // myPersonas:apiEntry?.myPersonas?.yourPersonas
          }))
        );
        setTempUserData(
          res.data.map((apiEntry) => ({
            id: apiEntry._id,
            email: apiEntry.email,
            role: apiEntry.role,
            username: apiEntry.name,
            status: apiEntry.status,
            personas: [
              ...(apiEntry?.myPersonas?.yourPersonas || []),
              ...(apiEntry?.myPersonas?.allowedPersonas || []),
            ],
            joined: apiEntry.timestamp,
            lastActive: apiEntry.lastActive,
            invitedBy: apiEntry.invitedBy
              ? apiEntry.invitedBy
              : "Brook Zimmatore",
            image: apiEntry.image,
            resizedImage: apiEntry?.resizedImage,
            // myPersonas:apiEntry?.myPersonas?.yourPersonas
          }))
        );
        setloading(false);
        if (message) {
          // Swal.fire({
          //   icon: "success",
          //   title: message,
          // });
          setErrorToast(true);
          setSnackbarMessage(message);
          setSnackbarSeverity("success");
        }
      } else {
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.log("🚀 ~ file: UsersDataTable.js:219 ~ getData ~ error:", error);
    }
  };
  const deleteUserHandler = async () => {
    if (
      !selectedEmail &&
      selectedUserPersonas?.length !== 0 &&
      personaSelection?.length !== 0
    ) {
      setErrorToast(true);
      setSnackbarMessage("Please select a new owner.");
      setSnackbarSeverity("error");
      return;
    }

    if (
      personaSelection?.length === 0 &&
      selectedUserPersonas?.length !== 0 &&
      selectedEmail
    ) {
      setErrorToast(true);
      setSnackbarMessage("Please select persona to transfer ownership.");
      setSnackbarSeverity("error");
      return;
    }
    try {
      setloading(true);

      const payload = {
        userId: TargetUserId,
        allPersonas:
          personaSelection?.length === selectedUserPersonas?.length
            ? true
            : false,
        selectedPersonas:
          personaSelection?.length === selectedUserPersonas?.length
            ? []
            : personaSelection,
        newOwner: selectedEmail,
      };
      // console.log("payload", payload);
      const res = await deleteUserService(payload);
      if (res.success) {
        setisRenderer(!isRenderer);
        setloading(false);
        closeModal2();
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("success");
      } else {
        setloading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const changeUserRoleHandler = async () => {
    try {
      setloading(true);
      const res = await changeUserRoleService(TargetUserId, userRole);
      if (res.success) {
        onCloseChangeRole();
        setShowModal3(false);
        setUserRole();
        setTargetUserId();
        // setisRenderer(!isRenderer);
        // const updatedUserData = await userData.map((user) => {
        //   if (user.id === TargetUserId) {
        //     // Update the user's role
        //     return { ...user, role: userRole };
        //   }
        //   return user;
        // });

        // Update the state with the modified user data
        // setUserData(updatedUserData);
        // getData(res.mesage)
        // setloading(false);

        getData(res.message);
      } else {
        setloading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setloading(false);
      console.log(
        "🚀 ~ file: UsersDataTable.js:262 ~ inviteUserHandler ~ error:",
        error
      );
    }
  };
  const changeUserStatusHandler = async () => {
    try {
      const currentUserStatus = targetuserStatus;
      const currentUserId = TargetUserId;
      setloading(true);

      const res = await changeUserStatusService(
        TargetUserId,
        targetuserStatus === "Active" ? false : true
      );

      if (res.success) {
        setShowModal4(false);
        setTargetUserStatus();
        setTargetUserId();

        // Update the user's status in the userData array
        getData(res.message);
        // setloading(false);
        // setErrorToast(true);
        // setSnackbarMessage(res.message);
        // setSnackbarSeverity("success");
      } else {
        setloading(false);
        setErrorToast(true);
        setSnackbarMessage(res.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setloading(false);
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [isRenderer]);
  const formatDate = (timestamp) => {
    return moment(timestamp).format("LL"); // Adjust the format as needed
  };
  const searchUserTableHandler = (searchText) => {
   
    const updatedArray = userData.filter((user) => {
      if (
        user.email?.toLowerCase().includes(searchText?.toLowerCase()) ||
        user.role?.toLowerCase().includes(searchText?.toLowerCase()) ||
        user.username?.toLowerCase().includes(searchText?.toLowerCase()) ||
        user.status?.toLowerCase().includes(searchText?.toLowerCase()) 
        // user.personas?.toLowerCase().includes(searchText?.toLowerCase()) ||
        // formatDate(user.joined)
        //   ?.toLowerCase()
        //   .includes(searchText?.toLowerCase()) ||
        // user.lastActive?.toLowerCase().includes(searchText?.toLowerCase()) ||
        // user.invitedBy?.toLowerCase().includes(searchText?.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });

    setTempUserData(updatedArray);
  };
 
  useEffect(() => {

    const performSearch = () => {
    //  console.log(searchValue)
      if (searchValue.length > 0) {
        searchUserTableHandler(searchValue);
      } else {
        setTempUserData(userData);
      }
    };

    // Set a timeout to perform the search operation after a delay
    const timeoutId = setTimeout(performSearch, 300); // Adjust the delay as needed

    // Cleanup function to clear the timeout when the component unmounts or searchValue changes
    return () => clearTimeout(timeoutId);
  }, [searchValue]); 
  
  const handleCloseErrorToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorToast(false);
  };
  return (
    <>
      <CustomToaster
        open={errorToast}
        onClose={handleCloseErrorToast}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <div className="table-container">
        {loading && (
          <div className="overlay">
            <div className="loaderImage-container">
              {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
              <RotatingLines
                strokeColor="#051D2C"
                strokeWidth="4"
                animationDuration="0.85"
                width="50"
                visible={true}
              />
            </div>
          </div>
        )}
        <ChakraProvider>
          <Stack mb={6} direction="row" justifyContent={"space-between"}>
            <div className="d-flex align-items-center">
              <div
                style={{ border: "none" }}
                className="align-items-center d-flex gap-3 search-input px-2"
              >
                <span>
                  <img
                    style={{ width: "17px", height: "17px" }}
                    src={search}
                    className="search-img"
                    alt=""
                  />
                </span>
                <input
                  style={{ width: "250px" }}
                  type="text"
                  // className="border-0"
                  placeholder="Search by user name or email"
                  aria-label="search"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                   
                  }}
                />
              </div>
              <div
                className="d-flex align-items-center gap-3"
                style={{ fontSize: "1.143rem", fontWeight: "400" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M14.6673 2.35938H1.33398L6.66732 8.66604V13.026L9.33398 14.3594V8.66604L14.6673 2.35938Z"
                    stroke="#051D2C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Filter</span>
              </div>
            </div>
            <ChakraButton
              onClick={onOpen}
              borderRadius={"5px"}
              fontSize={"sm"}
              size="md"
              bg="#051D2C"
              color="white"
              _hover={{ bg: "#051D2C" }}
            >
              INVITE MEMBER
            </ChakraButton>
          </Stack>
        </ChakraProvider>

        <DataTable
          responsive
          //   fixedHeader
          //   fixedHeaderScrollHeight="500px"
          columns={columns}
          data={tempuserData}
          customStyles={tableStyles}
          pagination
        />
        {/* invite user modal */}

        <ChakraProvider>
          <ChakraModal
            isCentered
            isOpen={isOpen}
            onClose={() => {
              setemail();
              setSelectedUserEmail("");
              setUserType("existing");
              setValue();
              setSelectedPersonaEmailIds([]);
              setErrors({});
              onClose();
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                alignItems={"center"}
                fontSize={"xl"}
                fontWeight={"bold"}
              >
                Invite user to Emissary
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack pb={4} spacing={6}>
                  {/* user type */}
                  <div>
                    <RadioGroup
                      size={"md"}
                      colorScheme="blackAlpha"
                      onChange={(user) => {
                        if (user == "new") {
                          if (value !== "Collaborator") {
                            setErrors({ ...error, personaEmailError: false });
                          }
                        } else {
                          setErrors({ ...error, roleError: false });
                        }
                        setUserType(user);
                      }}
                      value={userType}
                    >
                      <Stack spacing={6} direction="row">
                        <Radio value="existing">Existing User</Radio>
                        <Radio value="new">New User</Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                  {/* user email */}
                  <div>
                    <div
                      className="d-flex align-items-center mb-2"
                      style={{ color: "#051D2C" }}
                    >
                      <span>
                        <Img src={emailIcon} />
                      </span>{" "}
                      <span style={{ paddingLeft: "10px" }}>
                        Invite with email
                      </span>
                    </div>
                    {userType == "existing" ? (
                      <>
                        <Select
                          styles={{ border: "1.5px solid #051D2C" }}
                          options={options}
                          value={email}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          isSearchable={true}
                          placeholder="Search and select an email..."
                        />
                        {error.userEmailError === true && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            This field is required
                          </div>
                        )}
                      </>
                    ) : (
                      <div>
                        <div>
                          <input
                            type="email"
                            className="form-control"
                            name="comapnyName"
                            style={{
                              height: "38px",
                              border: "1.5px solid #1D1E24",
                            }}
                            placeholder="Enter user email"
                            value={selectedUserEmail}
                            onChange={(e) =>
                              setSelectedUserEmail(e.target.value)
                            }
                          />
                          {error.userEmailError === true && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* userrole */}
                  {userType == "new" && (
                    <>
                      <RadioGroup
                        size={"md"}
                        colorScheme="blackAlpha"
                        onChange={setValue}
                        value={value}
                      >
                        <Stack spacing={6} direction="row">
                          <Radio value="Admin">Admin</Radio>
                          <Radio value="User">Editor</Radio>
                          <Radio value="Collaborator">Collaborator</Radio>
                        </Stack>
                      </RadioGroup>
                      {error.roleError === true && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          This field is required
                        </div>
                      )}
                    </>
                  )}
                  {/* persona emails */}
                  <div className="">
                    <label className="form-label" style={{ color: "#051D2C" }}>
                      Invite specific personas{" "}
                      {userType == "existing"
                        ? null
                        : value !== "Collaborator"
                        ? "(optional)"
                        : null}
                    </label>
                    <Select
                      styles={{ cursor: "pointer" }}
                      isMulti={true}
                      value={formattedData.filter((option) =>
                        selectedPersonaEmailIds.includes(option.value)
                      )}
                      onChange={handleSelectChange}
                      onInputChange={handleInputChangePersonas}
                      options={formattedData}
                    />
                    {error.personaEmailError === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        This field is required
                      </div>
                    )}
                  </div>
                  <ChakraButton
                    onClick={addCollaboratorHandler}
                    alignSelf={"end"}
                    borderRadius={"5px"}
                    fontSize={"sm"}
                    size="md"
                    bg="#4775D5"
                    color="white"
                    // isDisabled={isDisabledButton}
                    _hover={{ bg: "black" }}
                  >
                    SEND INVITE
                  </ChakraButton>
                </Stack>
              </ModalBody>
            </ModalContent>
          </ChakraModal>
        </ChakraProvider>
        {/* confirm delete popup */}
        <div>
          <Modal
            show={showModal2}
            onHide={() => {
              setSelectedEmail();
              closeModal2();
            }}
            centered
          >
            <Modal.Header
              style={{ border: "none", backgroundColor: "#D4E2FF" }}
            >
              <div
                style={{
                  backgroundColor: "#D4E2FF",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  paddingBlock: "1rem",
                  position: "relative",
                }}
              >
                <CloseIcon
                  onClick={closeModal2}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: -5,
                    right: -5,
                  }}
                />
                <img src={assignowner} />
              </div>
            </Modal.Header>
            <Modal.Body style={{ paddingInline: "1.2rem" }}>
              <div className="d-flex flex-column gap-3  assign-owner  align-items-start">
                <h5>Delete {TargetUsername}</h5>
                <p>
                  Deleting a user will also delete all personas created by the
                  user. To keep personas, please select a new creator below or
                  delete user along with all their personas.
                </p>
              </div>
              {selectedUserPersonas.length > 0 && (
                <>
                  <Autocomplete
                    sx={{ background: "white" }}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    getOptionLabel={(option) => option.label}
                    onChange={handleUserSelect}
                    value={top100Films.find(
                      (option) => option.email === selectedEmail
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select new owner"
                        label="Select a new owner"
                      />
                    )}
                  />
                  <FormControl
                    sx={{ marginBlock: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel sx={{ color: "black" }} component="legend">
                      Please select the personas you would like to move
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              Object.keys(personaSelection)?.length ===
                              selectedUserPersonas?.length
                            }
                            name="selectAll"
                            onChange={handlePersonaSelection}
                          />
                        }
                        label="Select All"
                      />
                      {selectedUserPersonas?.map((persona) => (
                        <FormControlLabel
                          key={persona._id}
                          control={
                            <Checkbox
                              checked={
                                personaSelection?.some(
                                  (obj) => obj == persona._id
                                ) || false
                              }
                              onChange={handlePersonaSelection}
                              name={persona._id}
                            />
                          }
                          label={`${persona.firstName} ${persona.lastName}`}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </>
              )}
              <div className="d-flex gap-4 align-items-center justify-content-end">
                <Button
                  onClick={closeModal2}
                  style={{ color: "#051D2C", fontWeight: "600" }}
                  variant="text"
                >
                  CANCEL
                </Button>
                <ReusableButton
                  onClick={deleteUserHandler}
                  buttonText="DELETE USER"
                  bgColor="#C51333"
                  fontWeight="500"
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        {/* confirm change role popup */}
        <div>
          <Modal show={showModal3} onHide={() => setShowModal3(false)} centered>
            <Modal.Header
              style={{ border: "none", paddingBottom: 0 }}
              closeButton
            ></Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
              <div className="d-flex flex-column gap-4 px-5  align-items-center">
                <img src={alertIcon} />
                <h6
                  style={{
                    color: "#051D2C",
                    fontSize: "20px",
                    textAlign: "center",
                    lineHeight: "1.4",
                  }}
                >
                  Are you sure you want to change user's role?
                </h6>
              </div>
              <div
                style={{ borderTop: "1px solid #CED3D9" }}
                className="mt-4 d-flex gap-1 align-items-center justify-content-between"
              >
                <div
                  className="py-2 "
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    borderRight: "1px solid #CED3D9",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowModal3(false);
                    }}
                    style={{ color: "#051D2C", fontWeight: "600" }}
                    variant="text"
                  >
                    CANCEL
                  </Button>
                </div>
                <div
                  className="py-2 "
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={changeUserRoleHandler}
                    style={{ color: "#C51333", fontWeight: "600" }}
                    variant="text"
                  >
                    YES
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        {/* confirm user status change popup */}
        <div>
          <Modal show={showModal4} onHide={() => setShowModal4(false)} centered>
            <Modal.Header
              style={{ border: "none", paddingBottom: 0 }}
              closeButton
            ></Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
              <div className="d-flex flex-column gap-4 px-5  align-items-center">
                <img src={alertIcon} />
                <h6
                  style={{
                    color: "#051D2C",
                    fontSize: "20px",
                    textAlign: "center",
                    lineHeight: "1.4",
                  }}
                >
                  Are you sure you want to change user's status?
                </h6>
              </div>
              <div
                style={{ borderTop: "1px solid #CED3D9" }}
                className="mt-4 d-flex gap-1 align-items-center justify-content-between"
              >
                <div
                  className="py-2 "
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    borderRight: "1px solid #CED3D9",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowModal4(false);
                    }}
                    style={{ color: "#051D2C", fontWeight: "600" }}
                    variant="text"
                  >
                    CANCEL
                  </Button>
                </div>
                <div
                  className="py-2 "
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={changeUserStatusHandler}
                    style={{ color: "#C51333", fontWeight: "600" }}
                    variant="text"
                  >
                    YES
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UsersDataTable;
