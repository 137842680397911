import FrontPage from "../components/FrontPage/FrontPage";
import Mind from "../components/Mind/Mind";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Chat from "../components/Chat/Chat.js";
import Persona from "../components/Persona/Persona";
import EditFacsimile from "../components/EditFascimile/EditFacsimile";
import { ChakraProvider } from "@chakra-ui/react";
import Moments from "../components/Moments/Moments";
import ClientProfile from "../components/ClientProfile/ClientProfile";
import UpdateMoment from "../components/Moments/UpdateMoment";
import Login from "../components/SignIn/Login";
import Register from "../components/signup/Register";
import AnalizeMoment from "../components/analyzemoment/AnalizeMoment";
import ForgotPassword from "../components/forgotpassword/ForgotPassword";
import ResetPassword from "../components/resetpassword/ResetPassword";
import ApproveUser from "../components/approve/ApproveUser";
import Customization from "../components/adminDashboard/customization/Customization";
import UsersTable from "../components/adminDashboard/users/UsersTable";
import Security from "../components/adminDashboard/security/Security";
import UsageStatistics from "../components/adminDashboard/usageStats/UsageStatistics";
import Billing from "../components/adminDashboard/billing/Billing";
import JoinEmissaryComp from "../components/joinEmissary/JoinEmissaryComp";
import UserProfile from "../components/UserProfile/UserProfile";
import EditPersona from "../components/Persona/EditPersona";
import PersonaTeam from "../components/Persona/PersonaTeam";
import PrivateRoute from "../routes/PrivateRoute";
import PersonaResources from "../components/resources/PersonaResources.js";
import PersonaProjects from "../components/projects/PersonaProject.js";
import BrandGuide from "../components/massiveAlliance/brandguide/BrandGuide.js";
import LoginRoutes from "./LoginRoutes.js";
import ChangeUserPassword from "../components/adminDashboard/changepassword/ChangeUserPassword.js";
import BrandGuidePreview from "../components/massiveAlliance/brandguide/components/preview/BrandGuidePreview.jsx";
import PdfPreview from "../components/massiveAlliance/brandguide/components/preview/PdfPreview.js";
import PdfSecondPage from "../components/massiveAlliance/brandguide/components/preview/PdfSecondPage.js";

function MainRoutes() {
  return (
    // <Router>
    <Routes>
      <Route exact path="/" element={<LoginRoutes><Login /></LoginRoutes>}></Route>
      <Route exact path="/signup" element={<LoginRoutes><Register /></LoginRoutes>}></Route>
      <Route exact path="/forgot-password" element={<LoginRoutes><ForgotPassword /></LoginRoutes>}></Route>
      <Route exact path="/approve-user" element={<ApproveUser />}></Route>
      <Route exact path="/join-emissary" element={<JoinEmissaryComp />}></Route>
      <Route
        exact
        path="/reset-password/:token"
        element={<ResetPassword />}
      ></Route>
      <Route
        exact
        path="/frontpage"
        element={
          <PrivateRoute>
            <FrontPage />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/mind"
        element={
          <PrivateRoute>
            <Mind />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/editFacsimile"
        element={
          <PrivateRoute>
            <EditFacsimile />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/chat"
        element={
          <PrivateRoute>
            <Chat />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/chat/:id"
        element={
          <PrivateRoute>
            <Chat />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/moments"
        element={
          <PrivateRoute>
            <Moments />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/updatemoment"
        element={
          <PrivateRoute>
            <UpdateMoment />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/persona"
        element={
          <PrivateRoute>
            <Persona />
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/persona/:id"
        element={
          <PrivateRoute>
            <ChakraProvider>
              <Persona />
            </ChakraProvider>
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/mypersonas"
        element={
          <PrivateRoute>
            <ChakraProvider>
              <Persona />
            </ChakraProvider>
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/edit-persona"
        element={
          // <PrivateRoute>
            <EditPersona />
          // </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/team"
        element={
          <PrivateRoute>
            <PersonaTeam />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/resources"
        element={
          <PrivateRoute>
            <PersonaResources />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/projects"
        element={
          <PrivateRoute>
            <PersonaProjects />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/brand-guide"
        element={
          <PrivateRoute>
            <BrandGuide />
          </PrivateRoute>
        }
      />
       <Route
        exact
        path="/brand-guide-preview"
        element={
          <PrivateRoute>
            <PdfSecondPage/>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/admins"
        element={
          <PrivateRoute>
            <ChakraProvider>
              <Persona />
            </ChakraProvider>
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/client-profile"
        element={
          <PrivateRoute>
            <ChakraProvider>
              <ClientProfile />
            </ChakraProvider>
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/user-profile"
        element={
          <PrivateRoute>
            
              <UserProfile />
            
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/analyze-moment"
        element={
          <PrivateRoute>
            <AnalizeMoment />
          </PrivateRoute>
        }
      ></Route>
      {/* admin routes */}
      <Route
        exact
        path="/admin/general"
        element={
          <PrivateRoute>
            
              <UsageStatistics />
            {/* </ChakraProvider> */}
          </PrivateRoute>
        }
      ></Route>
         <Route
        exact
        path="/admin/change-password"
        element={
          <PrivateRoute>
            
              <ChangeUserPassword />
            {/* </ChakraProvider> */}
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/admin/customization"
        element={
          <PrivateRoute>
            {/* <ChakraProvider> */}
              <Customization />
            {/* </ChakraProvider> */}
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/admin/users"
        element={
          <PrivateRoute>
            {/* <ChakraProvider> */}
              <UsersTable />
            {/* </ChakraProvider> */}
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/admin/security"
        element={
          <PrivateRoute>
            {/* <ChakraProvider> */}
              <Security />
            {/* </ChakraProvider> */}
          </PrivateRoute>
        }
      ></Route>
      <Route
        exact
        path="/admin/billing"
        element={
          <PrivateRoute>
            {/* <ChakraProvider> */}
              <Billing />
            {/* </ChakraProvider> */}
          </PrivateRoute>
        }
      ></Route>
    </Routes>
    // </Router>
  );
}

export default MainRoutes;
