// ReusableButton.js

import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const ReusableButton = ({
  buttonText,
  loading = false,
  onClick,
  color = "#FFFFFF",
  bgColor = "#051D2C",
  fontWeight = "500",
  fontSize = "0.875rem",
  width = "fit-content",
  padding = "0.5rem 1rem",
  isDisabled = false,
  size = "medium", // Add the size prop for large buttons
  height,
  ...otherProps
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={isDisabled || loading}
      style={{
        color,
        backgroundColor: isDisabled?'#051d2c80':bgColor,
        fontWeight,
        fontSize,
        width,
        padding,
        textTransform: "uppercase",
        height: height || "auto",
      }}
      size={size}
      {...otherProps}
    >
      {loading ? <CircularProgress size={20} color="inherit" /> : buttonText}
    </Button>
  );
};

export default ReusableButton;
