import React from "react";
import campaign from "../../../../../Images/campaign.jpg";
import { useMediaQuery } from "@material-ui/core";

const Campaign = ({ data }) => {
  const campaignData = data?.Campaign;
  const smallLaptopScreen = useMediaQuery("(max-width:1200px)");
  const mobileScreen = useMediaQuery("(max-width:768px)");
  return (
    <>
      {campaignData?.map((campaignItem, index) => {
        const lines = campaignItem.Response.split("\n").filter(
          (line) =>
            line.trim() !== "" && line.trim().replace(/^\d+\.\s*/, "") !== ""
        ); // Filter out lines with no text after the number
        return (
          <div key={index} className="preview-header-lower-section mt-4">
            <div className="d-flex align-items-center">
              <div className="who-is-heading "></div>
              <div className="campaign-heading ">CAMPAIGN SUGGESTIONS</div>
            </div>

            <div
              className="d-flex gap-5 "
              style={{
                paddingLeft: mobileScreen ? "1.3rem" : "2.357rem",
                marginTop: "4rem",
                alignItems: mobileScreen ? "center" : "start",
                flexDirection: mobileScreen ? "column" : "row",
              }}
            >
              <img
                src={campaign}
                style={{
                  width: mobileScreen
                    ? "12rem"
                    : smallLaptopScreen
                    ? "16rem"
                    : "18rem",
                  height: mobileScreen
                    ? "12rem"
                    : smallLaptopScreen
                    ? "16rem"
                    : "18rem",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
              <div style={{ fontSize: "1rem", color: "#212529" }}>
                {lines.map((line, idx) => {
                  // Format the line to replace "1." with "01", "2." with "02", and so on
                  const formattedLine = line.replace(
                    /^(\d+)\./,
                    (match, number) => {
                      // Pad the number with a leading zero if it's less than 10
                      return parseInt(number) < 10
                        ? `0${number}   `
                        : `${number}   `;
                    }
                  );
                  return (
                    <p
                      key={idx}
                      style={{
                        fontWeight: line.includes("Title:") ? "500" : "normal",
                        color: "#212529",
                        fontSize: line.includes("Title:")
                          ? mobileScreen
                          ? "0.85rem"
                          : smallLaptopScreen
                          ? "1rem"
                          : "1.15rem"
                          : mobileScreen
                          ? "0.75rem"
                          : smallLaptopScreen
                          ? "0.75rem"
                          : "0.9rem",
                        marginBottom: line.includes("Title:")
                          ? "0.4rem"
                          : "1.2rem",
                      }}
                    >
                      {formattedLine}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Campaign;
