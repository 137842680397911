import React, { useContext, useEffect, useRef, useState } from "react";
import Unknown from "../../../../../Images/Unknown.jpg";
import chatIcon from "../../../../../Assests/footerchaticon.svg";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import uploadPhoto from "../../../../../Images/uploadPhoto.svg";

import {
  DragPlusIcon,
  MinusIcon,
  MinusIconRed,
} from "../../../../../customIcons/customIcons";
import { TextField, useMediaQuery } from "@mui/material";
import TextEditor from "./TextEditor";
import ConfirmTooltip from "./ConfirmTooltip";
import {
  AddSectionInBrandGuide,
  UpdateBrandGuideQuries,
  UpdatePersonaProfileService,
  getBrandGuideChatService,
} from "../../../../../Service/service";
import { RotatingLines } from "react-loader-spinner";
import ChatPopup from "./ChatPopup";
import CustomToaster from "../../../../../utils/toaster/CustomToaster";
import BrandGuidePreview from "../preview/BrandGuidePreview";
import {
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import PersonaChatContext from "../../../../../context/ChatContext";
import ReusableButton from "../../../../reusableComponents/ReusableButton";
import DownloadPreview from "../preview/DownloadPreview";
function ConetentBlocks({ isLoading, textData, setTextData, responseData }) {
  // console.log("textData>>>>", textData);
  const { emptyChatArray, updateChatArray } = useContext(PersonaChatContext);
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const [UpdateLoading, setUpdateLoading] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [isSaveDisabled, setisSaveDisabled] = useState(true);
  const [isUpdatePersonaDisabled, setIsUpdatePersonaDisabled] = useState(true);
  const [chatResponse, setChatResponse] = useState({});
  const [messages, setMessages] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [errorToast, setErrorToast] = useState(false);
  const [error, setError] = useState({});

  const [fieldValues, setFieldValues] = useState({
    activeImage: null,
    firstName: "",
    lastName: "",
    jobTitle: "",
    epClientCompany: "",
    linkedinUrl: "",
    websiteUrl: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    facebook: "",
  });
  const [textDataReplica, setTextDataReplica] = useState(textData);
  // console.log("textDataReplica", textDataReplica);
  const [previewImage, setPreviewImage] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const handleTextChange = (
    updatedText,
    serializedEditorState,
    key,
    index,
    answerKey
  ) => {
    if (
      !textDataReplica ||
      !textDataReplica[key] ||
      !textDataReplica[key][index]
    ) {
      console.error("Invalid textDataReplica or key or index");
      return;
    }

    // Determine the specific editorObject key based on the answerKey
    const editorObjectKey =
      answerKey === "Who_is_Answer_1" ? "editorObject_1" : "editorObject_2";

    const updatedTextData = { ...textDataReplica };

    // Update the specific Answer key with the plain text
    if (answerKey) {
      updatedTextData[key][index][answerKey] = updatedText;
    } else {
      updatedTextData[key][index]["Response"] = updatedText;
    }

    // Update or add the specific editorObject key with the serialized editor state
    updatedTextData[key][index][editorObjectKey] = serializedEditorState;

    setTextDataReplica(updatedTextData);
  };

  useEffect(() => {
    const storedFieldValues = {
      activeImage: activePersona?.resizedImage,
      firstName: activePersona?.firstName || "",
      lastName: activePersona?.lastName || "",
      epClientCompany: activePersona?.epClientCompany || "",
      jobTitle: activePersona?.jobTitle || "",
      linkedinUrl: activePersona?.socialLinks?.linkedin || "",
      websiteUrl: activePersona?.socialLinks?.websiteUrl || "",
      instagram: activePersona?.socialLinks?.instagram || "",
      twitter: activePersona?.socialLinks?.twitter || "",
      tiktok: activePersona?.socialLinks?.tikTok || "",
      facebook: activePersona?.socialLinks?.facebook || "",
    };

    setFieldValues((prevValues) => ({
      ...prevValues,
      ...storedFieldValues,
    }));
  }, [updateChatArray]);
  useEffect(() => {
    getBrandGuideChatService(responseData?._id).then((res) => {
      setChatResponse(res);
    });
  }, [messages]);
  const nameRegex = /^[a-zA-Z\s_]+$/;
  const companyNameRegex = /^[a-zA-Z0-9\s_]+$/;
  const linkValidationRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  const handleFieldChange = (fieldName) => (event) => {
    const newValue = event.target.value;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
    setIsUpdatePersonaDisabled(false);
  };
  const validateForm = () => {
    let errors = {};
    if (!fieldValues?.firstName?.trim()) {
      errors.firstName = "First name is required.";
    } else if (!nameRegex.test(fieldValues?.firstName)) {
      errors.firstName = "Only alphabets, underscore, and spaces are allowed.";
    } else if (fieldValues?.firstName?.length > 30) {
      errors.firstName = "First name should not exceed 30 characters.";
    }
    if (!fieldValues?.lastName?.trim()) {
      errors.lastName = "Last name is required.";
    } else if (!nameRegex.test(fieldValues?.lastName)) {
      errors.lastName = "Only alphabets, underscore, and spaces are allowed.";
    } else if (fieldValues?.lastName?.length > 30) {
      errors.lastName = "Last name should not exceed 30 characters.";
    }
    if (
      fieldValues?.jobTitle !== "" &&
      !nameRegex.test(fieldValues?.jobTitle)
    ) {
      errors.jobTitle = "Only alphabets, underscore, and spaces are allowed.";
    } else if (fieldValues?.jobTitle?.length > 50) {
      errors.jobTitle = "Job Title should not exceed 50 characters.";
    }
    if (!fieldValues?.epClientCompany?.trim()) {
      errors.companyName = "Company name is required.";
    } else if (!companyNameRegex.test(fieldValues?.epClientCompany)) {
      errors.companyName =
        "Only alphabets, underscore, and spaces are allowed.";
    } else if (fieldValues?.epClientCompany?.length > 50) {
      errors.companyName = "Company name should not exceed 50 characters.";
    }
    if (
      fieldValues?.websiteUrl !== "" &&
      !linkValidationRegex.test(fieldValues?.websiteUrl)
    ) {
      errors.websiteUrl = "Invalid website URL";
    }
    if (
      fieldValues?.instagram !== "" &&
      !linkValidationRegex.test(fieldValues?.instagram)
    ) {
      errors.instagram = "Invalid instagram URL";
    }
    if (
      fieldValues?.linkedinUrl !== "" &&
      !linkValidationRegex.test(fieldValues?.linkedinUrl)
    ) {
      errors.linkedin = "Invalid linkedin URL";
    }
    if (
      fieldValues?.tiktok !== "" &&
      !linkValidationRegex.test(fieldValues?.tiktok)
    ) {
      errors.tiktok = "Invalid tikTok URL";
    }
    if (
      fieldValues?.twitter !== "" &&
      !linkValidationRegex.test(fieldValues?.twitter)
    ) {
      errors.twitter = "Invalid twitter URL";
    }

    // Add other validations here

    setError(errors);
    return Object.keys(errors).length === 0;
  };
  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file?.name;
    const fileExtension = fileName?.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension?.toLowerCase());
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && !isFileFormatValid(selectedFile)) {
      setErrorToast(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please upload png, jpg and jpeg files only");
      return;
    }

    setProfilePic(selectedFile);
    setIsUpdatePersonaDisabled(false);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewImage(null);
    }
  };
  const updatePersona = () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    editPersonaProfile();
  };

  const editPersonaProfile = async () => {
    setUpdateLoading(true);
    setError({});
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const formData = new FormData();
    formData.append("firstName", fieldValues?.firstName);
    formData.append("lastName", fieldValues?.lastName);
    formData.append("epClientCompany", fieldValues?.epClientCompany);
    formData.append("personaId", activePersona?._id);
    formData.append("image", profilePic);
    formData.append("jobTitle", fieldValues?.jobTitle ?? "");
    formData.append("socialLinks[websiteUrl]", fieldValues?.websiteUrl ?? "");
    formData.append("socialLinks[instagram]", fieldValues?.instagram ?? "");
    formData.append("socialLinks[tikTok]", fieldValues?.tiktok ?? "");
    formData.append("socialLinks[linkedin]", fieldValues?.linkedinUrl ?? "");
    formData.append("socialLinks[twitter]", fieldValues?.twitter ?? "");

    try {
      const response = await UpdatePersonaProfileService(formData);

      if (response.success) {
        setPreviewImage(null);
        // setValidateLinksOnSubmit(false); // Reset the flag after validation
        // emptyChatArray();
        localStorage.setItem(
          "active_persona_data",
          JSON.stringify(response.data)
        );
        setError({});
        setUpdateLoading(false);
        setErrorToast(true);
        setSnackbarSeverity("success");
        setSnackbarMessage(response.message);
      } else {
        setUpdateLoading(false);
        setErrorToast(true);
        setSnackbarSeverity("error");
        setSnackbarMessage(response.message);
      }
    } catch (error) {
      setUpdateLoading(false);
      // Handle fetch error
      setErrorToast(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(error);
    }
  };
  const SubmitChat = () => {
    setIsOpenChat(!isOpenChat);
  };
  const matches = useMediaQuery("(min-width:1400px)");

  const handleRemoveBlock = (keyToRemove, index) => {
    // const updatedObject = { ...textData };
    const updatedTextData = { ...textData };
    updatedTextData[keyToRemove] = updatedTextData[keyToRemove].filter(
      (item, i) => i !== index
    );

    const data = {
      brandGuideId: responseData._id,
      brandGuideQueries: updatedTextData,
    };
    setUpdateLoading(true);
    UpdateBrandGuideQuries(data).then((result) => {
      setUpdateLoading(false);
      if (result.success) {
        const newData = result?.data?.brandGuideQueries;
        const rearrangedTextData = {
          Who_is: newData && newData["Who_is"],
          Program_Goals: newData && newData["Program_Goals"],
          The_Audience: newData && newData["The_Audience"],
          Audience_Challenges: newData && newData["Audience_Challenges"],
          Content_Guidelines: newData && newData["Content_Guidelines"],
          Campaign: newData && newData["Campaign"],
          ...(newData?.Blank_Campaign
            ? { Blank_Campaign: newData["Blank_Campaign"] }
            : {}),
        };
        setTextData(rearrangedTextData);
        setTextDataReplica(rearrangedTextData);
      }
    });
  };
  const UpdateBrandGuideContent = () => {
    // console.log("textDataReplica",textDataReplica)

    const data = {
      brandGuideId: responseData._id,
      brandGuideQueries: textDataReplica,
      // !textDataReplica.hasOwnProperty("Blank_Campaign")
      //   ? {
      //       ...textDataReplica,
      //       Blank_Campaign: [{ Question: "", Response: "" }],
      //     }
      //   : textDataReplica,
    };
    setUpdateLoading(true);
    UpdateBrandGuideQuries(data).then((result) => {
      setUpdateLoading(false);
      if (result.success) {
        setTextData(result?.data?.brandGuideQueries);
        setTextDataReplica(result?.data?.brandGuideQueries);
        setisSaveDisabled(true);
      }
    });
  };
  const addBrandGuideSectionHandler = async (name) => {
    setUpdateLoading(true);
    const res = await AddSectionInBrandGuide(name, responseData?._id);
    if (res.success) {
      const newData = res?.data?.brandGuideQueries;
      const rearrangedTextData = {
        Who_is: newData && newData["Who_is"],
        Program_Goals: newData && newData["Program_Goals"],
        The_Audience: newData && newData["The_Audience"],
        Audience_Challenges: newData && newData["Audience_Challenges"],
        Content_Guidelines: newData && newData["Content_Guidelines"],
        Campaign: newData && newData["Campaign"],
        ...(newData?.Blank_Campaign
          ? { Blank_Campaign: newData["Blank_Campaign"] }
          : {}),
      };
      setTextData(rearrangedTextData);
      setTextDataReplica(rearrangedTextData);
      setUpdateLoading(false);
    } else {
      setUpdateLoading(false);
    }
  };
  const popupRef = useRef(null);

  const handleCloseErrorToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorToast(false);
  };
  const previewData = {
    img:
      activePersona && activePersona?.image === null
        ? Unknown
        : `${process.env.REACT_APP_IMAGES_URL}/Personas/${activePersona?.creatorEmail}/${fieldValues.activeImage}`,
    name: activePersona?.firstName + " " + activePersona?.lastName,
    jobTitle: activePersona?.jobTitle,
    companyName: activePersona?.epClientCompany,
    // who_is_1: textData?.Who_is_1?.Response,
    who_is_1: textData && textData["Who_is"],
    who_is_2: textData && textData["Who_is"],
    // Program_Goals: texttextData?.Program_Goals?.Response,
    Program_Goals: textData && textData["Program_Goals"],
    The_Audience: textData && textData["The_Audience"],
    Audience_Challenges: textData && textData["Audience_Challenges"],
    Content_Guidelines: textData && textData["Content_Guidelines"],
    Campaign: textData && textData["Campaign"],
    Blank_Campaign: textData && textData["Blank_Campaign"],
    socialLinks: activePersona?.socialLinks,
  };

  return (
    <>
      <CustomToaster
        open={errorToast}
        onClose={handleCloseErrorToast}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {UpdateLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="#051D2C"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="brand-guid-blocks">
        <div className="d-flex justify-content-end">
          {/* <DownloadPreview
            brandGuideTitle={responseData?.title}
            brandGuideQueries={textDataReplica}
          /> */}
          <button onClick={onOpen} className="download-btn">
            Preview
          </button>
        </div>
        <div className="my-4 content-block">
          <h2 className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-3 align-items-center">
              <span className="circle-item">1</span>
              <span className="block-title">Content Block</span>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <span className="circle-item">
                <DragPlusIcon />
              </span>
            </div>
          </h2>
          {/* edit persona section */}
          <div
            className="contentWraper"
            style={{ padding: "30px", borderTop: "1px solid #DDD" }}
          >
            <div style={{ width: "100%" }}>
              <label
                htmlFor="img-upload"
                className="w-100 h-100 d-flex cursor-pointer"
              >
                <input
                  id="img-upload"
                  type="file"
                  name="profilePic"
                  className="fileUpload-Custom"
                  accept=".png,.jpg,.jpeg"
                  onChange={handleFileChange}
                />
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="preview"
                    style={{
                      borderRadius: "100%",
                      height: "8rem",
                      width: "8rem",
                    }}
                  />
                ) : activePersona?.image === null ? (
                  <img
                    style={{
                      borderRadius: "100%",
                      height: "8rem",
                      width: "8rem",
                    }}
                    src={uploadPhoto}
                    alt=""
                  />
                ) : (
                  <img
                    style={{
                      borderRadius: "100%",
                      height: "8rem",
                      width: "8rem",
                    }}
                    src={
                      activePersona?.resizedImage
                        ? `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                            activePersona?.creatorEmail
                          }/${
                            activePersona?.resizedImage
                          }?random=${Math.random()}`
                        : `${process.env.REACT_APP_IMAGES_URL}/Personas/${
                            activePersona?.creatorEmail
                          }/${activePersona?.image}?random=${Math.random()}`
                    }
                    alt=""
                  />
                )}
              </label>
            </div>

            <TextField
              size={matches ? "medium" : "small"}
              label="First Name"
              value={fieldValues.firstName || ""}
              onChange={handleFieldChange("firstName")}
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.firstName} // Add this line if you want to show an error state
              helperText={error.firstName}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Last Name"
              value={fieldValues.lastName}
              onChange={handleFieldChange("lastName")}
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.lastName} // Add this line if you want to show an error state
              helperText={error.lastName}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Company Name"
              value={fieldValues.epClientCompany}
              onChange={handleFieldChange("epClientCompany")}
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.companyName} // Add this line if you want to show an error state
              helperText={error.companyName}
            />
            <TextField
              size={matches ? "medium" : "small"}
              value={fieldValues?.jobTitle}
              onChange={handleFieldChange("jobTitle")}
              fullWidth
              label="Job Title"
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.jobTitle} // Add this line if you want to show an error state
              helperText={error.jobTitle}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Linkedin Url"
              value={fieldValues.linkedinUrl}
              onChange={handleFieldChange("linkedinUrl")}
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.linkedin} // Add this line if you want to show an error state
              helperText={error.linkedin}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Website URL"
              value={fieldValues.websiteUrl}
              onChange={handleFieldChange("websiteUrl")}
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.websiteUrl} // Add this line if you want to show an error state
              helperText={error.websiteUrl}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Instagram"
              value={fieldValues.instagram}
              onChange={handleFieldChange("instagram")}
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.instagram} // Add this line if you want to show an error state
              helperText={error.instagram}
            />
            <TextField
              size={matches ? "medium" : "small"}
              label="Twitter/X"
              value={fieldValues.twitter}
              onChange={handleFieldChange("twitter")}
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.twitter} // Add this line if you want to show an error state
              helperText={error.twitter}
            />

            <TextField
              size={matches ? "medium" : "small"}
              label="Tiktok"
              value={fieldValues.tiktok}
              onChange={handleFieldChange("tiktok")}
              sx={{ width: "calc(50% - 12px)", background: "white" }}
              error={error.tikTok} // Add this line if you want to show an error state
              helperText={error.tikTok}
            />
            <div
              style={{ marginTop: "4rem" }}
              className="d-flex justify-content-end "
            >
              <ReusableButton
                onClick={updatePersona}
                buttonText={"SAVE"}
                width={matches ? "5rem" : "5rem"}
                isDisabled={isUpdatePersonaDisabled}
              />
            </div>
          </div>
        </div>
        {Object.keys(textDataReplica)?.map(
          (key, index) =>
            textDataReplica?.hasOwnProperty(key) &&
            textDataReplica[key]?.map((item, i) => {
              const responseLines =
                key !== "Who_is" && key !== "Campaign"
                  ? item?.Response?.split("\n").filter((line) => {
                      const trimmedLine = line?.trim();
                      return (
                        trimmedLine !== "" && /\d+\.\s*\S/.test(trimmedLine)
                      );
                    })
                  : [item?.Response]; // Split and filter response lines only if the key is not "Who_is"

              const formattedResponse = responseLines?.join("\n"); // Join the filtered lines back into a string with newline characters

              return (
                <div key={key + "-" + i} className="my-4 content-block">
                  <h2 className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3 align-items-center">
                      <span className="circle-item">{index + 2}</span>
                      <span className="block-title">
                        {key.replace(/_/g, " ")}
                      </span>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <ConfirmTooltip
                        handleRemoveBlock={() => handleRemoveBlock(key, i)}
                        keyValue={key}
                      >
                        <span role="button" className="circle-item delete-icon">
                          <MinusIcon className="delete-icon-notActive" />
                          <MinusIconRed className="delete-icon-active" />
                        </span>
                      </ConfirmTooltip>
                      <span className="circle-item">
                        <DragPlusIcon />
                      </span>
                    </div>
                  </h2>
                  <div
                    className=""
                    style={{ padding: "30px", borderTop: "1px solid #DDD" }}
                  >
                    <div className="mb-4">
                      {key === "Who_is" ? (
                        <>
                          {isLoading ? (
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress />
                            </Box>
                          ) : (
                            <div className="mb-4">
                              <TextEditor
                                oldStylingState={item?.editorObject_1 ?? ""}
                                text={item?.Who_is_Answer_1}
                                setisSaveDisabled={setisSaveDisabled}
                                onTextChange={(
                                  updatedText,
                                  serializedEditorState
                                ) => {
                                  handleTextChange(
                                    updatedText,
                                    serializedEditorState,
                                    key,
                                    i,
                                    "Who_is_Answer_1"
                                  );
                                }}
                              />
                            </div>
                          )}
                          {isLoading ? (
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress />
                            </Box>
                          ) : (
                            <div className="mb-4">
                              <TextEditor
                                oldStylingState={item?.editorObject_2 ?? ""}
                                text={item?.Who_is_Answer_2}
                                setisSaveDisabled={setisSaveDisabled}
                                onTextChange={(
                                  updatedText,
                                  serializedEditorState
                                ) => {
                                  handleTextChange(
                                    updatedText,
                                    serializedEditorState,
                                    key,
                                    i,
                                    "Who_is_Answer_2"
                                  );
                                }}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {isLoading ? (
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress />
                            </Box>
                          ) : (
                            <TextEditor
                              oldStylingState={item?.editorObject_2 ?? ""}
                              text={item?.Response}
                              setisSaveDisabled={setisSaveDisabled}
                              onTextChange={(
                                updatedText,
                                serializedEditorState
                              ) => {
                                handleTextChange(
                                  updatedText,
                                  serializedEditorState,
                                  key,
                                  i
                                );
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
        )}

        <div className="d-flex justify-content-between">
          <button
            style={{
              cursor: isSaveDisabled ? "not-allowed" : "pointer",
              opacity: isSaveDisabled ? "0.7" : "1",
            }}
            onClick={UpdateBrandGuideContent}
            className="download-btn"
            disabled={isSaveDisabled}
          >
            SAVE
          </button>

          <ChakraProvider>
            <Menu bg="#051D2C" isLazy>
              <MenuButton
                style={{
                  background: "white",
                }}
              >
                <div className="menuButton">
                  <button className="download-btn">ADD SECTION</button>
                </div>
              </MenuButton>
              <MenuList bg="#051D2C" sx={{ color: "white" }}>
                <MenuItem
                  onClick={() => addBrandGuideSectionHandler("The_Audience")}
                  bg="#051D2C"
                  sx={{ color: "white" }}
                  px={4}
                  _hover={{}}
                >
                  The Audience
                </MenuItem>
                <MenuItem
                  onClick={() => addBrandGuideSectionHandler("Program_Goals")}
                  bg="#051D2C"
                  sx={{ color: "white" }}
                  px={4}
                  _hover={{}}
                >
                  Program Goals
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    addBrandGuideSectionHandler("Audience_Challenges")
                  }
                  bg="#051D2C"
                  sx={{ color: "white" }}
                  px={4}
                  _hover={{}}
                >
                  Audience Challenges
                </MenuItem>
                <MenuItem
                  onClick={() => addBrandGuideSectionHandler("Campaign")}
                  bg="#051D2C"
                  sx={{ color: "white" }}
                  px={4}
                  _hover={{}}
                >
                  Campaign
                </MenuItem>
                {
                  // Check if Blank_Campaign does not exist OR if it exists and its length is 0
                  (!textDataReplica?.Blank_Campaign ||
                    textDataReplica?.Blank_Campaign.length === 0) && (
                    <MenuItem
                      onClick={() =>
                        addBrandGuideSectionHandler("Blank_Campaign")
                      }
                      bg="#051D2C"
                      sx={{ color: "white" }}
                      px={4}
                      _hover={{ bg: "gray.700" }} // Add your desired hover styles here
                    >
                      Blank Campaign
                    </MenuItem>
                  )
                }
              </MenuList>
            </Menu>
          </ChakraProvider>
        </div>
      </div>
      <div
        className="chat-footer d-flex justify-content-end align-items-center"
        style={{ height: "6.2rem", background: "#fff" }}
      >
        <figure
          role="button"
          onClick={SubmitChat}
          style={{ width: "3.571rem" }}
        >
          <img src={chatIcon} alt="chat" />
        </figure>
        {isOpenChat && (
          <div ref={popupRef} className="chat_popup">
            <ChatPopup
              closeChat={() => setIsOpenChat(false)}
              responseData={responseData}
              setUpdateLoading={setUpdateLoading}
              chatResponse={chatResponse}
              setMessages={setMessages}
              messages={messages}
              setSnackbarSeverity={setSnackbarSeverity}
              setSnackbarMessage={setSnackbarMessage}
              errorToast={errorToast}
              setErrorToast={setErrorToast}
            />
          </div>
        )}
      </div>

      {/* preview drawer */}

      <ChakraProvider>
        <Drawer size={"xl"} isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent maxW={"1000px"}>
            <DrawerCloseButton />

            <DrawerBody padding={0}>
              <BrandGuidePreview decodedData={previewData} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </ChakraProvider>
    </>
  );
}

export default ConetentBlocks;
