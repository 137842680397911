import { React, useState } from "react";
import { Link } from "react-router-dom";
import ImageUpload from "../../Images/upload.svg";
import FileUpload from "../../Images/upload.svg";
import "./FrontPage.css";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
const FrontPage = () => {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  function handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImageFile(file);
      setImagePreviewUrl(reader.result);
      // console.log(reader.result);
    };

    reader.readAsDataURL(file);
  }
  const [fields, setFields] = useState({
    // photoUpload: "",
    clientName: "",
    clientTitle: "",
    clientCompany: "",
    clientBio: "",
    clientSocialLink: "",
    companyBio: "",
    clientWebLink: "",
    clientStylistic: "",
    clientExpertise: "",
    clientIndustry: "",
    audienceTitle: "",
    audienceCompanySize: "",
    audienceProblem: "",
    // fileUpload: "",
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFields({
      ...fields,
      [name]: value,
    });
    setIsSaveDisabled(Object.values(fields).some((value) => !value));
    setIsNextDisabled(true);
  };
  const handleSave = (event) => {
    event.preventDefault();
    setIsSaveDisabled(true);
    setIsNextDisabled(false);
    // setFields({
    //   photoUpload: "",
    //   clientName: "",
    //   clientTitle: "",
    //   clientCompany: "",
    //   clientBio: "",
    //   clientSocialLink: "",
    //   companyBio: "",
    //   clientWebLink: "",
    //   clientStylistic: "",
    //   clientExpertise: "",
    //   clientIndustry: "",
    //   audienceTitle: "",
    //   audienceCompanySize: "",
    //   audienceProblem: "",
    //   fileUpload: "",
    // });
  };
  return (
    <>
      <NavBar />
      <div className="front-page mt-80">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="heading">Create a Persona</h1>
          <hr />
          <label htmlFor="photo-upload">
            <img className="upload-img" src={ImageUpload} alt="" />
            <input
              name="photoUpload"
              value={fields.photoUpload}
              onChange={handleImageChange}
              id="photo-upload"
              type="file"
              accept="image/*"
              required
            />
          </label>
        </div>
        <form>
          <div className="row my-5 d-flex justify-content-end">
            <div className="col-md-5 form-input">
              <label htmlFor="exampleInputEmail1" className="form-label mx-5">
                ep-client-name <span className="red-star">*</span>
              </label>
              <input
                name="clientName"
                value={fields.clientName}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-client-title <span className="red-star">*</span>
              </label>
              <input
                name="clientTitle"
                value={fields.clientTitle}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5 form-input">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-client-company <span className="red-star">*</span>
              </label>
              <input
                name="clientCompany"
                value={fields.clientCompany}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-client-bio <span className="red-star">*</span>
              </label>
              <input
                name="clientBio"
                value={fields.clientBio}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5 form-input">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-client-social-link <span className="red-star">*</span>
              </label>
              <input
                name="clientSocialLink"
                value={fields.clientSocialLink}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-company-bio <span className="red-star">*</span>
              </label>
              <input
                name="companyBio"
                value={fields.companyBio}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5 form-input">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-client-web-link <span className="red-star">*</span>
              </label>
              <input
                name="clientWebLink"
                value={fields.clientWebLink}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-client-stylistic <span className="red-star">*</span>
              </label>
              <input
                name="clientStylistic"
                value={fields.clientStylistic}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5 form-input">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-client-expertise <span className="red-star">*</span>
              </label>
              <input
                name="clientExpertise"
                value={fields.clientExpertise}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-client-industry <span className="red-star">*</span>
              </label>
              <input
                name="clientIndustry"
                value={fields.clientIndustry}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5 form-input">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-audience-title <span className="red-star">*</span>
              </label>
              <input
                name="audienceTitle"
                value={fields.audienceTitle}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-audience-company-size <span className="red-star">*</span>
              </label>
              <input
                name="audienceCompanySize"
                value={fields.audienceCompanySize}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label mx-5"
              >
                ep-audience-problem <span className="red-star">*</span>
              </label>
              <input
                name="audienceProblem"
                value={fields.audienceProblem}
                onChange={handleChange}
                type="text"
                className="form-control mx-5"
                required
              />
            </div>
          </div>
        </form>
        <div className="container d-flex justify-content-center">
          <div className="upload-div px-5 py-3">
            {/* <label htmlFor="photo-upload" className="upload-file"> */}
            <label htmlFor="photo-upload" className="w-100 h-100">
              <img src={FileUpload} alt="" style={{ marginLeft: "35%" }} />
              <h5>Upload File</h5>
              <input
                name="fileUpload"
                value={fields.fileUpload}
                onChange={handleChange}
                id="file-upload"
                type="file"
                // accept="image/*"
                required
              />
            </label>
          </div>
        </div>
        <div className="d-flex my-5">
          <button
            className="btn btn-success save-btn"
            disabled={isSaveDisabled}
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="btn btn-primary next-btn"
            disabled={isNextDisabled}
            // onClick={handleNext}
          >
            <Link
              to={{
                pathname: "/mind",
                state: { prop1: fields, prop2: imagePreviewUrl },
              }}
              className="text-white text-decoration-none"
            >
              Next
            </Link>
          </button>
        </div>
        {/* {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" />} */}
        {/* <textarea
            placeholder="Human Notes"
            className="textArea form-control"
          ></textarea> */}
      </div>
      <Footer />
    </>
  );
};

export default FrontPage;
