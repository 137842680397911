import React from "react";
import SideNav from "../../sideNav/sideNav";
import NavBar from "../../NavBar/NavBar";
import AdminNav from "../../sideNav/AdminNav";
import { Link } from "react-router-dom";
import { ChakraProvider, Text } from "@chakra-ui/react";
import Feedback from "../../feedback/Feedback";

const Billing = () => {
  return (
    <>
      <Feedback />
      <NavBar />
      <div className={"persona-container-light"}>
        <div className="mainContainer">
          {" "}
          <input type="checkbox" id="sideNavDrawer-toggle" name="sideNavDrawer-toggle"/>
          <label for="sideNavDrawer-toggle" id="sideNavDrawer-toggle-label"></label>
          <AdminNav />
          <div className="mainContainer-right">
            <div className="d-flex justify-content-between">
            <ChakraProvider>
            <Text fontWeight={"bold"} fontSize={"1.4rem"}>
             Billing
            </Text>
            </ChakraProvider>
            <div className="dropdown hide-desktop">
              <button
                className="togglebtn1 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="icon-more-vertical"></i>
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  {" "}
                  <Link
                    className="w-100 dropdown-item"
                    to={'/admin/general'}
                  >
                    General
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to={"/admin/customization"}>
                  Customization
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/users">
                    Users
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/security">
                    Security
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/billing">
                    Billing
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/usage-stats">
                    Usage Stats
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>{" "}
      </div>
      </div>
    </>
  );
};

export default Billing;
