import React, { useState } from "react";
import "./ProfileTabs.css";
import facebook from "../../../Images/facebook.svg";
import instagram from "../../../Images/instagram.svg";
import twitter from "../../../Images/twitter.svg";
import youtube from "../../../Images/youtube.svg";
import tiktok from "../../../Images/tiktok.svg";
import linkedin from "../../../Images/linkedin.svg";
import edit from "../../../Images/edit-2.svg";
import {
  UpdatePersonalDetailsService,
  getPersonalDetailsService,
} from "../../../Service/service";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import LoadingSpinner from "../../../Assests/loaderspinner.gif";
import { RotatingLines } from "react-loader-spinner";
function PersonalDetail() {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [biography, setBiography] = useState("");
  const [personalDetails, setPersonalDetails] = useState();
  const [socialLinks, setSocialLinks] = useState({
    Facebook: "",
    TikTok: "",
    Instagram: "",
    Twitter: "",
    Linkedin: "",
    Youtube: "",
  });

  const validateURL = (url) => {
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:facebook|twitter|instagram|linkedin|tikTok|youtube)\.com\/(?:(?:\w)*#!\/)?(?:[\w\-\.]*)(?:\/[\w\-\.]*)?(?:\?.*)?$/;
    return regex.test(url);
  };

  const updatePersonalDetailsHandler = async () => {
    setLoading(true);

    const invalidLinks = [];
    let isValid = true;

    Object.entries(socialLinks).forEach(([platform, url]) => {
      if (url !== "" && !validateURL(url)) {
        isValid = false;
        invalidLinks.push(platform);
      }
    });

    if (isValid) {
      const res = await UpdatePersonalDetailsService(biography, socialLinks);
      if (res.success) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: res.message,
        });
        getPersonalDetailsService().then((res) => {
          if (res.success) {
            setPersonalDetails(res.data);
          }
        });
        setIsEdit(false);
      }
    } else {
      setLoading(false);
      const errorMessages = invalidLinks.map(
        (platform) =>
          `Please add valid ${
            platform.charAt(0).toUpperCase() + platform.slice(1)
          } URL`
      );

      Swal.fire({
        icon: "error",
        title: "Invalid URLs",
        html: errorMessages.join("<br>"),
        confirmButtonText: "OK",
      });
    }
  };

  const handleChangeSocialLinks = (event) => {
    const { name, value } = event.target;
    setSocialLinks((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getPersonalDetailsService().then((res) => {
      if (res.success) {
        setPersonalDetails(res.data);
        setBiography(res.data[0].text);
      }
    });
  }, []);

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width='50'
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="bg-white mb-16 personal-details moment-border p-0 moment-text d-flex justify-content-between flex-column">
        {!isEdit ? (
          <>
            <div className="py-3 mt-2 d-flex justify-content-between color-darkGray box-header">
              <span>Client Biography</span>
              <div role="button" onClick={() => setIsEdit(true)}>
                <img src={edit} className="edit-img" alt="" />
              </div>
            </div>
            <div className="py-5">
              <p>
                {personalDetails?.length > 0 && personalDetails[0]?.text !== ""
                  ? personalDetails[0]?.text
                  : "No biography yet"}
              </p>
            </div>
            <div className="d-flex px-4 py-2 mb-4 justify-content-between social-img">
              {/* facebook */}
              <a
                className={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Facebook !== ""
                    ? "cusror-pointer"
                    : "cursor-not-allowed"
                }
                href={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Facebook !== ""
                    ? personalDetails[0]?.socialLinks?.Facebook
                    : "#"
                }
                target={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Facebook !== ""
                    ? "_blank"
                    : "_parent"
                }
              >
                <img src={facebook} alt="" />
              </a>
              {/* twitter */}
              <a
                className={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Twitter !== ""
                    ? "cusror-pointer"
                    : "cursor-not-allowed"
                }
                href={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Twitter !== ""
                    ? personalDetails[0]?.socialLinks?.Twitter
                    : "#"
                }
                target={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Twitter !== ""
                    ? "_blank"
                    : "_parent"
                }
              >
                {" "}
                <img src={twitter} alt="" />
              </a>
              {/* instagram */}
              <a
                className={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Instagram !== ""
                    ? "cusror-pointer"
                    : "cursor-not-allowed"
                }
                href={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Instagram !== ""
                    ? personalDetails[0]?.socialLinks?.Instagram
                    : "#"
                }
                target={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Instagram !== ""
                    ? "_blank"
                    : "_parent"
                }
              >
                {" "}
                <img src={instagram} alt="" />
              </a>
              {/* linkedin */}
              <a
                className={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Linkedin !== ""
                    ? "cusror-pointer"
                    : "cursor-not-allowed"
                }
                href={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Linkedin !== ""
                    ? personalDetails[0]?.socialLinks?.Linkedin
                    : "#"
                }
                target={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Linkedin !== ""
                    ? "_blank"
                    : "_parent"
                }
              >
                {" "}
                <img src={linkedin} alt="" />
              </a>
              {/* tikTok */}
              <a
                className={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.TikTok !== ""
                    ? "cusror-pointer"
                    : "cursor-not-allowed"
                }
                href={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.TikTok !== ""
                    ? personalDetails[0]?.socialLinks?.TikTok
                    : "#"
                }
                target={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.TikTok !== ""
                    ? "_blank"
                    : "_parent"
                }
              >
                {" "}
                <img src={tiktok} alt="" />
              </a>
              {/* youtube */}
              <a
                className={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Youtube !== ""
                    ? "cusror-pointer"
                    : "cursor-not-allowed"
                }
                href={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Youtube !== ""
                    ? personalDetails[0]?.socialLinks?.Youtube
                    : "#"
                }
                target={
                  personalDetails?.length > 0 &&
                  personalDetails[0]?.socialLinks?.Youtube !== ""
                    ? "_blank"
                    : "_parent"
                }
              >
                {" "}
                <img src={youtube} alt="" />
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="py-3 mt-2 d-flex justify-content-between color-darkGray box-header">
              <span>Client Biography</span>
            </div>
            <div className="form-field mt-4">
                <textarea
                  placeholder="Write biography here"
                  className="w-100 p-2 rounded border border-dark"
                  value={biography}
                  onChange={(e) => setBiography(e.target.value)}
                ></textarea>
            </div>
            <div className="socialMediaFields edit-div">
                <div className="form-field">
                  <label>Facebook</label>
                  <input
                    name="Facebook"
                    onChange={handleChangeSocialLinks}
                    defaultValue={
                      personalDetails && personalDetails.length > 0
                        ? personalDetails[0].socialLinks?.Facebook
                        : socialLinks.Facebook
                    }
                    type="text"
                    className="form-control"
                    placeholder="#"
                  />
                </div>

                <div className="form-field">
                  <label>Twitter</label>
                  <input
                    name="Twitter"
                    onChange={handleChangeSocialLinks}
                    defaultValue={
                      personalDetails && personalDetails.length > 0
                        ? personalDetails[0].socialLinks?.Twitter
                        : socialLinks.Twitter
                    }
                    type="text"
                    className="form-control"
                    placeholder="#"
                  />
                </div>        
                <div className="form-field">
                  <label>Instagram</label>
                  <input
                    name="Instagram"
                    onChange={handleChangeSocialLinks}
                    defaultValue={
                      personalDetails && personalDetails.length > 0
                        ? personalDetails[0].socialLinks?.Instagram
                        : socialLinks.Instagram
                    }
                    type="text"
                    className="form-control"
                    placeholder="#"
                  />
                </div>
           
                <div className="form-field">
                  <label>LinkedIn</label>
                  <input
                    name="Linkedin"
                    onChange={handleChangeSocialLinks}
                    defaultValue={
                      personalDetails && personalDetails.length > 0
                        ? personalDetails[0].socialLinks?.Linkedin
                        : socialLinks.Linkedin
                    }
                    type="text"
                    className="form-control"
                    placeholder="#"
                  />
                </div>
                <div className="form-field">
                  <label>TikTok</label>
                  <input
                    name="TikTok"
                    onChange={handleChangeSocialLinks}
                    defaultValue={
                      personalDetails && personalDetails.length > 0
                        ? personalDetails[0].socialLinks?.TikTok
                        : socialLinks.TikTok
                    }
                    type="text"
                    className="form-control"
                    placeholder="#"
                  />
                </div>
                <div className="form-field">
                  <label>YouTube</label>
                  <input
                    name="Youtube"
                    onChange={handleChangeSocialLinks}
                    defaultValue={
                      personalDetails && personalDetails.length > 0
                        ? personalDetails[0].socialLinks?.Youtube
                        : socialLinks.Youtube
                    }
                    type="text"
                    className="form-control"
                    placeholder="#"
                  />
                </div>
            </div>
            <div className="footer-div mt-4 d-flex justify-content-end gap-3 py-4">
              <div
                role="button"
                className="footer-cancel-btn d-flex justify-content-center align-items-center"
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                Cancel
              </div>
              <div
                role="button"
                className="footer-update-btn d-flex justify-content-center align-items-center"
                onClick={updatePersonalDetailsHandler}
              >
                Update
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PersonalDetail;
