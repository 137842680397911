import React, { useContext, useState } from "react";
import SideNav from "../../sideNav/sideNav";
import NavBar from "../../NavBar/NavBar";
import Feedback from "../../feedback/Feedback";
import "./brandguide.css";
import PersonaChatContext from "../../../context/ChatContext";
import { useEffect } from "react";
import SelectTheme from "./components/SelectTheme";
import LandingPage from "./components/LandingPage";
import {  GetBrandGuideInfo } from "../../../Service/service";
const BrandGuide = () => {

  const [step, setStep] = useState(0);
  const [isFlag, setIsFlag]= useState(false)
  const [responseData, setResponseData] =useState({})
  const [formData, setFormData] = useState({
    title: '',
    isMale: 'Brand Guide - Male',
  });
  const [textData,setTextData] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [isBrandGuide, setIsBrnadGuide]= useState(false)
  const { updateChatArray } = useContext(PersonaChatContext);
 
 



  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  // const user_info = JSON.parse(localStorage.getItem("user_info"));
  useEffect(() => {
    setIsFlag(false)
    setFormData({
      title: '',
      isMale: 'Brand Guide - Male',
    })
    setIsLoading(true)
    setResponseData({})
    setStep(0)
    GetBrandGuideInfo(activePersona?._id).then((result)=>{
      setIsFlag(true)
      if(result.success){
        setIsBrnadGuide(true)
        setStep(1)
        setResponseData(result?.data)
        setTextData(result?.data?.brandGuideQueries)
      }
      else{
        setIsBrnadGuide(false)
      }
      setIsLoading(false)
    }).catch((error)=>{
      console.log(error)
    })
  }, [updateChatArray])
  
  const NextStepHandle=()=>{
  setStep(1)  
  }
  return (
    <>
      <Feedback />
      <NavBar />
      <div className="persona-container-light ">
        <div className="mainContainer">
            {" "}
            <input type="checkbox" id="sideNavDrawer-toggle" name="sideNavDrawer-toggle"/>
            <label htmlFor="sideNavDrawer-toggle" id="sideNavDrawer-toggle-label"></label>
            <SideNav />
          <div className="mainContainer-right" style={{overflow:'hidden'}}>
            {/* {/ massive alliance features /} */}
            {
           isFlag && !isBrandGuide && step===0 && 
              <SelectTheme
                NextStepHandle={NextStepHandle}
                formData={formData}
                setFormData={setFormData}
              />
            }
            {
              isFlag && step===1 &&
                <LandingPage
                formData={formData}
                // selectedProject={selectedProject}
                textData={textData}
                setTextData={setTextData}
                setIsBrnadGuide={setIsBrnadGuide}
                isBrandGuide={isBrandGuide}
                LoadingState={isLoading}
                responseData={responseData}
                setResponseData={setResponseData}
                />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandGuide;
