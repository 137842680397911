import React from "react";
import ImageUpload from "../../Images/upload.svg";
import { Link } from "react-router-dom";
const UploadAndNextBtn = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h1 className="my-5">Mind</h1>
      <label htmlFor="photo-upload" className="my-2">
        <img
          // style={{ marginLeft: "100px" }}
          className="upload-img"
          src={ImageUpload}
          alt=""
        />
      </label>
      <input
        name="field1"
        // value={fields.field1}
        // onChange={handleChange}
        id="photo-upload"
        type="file"
        accept="image/*"
      />
      <Link to="/chat">
        <button className="btn btn-primary my-5">Next</button>
      </Link>
    </div>
  );
};

export default UploadAndNextBtn;
