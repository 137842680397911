import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        padding: theme.spacing(1), // Added padding for better spacing
        fontSize: '12px', // Set font size to 20px
        whiteSpace: 'pre-line', // Preserve line breaks
        '& span': {
          marginLeft: '8px', // Add spacing between words
        },
      },
    }));

function ConfirmTooltip({ children, handleRemoveBlock, keyValue }) {
  const [open, setOpen] = useState(false);

  const handleRemove = () => {
    handleRemoveBlock(keyValue);
    setOpen(false); // Close the tooltip after action
  };

  return (
    <div onClick={() => setOpen(!open)} style={{ display: 'inline-block' }}>
      <BootstrapTooltip
        open={open}
        title={
          <div>
            Are you sure?
            <span onClick={handleRemove} role="button" className="text-danger">
              Remove
            </span>{' '}
            <span role="button" onClick={() => setOpen(false)}>Cancel</span>
          </div>
        }
        onClose={() => setOpen(false)}
      >
        {children}
      </BootstrapTooltip>
    </div>
  );
}

export default ConfirmTooltip;
