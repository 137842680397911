import { Center, ChakraProvider, Divider, Stack, Text } from "@chakra-ui/react";
import React from "react";

const Subscriptions = () => {
  return (
    <ChakraProvider>
      <Stack
        className="subscription-container"
        border={"1px solid #CED3D9"}
        rounded={"lg"}
        marginTop={'1rem'}
        background={'rgba(246, 246, 246, 0.50)'}
        p={6}
      >
        <h1>Plan Details</h1>
        <Stack direction={{base:"column", md:"row"}} justifyContent={"space-between"}>
          <Stack
            justifyContent={"space-between"}
            w={{base:"100%",md:"49%"}}
            spacing={8}
            direction="row"
            pr={{base:0,md:10}}
          >
            <Stack spacing={3}>
              <Text fontSize={"1rem"} fontWeight={"300"} mb='0' color={'#76828B'}>Current Plan</Text>
              <h1>Executive</h1>
            </Stack>
            <Stack spacing={3}>
              <Text fontSize={"1rem"} fontWeight={"300"} mb='0' color={'#76828B'}>Period</Text>
              <h1>Monthly</h1>
            </Stack>
          </Stack>
          <Stack borderRight={"1px solid #CED3D9"}></Stack>
          <Stack px={{base:0,md:10}} w={{base:"100%",md:"49%"}}>
            <Stack spacing={3}>
              <Text fontSize={"1rem"} fontWeight={"medium"} mb='0'>Renewal amount</Text>
              <h1 className="renewal-amount">
              $0.00 <span>$499.99</span> 
              </h1>
            </Stack>
            <Text pt={3} fontSize={"1rem"} fontWeight={"300"} mb='0' color={'#76828B'}>
              Included In the Executive Leadership Branding Subscription
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </ChakraProvider>
  );
};

export default Subscriptions;
